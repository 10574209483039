<template>
  <rp-container-small v-if="isLoaded">
    <RSimpleCard
      :title="`איחוד מאגרים - ${stock.name}`"
      subtitle="איזה מאגר תרצו לאחד לתוך המאגר הזה?"
    >
      <div class="stocksList mb-6 mt-4">
        <div
          v-for="stockItem in stocksToMerge"
          :key="stockItem.id"
          class="stocksList-item"
          :class="{
            'stocksList-item--selected': stockIdToMerge === stockItem.id,
            'is-hidden':
              stockItem.id === stock.id || !stockItem.data.categories.length,
          }"
          @click="stockIdToMerge = stockItem.id"
        >
          <div class="stocksList-item-name">
            {{
              isStockFromLibrary(stockItem.id)
                ? `(המאגרים של רפורטו) - ${stockItem.name}`
                : `(המאגרים שלי) - ${stockItem.name}`
            }}
          </div>
        </div>
      </div>

      <RButton
        color="primary"
        lg
        :disabled="!stockIdToMerge"
        :loading="isLoading"
        block
        @click="mergeStocks"
      >
        איחוד המאגרים
      </RButton>
    </RSimpleCard>

    <div v-if="stockToMerge" style="margin-top: 20px">
      <div class="rp-h5">זה מה שנעשה בזמן איחוד המאגרים:</div>
      <div class="rp-p2">
        נאחד את המאגר >
        <b style="color: var(--rp-color-orange)">{{ stock.name }}</b>
        <br />
        והמאגר >
        <b style="color: var(--rp-color-teal)">{{ stockToMerge.name }}</b>
        <br />
        וניצור מהם מאגר חדש ומאוחד בשם >
        <b style="color: var(--rp-color-purple)">
          איחוד מאגרים -
          {{ stock.name }}
          -
          {{ stockToMerge.name }}
        </b>
        <br />

        אם נראה שיש ממצא שנמצא בשני המאגרים, נשאיר את הממצא מהמאגר הראשון.
        <br />
        אחר כך, תצטרכו לעבור על התבניות שלכם ולהגדיר את המאגר החדש המאוחד.
      </div>
    </div>
  </rp-container-small>
  <page-loader v-else />
</template>
<script>
import { mapGetters } from "vuex";
import StocksApi from "@/api/StocksApi";
import PageLoader from "@/components/PageLoader.vue";

export default {
  components: { PageLoader },
  data() {
    return {
      isLoading: false,
      isLoaded: false,
      stockId: this.$route.params.id,
      stockIdToMerge: null,
    };
  },
  computed: {
    ...mapGetters("stocks", ["stocks", "stocksLibrary"]),
    stocksToMerge() {
      return [...this.stocks, ...this.stocksLibrary];
    },
    stockToMerge() {
      let stock = null;

      let stocksToUse = this.isStockFromLibrary(this.stockIdToMerge)
        ? this.stocksLibrary
        : this.stocks;

      stocksToUse.forEach((stockItem) => {
        if (stockItem.id === this.stockIdToMerge) {
          stock = stockItem;
        }
      });

      return stock;
    },
    stock() {
      let stock = null;

      this.stocks.forEach((stockItem) => {
        if (stockItem.id === this.stockId) {
          stock = stockItem;
        }
      });

      return stock;
    },
  },
  async created() {
    await this.$store.dispatch("stocks/fetchStocks");
    await this.$store.dispatch("stocks/fetchStocksLibrary");
    this.isLoaded = true;
  },
  methods: {
    isStockFromLibrary(stockId) {
      let result = false;
      this.stocksLibrary.forEach((stock) => {
        if (stock.id === stockId) {
          result = true;
        }
      });
      return result;
    },
    async mergeStocks() {
      this.isLoading = true;
      this.$toast.info("הפעולה עשויה לקחת 60 שניות אנא המתינו בדף זה...");
      let loader = this.$loading.show();
      try {
        let stockMerged = await StocksApi.mergeStocks(
          this.stockId,
          this.stockIdToMerge,
          this.isStockFromLibrary(this.stockIdToMerge)
        );
        this.isLoading = false;
        loader.hide();
        this.$toast.success("המאגרים אוחדו בהצלחה למאגר אחד חדש!");
        setTimeout(() => {
          this.$toast.success("אתם מועברים לעמוד המאגר המאוחד שיצרנו עכשיו...");
        }, 900);

        setTimeout(() => {
          this.$router.push({
            name: "stocks.edit",
            params: { id: stockMerged.id },
          });
        }, 2500);
      } catch (err) {
        this.isLoading = false;
        loader.hide();
        this.$toast.error("שגיאה בתהליך איחוד המאגרים");
        this.$toast.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
.stocksList {
  &-item {
    padding: 10px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    transition: all 0.3s;

    &:not(:last-child) {
      border-bottom: 0;
    }

    &:hover:not(.stocksList-item--selected) {
      background-color: #f5f5f5;
    }

    &:active:not(.stocksList-item--selected) {
      background-color: #e0e0e0;
    }

    &--selected {
      background-color: #e8d9fd;
      color: #5e21d2;
    }
  }
}
</style>
