<template>
  <header
    class="r-app-header"
    :class="{
      'is-open': isMenuOpen,
    }"
  >
    <button class="r-app-header-toggle" @click="openMenu">
      <span class="r-app-header-toggle__lines">
        <span class="line" data-line="1"></span>
        <span class="line" data-line="2"></span>
        <span class="line" data-line="3"></span>
      </span>
    </button>

    <div class="r-app-header-logo u-desktopMode-only">
      <router-link to="/" @click="closeMenu">
        <img :src="headerLogo" alt="logo" />
      </router-link>
    </div>

    <section ref="mobileMenu" class="r-app-header-mobileMenu">
      <div class="r-app-header-logo u-mobileMode-only">
        <router-link to="/" @click="closeMenu">
          <img :src="headerLogo" alt="logo" />
        </router-link>
      </div>

      <nav class="r-app-header-menu">
        <div
          v-for="item in items"
          :key="item.title"
          class="r-app-header-menu-item"
        >
          <router-link :to="item.link" @click="closeMenu">
            <span v-if="item.icon" class="r-app-header-menu-item-icon">
              <v-icon>{{ item.icon }}</v-icon>
            </span>
            {{ item.title }}
          </router-link>
        </div>

        <div class="r-app-header-menu-item u-mobileMode-only">
          <a href="#chat" type="button" @click="openSupport">
            <span class="r-app-header-menu-item-icon chat-item-icon">
              <img src="@/assets/images/icon-msg.svg" />
            </span>
            וואטסאפ איתנו
          </a>
        </div>
      </nav>
    </section>

    <div class="r-app-header-actions">
      <RButton
        class="u-desktopMode-only chat-menu-item"
        lg
        text
        color="header"
        icon-before="mdi-chat-outline"
        @click="openSupport"
        >וואטסאפ איתנו</RButton
      >

      <RActions>
        <template #activator="{ props }">
          <div class="topAvatar mr-2" v-bind="props">
            <div class="topNameAndCompany">
              <div class="topName text-ellipsis">
                {{ shortenUserName(displayName) }},
              </div>
              <div class="topCompany text-ellipsis" style="margin-top: -3px">
                {{ shortenCompanyName(displayCompanyName) }}
              </div>
            </div>

            <RIcon small>mdi-chevron-down</RIcon>
          </div>
        </template>
        <RAction
          text="הפרופיל שלי"
          icon="mdi-account"
          :to="{ name: 'profile' }"
        />
        <RAction
          v-if="userHasManyCompanies"
          :to="{ name: 'change-company' }"
          text="החלפת חברה"
          icon="mdi-swap-horizontal"
        />
        <RAction text="התנתק" icon="mdi-power" @click="logout" />
      </RActions>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { supportChatUrl } from "@/common/settings";
import { trackEvent } from "@/services/TrackingService";
import reportoLogo from "@/assets/images/reporto-logo.svg";

export default {
  data() {
    return {
      isMenuOpen: false,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "demoMeetingUrl",
      "companyId",
      "company",
      "companies",
      "displayName",
      "displayCompanyName",
      "displayNameAcronym",
      "companyLogoUrl",
    ]),
    headerLogo() {
      return this.companyLogoUrl || reportoLogo;
    },
    userHasManyCompanies() {
      return this.companies.length > 1;
    },
    items() {
      let items = [
        // {
        //   title: "ראשי",
        //   icon: "mdi-home",
        //   isOnlyMobile: true,
        //   link: "/",
        // },
        {
          title: "דוחות",
          icon: "mdi-file-document-outline",
          link: "/reports",
        },
        // {
        //   title: "הפרופיל שלי",
        //   icon: "mdi-account",
        //   link: "/profile",
        // },
      ];

      // if (this.userHasManyCompanies) {
      //   items.push({
      //     title: "החלפת חברה",
      //     icon: "mdi-swap-horizontal",
      //     link: "/change-company",
      //   });
      // }

      items.push({
        title: "פרויקטים",
        icon: "mdi-folder-outline",
        link: "/projects",
      });

      if (this.$checkRole(["admin", "owner"])) {
        items.push({
          title: "תבניות",
          icon: "mdi-view-compact-outline",
          link: "/report-templates",
        });

        items.push({
          title: "משתמשים",
          icon: "mdi-account-multiple-outline",
          link: "/users",
        });

        items.push({
          title: "מאגרים",
          icon: "mdi-view-grid-outline",
          link: "/stocks",
        });

        items.push({
          title: "עיצובים",
          icon: "mdi-tune-vertical",
          link: "/preferences",
        });

        items.push({
          title: "דוחות חברה",
          icon: "mdi-file-document-outline",
          link: "/company-reports",
        });
      }

      if (this.$checkRole(["owner"])) {
        items.push({
          title: "הגדרות חברה",
          icon: "mdi-cog-outline",
          link: "/company-settings",
        });
      }

      items.push({
        title: "סרטוני הדרכה",
        icon: "mdi-video-outline",
        link: "/videos",
        style: "width: 16px; height: 16px;",
      });

      return items;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutsideMobileMenu);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutsideMobileMenu);
  },
  methods: {
    openSupport() {
      window.open(supportChatUrl, "_blank");
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    openMenu() {
      setTimeout(() => {
        this.isMenuOpen = true;
      }, 1);
    },
    handleClickOutsideMobileMenu(event) {
      if (
        this.isMenuOpen &&
        this.$refs.mobileMenu &&
        !this.$refs.mobileMenu.contains(event.target)
      ) {
        this.isMenuOpen = false;
      }
    },
    trackEvent,
    ...mapActions("auth", ["logout"]),
    shortenUserName(str) {
      let n = 25;
      if (window.innerWidth < 1200) {
        n = 19;
      }
      if (window.innerWidth < 400) {
        n = 20;
      }
      if (window.innerWidth < 380) {
        n = 18;
      }
      return str.length > n ? str.slice(0, n - 1) + "..." : str;
    },
    shortenCompanyName(str = "") {
      let n = 30;
      if (window.innerWidth < 1200) {
        n = 28;
      }
      if (window.innerWidth < 400) {
        n = 28;
      }
      if (window.innerWidth < 380) {
        n = 24;
      }
      return str.length > n ? str.slice(0, n - 1) + "..." : str;
    },
  },
};
</script>
