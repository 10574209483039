<template>
  <RModal v-model="isPreviewReportModalOpen" title="תצוגה מקדימה" size="lg">
    <div
      v-if="isLoaded"
      class="report-preview-wrapper"
      :style="reportPreviewWrapperStyle"
    >
      <!-- eslint-disable vue/no-v-html -->
      <!--        <iframe-->
      <!--          :srcdoc="reportPreviewHtml"-->
      <!--          frameborder="0"-->
      <!--          style="width: 100%; height: 600px"-->
      <!--        ></iframe>-->
      <div
        class="report-preview-inner text-right"
        v-html="reportPreviewHtml"
      ></div>
      <!--eslint-enable-->
    </div>

    <div v-if="!isLoaded" class="text-center">
      <RIconLoading v-if="isLoading" size="30" class="mt-4 mb-5" />
      <div v-if="showError" class="text-center" style="color: #000">
        <div class="mb-2">
          חלה שגיאה בטעינת התצוגה המקדימה.
          <br />
          אם הבעיה חוזרת על עצמה צרו איתנו קשר.
        </div>
        <RButton outlined class="mt-1 mb-5" @click="getReportPreviewHtml">
          ניסיון נוסף
        </RButton>
      </div>
    </div>
  </RModal>
</template>

<script>
import ReportsApi from "@/api/ReportsApi";

export default {
  props: {
    reportId: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      isLoaded: false,
      isLoading: false,
      windowWidth: window.innerWidth,
      reportPreviewHtml: "",
      showError: false,
      isPreviewReportModalOpen: false,
    };
  },
  computed: {
    reportPreviewWrapperStyle() {
      let zoom = 0.85;
      if (this.windowWidth < 700) {
        zoom = this.windowWidth / (773 + 100);
      }
      return {
        zoom: zoom,
      };
    },
  },
  methods: {
    init() {
      this.getReportPreviewHtml();
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    },
    openModal() {
      this.init();
      this.isPreviewReportModalOpen = true;
    },
    async getReportPreviewHtml() {
      this.showError = false;
      this.isLoading = true;
      this.isLoaded = false;

      try {
        const data = await ReportsApi.getReportPreview(this.reportId);
        this.reportPreviewHtml = data;
        this.isLoaded = true;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.isLoaded = false;
        this.showError = true;
        this.$toast.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss">
.report-preview-wrapper {
  all: revert;
  display: flex;
  justify-content: center;
}
.report-preview-inner {
  width: 773px;
}
.report-preview-wrapper {
  pagebreak {
    border-top: 3px dashed #c2c1c1;
    display: block;
    position: relative;
    margin: 90px 0;
    @media (max-width: 767px) {
      border-top-width: 4px;
    }
  }
  pagebreak:before {
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    border: 1px solid #c2c1c1;
    color: #5b5a5a;
    position: absolute;
    background: #fafafa;
    content: "שבירת עמוד";
    padding: 10px 25px;
    line-height: 1.3;
    @media (max-width: 767px) {
      font-size: 1.2em;
    }
  }
}
</style>
