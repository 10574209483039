<template>
  <div class="page">
    <div
      v-for="item in items"
      :key="item.id"
      :class="itemClass(item)"
      :style="itemStyle(item)"
      @click="$emit('open-edit-item', item)"
    >
      <div class="reportTemplateItemPreview-header">
        {{ item.settings.label }}
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="item.type === 'wysiwyg' && item.settings.content"
        v-html="item.settings.content"
      ></div>
      <!-- eslint-enable -->

      <template v-else-if="item.type === 'userSignature'">
        <div :style="`text-align:${item.settings.signatureAlign};margin:10px;`">
          <span class="reportTemplateItemPreview-inlinePlaceholder">
            חתימת משתמש תופיע כאן
          </span>
        </div>
      </template>

      <template v-else-if="item.type === 'headline'">
        <h2 :style="`text-align:${item.settings.headlineAlign}`">
          {{ item.settings.headline }}
        </h2>
      </template>

      <div v-else-if="item.type === 'image' && item.settings.imageUrl">
        <h2 :style="`text-align:${item.settings.headlineAlign}`">
          {{ item.settings.headline }}
        </h2>

        <div :style="`text-align:${item.settings.imageAlign}`">
          <img
            :src="getFullImageUrl(item.settings.imageUrl)"
            alt=""
            :style="`width:${item.settings.imageWidth}px`"
          />
        </div>
      </div>

      <div
        v-else-if="item.type === 'signature'"
        :style="`text-align:${item.settings.signatureAlign}`"
      >
        <img
          v-if="item.settings.signature"
          :src="getFullImageUrl(item.settings.signature)"
          alt=""
          :style="`width:${item.settings.signatureWidth}px`"
        />

        <span v-else class="reportTemplateItemPreview-inlinePlaceholder">
          חתימת תופיע כאן
        </span>
      </div>

      <table
        v-else-if="
          item.type === 'sideToSideTwoTexts' &&
          (item.settings.textLeft || item.settings.textRight)
        "
        style="border-collapse: collapse; width: 100%"
      >
        <tbody>
          <tr>
            <td style="text-align: right; vertical-align: top; width: 50%">
              <p v-html="item.settings.textRight"></p>
            </td>
            <td style="text-align: left; vertical-align: top; width: 50%">
              <p v-html="item.settings.textLeft"></p>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-else-if="item.type === 'spacer'"
        :style="'height:' + item.settings.size + 'px;width:100%;'"
      ></div>

      <div v-else class="reportTemplateItemPreview-placeholder">
        <div :style="`text-align:center;margin:10px;`">
          <span
            class="reportTemplateItemPreview-inlinePlaceholder block"
            style="min-height: 50px"
          >
            {{ item.settings.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Utils } from "@/common/Utils";

export default {
  props: {
    reportTemplate: {
      type: Object,
      default: null,
    },
    editorMode: {
      type: String,
      default: null,
    },
    editorCurrentItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    items() {
      return Utils.convertObjectToArrayWithOrder(
        this.reportTemplate.data.items
      );
    },
  },
  methods: {
    getFullImageUrl(path) {
      return this.$Reporto.globals.MEDIA_URL + path;
    },
    itemStyle(item) {
      // First get from global settings
      let paddingTop = this.reportTemplate.itemPaddingTop
        ? this.reportTemplate.itemPaddingTop
        : 0;
      let paddingBottom = this.reportTemplate.itemPaddingBottom
        ? this.reportTemplate.itemPaddingBottom
        : 0;

      // Try to override global settings from custom settings inside item
      if (item.settings.isPaddingsCustom) {
        if (item.settings.paddingTop !== null) {
          paddingTop = item.settings.paddingTop;
        }
        if (item.settings.paddingBottom !== null) {
          paddingBottom = item.settings.paddingBottom;
        }
      }

      return {
        paddingTop: paddingTop + "px",
        paddingBottom: paddingBottom + "px",
      };
    },
    itemClass(item) {
      let classes = [
        "reportTemplateItemPreview",
        `reportTemplateItemPreview-${item.type}`,
      ];
      if (
        this.editorMode === "item" &&
        this.editorCurrentItem.item &&
        this.editorCurrentItem.item.id === item.id
      ) {
        classes.push("is-current");
      }

      if (item.settings.isDisabled) {
        classes.push("u-hidden");
      }

      return classes.join(" ");
    },
  },
};
</script>

<style lang="scss">
.page,
.page * {
  all: revert;
}

.page {
  color: #000;
  width: 100%;

  p,
  ul,
  ol,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000;
  }

  //ol {
  //  list-style-position: inside;
  //}
}

.reportTemplateItemPreview {
  cursor: pointer;
  position: relative;
  --edit-color: #6711f1;

  border: 1px dashed transparent; // #dadada
  padding: 0;

  &-header {
    position: absolute;
    transition: 0.2s;
    opacity: 0;
    top: 0;
    right: 0;
    background: #eee;
    color: #000;
    padding: 4px 6px;
    line-height: 1em;
    font-weight: 500;
    font-size: 12px;
    z-index: 11;
  }

  &.is-current {
    border-color: #6711f1;
    .reportTemplateItemPreview-header {
      opacity: 1;
      background: #6711f1;
      color: #fff;
    }
  }

  &:hover:not(.is-current) {
    border-color: #9f9f9f;
    .reportTemplateItemPreview-header {
      opacity: 1;
    }
  }

  &-inlinePlaceholder {
    background: #eee;
    color: #000;
    padding: 8px 10px;
    line-height: 1em;
    font-weight: 500;
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &.block {
      width: calc(100% - 20px);
      display: flex;
    }
  }
}

.reportTemplateItemPreview-placeholder {
  min-height: 30px;
}
</style>
