<template>
    <impersonate />
    <VApp>
      <div class="r-the-main-layout">
        <AppHeader />
        <VMain v-if="isLoggedIn && !shouldShowSyncScreen">
          <RouterView></RouterView>
        </VMain>
      </div>
      <AppSync />
    </VApp>
</template>

<script>
import { mapGetters } from "vuex";
import Impersonate from "@/components/Impersonate";
import AppHeader from "@/components/AppHeader.vue";
import AppSync from "./AppSync.vue";

export default {
  components: {
    AppHeader,
    Impersonate,
    AppSync,
  },
  data: () => ({
    drawer: false,
  }),

  computed: {
    ...mapGetters("auth", ["user", "company", "isLoggedIn"]),
    ...mapGetters("reportQue", ["showSyncScreen"]),
    shouldShowSyncScreen() {
      return this.showSyncScreen;
    },
  },

  async created() {
    if (this.$checkRole(["admin", "owner"])) {
      await this.$store.dispatch("users/fetchUsers");
    }
  },
};
</script>
