<template>
  <div class="d-flex justify-center">
    <template v-if="activated">
      <div class="add-section-container px-4 pt-6 pb-2 rounded-lg app-border">
        <RIcon
          class="add-section-close-btn mr-3"
          color="primary"
          outlined
          @click="activated = false"
          >mdi-close</RIcon
        >

        <div>
          <div class="subtitle-2 text-center mb-1">כמה עמודות?</div>

          <div class="columns">
            <div class="column" @click="createSection(1)">
              <svg
                width="90"
                height="45"
                viewBox="0 0 90 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="90" height="45" fill="white" />
                <rect width="90" height="45" fill="#D5DADE" />
              </svg>
            </div>
            <div class="column" @click="createSection(2)">
              <svg
                width="90"
                height="45"
                viewBox="0 0 90 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="90" height="45" fill="white" />
                <rect width="43.5" height="45" fill="#D5DADE" />
                <rect x="46.5" width="43.5" height="45" fill="#D5DADE" />
              </svg>
            </div>
            <div class="column" @click="createSection(3)">
              <svg
                width="90"
                height="45"
                viewBox="0 0 90 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="90" height="45" fill="white" />
                <rect width="28" height="45" fill="#D5DADE" />
                <rect x="31" width="28" height="45" fill="#D5DADE" />
                <rect x="62" width="28" height="45" fill="#D5DADE" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <RButton
        class="mx-1"
        outlined
        color="primary"
        sm
        icon-before="mdi-plus"
        @click="activated = true"
      >
        הוספת אזור
      </RButton>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    activated: false,
  }),
  methods: {
    createSection(numOfColumns) {
      this.$emit("create-section", numOfColumns);
      this.activated = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-section-container {
  position: relative;
  display: flex;
  width: 325px;
  background: #fff;
  align-items: center;

  .add-section-close-btn {
    position: absolute;
    right: -5px;
    top: 5px;
    cursor: pointer;
  }
}

.columns {
  display: flex;
  margin: -5px;
}
.column {
  margin: 5px;
  cursor: pointer;
  rect {
    transition: 0.2s;
  }
  &:hover [fill="#D5DADE"] {
    fill: #8c9fad;
  }
}
</style>
