<template>
  <div class="r-auth-otp">
    <v-otp-input
      ref="otpInput"
      :length="4"
      type="number"
      v-model="internalValue"
    ></v-otp-input>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      internalValue: this.modelValue,
    };
  },

  watch: {
    internalValue(value) {
      this.$emit("update:modelValue", value);
    },
  },

  mounted() {
    setTimeout(() => {
      this.focusFirstInput();
    }, 300);
  },

  methods: {
    focusFirstInput() {
      if (
        this.$refs.otpInput &&
        typeof this.$refs.otpInput.focus === "function"
      ) {
        this.$refs.otpInput.focus();
      }
    },
  },
};
</script>

<style lang="scss">
.r-auth-otp {
  .v-otp-input {
    direction: ltr;
    max-width: 272px;
    margin: 0 auto;
  }
  input {
    width: 60px;
    height: 60px;
    //margin: 0 5px;
    //text-align: center;
    font-weight: 700;
    font-size: 30px;
    max-height: none;
    padding: 6px;
    border: 1px solid var(--color-gray-400);
    border-radius: 12px;
    color: var(--color-gray-900);

    &.filled {
      border-color: var(--color-gray-900);
    }

    &:focus {
      outline: none;
      border-color: var(--color-brand-600);
      border-width: 2px;
    }
  }
  .v-input__slot {
    margin: 0;
    min-height: 0;
  }
  .v-text-field--outlined fieldset {
    display: none;
  }
}
</style>
