<template>
  <div class="r-page-site-page-view">
    <div class="d-flex justify-space-between mb-5 mt-4">
      <div style="font-weight: 600; font-size: 18px">
        אזור:
        {{ currentAreaText }}
      </div>
      <div class="d-flex u-gap-2">
        <RButton color="grey" to="/sites/building/" sm
          >חזרה לכל המפלסים</RButton
        >
      </div>
    </div>
    <table class="r-page-site-table">
      <thead>
        <tr>
          <th style="min-width: 40px; width: 3%"></th>
          <th style="min-width: 80px; width: 12%">קטגוריה</th>
          <th style="min-width: 80px; width: 15%">תיאור</th>
          <th style="min-width: 100px; width: 8%">סטטוס</th>
          <th style="min-width: 80px; width: 10%">לטיפול</th>
          <th style="min-width: 80px; width: 5%">בניין</th>
          <th style="min-width: 80px; width: 5%">מפלס</th>
          <th style="min-width: 80px; width: 5%">אזור</th>
          <th style="min-width: 80px; width: 4%">מיקום</th>
          <th style="min-width: 200px; width: 16%">הערות</th>
          <th style="min-width: 80px; width: 5%">תמונות</th>
          <th style="min-width: 80px; width: 4%">משתמש</th>
          <th style="min-width: 120px; width: 8%">נוצר בתאריך</th>
        </tr>
      </thead>
      <tbody>
        <router-link
          v-for="finding in findings"
          :key="finding.id"
          tag="tr"
          :to="`/sites/finding/${finding.id}`"
        >
          <td class="r-page-site-table-column-checkbox">
            <div class="r-page-site-table-checkbox"></div>
          </td>
          <td>{{ finding.category }}</td>
          <td>{{ finding.description }}</td>
          <td class="r-page-site-table-column-status">
            {{ finding.status }}
          </td>
          <td>{{ finding.responsibility }}</td>
          <td>{{ finding.building }}</td>
          <td>{{ finding.level }}</td>
          <td>{{ finding.area }}</td>
          <td>{{ finding.location }}</td>
          <td>
            <div class="r-page-site-table-td-text">
              {{ finding.notes }}
            </div>
          </td>
          <td>0</td>
          <td>{{ finding.user }}</td>
          <td>
            <div class="r-page-site-table-td-text">
              {{ $Utils.presentHebrewDate(finding.created_at) }}
            </div>
          </td>
        </router-link>
      </tbody>
      <tfoot>
        <router-link
          slot="footer"
          tag="tr"
          :to="`/sites/add-finding/?area-id=${currentAreaId}&level-id=${currentLevelId}`"
        >
          <td class=""></td>
          <td :colspan="12">
            <span>+ הוספת שורה</span>
          </td>
        </router-link>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentAreaId: this.$route.params.id,
    };
  },
  computed: {
    currentLevelId() {
      let levelArea = this.site.levels_areas.find(
        (l) => l.area_id === this.currentAreaId
      );
      return levelArea.level_id;
    },
    site() {
      return this.$store.state.site.site;
    },
    currentArea() {
      return this.site.areas.find((l) => l.id === this.currentAreaId);
    },
    currentAreaText() {
      return this.currentArea.text;
    },
    findings() {
      let findings = [];

      this.site.findings.forEach((finding) => {
        if (finding.area === this.currentAreaText) {
          findings.push(finding);
        }
      });

      return findings;
    },
  },
  methods: {},
};
</script>
