<template>
  <div class="maintenance">
    <div style="text-align: center; max-width: 400px; line-height: 1.4">
      <h3>שלום חברים!</h3>
      <br />
      <div>
        🛠️ אנחנו עכשיו בתהליך שדרוג ותחזוקה כדי להבטיח שהאפליקציה שלנו תמשיך
        להיות הטובה ביותר עבורכם.
        <br />
        אנחנו מעריכים שעד השעה
        <b>12:00</b>
        הכל יחזור לשגרה חוויות חדשות ומשופרות.
        <br /><br />
        תודה על הסבלנות והתמיכה,
        <br />
        צוות רפורטו 🙏
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.maintenance {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.97);
  color: #fff;
  z-index: 999999;
}
</style>
