<template>
  <div>
    <div class="r-page-site-page-findings">
      <div class="r-page-site-filters">
        <RFieldSelectMultiple
          v-model="filters.category"
          :items="site.categories"
          label="קטגוריה"
        />
        <RFieldSelectMultiple
          v-model="filters.status"
          :items="site.statuses"
          label="סטטוס"
        />
        <RFieldSelectMultiple
          v-model="filters.building"
          :items="site.buildings"
          label="בניין"
        />
        <RFieldSelectMultiple
          v-model="filters.level"
          :items="site.levels"
          label="מפלס"
        />
        <RFieldSelectMultiple
          v-model="filters.area"
          :items="site.areas"
          label="אזור"
        />
        <RFieldSelectMultiple
          v-model="filters.responsibility"
          :items="site.responsibilities"
          label="לטיפול"
        />

        <!--
        <RButton
          color="black"
          icon-before="mdi-filter"
          outlined
          @click="openFiltersModal"
          >סינון</RButton
        >
        -->
      </div>
      <table class="r-page-site-table">
        <thead>
          <tr>
            <th style="min-width: 40px; width: 3%"></th>
            <th style="min-width: 80px; width: 12%">קטגוריה</th>
            <th style="min-width: 80px; width: 15%">תיאור</th>
            <th style="min-width: 100px; width: 8%">סטטוס</th>
            <th style="min-width: 100px; width: 8%">משתמש</th>
            <th style="min-width: 80px; width: 10%">לטיפול</th>
            <th style="min-width: 80px; width: 5%">בניין</th>
            <th style="min-width: 80px; width: 5%">מפלס</th>
            <th style="min-width: 80px; width: 5%">אזור</th>
            <th style="min-width: 200px; width: 16%">הערות</th>
            <th style="min-width: 80px; width: 5%">תמונות</th>
            <th style="min-width: 120px; width: 8%">נוצר בתאריך</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="finding in site.findings"
            :key="finding.id"
            :class="{
              'is-selected': isFindingSelected(finding),
              'is-hidden': !isFindingInFilters(finding),
            }"
            @click="openEditFindingModal(finding)"
          >
            <td
              class="r-page-site-table-column-checkbox"
              @click.stop="toggleSelectFinding(finding.id)"
            >
              <div
                class="r-page-site-table-checkbox"
                :class="{ 'is-checked': isFindingSelected(finding.id) }"
              ></div>
            </td>
            <td>{{ finding.category }}</td>
            <td>{{ finding.description }}</td>
            <td
              class="r-page-site-table-column-status"
              :style="`--column-status-color: ${getColumnStatusColor(
                finding.status
              )}`"
            >
              {{ finding.status }}
            </td>
            <td>{{ finding.user }}</td>
            <td>{{ finding.responsibility }}</td>
            <td>{{ finding.building }}</td>
            <td>{{ finding.level }}</td>
            <td>{{ finding.area }}</td>
            <td>
              <div class="r-page-site-table-td-text">
                {{ finding.notes }}
              </div>
            </td>
            <td>0</td>
            <td>
              <div class="r-page-site-table-td-text">
                {{ $Utils.presentHebrewDate(finding.created_at) }}
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr slot="footer">
            <td class=""></td>
            <td :colspan="11" @click="openAddRowModal()">
              <span>+ הוספת שורה</span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <RModal
      v-model="rowModal.isOpen"
      :title="
        rowModal.mode === 'edit' ? 'עריכת שורה בטבלה' : 'הוספת שורה לטבלה'
      "
    >
      <RFields>
        <RFieldSelect
          v-model="rowModal.row.category"
          label="קטגוריה"
          :items="site.categories"
        />

        <RFieldText v-model="rowModal.row.description" label="תיאור" />
        <RFieldSelect
          v-model="rowModal.row.status"
          label="סטטוס"
          :items="site.statuses"
        />
        <RFieldText v-model="rowModal.row.user" label="משתמש" />
        <RFieldText v-model="rowModal.row.responsibility" label="לטיפול" />

        <RFieldSelect
          v-model="rowModal.row.building"
          label="בניין"
          :items="site.buildings"
        />
        <RFieldSelect
          v-model="rowModal.row.level"
          label="מפלס"
          :items="site.levels"
        />
        <RFieldSelect
          v-model="rowModal.row.area"
          label="אזור"
          :items="rowModalLevelAreas"
        />

        <RFieldText v-model="rowModal.row.notes" label="הערות" />
        <RButton class="mt-5" lg block @click="saveRowModal"> שמירה</RButton>
      </RFields>
    </RModal>

    <RModal v-model="filtersModal.isOpen" title="סינון דוחות">
      <RFields>
        <RFieldSelect
          v-model="filters.status"
          :clearable="true"
          :items="site.statuses"
          label="סטטוס"
        />
        <RButton
          color="primary"
          block
          lg
          type="submit"
          @click="closeFiltersModal"
        >
          סגירה
        </RButton>
      </RFields>
    </RModal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rowModal: {
        mode: "add",
        isOpen: false,
        row: {
          id: null,
          category: "",
          description: "",
          status: "",
          user: "",
          responsibility: "",
          building: "",
          level: "",
          area: "",
          notes: "",
          images: [],
          created_at: null,
        },
      },
      searchQuery: null,
      filters: {
        status: [],
        building: [],
        level: [],
        area: [],
        responsibility: [],
        category: [],
      },
      filtersModal: {
        isOpen: false,
      },
      selectedFindings: [],
    };
  },
  computed: {
    site() {
      return this.$store.state.site.site;
    },
    rowModalLevelAreas() {
      let areas = [];

      this.site.levels_areas.forEach((level_area) => {
        if (level_area.level_value === this.rowModal.row.level) {
          // get area object from areas
          const area = this.site.areas.find(
            (a) => a.value === level_area.area_value
          );
          areas.push(area);
        }
      });

      return areas;
    },
  },
  async created() {
    this.isLoaded = true;
  },
  methods: {
    closeFiltersModal() {
      this.filtersModal.isOpen = false;
    },
    openFiltersModal() {
      this.filtersModal.isOpen = true;
    },
    saveRowModal() {
      if (this.rowModal.mode === "edit") {
        this.site.findings.forEach((row, rowIndex) => {
          if (row.id === this.rowModal.row.id) {
            this.site.findings[rowIndex] = {
              ...row,
              ...this.rowModal.row
            };
          }
        });
      }
      if (this.rowModal.mode === "add") {
        this.site.findings.push(JSON.parse(JSON.stringify(this.rowModal.row)));
      }

      this.closeRowModal();
    },
    closeRowModal() {
      this.rowModal.isOpen = false;
    },
    openEditFindingModal(finding) {
      this.rowModal.mode = "edit";
      this.rowModal.row = JSON.parse(JSON.stringify(finding));
      this.rowModal.isOpen = true;
    },
    openAddRowModal() {
      this.rowModal.row = {
        id: this.site.findings.length + 1,
        category: "",
        description: "",
        status: "",
        user: "",
        responsibility: "",
        building: "",
        level: "",
        area: "",
        notes: "",
        images: [],
        created_at: new Date().toISOString(),
      };
      this.rowModal.mode = "add";
      this.rowModal.isOpen = true;
    },
    toggleSelectFinding(findingId) {
      if (this.selectedFindings.includes(findingId)) {
        this.selectedFindings = this.selectedFindings.filter(
          (id) => id !== findingId
        );
      } else {
        this.selectedFindings.push(findingId);
      }
    },
    isFindingSelected(finding) {
      return this.selectedFindings.includes(finding.id);
    },
    isFindingInFilters(finding) {
      let inFilters = true;

      if (
        this.filters.category.length > 0 &&
        !this.filters.category.includes(finding.category)
      ) {
        inFilters = false;
      }

      if (
        this.filters.status.length > 0 &&
        !this.filters.status.includes(finding.status)
      ) {
        inFilters = false;
      }

      if (
        this.filters.building.length > 0 &&
        !this.filters.building.includes(finding.building)
      ) {
        inFilters = false;
      }

      if (
        this.filters.level.length > 0 &&
        !this.filters.level.includes(finding.level)
      ) {
        inFilters = false;
      }

      if (
        this.filters.area.length > 0 &&
        !this.filters.area.includes(finding.area)
      ) {
        inFilters = false;
      }

      if (
        this.filters.responsibility.length > 0 &&
        !this.filters.responsibility.includes(finding.responsibility)
      ) {
        inFilters = false;
      }

      return inFilters;
    },
    getColumnStatusColor(status) {
      const foundStatus = this.site.statuses.find((s) => s.text === status);
      return foundStatus ? foundStatus.color : "";
    },
  },
};
</script>
