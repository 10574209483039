<template>
  <rp-container-small>
    <RSimpleCard :loading="isLoading" title="יצירת עיצוב חדש">
      <RFields>
        <RFieldText v-model="newPreferenceName" label="שם העיצוב" />
        <RButton
          :loading="isLoading"
          :disabled="!newPreferenceName"
          block
          lg
          @click="addPreference"
        >
          הוספת עיצוב חדש
        </RButton>
      </RFields>
    </RSimpleCard>
  </rp-container-small>
</template>
<script>
import PreferencesApi from "@/api/PreferencesApi";

export default {
  data() {
    return {
      isLoading: false,
      newPreferenceName: null,
    };
  },
  methods: {
    async addPreference() {
      this.isLoading = true;

      try {
        const response = await PreferencesApi.createPreference(
          this.newPreferenceName
        );
        this.isLoading = false;
        this.$toast.success("העיצוב נוסף בהצלחה");
        await this.$router.push({
          name: "preferences.edit",
          params: { id: response.id },
        });
      } catch (error) {
        console.error(error);
        this.$toast.error("שגיאה בהוספת העיצוב");

        this.isLoading = false;
      }
    },
  },
};
</script>
