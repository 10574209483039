<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ props }">
      <div class="r-field r-field--datepicker" v-bind="props">
        <label v-if="label" class="r-field-label"> {{ label }} </label>
        <div class="r-field-input">
          <input :value="dateFormatted" v-bind="$attrs" />
        </div>
      </div>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      color="primary"
      locale="he-il"
      @update:model-value="handleDateSelect"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: [String],
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menu: false,
      date: null,
      canChange: false,
    };
  },
  computed: {
    dateFormatted() {
      return this.date ? new Date(this.date).toISOString().split('T')[0] : this.date;
    },
  },
  watch: {
    date(val) {
      if (this.canChange) {
        const date = val ? new Date(val) : null;
        const formattedDate = date 
          ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
          : val;
        this.$emit("update:modelValue", formattedDate);
      }
    },
    modelValue(val) {
      this.date = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.date = this.modelValue;
    }
    setTimeout(() => {
      this.canChange = true;
    }, 10);
  },
  methods: {
    updateParentValue(val) {
      this.date = val;
    },
    handleDateSelect(val) {
      this.date = val;
      this.menu = false;
    },
  },
};
</script>
