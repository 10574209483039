<template>
  <div class="r-field r-field--radios">
    <div
      v-for="item in items"
      :key="item.value"
      class="r-field--radios-radio"
      :class="{
        'is-selected': item.value === selectedValue,
      }"
      @click="setSelected(item.value)"
    >
      <label v-if="item.text" class="r-field--radios-radio-label">
        {{ item.text }}
      </label>
      <div class="r-field--radios-radio-input"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      selectedValue: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.selectedValue = newValue;
    },
  },
  methods: {
    setSelected(value) {
      if (value === this.selectedValue) {
        this.clearValue();
        return;
      }
      this.selectedValue = value;
      this.$emit("update:modelValue", value);
    },
    clearValue() {
      this.selectedValue = "";
      this.$emit("update:modelValue", this.selectedValue);
    },
  },
};
</script>
