async function mergeStocks(stockId, stockIdToMerge, isStockFromLibrary) {
  const response = await window.axios.post(
    `/api/v1/stocks/${stockId}/merge/${stockIdToMerge}`,
    {
      isStockFromLibrary,
    }
  );
  return response.data;
}

async function getStocksLibrary() {
  const response = await window.axios.get(`/api/v1/stocks/library`);
  return response.data;
}

async function createStock(stockIdToAdd) {
  const response = await window.axios.post(`/api/v1/stocks`, {
    stockIdToAdd: stockIdToAdd,
  });
  return response.data;
}

async function getStocks() {
  const response = await window.axios.get(`/api/v1/stocks`);
  return response.data;
}
async function removeStock(stockId) {
  const response = await window.axios.delete(`/api/v1/stocks/${stockId}`);
  return response.data;
}

async function updateStock(stockId, stock) {
  const response = await window.axios.put(`/api/v1/stocks/${stockId}`, stock);
  return response.data;
}

async function getStock(stockId) {
  const response = await window.axios.get(`/api/v1/stocks/${stockId}`);
  return response.data;
}

async function duplicateStock(stockId) {
  const response = await window.axios.put(
    `/api/v1/stocks/${stockId}/duplicate`
  );
  return response.data;
}

async function createCategory(stockId, params) {
  const response = await window.axios.put(
    `/api/v1/stocks/${stockId}/create-category/`,
    params
  );
  return response.data;
}

async function createNode(stockId, categoryId, params) {
  const response = await window.axios.put(
    `/api/v1/stocks/${stockId}/create-node/${categoryId}`,
    params
  );
  return response.data;
}

async function updateNode(stockId, nodeId, params) {
  const response = await window.axios.put(
    `/api/v1/stocks/${stockId}/update-node/${nodeId}`,
    params
  );
  return response.data;
}

async function importStock(stockId, file) {
  let fd = new FormData();
  fd.append("file", file);
  const response = await window.axios.post(
    `/api/v1/stocks/${stockId}/import`,
    fd
  );
  return response.data;
}

export default {
  mergeStocks,
  getStocksLibrary,
  createStock,
  getStocks,
  removeStock,
  updateStock,
  getStock,
  duplicateStock,
  createCategory,
  createNode,
  updateNode,
  importStock,
};
