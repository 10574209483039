<template>
  <div>
    <div class="pdf-paper-wrapper">
      <div
        :style="getInlineCss('paper', template.style)"
        :class="{
          current: current.name === template.name,
          'pdf-paper': true,
          'pdfSectionsPreview-header': template.name === 'header',
          'pdfSectionsPreview-footer': template.name === 'footer',
        }"
        @click.stop="$emit('change-active-tool')"
        @contextmenu="openContextMenu($event, template)"
      >
        <draggable
          v-model="templateSections"
          draggable=".pdf-section"
          handle=".move-section-marker"
          class="section-drag-container"
          group="sections-group"
          itemKey="id"
          v-bind="dragOptions"
          @end="updateTemplateHandler"
        >
          <template #item="{ element: section, index: sectionIndex }">
            <div
              :key="sectionIndex"
              :style="
                getInlineCss('section', section.style, { paper: template })
              "
              class="pdf-section"
              :class="{ current: current.id === section.id }"
              @click.stop="setCurrent(section)"
              @contextmenu="openContextMenu($event, section)"
            >
              <draggable
                v-model="section.columns"
                handle=".move-column-marker"
                draggable=".pdf-column"
                class="col-drag-container"
                direction="horizontal"
                itemKey="id"
                v-bind="dragOptions"
                @end="updateTemplateHandler"
              >
                <template #item="{ element: column, index: columnIndex }">
                  <div
                    :key="columnIndex"
                    :style="
                      getInlineCss('column', column.style, {
                        section: section,
                        paper: template,
                      })
                    "
                    class="pdf-column"
                    :class="{
                      current: current.id === column.id,
                      empty: isColumnEmpty(column),
                      resizable: columnIndex < section.columns.length - 1,
                    }"
                    @click.stop="setCurrent(column)"
                    @contextmenu="openContextMenu($event, column)"
                  >
                    <draggable
                      v-model="column.fields"
                      handle=".move-field-marker"
                      draggable=".pdf-field"
                      group="fields-group"
                      class="draggable-container"
                      v-bind="dragOptions"
                      itemKey="id"
                      @end="updateTemplateHandler"
                      @change="changeElementHandler"
                    >
                      <template #item="{ element: field, index: fieldIndex }">
                        <div
                          :key="fieldIndex"
                          class="pdf-field"
                          :class="{ current: current.id === field.id }"
                          @click.stop="setCurrent(field)"
                          @contextmenu="openContextMenu($event, field)"
                        >
                          <!-- eslint-disable vue/no-v-html -->
                          <table
                            v-if="field.type === 'text'"
                            style="
                              width: 100%;
                              border-spacing: 0;
                              min-height: 15px;
                            "
                          >
                            <tbody>
                              <tr>
                                <td
                                  :style="
                                    getInlineCss('field-text', field.style)
                                  "
                                >
                                  <div v-html="field.text"></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <!--eslint-enable-->

                          <table
                            v-if="field.type === 'image'"
                            style="width: 100%; border-spacing: 0; padding: 0"
                          >
                            <tbody>
                              <tr>
                                <td
                                  :style="
                                    getInlineCss(
                                      'fieldWrapper-image',
                                      field.style,
                                      {
                                        paper: template,
                                        section: section,
                                        column: column,
                                      }
                                    )
                                  "
                                >
                                  <img
                                    :src="
                                      field.imageUrl
                                        ? mediaUrl + field.imageUrl
                                        : '/images/tools/placeholder.png'
                                    "
                                    :style="
                                      getInlineCss('field-image', field.style, {
                                        paper: template,
                                        section: section,
                                        column: column,
                                      })
                                    "
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div class="move-field-marker move-marker">
                            <RIcon x-small color="white"
                              >mdi-arrow-expand-all
                            </RIcon>
                            <div>שדה</div>
                          </div>
                        </div>
                      </template>
                    </draggable>
                    <div
                      v-if="columnIndex > 0"
                      :key="`resize-bar-${columnIndex}`"
                      class="resize-bar"
                      @mousedown.stop="
                        (e) =>
                          resizeDownHandler(
                            e,
                            section,
                            section.columns[columnIndex - 1],
                            column
                          )
                      "
                    ></div>
                    <div class="move-column-marker move-marker">
                      <RIcon x-small color="white">mdi-arrow-expand-all</RIcon>
                      <div>עמודה</div>
                    </div>
                  </div>
                </template>
              </draggable>
              <div class="move-section-marker move-marker">
                <RIcon x-small color="white">mdi-arrow-expand-all</RIcon>
                <div>אזור</div>
              </div>
            </div>
          </template>
        </draggable>

        <div class="move-paper-marker move-marker">
          <RIcon x-small color="white">mdi-arrow-expand-all</RIcon>
          <div>{{ convertPaperNameToHebrew(template.name) }}</div>
        </div>
      </div>
      <div
        class="d-flex justify-center mt-3"
        style="position: absolute; left: 0; right: 0; z-index: 1"
      >
        <add-section @create-section="createSectionHandler" />

        <RButton
          class="mx-1"
          outlined
          icon-before="mdi-folder"
          sm
          color="primary"
          @click="openTemplatesModal(template.name)"
        >
          עיצובים מוכנים
        </RButton>
      </div>
    </div>

    <v-menu
      v-model="contextMenu.isOpen"
      absolute
      offset-y
      origin="top left"
      location="auto"
      :style="{
        left: `${contextMenu.x}px !important`,
        top: `${contextMenu.y}px !important`,
        bottom: 'unset',
        right: 'unset',
      }"
    >
      <v-list id="app-editor-context-menu" class="custom-dropdown">
        <v-list-item link @click="contextMenuEditItem">
          <v-list-item-title
            >עריכת {{ contextMenu.typeName }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="
            contextMenu.current && contextMenu.current.elementType === 'section'
          "
          link
          @click="contextMenuAddColumn"
        >
          <v-list-item-title>הוספת עמודה</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="contextMenuDeleteItem">
          <v-list-item-title
            >מחיקת {{ contextMenu.typeName }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <RModal
      v-model="templatesModal.isOpen"
      size="lg"
      :title="`בחירת תבנית מוכנה - ${convertPaperNameToHebrew(template.name)}`"
    >
      <div class="readyTemplates-grid">
        <div
          v-for="(
            preferenceTemplate, preferenceTemplateIndex
          ) in preferencesLibrary[template.name]"
          :key="preferenceTemplateIndex"
          class="readyTemplates-item"
          @click="setPreferenceTemplate(preferenceTemplate)"
        >
          <img
            :src="`/images/preferencesLibrary/${template.name}/${
              preferenceTemplateIndex + 1
            }.png`"
            alt=""
          />
        </div>
      </div>
    </RModal>
  </div>
</template>

<script>
import AddSection from "./AddSection.vue";
import draggable from "vuedraggable";
import { dragOptions, preferencesLibrary } from "@/common/settings";
import { addColumnHandler, getInlineCss } from "../editorHelpers";

export default {
  components: {
    AddSection,
    draggable,
  },
  props: {
    preference: {
      type: Object,
      default() {
        return {};
      },
    },
    template: {
      type: Object,
      default() {
        return {};
      },
    },
    current: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    preferencesLibrary,
    getInlineCss,
    dragOptions,
    contextMenu: {
      current: null,
      isOpen: false,
      typeName: "",
      x: 0,
      y: 0,
    },
    templatesModal: {
      isOpen: false,
      template: "header",
    },
  }),
  computed: {
    templateSections: {
      get() {
        return this.template.sections;
      },
      set(val) {
        const template = { ...this.template };
        template.sections = val;
        this.$emit("update-template", template);
      },
    },
    mediaUrl() {
      return this.$Reporto.globals.MEDIA_URL;
    },
  },
  methods: {
    contextMenuAddColumn() {
      this.$emit("update-current", addColumnHandler(this.contextMenu.current));
    },
    contextMenuEditItem() {
      this.setCurrent(this.contextMenu.current);
    },
    contextMenuDeleteItem() {
      this.$emit("delete", this.contextMenu.current, this.template.name);
    },
    openContextMenu(e, current) {
      e.stopPropagation();
      e.preventDefault();
      this.contextMenu.x = e.clientX;
      this.contextMenu.y = e.clientY;

      this.contextMenu.current = current;

      let typeName = "";
      if (current.elementType === "paper") {
        typeName = "נייר";
      } else if (current.elementType === "section") {
        typeName = "אזור";
      } else if (current.elementType === "column") {
        typeName = "עמודה";
      } else if (current.elementType === "field") {
        typeName = "שדה";
      }

      this.contextMenu.isOpen = false;
      this.contextMenu.typeName = typeName;

      this.$nextTick(() => {
        setTimeout(() => {
          this.contextMenu.isOpen = true;
        });
      });

      if (current.elementType === "paper") {
        this.$emit("change-active-tool");
      } else {
        this.setCurrent(current);
      }
    },
    setPreferenceTemplate(preferenceTemplate) {
      if (confirm("בטוח שתרצו להחליף את התבנית הקיימת?")) {
        this.$emit("onBack");
        this.$emit("update-template", preferenceTemplate);
      }
    },
    closeTemplatesModal() {
      this.templatesModal.isOpen = false;
    },
    openTemplatesModal(template) {
      this.templatesModal.template = template;
      this.templatesModal.isOpen = true;
    },
    setCurrent(current) {
      this.$emit("set-current", current);
    },
    isColumnEmpty(column) {
      return !column.fields || !column.fields.length;
    },
    createSectionHandler(columnsCount) {
      this.$emit("create-section", columnsCount);
    },
    resizeDownHandler(e, section, column, prevCol) {
      const col = e.target.parentNode;
      const sectionNode = col.parentNode;
      let _self = this;

      window.addEventListener("mousemove", resizeListener);
      window.addEventListener("mouseup", clearListeners);

      function resizeListener(e) {
        e.preventDefault();
        const step = sectionNode.getBoundingClientRect().width / 100;
        const offset = e.movementX / step;
        let currWidth = column.style.width - offset;
        let nextWidth = prevCol.style.width + offset;

        if (currWidth > 1 && nextWidth > 1) {
          column.style.width = currWidth;
          prevCol.style.width = nextWidth;
        }
      }

      function clearListeners() {
        window.removeEventListener("mousemove", resizeListener);
        window.removeEventListener("mouseup", clearListeners);

        // Round numbers
        column.style.width = Math.round(column.style.width);
        prevCol.style.width = Math.round(prevCol.style.width);

        // Fix section columns width, make sure its 100.
        _self.fixSectionColumnsWidth(section);
      }
    },
    updateTemplateHandler() {
      this.$emit("update-template", this.template);
    },
    fixSectionColumnsWidth(section) {
      let totalColumns = 0;
      section.columns.forEach((column) => {
        totalColumns += column.style.width;
      });
      if (totalColumns !== 100) {
        section.columns[0].style.width -= totalColumns - 100;
      }
    },
    changeElementHandler(event) {
      if (
        event.added &&
        (!this.current || this.current.id !== event.added.element.id)
      ) {
        this.setCurrent(event.added.element);
      }
    },
    convertPaperNameToHebrew(paperName) {
      if (paperName === "header") {
        return "חלק עליון";
      } else {
        return "חלק תחתון";
      }
    },
  },
};
</script>

<style lang="scss">
//.pdf-paper-wrapper {
//  box-sizing: initial;
//  *,
//  *:before,
//  *:after {
//    box-sizing: initial;
//  }
//}
.pdf-drag-container {
  width: 100%;
  min-height: 100%;
}

.pdf-paper {
  position: relative;
  z-index: 4;

  img {
    display:inline;
  }

  &.current,
  &:hover {
    outline: 1px solid #5e21d2;
    outline-offset: 0;
    cursor: pointer;
    z-index: 5;
  }

  .move-paper-marker {
    left: calc(50% - 50px);
    top: -20px;
    visibility: hidden;
    background: #5e21d2;
    color: #fff;
    z-index: 4;
  }

  &:hover {
    .move-paper-marker {
      visibility: visible;
    }
  }
}

.pdf-section {
  position: relative;
  display: flex;
  z-index: 4;

  &.current,
  &:hover {
    outline: 1px solid #5e21d2;
    outline-offset: 0;
    cursor: pointer;
    z-index: 10;
  }

  .move-section-marker {
    left: calc(50% - 50px);
    top: -20px;
    visibility: hidden;
    background: #5e21d2;
    color: #fff;
    z-index: 10;
  }

  &:hover {
    .move-section-marker {
      visibility: visible;
    }
  }
}

.col-drag-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.pdf-column {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;

  .draggable-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .move-column-marker {
    left: 0;
    top: 0;
    visibility: hidden;
    background: #000;
    z-index: 2;
  }

  &:hover {
    .move-column-marker {
      visibility: visible;
    }
  }

  &.empty {
    min-height: 50px;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      outline: 1px dashed grey;
    }
  }

  &.current,
  &:hover {
    outline: 1px dashed #000;
    outline-offset: 0;
    cursor: pointer;
    z-index: 5;
  }

  &[style*="vertical-align: top;"] {
    .draggable-container {
      justify-content: flex-start;
    }
  }

  &[style*="vertical-align: middle;"] {
    .draggable-container {
      justify-content: center;
    }
  }

  &[style*="vertical-align: bottom;"] {
    .draggable-container {
      justify-content: flex-end;
    }
  }
}

.resize-bar {
  background: transparent;
  position: absolute;
  width: 10px;
  right: -5px;
  top: 0px;
  bottom: 0;
  z-index: 3;
  cursor: col-resize;
}

.pdf-field {
  width: 100%;
  position: relative;

  p {
    margin-bottom: 0;
  }

  .move-field-marker {
    right: 0;
    top: 0;
    visibility: hidden;
    background: #71d8f6;
  }

  &:hover {
    .move-field-marker {
      visibility: visible;
    }
  }

  &.current,
  &:hover {
    outline: 1px dashed #71d8f6;
    outline-offset: 0;
    z-index: 1;
  }
}

.template-tip-wrapper {
  border: 1px solid #a9a9a9;
  display: flex;
  align-items: center;
  border-right: 0;
  color: #a9a9a9;
  margin-right: 10px;

  .template-tip {
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}

.move-marker {
  height: 20px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  padding: 2px 5px;
  font-family: var(--font-family-primary);
  min-width: 40px;

  i {
    margin-left: 4px;
  }
}

.readyTemplates {
  &-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  &-item {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      border-color: #5d21d2;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.ghost {
  background: #5e21d2 !important;
  height: auto !important;
  width: 100% !important;
}

#app-editor-context-menu {
  position: absolute !important;
  transform: none !important; /* Reset any automatic positioning */
  right: unset !important; /* Ensure manual `position-x` and `position-y` are applied */
  bottom: unset !important;
  min-width: 150px;
  height: auto;
}
</style>
