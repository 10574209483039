<template>
  <div class="app-field app-field--image">
    <report-label :label="label" />

    <AppFieldSignature
      :model-value="imagePath"
      :modal-title="label"
      @update:model-value="updateSignature"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ReportLabel from "@/views/reports/components/ReportLabel";

export default {
  components: { ReportLabel },
  props: {
    loadSignatureUrl: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    updateKey: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      imagePath: this.loadSignatureUrl,
    };
  },
  computed: {
    hasCustomDoUpdate() {
      return this.$attrs && this.$attrs['onDoUpdate'];
    },
  },
  methods: {
    ...mapActions("report", ["doUpdate"]),
    async localDoUpdate({ key, value }) {
      if (this.hasCustomDoUpdate) {
        this.$emit('doUpdate', {
          key: key,
          value: value,
        });
      } else {
        await this.doUpdate({
          key: key,
          value: value,
        });
      }
    },
    async updateSignature(event) {
      await this.localDoUpdate({
        key: this.updateKey,
        value: event,
      });
    },
  },
};
</script>
