<template>
  <ReportItemCard :label="label">
    <div class="report-item-card__body">
      <div v-if="imagePath" class="d-flex align-start flex-column mt-3">
        <RModal v-model="isPreviewImageModalOpen" title="תצוגת תמונה" size="lg">
          <div class="text-center">
            <img
              :src="$Utils.getImageUrl(imagePath)"
              style="max-width: 100%; max-height: 80vh"
            />
          </div>
        </RModal>

        <img
          :src="$Utils.getImageUrl(imagePath)"
          class="preview-image u-cursor-pointer"
          @click="isPreviewImageModalOpen = true"
        />

        <RButton class="mt-3" color="red" outlined sm @click="removeImage">
          מחיקת תמונה
        </RButton>
      </div>

      <div class="d-flex flex-wrap align-center mt-4">
        <div
          class="ml-2 app-field--image-addMediaButtonWrapper with-camera-button"
        >
          <RButton outlined color="primary" icon-before="mdi-camera-outline">
            צילום
          </RButton>

          <input
            ref="camera"
            class="app-field--image-addMediaInput"
            type="file"
            accept="image/*"
            capture=""
            @change="uploadImage"
          />
        </div>

        <div class="ml-2 app-field--image-addMediaButtonWrapper">
          <RButton
            outlined
            color="primary"
            icon-before="mdi-folder-multiple-outline"
          >
            בחירה מהגלריה
          </RButton>
          <input
            ref="gallery"
            class="app-field--image-addMediaInput"
            type="file"
            accept="image/*"
            @change="uploadImage"
          />
        </div>
      </div>
    </div>
  </ReportItemCard>
</template>

<script>
import { mapActions } from "vuex";
import ReportItemCard from "@/views/reports/components/ReportItemCard.vue";

export default {
  components: { ReportItemCard },
  props: {
    loadImageUrl: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    updateKey: {
      type: String,
      default: null,
      required: false,
    },
  },
  emits: ['doUpdate'],
  data() {
    return {
      isPreviewImageModalOpen: false,
      imagePath: this.loadImageUrl,
    };
  },
  computed: {
    hasCustomDoUpdate() {
      return this.$attrs && this.$attrs['onDoUpdate'];
    },
  },
  methods: {
    ...mapActions("report", ["doUpdate"]),
    async localDoUpdate({ key, value }) {
      if (this.hasCustomDoUpdate) {
        this.$emit('doUpdate', {
          key: key,
          value: value,
        });
      } else {
        await this.doUpdate({
          key: key,
          value: value,
        });
      }
    },
    async removeImage() {
      if (confirm("בטוח למחוק?")) {
        await this.localDoUpdate({
          key: this.updateKey,
          value: "",
        });

        this.imagePath = null;
      }
    },
    uploadImage(event) {
      if (!event.target.files || !event.target.files.length) {
        return;
      }

      let file = event.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        await this.localDoUpdate({
          key: this.updateKey,
          value: reader.result,
        });

        this.imagePath = reader.result;
        this.$forceUpdate();
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-image {
  max-width: 100%;
  width: 240px;
  // height: 150px;
  background: #fff;
  padding: 5px;
  border-radius: 4px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app-field--image-addMediaButtonWrapper {
  position: relative;

  &.with-camera-button {
    @media (min-width: 1440px) {
      display: none;
    }
  }
}
.app-field--image {
  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
}
.app-field--image-addMediaInput {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
</style>
