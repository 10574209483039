let env = {
  VITE__API_URL: import.meta.env.VITE_API_URL,
  VITE__MEDIA_URL: import.meta.env.VITE_MEDIA_URL,
};

if (!env.VITE__API_URL || env.VITE__API_URL.length < 3) {
  env.VITE__API_URL = "https://api.reporto.co.il/";
}

if (!env.VITE__MEDIA_URL || env.VITE__MEDIA_URL.length < 3) {
  env.VITE__MEDIA_URL = "https://media.reporto.co.il/";
}

window.myEnv = env;

export { env };
