<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    $checkRole(roles) {
      var foundRole = false;
      if (this.user) {
        let currentUserRoleId = this.user.role_id;
        let currentUserRoleName = null;

        if (currentUserRoleId === 2) {
          currentUserRoleName = "admin";
        }

        if (currentUserRoleId === 3) {
          currentUserRoleName = "user";
        }

        if (currentUserRoleId === 4) {
          currentUserRoleName = "owner";
        }

        if (roles.includes(currentUserRoleName)) {
          foundRole = true;
        }
      }
      return foundRole;
    },
  },
};
</script>
