<template>
  <div class="r-field r-field--selectMultiple">
    <label v-if="label" class="r-field-label"> {{ label }} </label>

    <v-menu v-model="isMenuOpen" offset-y :close-on-content-click="false">
      <template #activator="{ props }">
        <div class="r-field-input" v-bind="props">
          {{ selectedValues.join(", ") }}
        </div>
      </template>
      <div class="r-field--selectMultiple-list">
        <div
          v-for="item in items"
          :key="item.value"
          class="r-field--selectMultiple-list-item"
          :class="{
            'is-selected': selectedValues.includes(item.value),
          }"
          @click="toggleFromList(item.value)"
        >
          <div
            class="r-field--selectMultiple-list-checkbox"
            :class="{
              'is-checked': selectedValues.includes(item.value),
            }"
          ></div>
          {{ item.text }}
        </div>
        <div class="r-field--selectMultiple-list-clear" @click="clearAll">
          נקה הכל
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isMenuOpen: false,
      selectedValues: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.selectedValues = newValue;
    },
  },
  methods: {
    clearAll() {
      this.selectedValues = [];
      this.isMenuOpen = false;
      this.$emit("update:modelValue", this.selectedValues);
    },
    toggleFromList(value) {
      if (this.selectedValues.includes(value)) {
        this.selectedValues = this.selectedValues.filter((v) => v !== value);
      } else {
        this.selectedValues = [...this.selectedValues, value];
      }
      this.$emit("update:modelValue", this.selectedValues);
    },
  },
};
</script>
