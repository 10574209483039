export const isAndroid = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("android") > -1 && ua.indexOf("mobile") > -1;
};

export const isIPhone = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("iphone") > -1;
};

export const isMobile = () =>
  ["ipad", "iphone", "ipod", "android", "webos"].some((item) => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf(item) > -1;
  });
