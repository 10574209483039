<template>
  <RAlert
    v-if="
      $checkRole(['admin', 'owner']) &&
      company.daysLeftInTrial &&
      company.daysLeftInTrial <= 10 &&
      !company.hasCreditCard
    "
    type="warning"
    class="mt-3"
  >
    <p style="font-weight: 600">
      תקופת הניסיון תיגמר בעוד
      {{ company.daysLeftInTrial }} ימים 😕
    </p>
    כדי להמשיך להשתמש במערכת יש להזין פרטי אשראי לתשלום.
    <br />
    במידה ולא יוכנס אשראי המערכת תיסגר באופן אוטומטי.

    <RButton
      v-if="$checkRole(['owner'])"
      class="mt-2"
      color="blue"
      to="/company-settings/?credit-card-modal=1"
      >עדכון פרטי אשראי</RButton
    >
  </RAlert>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", [
      "displayName",
      "displayCompanyName",
      "companyLogoUrl",
      "company",
    ]),
  },
};
</script>
