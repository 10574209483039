<template>
  <RSimpleCard
    v-if="show && category_video"
    :title="`▶️ סרטון הדרכה: ${category}`"
    subtitle="אנחנו ממש ממליצים שתצפו בסרטון הזה שמותאם עבורכם."
    style="max-width: 500px"
  >
    <RButtonIcon
      class="category_video_close"
      icon="mdi-close"
      @click="closeClicked"
    />
    <VideoCard :video-id="category_video" />
    <RButton class="mt-3" to="/videos" color="grey" block>
      לכל סרטוני ההדרכה
    </RButton>
  </RSimpleCard>
</template>
<script>
import { trackEvent } from "@/services/TrackingService";
import VideoCard from "@/components/VideoCard.vue";
import { mapGetters } from "vuex";

export default {
  components: { VideoCard },
  data: () => ({
    category: null,
    show: false,
  }),
  computed: {
    ...mapGetters("auth", ["company"]),
    category_video() {
      if (this.category === "בדק בית") {
        return "oJ-GbCx3rME";
      }
      if (this.category === "קונסטרוקציה") {
        return "m75WcBTD7_4";
      }
      if (this.category === "אדריכלות") {
        return "u_qDQjQs_AU";
      }
      if (this.category === "איתור נזילות") {
        return "8aEOPgF_jeo";
      }
      if (this.category === "פיקוח דיירים") {
        return "-Od0ippi1p4";
      }
      if (this.category === "יועצי אלומיניום") {
        return "2Q39R0B5IRQ";
      }
      return false;
    },
  },
  created() {
    if (!this.getUserCache()) {
      this.show = true;
    }
    if (this.company && this.company.category) {
      this.category = this.company.category;
    }
  },
  methods: {
    trackEvent,
    getUserCache() {
      return window.localStorage.getItem("cache/hasBeenPrimaryVideoBanner");
    },
    addToUserCache() {
      window.localStorage.setItem("cache/hasBeenPrimaryVideoBanner", true);
    },
    closeClicked() {
      trackEvent("Button Clicked", {
        "Button Name": "Primary Video Card Dismiss",
      });
      this.addToUserCache();
      this.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
.category_video_close {
  position: absolute;
  left: 10px;
  top: 10px;
}
</style>
