<template>
  <div
    :class="{
      'r-simple-card': true,
      'r-simple-card--disabled': disabled || loading,
    }"
  >
    <div class="r-simple-card-loading">
      <RIconLoading v-if="loading" size="14" />
    </div>
    <div class="r-simple-card-body">
      <div v-if="title || subtitle" class="r-simple-card-header">
        <div v-if="title" class="r-simple-card-title">{{ title }}</div>
        <div v-if="subtitle" class="r-simple-card-subtitle">
          {{ subtitle }}
        </div>
      </div>
      <div class="r-simple-card-text"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RSimpleCard",
  props: {
    title: {
      type: String,
      default: undefined,
    },
    subtitle: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
