<template>
  <div class="app-field">
    <label v-if="label" class="app-field__label"> {{ label }} </label>
    <v-select
      v-model="inputText"
      :items="items"
      style="background: #fff"
      :small="small"
      hide-details
      density="compact"
      variant="outlined"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
    small: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      inputText: false,
    };
  },
  watch: {
    inputText(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.inputText = val;
    },
  },
  created() {
    this.inputText = this.modelValue;
  },
  methods: {
    updateParentValue(val) {
      this.inputText = val;
    },
  },
};
</script>
