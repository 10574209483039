import mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";
import { Utils } from "@/common/Utils";

const initialState = {
  isLoggedIn: false,
  isImpersonate: Utils.isImpersonateMode(),
  token: Utils.getToken(),
  user: null,
  company: null,
  companies: null,
};

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations,
};
