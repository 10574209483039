<template>
  <v-dialog
    :model-value="modelValue"
    :max-width="maxWidth"
    :retain-focus="false"
    :persistent="persistent"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <v-card>
      <v-card-title
        class="d-flex align-center justify-space-between flex-wrap mb-0 py-0"
      >
        <span>{{ title }}</span>
        <RButtonIcon
          v-if="includeCloseButton"
          icon="mdi-close"
          @click="closeModal"
        />
      </v-card-title>
      <v-card-text class="px-3 py-3">
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RModal",
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false
    },
    includeCloseButton: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "md", // sm, md, lg
    },
  },
  emits: ['update:modelValue', 'close'],
  computed: {
    maxWidth() {
      return this.size === "sm"
        ? "400px"
        : this.size === "lg"
        ? "800px"
        : "600px";
    },
  },
  methods: {
    closeModal() {
      this.$emit("update:modelValue", false);
      this.$emit("close");
    },
  },
};
</script>
