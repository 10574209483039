<template>
  <div class="app-field">
    <label v-if="label" class="app-field__label"> {{ label }} </label>
    <v-textarea
        v-model="inputText"
        outlined
        hide-details
        rows="1"
        dense
        auto-grow
        v-bind="$attrs"
        variant="outlined"
        density="compact"
        @keyup.native="inputText = $event.target.value"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      inputText: false,
    };
  },
  watch: {
    inputText(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.inputText = val;
    },
  },
  created() {
    this.inputText = this.modelValue;
  },
  methods: {
    updateParentValue(val) {
      this.inputText = val;
    },
  },
};
</script>
