<template>
  <div v-bind="$attrs" :class="chipClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "RChip",
  props: {
    color: {
      type: String,
      default: "purple",
    },
    sm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    chipClass() {
      let classes = [];

      classes.push("r-chip");

      // color
      classes.push(`r-chip--${this.color}`);

      // sm
      if (this.sm) {
        classes.push("r-chip--sm");
      }

      return classes;
    },
  },
};
</script>
