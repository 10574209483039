<template>
  <div class="r-auth-wrapper">
    <div class="r-auth-inner">
      <div class="r-auth-logo">
        <img src="@/assets/images/reporto-logo.svg" alt="logo" />
      </div>
      <div class="r-auth-title">קישור אימות המייל פג תוקף</div>
      <div class="r-auth-text">
        באופן מוזר, הקישור לאימות המייל הביא אותך לכאן,
        <br />
        לחצו על הכפתור למטה כדי לחזור לעניינים
      </div>
      <div class="r-auth-form">
        <RButton lg to="/login" color="primary" block> כניסה לחשבון </RButton>
      </div>
    </div>
  </div>
</template>
