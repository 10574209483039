import { appVersion } from "@/common/appVersion";
import posthog from "posthog-js";
// import { retryWithBackoff } from "../common/Utils";

const sendLog = async (message, payload = null) => {
  if (payload) payload = _createPayload(payload);

  const body = {
    user_agent: navigator.userAgent,
    platform: navigator.platform,
    app_version: appVersion,
    message,
    payload,
  };
  try {
    // await window.axios.post(`/api/v1/log`, body);
    console.log("sendLog: Logging event: " + message, body);
    posthog.capture(message, {
      distinct_id: posthog.get_distinct_id(), // Ensure events are tied to a unique user
      ...body,
    });
  } catch (err) {
    console.error("sendLog: Error sending log", payload, err);
    // retryWithBackoff(() => window.axios.post(`/api/v1/log`, body));
  }
};

function _createPayload({ err, data }) {
  if (err) {
    err = {
      message: err.message || "No error message available",
      code: err.code || "No code",
      method: err.config?.method || "Unknown method", // HTTP method (GET, POST, etc.)
      url: err.config?.url || "No URL specified",
      responseStatus: err.response?.status || "No response status",
      responseData: err.response?.data || "No response data",
      requestStatus: err.request
          ? "Request made but no response received"
          : "No request sent",
      headers: err.config?.headers || "No headers",
    };
  }
  return {
    error: err || "No error",
    additionalData: data || "No additional data",
  };
}

export { sendLog };
