<template>
  <div class="r-page-site-page-view">
    <div class="r-site-view-list">
      <div
        v-for="(building, buildingIndex) in site.buildings"
        :key="buildingIndex"
        class="r-site-view-list-item"
        :class="{
          'r-site-view-list-item-selected': building.id === selectedBuilding,
        }"
        @click="selectBuilding(building.id)"
      >
        <div class="r-site-view-list-item-image">
          <img src="@/assets/images/building-example.png" alt="" />
        </div>
        <div class="r-site-view-list-item-content">
          <div class="r-site-view-list-item-title">
            בניין {{ building.text }}
          </div>
          <div class="r-site-view-list-item-text">צ׳קליסט: 11/100</div>
        </div>
      </div>
    </div>
    <template v-if="selectedBuilding">
      <div class="r-site-view-next">
        <RIcon>mdi-chevron-triple-down</RIcon>
      </div>
      <div class="r-site-view-list">
        <div
          v-for="(level, levelIndex) in site.levels"
          :key="levelIndex"
          class="r-site-view-list-item"
          :class="{
            'r-site-view-list-item-selected': level.value === selectedLevel,
          }"
          @click="selectLevel(level.value)"
        >
          <div class="r-site-view-list-item-image">
            <img src="@/assets/images/building-example.png" alt="" />
          </div>
          <div class="r-site-view-list-item-content">
            <div class="r-site-view-list-item-title">{{ level.text }}</div>
            <div class="r-site-view-list-item-text">
              אזורים/דירות:
              {{ getLevelAreas(level.value).length }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="selectedLevel">
      <div class="r-site-view-next">
        <RIcon>mdi-chevron-triple-down</RIcon>
      </div>
      <div class="r-site-view-list">
        <div
          v-for="(area, areaIndex) in levelAreas"
          :key="areaIndex"
          class="r-site-view-list-item"
          :class="{
            'r-site-view-list-item-selected': area.value === selectedArea,
          }"
          @click="selectArea(area.value)"
        >
          <div class="r-site-view-list-item-image">
            <img src="@/assets/images/building-example.png" alt="building" />
          </div>
          <div class="r-site-view-list-item-content">
            <div class="r-site-view-list-item-title">{{ area.text }}</div>
            <div class="r-site-view-list-item-text">צ׳קליסט: 11/100</div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="selectedArea">
      <div class="r-site-view-area">
        <div class="r-site-view-area-title">
          {{ currentSelectedArea.text }}
        </div>
        <div class="r-site-view-area-subtitle">ממצאים</div>
        <div class="r-site-view-area-table">
          <table class="r-page-site-table">
            <thead>
              <tr>
                <th style="min-width: 40px; width: 3%"></th>
                <th style="min-width: 80px; width: 12%">קטגוריה</th>
                <th style="min-width: 80px; width: 15%">תיאור</th>
                <th style="min-width: 100px; width: 8%">סטטוס</th>
                <th style="min-width: 100px; width: 8%">משתמש</th>
                <th style="min-width: 80px; width: 10%">לטיפול</th>
                <th style="min-width: 80px; width: 5%">בניין</th>
                <th style="min-width: 80px; width: 5%">מפלס</th>
                <th style="min-width: 80px; width: 5%">אזור</th>
                <th style="min-width: 200px; width: 16%">הערות</th>
                <th style="min-width: 80px; width: 5%">תמונות</th>
                <th style="min-width: 120px; width: 8%">נוצר בתאריך</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="finding in currentSelectedAreaFindings"
                :key="finding.id"
              >
                <td class="r-page-site-table-column-checkbox">
                  <div class="r-page-site-table-checkbox"></div>
                </td>
                <td>{{ finding.category }}</td>
                <td>{{ finding.description }}</td>
                <td class="r-page-site-table-column-status">
                  {{ finding.status }}
                </td>
                <td>{{ finding.user }}</td>
                <td>{{ finding.responsibility }}</td>
                <td>{{ finding.building }}</td>
                <td>{{ finding.level }}</td>
                <td>{{ finding.area }}</td>
                <td>
                  <div class="r-page-site-table-td-text">
                    {{ finding.notes }}
                  </div>
                </td>
                <td>0</td>
                <td>
                  <div class="r-page-site-table-td-text">
                    {{ $Utils.presentHebrewDate(finding.created_at) }}
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr slot="footer">
                <td class=""></td>
                <td :colspan="11">
                  <span>+ הוספת שורה</span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <br /><br />
        <div class="r-site-view-area-subtitle">צ׳קליסט</div>
        <div class="r-site-view-area-table">
          <table class="r-page-site-table">
            <thead>
              <tr>
                <th style="min-width: 40px; width: 3%"></th>
                <th style="min-width: 80px; width: 12%">קטגוריה</th>
                <th style="min-width: 80px; width: 15%">תיאור</th>
                <th style="min-width: 100px; width: 8%">סטטוס</th>
                <th style="min-width: 100px; width: 8%">משתמש</th>
                <th style="min-width: 80px; width: 10%">לטיפול</th>
                <th style="min-width: 80px; width: 5%">בניין</th>
                <th style="min-width: 80px; width: 5%">מפלס</th>
                <th style="min-width: 80px; width: 5%">אזור</th>
                <th style="min-width: 200px; width: 16%">הערות</th>
                <th style="min-width: 80px; width: 5%">תמונות</th>
                <th style="min-width: 120px; width: 8%">נוצר בתאריך</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="finding in getChecklistByType('apartment').items"
                :key="finding.id"
              >
                <td class="r-page-site-table-column-checkbox">
                  <div class="r-page-site-table-checkbox"></div>
                </td>
                <td>{{ finding.category }}</td>
                <td>{{ finding.description }}</td>
                <td class="r-page-site-table-column-status">
                  {{ finding.status }}
                </td>
                <td>{{ finding.user }}</td>
                <td>{{ finding.responsibility }}</td>
                <td>{{ finding.building }}</td>
                <td>{{ finding.level }}</td>
                <td>{{ finding.area }}</td>
                <td>
                  <div class="r-page-site-table-td-text">
                    {{ finding.notes }}
                  </div>
                </td>
                <td>0</td>
                <td>
                  <div class="r-page-site-table-td-text">
                    <template v-if="finding.created_at">
                      {{ $Utils.presentHebrewDate(finding.created_at) }}
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr slot="footer">
                <td class=""></td>
                <td :colspan="11">
                  <span>+ הוספת שורה</span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
      selectedBuilding: null,
      selectedLevel: null,
      selectedArea: null,
    };
  },
  computed: {
    currentSelectedArea() {
      return this.site.areas.find((a) => a.value === this.selectedArea);
    },
    currentSelectedAreaFindings() {
      let findings = this.$store.state.site.site.findings;
      if (!findings) {
        return [];
      }
      return findings.filter(
        (finding) => finding.area === this.currentSelectedArea.text
      );
    },
    site() {
      return this.$store.state.site.site;
    },
    levelAreas() {
      let areas = [];

      this.site.levels_areas.forEach((level_area) => {
        if (level_area.level_value === this.selectedLevel) {
          const area = this.site.areas.find(
            (a) => a.value === level_area.area_value
          );
          areas.push(area);
        }
      });

      return areas;
    },
  },
  watch: {
    selectedBuilding() {
      this.selectedLevel = null;
      this.selectedArea = null;
    },
    selectedLevel() {
      this.selectedArea = null;
    },
  },
  methods: {
    getChecklistByType(type) {
      return this.site.checklists.find((checklist) => checklist.type === type);
    },
    getLevelAreas(levelValue) {
      let areas = [];

      this.site.levels_areas.forEach((level_area) => {
        if (level_area.level_value === levelValue) {
          const area = this.site.areas.find(
            (a) => a.value === level_area.area_value
          );
          areas.push(area);
        }
      });

      return areas;
    },
    selectBuilding(buildingId) {
      if (this.selectedBuilding === buildingId) {
        this.selectedBuilding = null;
        return;
      }
      this.selectedBuilding = buildingId;
    },
    selectLevel(levelId) {
      if (this.selectedLevel === levelId) {
        this.selectedLevel = null;
        return;
      }
      this.selectedLevel = levelId;
    },
    selectArea(areaId) {
      if (this.selectedArea === areaId) {
        this.selectedArea = null;
        return;
      }
      this.selectedArea = areaId;
    },
  },
};
</script>
