<template>
  <div>
    <RFieldText v-model="name" class="mb-3" label="שם העיצוב" />

    <RFieldSelect
      v-model="fontFamily"
      class="mb-3"
      label="משפחת גופן"
      :items="fontFamilyOptions"
    />

    <AppFieldImage v-model="backgroundImagePath" label="תמונת רקע (794x1123)" />

    <AppFieldSwitch
      v-model="firstPageHideHeader"
      label="אל תציג חלק עליון בדף הראשון"
    />

    <AppFieldSwitch
      v-model="firstPageHideFooter"
      label="אל תציג חלק תחתון בדף הראשון"
    />

    <RButton color="red" class="mt-5" @click="removePreference">
      מחיקת עיצוב זה
    </RButton>
  </div>
</template>

<script>
import { fontFamilyOptions, typography } from "@/common/settings";
import PreferencesApi from "@/api/PreferencesApi";

export default {
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    fontFamilyOptions,
    typography,
  }),
  computed: {
    name: {
      get: function () {
        return this.modelValue.name;
      },
      set: function (name) {
        this.$emit("update:modelValue", { ...this.modelValue, name });
      },
    },
    fontFamily: {
      get() {
        return this.modelValue.data.style.fontFamily;
      },
      set(fontFamily) {
        this.updateStyle("fontFamily", fontFamily);
      },
    },
    firstPageHideHeader: {
      get() {
        return this.modelValue.data.style.firstPageHideHeader;
      },
      set(firstPageHideHeader) {
        this.updateStyle("firstPageHideHeader", firstPageHideHeader);
      },
    },
    firstPageHideFooter: {
      get() {
        return this.modelValue.data.style.firstPageHideFooter;
      },
      set(firstPageHideFooter) {
        this.updateStyle("firstPageHideFooter", firstPageHideFooter);
      },
    },
    backgroundImagePath: {
      get() {
        return this.modelValue.data.style.backgroundImagePath;
      },
      set(backgroundImagePath) {
        this.updateStyle("backgroundImagePath", backgroundImagePath);
      },
    },
  },
  created() {
    if (!this.modelValue.data.style) {
      let newValue = { ...this.modelValue };

      if (!newValue.data.style) {
        newValue.data.style = {};
      }

      if (!newValue.data.style.fontFamily) {
        newValue.data.style.fontFamily = "Assistant";
      }

      this.$emit("update:modelValue", newValue);
    }
  },
  methods: {
    updateStyle(key, value) {
      let newValue = { ...this.modelValue };

      if (!newValue.data.style) {
        newValue.data.style = {};
      }

      newValue.data.style[key] = value;

      this.$emit("update:modelValue", newValue);
    },
    async removePreference() {
      if (confirm("בטוח למחוק את העיצוב הזה?")) {
        let loader = this.$loading.show();
        await PreferencesApi.removePreference(this.$route.params.id);
        loader.hide();
        await this.$router.push("/preferences");
      }
    },
  },
};
</script>
