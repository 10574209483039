<template>
  <div>
    <v-textarea
      v-model="localItem.settings.content"
      label="html"
      class="mt-3"
      outlined
      hide-details
      rows="7"
      style="text-align: left; direction: ltr"
    >
    </v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    editContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localItem: null,
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
  },
};
</script>
