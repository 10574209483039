<template>
  <div class="r-field r-field--search">
    <div class="r-field-input">
      <input v-model="inputText" :placeholder="placeholder" v-bind="$attrs" />
      <div
        v-if="inputText"
        class="r-field--search-clear"
        @click.stop="inputText = null"
      >
        <v-btn icon x-small>
          <v-icon size="16">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    placeholder: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  data() {
    return {
      inputText: this.modelValue,
    };
  },
  watch: {
    inputText(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.inputText = val;
    },
  },
  methods: {
    updateParentValue(val) {
      this.inputText = val;
    },
  },
};
</script>
