<template>
  <div v-if="isRendered" class="r-page-edit">
    <div class="r-page-edit-header">
      <div class="r-page-edit-header-content">
        <div class="r-page-edit-header-title">👤 הפרופיל שלי</div>
      </div>
    </div>

    <div class="r-page-edit-body">
      <div class="r-page-edit-body-container" style="max-width: 700px">
        <div class="r-page-edit-body-content">
          <RSimpleCard :disabled="isLoading" class="mb-4" title="פרטים אישיים">
            <RFields>
              <RFieldText v-model="userSettings.name" disabled label="שם מלא" />

              <RFieldText
                v-model="userSettings.email"
                disabled
                label="אימייל"
              />

              <RFieldText
                v-model="userSettings.phone"
                label="טלפון"
                data-test="phone"
                :only-numbers="true"
              />

              <RButton
                block
                lg
                :loading="isLoading"
                @click="submitUpdateProfile"
              >
                שמירת שינויים
              </RButton>
            </RFields>
          </RSimpleCard>
          <RSimpleCard
            :disabled="isLoading"
            :title="`הפרופיל שלך ב-${displayCompanyName}`"
          >
            <RFieldText
              v-model="userSettings.profession"
              class="mb-4"
              label="מקצוע"
            />

            <AppFieldWysiwyg v-model="userSettings.education" label="השכלה" />

            <AppFieldWysiwyg v-model="userSettings.experience" label="ניסיון" />

            <AppFieldWysiwyg
              v-model="userSettings.signatureText"
              label="חתימה"
              :items="['bold', 'italic', 'underline']"
            />

            <RButton
              v-if="shouldCreateAutoUserSignature"
              class="mt-0 mb-6"
              sm
              outlined
              color="black"
              @click="createAutoUserSignature"
            >
              צרו לי חתימה אוטומטית
            </RButton>

            <div class="app-field">
              <label class="mb-2 d-block subtitle-2"
                >איזה סוג חתימה תרצו?</label
              >
              <div class="d-flex u-gap-1">
                <RButton
                  icon-before="mdi-image"
                  :outlined="userSettings.signatureType === 'draw'"
                  sm
                  @click="userSettings.signatureType = 'image'"
                  >תמונה</RButton
                >
                <RButton
                  icon-before="mdi-pencil"
                  :outlined="userSettings.signatureType === 'image'"
                  sm
                  @click="userSettings.signatureType = 'draw'"
                  >ציור חתימה</RButton
                >
              </div>
            </div>

            <AppFieldImage
              v-if="userSettings.signatureType === 'image'"
              v-model="userSettings.signatureImage"
              label="תמונת חתימה"
            />

            <AppFieldSignature
              v-if="userSettings.signatureType === 'draw'"
              v-model="userSettings.signatureDraw"
              label="ציור חתימה"
              modal-title="חתימה"
            />

            <br />

            <RButton block lg :loading="isLoading" @click="submitUpdateProfile">
              שמירת שינויים
            </RButton>
          </RSimpleCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { trackEvent } from "@/services/TrackingService";
import AuthApi from "@/api/AuthApi";
// import AppTabs from "@/components/ui/AppTabs.vue";
export default {
  // components: { AppTabs },
  data() {
    return {
      selectedTab: "",
      userSettings: {
        name: null,
        email: null,
        phone: null,
        education: null,
        experience: null,
        profession: null,
        signatureText: null,
        signatureImage: null,
        signatureType: "image",
        signatureDraw: null,
      },
      isRendered: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["user", "displayCompanyName"]),
    shouldCreateAutoUserSignature() {
      return (
        !this.userSettings.signatureText ||
        this.userSettings.signatureText.length < 10
      );
    },

    profileTabs() {
      return [
        {
          label: "הפרופיל שלך",
          id: "personal",
        },
        {
          label: `הפרופיל שלך ב-${this.displayCompanyName}`,
          id: "company",
        },
      ];
    },
  },

  async created() {
    this.selectedTab = this.$route.params.tab || "personal";
    await this.setInitialData();
    this.isRendered = true;
  },

  methods: {
    ...mapActions("auth", ["fetchMe"]),
    createAutoUserSignature() {
      let nameAndProfession = this.userSettings.name;
      if (this.userSettings.profession) {
        nameAndProfession += ", " + this.userSettings.profession;
      }

      this.userSettings.signatureText = `<p>
        <strong>בברכה,</strong>
        <br>
        <strong>${nameAndProfession}</strong>
        <br>
        <strong>${this.displayCompanyName}</strong>
        </p>`;
      this.isRendered = false;
      this.$nextTick(() => {
        this.isRendered = true;
      });
    },
    async setInitialData() {
      let loader = this.$loading.show();

      this.userSettings.name = this.user.name;
      this.userSettings.email = this.user.email;
      this.userSettings.phone = this.user.phone;
      this.userSettings.profession = this.user.profession;
      this.userSettings.education = this.user.education;
      this.userSettings.experience = this.user.experience;
      this.userSettings.signatureText = this.user.signatureText;
      this.userSettings.signatureType = this.user.signatureType || "image";
      this.userSettings.signatureImage = this.user.signatureImage;
      this.userSettings.signatureDraw = this.user.signatureDraw
        ? this.user.signatureDraw
        : "";

      loader.hide();
    },
    async submitUpdateProfile() {
      trackEvent("Profile Updated");

      let userData = {
        name: this.userSettings.name,
        email: this.userSettings.email,
        phone: this.userSettings.phone,
        profession: this.userSettings.profession,
        experience: this.userSettings.experience,
        signatureText: this.userSettings.signatureText,
        signatureType: this.userSettings.signatureType,
        signatureImage: this.userSettings.signatureImage,
        signatureDraw: this.userSettings.signatureDraw,
        education: this.userSettings.education,
      };

      this.isLoading = true;

      try {
        await AuthApi.updateMe(userData);
        await this.fetchMe();
        this.$toast.success("הפרופיל עודכן בהצלחה");
        this.isLoading = false;
      } catch (e) {
        this.$toast.error(
          this.$Utils.toastMessageFromResponse(
            e,
            "שגיאה: לא הצלחנו לעדכן את הפרופיל"
          )
        );
        this.isLoading = false;
      }
    },
  },

  watch: {
    selectedTab() {
      // Update the URL query parameter when the selected tab changes
      if (this.selectedTab === undefined) return;
      const tab = this.profileTabs[this.selectedTab]?.id;

      if (this.$route.params.tab === tab) return;

      this.$router.push({
        params: { tab },
      });
    },
  },
};
</script>

<style lang="scss">
.profile-container {
  --ck-border-radius: var(--r-field-input-border-radius);

  padding: 1.5rem 1.5rem 1.5rem !important;
  border-radius: 1.5rem;
  background: white;
  margin-top: 1.5rem !important;
  margin: 3rem !important;
  font-family: var(--font-family-noto);
  @media (max-width: 767px) {
    border-radius: 0;
    margin: 0rem !important;
  }

  .v-tab {
    font-size: 1rem !important;
    border-bottom: 1px solid var(--color-gray-300);

    &--active {
      font-family: var(--font-family-noto);
      font-weight: 700;
    }

    &:hover {
      &::before {
        opacity: 0 !important;
      }
    }
  }

  .r-simple-card {
    font-family: var(--font-family-noto);
    border: none;
    color: var(--color-gray-700) !important;
    @media (max-width: 767px) {
      padding-inline: 1rem;
    }

    &-title {
      font-family: var(--font-family-noto);
      font-weight: 700;
      font-size: 1.25rem;
      margin-top: 0.5rem;
    }

    &-subtitle {
      color: var(--color-gray-500) !important;
    }

    .app-field label,
    .r-field label {
      font-family: var(--font-family-noto);
      font-size: 16px !important;
      color: var(--color-gray-700) !important;
      margin-bottom: 0.5rem !important;
    }

    input {
      padding: 15px 20px;
      color: var(--color-gray-700) !important;
      font-size: 1rem !important;
      max-width: 30rem;
    }

    .ck-editor {
      color: var(--color-gray-700) !important;
      font-size: 1rem !important;
      max-width: 30rem;
    }

    .r-button {
      font-family: var(--font-family-noto);
      border-radius: 100px;
      padding: 0.65rem 1rem;
    }

    .r-fields {
      margin-top: 1.5rem;
    }
  }
}
</style>
