<template>
  <div class="r-simple-card-details__item">
    <div v-if="label" class="r-simple-card-details__item-label">
      {{ label }}
    </div>
    <div class="r-simple-card-details__item-value">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>
