<template>
  <RSimpleCard title="הוספת מפלס">
    <RFields>
      <RFieldText v-model="addLevelText" label="שם המפלס" />
      <RButton lg block :disabled="!addLevelText" @click="addLevel"
        >הוספה</RButton
      >
    </RFields>
  </RSimpleCard>
</template>

<script>
export default {
  data() {
    return {
      addLevelText: "",
      addLevelType: "",
    };
  },
  computed: {
    site() {
      return this.$store.state.site.site;
    },
  },
  methods: {
    addLevel() {
      this.$store.dispatch("site/addLevel", {
        text: this.addLevelText,
      });
      this.$router.push("/sites/building");
    },
  },
};
</script>
