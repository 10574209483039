async function createPreference(name) {
  const response = await window.axios.post(`/api/v1/preferences`, {
    name: name,
  });
  return response.data;
}

async function exportPreference(preferenceId) {
  await window.axios
    .get(`/api/v1/preferences/${preferenceId}/export`, {
      responseType: "blob",
    })
    .then((response) => {
      const headerval = response.headers["content-disposition"];
      if (headerval != null) {
        let filename = headerval
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");
        filename = decodeURIComponent(filename.replace(/\+/g, " "));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        // link.dispatchEvent(new MouseEvent("click"));
        window.URL.revokeObjectURL(url);
        link.remove();
      } else {
        console.error(response);
      }
    })
    .catch((error) => {
      console.error("Fail to download preference", error);
    });
}

async function importPreference(file) {
  let fd = new FormData();
  fd.append("file", file);
  const response = await window.axios.post(`/api/v1/preferences/import`, fd);
  return response.data;
}

async function getPreferences() {
  const response = await window.axios.get(`/api/v1/preferences`);
  return response.data;
}

async function getPreference(preferenceId) {
  const response = await window.axios.get(
    `/api/v1/preferences/${preferenceId}`
  );
  return response.data;
}

async function updatePreference(preferenceId, preference) {
  const response = await window.axios.put(
    `/api/v1/preferences/${preferenceId}`,
    preference
  );
  return response.data;
}

async function removePreference(preferenceId) {
  const response = await window.axios.delete(
    `/api/v1/preferences/${preferenceId}`
  );
  return response.data;
}

export default {
  createPreference,
  exportPreference,
  importPreference,
  getPreferences,
  getPreference,
  updatePreference,
  removePreference,
};
