<template>
  <div class="r-auth-wrapper">
    <div class="r-auth-inner">
      <div class="r-auth-logo">
        <img src="@/assets/images/reporto-logo.svg" alt="logo" />
      </div>
      <div class="r-auth-title">איזה כיף! הסיסמה שונתה בהצלחה</div>
      <div class="r-auth-text">
        המלצה מאיתנו - אחסן את הסיסמה בפתקים בטלפון ו\או בפתקית או נייר בסמוך
        למכשיר שאיתו אתה עובד במערכת של רפורטו
      </div>

      <RButton
        lg
        color="primary"
        class="mt-10"
        block
        type="submit"
        :to="{ name: 'login' }"
      >
        תודה רבה! קחו אותי למערכת
      </RButton>
    </div>
  </div>
</template>
