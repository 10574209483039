<template>
  <rp-container-small v-if="isRendered">
    <RSimpleCard
      :disabled="isLoadingEdit || isLoadingDelete"
      :title="`עריכת משתמש / ${edit_user.name}`"
    >
      <v-form ref="form" v-model="valid" @submit.prevent="">
        <RFields>
          <RFieldText
            v-model="edit_user.name"
            :rules="rules.required"
            label="שם מלא"
            disabled
          />

          <RFieldText
            v-model="edit_user.email"
            :rules="rules.email"
            label="אימייל"
            disabled
          />

          <RFieldText v-model="edit_user.profession" label="מקצוע" />
          <RFieldSelect
            v-model="edit_user.role_id"
            :items="rolesChoices"
            label="הרשאות"
          />
        </RFields>

        <br />

        <AppFieldWysiwyg v-model="edit_user.experience" label="ניסיון" />

        <AppFieldWysiwyg v-model="edit_user.education" label="השכלה" />

        <AppFieldSwitch
          v-model="edit_user.permissionDisableSendReportToEmail"
          label="לא לאפשר למשתמש זה לשלוח דוחות למייל"
        />

        <AppFieldSwitch v-model="edit_user.status" label="משתמש פעיל" />

        <br />

        <RButton
          v-if="userId !== user.id"
          color="red"
          outlined
          :loading="isLoadingDelete"
          class="mb-10"
          sm
          icon-before="mdi-delete"
          @click="removeUser"
        >
          מחיקת משתמש זה
        </RButton>

        <RButton
          color="primary"
          class="mt-2"
          block
          lg
          :loading="isLoadingEdit"
          @click="editUser"
        >
          עדכון משתמש
        </RButton>
      </v-form>
    </RSimpleCard>
  </rp-container-small>
</template>
<script>
import { roles } from "@/common/settings";
import { mapGetters } from "vuex";
import UsersApi from "@/api/UsersApi";

export default {
  data() {
    return {
      isLoadingEdit: false,
      isLoadingDelete: false,
      isRendered: false,
      valid: false,
      userId: this.$route.params.id,
      edit_user: {
        name: null,
        email: null,
        role_id: null,
        phone: null,
        profession: null,
        experience: null,
        signatureText: null,
        signatureImage: null,
        education: null,
        permissionDisableSendReportToEmail: false,
        status: false,
      },
      rules: {
        required: [(value) => !!value || "שדה חובה"],
        email: [
          (v) => !!v || "אימייל שדה חובה",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "אימייל לא תקין",
        ],
      },
      roles,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    rolesChoices() {
      let roles = [];
      Object.keys(this.roles).forEach((key) => {
        // Exclude Owner role from Admins
        if (this.roles[key] === "Owner" && !this.$checkRole(["owner"])) {
          return;
        }
        roles.push({
          value: parseInt(key),
          text: this.$Utils.getRoleHebrewName(this.roles[key]),
        });
      });

      return roles;
    },
  },
  async created() {
    await this.fetchUser();
    this.isRendered = true;
  },
  methods: {
    async fetchUser() {
      let loader = this.$loading.show();
      const data = await UsersApi.getUser(this.userId);

      this.edit_user.name = data.data.name;
      this.edit_user.email = data.data.email;
      this.edit_user.role_id = data.data.role_id;
      if (data.data.phone) {
        this.edit_user.phone = data.data.phone;
      }
      if (data.data.profession) {
        this.edit_user.profession = data.data.profession;
      }
      this.edit_user.education = data.data.education;
      this.edit_user.experience = data.data.experience;
      this.edit_user.signatureText = data.data.signatureText;
      this.edit_user.signatureImage = data.data.signatureImage;
      this.edit_user.permissionDisableSendReportToEmail =
        data.data.permissionDisableSendReportToEmail;
      this.edit_user.status = data.data.status === 1 ? true : false;

      loader.hide();
    },
    async editUser() {
      this.valid = this.$refs.form.validate();
      if (!this.valid) return;

      this.isLoadingEdit = true;
      let edit_user = { ...this.edit_user };

      edit_user.status = edit_user.status === true ? 1 : 0;
      edit_user.role_id = parseInt(edit_user.role_id);

      try {
        await UsersApi.updateUser(this.userId, edit_user);
        this.$toast.success("המשתמש עודכן בהצלחה");
        // this.$router.push("/users");
        this.isLoadingEdit = false;
      } catch (e) {
        console.log("e", e);
        this.$toast.error(
          this.$Utils.toastMessageFromResponse(
            e,
            "שגיאה: לא הצלחנו לעדכן את המשתמש"
          )
        );
        this.isLoadingEdit = false;
      }
    },
    async removeUser() {
      if (!confirm("בטוח למחוק את המשתמש " + this.edit_user.name)) return;

      this.isLoadingDelete = true;

      try {
        await UsersApi.deleteUser(this.userId);
        this.$toast.success("המשתמש נמחק בהצלחה");
        this.$router.push("/users");
        this.isLoadingDelete = false;
      } catch (error) {
        this.isLoadingDelete = false;
      }
    },
  },
};
</script>
