import { Utils } from "@/common/Utils";

const trackEvent = (event, eventObject = {}) => {
  if (
    typeof window.mixpanel !== "undefined" &&
    typeof window.mixpanel.track !== "undefined"
  ) {
    console.log("mixpanel.track:", event, eventObject);
    // if (Utils.getEnv() !== "local") {
    //   window.mixpanel.track(event, {
    //     ...eventObject,
    //   });
    // } else {
    //   console.log("mixpanel.track:", event, eventObject);
    // }
  }
};

const trackPageView = (eventObject = {}) => {
  if (
    typeof window.mixpanel !== "undefined" &&
    typeof window.mixpanel.track !== "undefined"
  ) {
    if (Utils.getEnv() !== "local") {
      window.mixpanel.track_pageview(eventObject);
    } else {
      console.log("mixpanel.track_pageview:", eventObject);
    }
  }
};

export { trackEvent, trackPageView };
