<template>
  <div v-if="shouldSee">
    <v-card
      v-if="projects.length && !loading"
      outlined
      class="mobile-full-width"
    >
      <v-card-title class="d-flex justify-space-between">
        <div>פרויקטים</div>
        <RButton
          v-if="$checkRole(['admin', 'owner'])"
          color="black"
          sm
          :to="{
            name: 'projects.add',
          }"
        >
          פרויקט חדש
        </RButton>
      </v-card-title>
      <v-card-subtitle v-if="!loading && projects.length" class="mt-n2">
        <v-text-field
          v-model="searchQuery"
          prepend-inner-icon="mdi-magnify"
          placeholder="חיפוש"
          density="compact"
          variant="outlined"
          hide-details
          :clearable="true"
          class="search-input"
        ></v-text-field>
      </v-card-subtitle>

      <div class="home-projects-table-wrapper">
        <v-data-table
          v-if="projects.length"
          :headers="headers"
          :items="projects"
          :hide-default-footer="true"
          :items-per-page="-1"
          :search="searchQuery"
          no-results-text="לא נמצאו פרויקטים לחיפוש"
          class="elevation-0 projects-table"
          mobile-breakpoint="0"
          @click:row="goToProject"
        >
          <template #[`item.number`]="{ item }">
            {{ item.number }}
            <v-icon v-if="item.visibility === 'private'" class="mr-1" size="14">
              mdi-lock
            </v-icon>
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ $Utils.presentHebrewDate(item.created_at) }}
          </template>
        </v-data-table>
      </div>

      <v-card-actions>
        <RButton color="grey" to="/projects" block> לכל הפרויקטים </RButton>
      </v-card-actions>
    </v-card>
    <v-card
      v-if="!projects.length && !loading"
      outlined
      class="py-12 px-5 text-center mobile-full-width"
    >
      <br />
      <img
        src="@/assets/images/cta-first-report.png"
        srcset="@/assets/images/cta-first-report@2x.png 2x"
        alt="first-report"
        style="pointer-events: none; max-width: 100%; height: auto"
      />
      <div class="rp-h4">בואו ניצור את הפרויקט הראשון שלכם!</div>
      <div class="rp-p2 mt-1 mb-4">3 קליקים ויצרתם פרויקט 🥳</div>

      <v-btn color="black" depressed dark to="/projects/add">
        יצירת הפרויקט הראשון
        <v-icon class="mr-4">mdi-text-box-plus</v-icon>
      </v-btn>

      <br />
    </v-card>
    <v-card v-if="loading" outlined class="mobile-full-width">
      <div class="text-center px-2 py-6">
        <RIconLoading />
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      projects: [],
      searchQuery: null,
    };
  },
  computed: {
    headers() {
      let headers = [];

      headers.push({
        title: "מספר",
        value: "number",
        width: "100px",
        sortable: true,
      });

      headers.push({
        title: "שם פרויקט",
        value: "name",
        sortable: true,
      });

      headers.push({
        title: "כתובת",
        value: "address",
        sortable: true,
      });

      headers.push({
        title: "לקוח",
        value: "customer_name",
        sortable: true,
      });

      if (window.innerWidth > 767) {
        headers.push({
          title: "תאריך יצירה",
          value: "created_at",
          width: "180px",
          sortable: true,
        });
      }

      return headers;
    },
    shouldSee() {
      if (this.$checkRole(["admin", "owner"])) {
        return true;
      }

      if (!this.projects.length && !this.loading) {
        return false;
      }

      return true;
    },
  },
  async created() {
    this.loading = true;
    await this.fetchProjects();
    this.loading = false;
  },
  methods: {
    goToProject(event, row) {
      let projectId = row.item.id;
      this.$router.push({
        name: "projects.edit",
        params: { id: projectId },
      });
    },
    async fetchProjects() {
      await this.$store.dispatch("projects/fetchProjects");
      this.projects = this.$store.state.projects.projects;
    },
  },
};
</script>
<style lang="scss">
.home-projects-table-wrapper {
  max-height: 457px;
  overflow-y: auto;
  @media (max-width: 767px) {
    max-height: 265px;
  }
}

.projects-table ::v-deep tbody tr {
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}
</style>
