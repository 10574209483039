<template>
  <div>
    <v-card flat class="px-0 pa-0 bg-transparent">
      <AppFieldText v-model="localItem.settings.headline" label="כותרת" />

      <AppFieldAlign
        v-model="localItem.settings.headlineAlign"
        label="ישור כותרת"
      />

      <AppFieldAlign
        v-model="localItem.settings.signaturesAlign"
        label="ישור חתימות"
        :items="['right', 'left']"
      />

      <v-list class="pa-0 bg-transparent">
        <draggable
          :list="localItem.settings.columns"
          handle=".v-avatar"
          v-bind="dragOptions"
          item-key="id"
        >
          <template #item="{ element: column }">
            <v-list-item
              :key="column.id"
              border
              class="mb-2 rounded"
              style="background:#fff"
              :class="{ disableDelete: column.type === 'signature' }"
            >
              <template #prepend>
                <v-avatar size="25" class="mr-2">
                  <v-icon>mdi-drag</v-icon>
                </v-avatar>
              </template>

              <input
                v-model="column.settings.label"
                placeholder="הכנס שם שדה"
                type="text"
                class="tableColumnOptions-input ml-2"
                style="flex: 1;width:100%;border:0;background:none;"
              />

              <template #append>
                <RButtonIcon
                  icon="mdi-delete"
                  color="grey-lighten-1"
                  @click="removeRepeaterItem(column.id)"
                />
              </template>
            </v-list-item>
          </template>
        </draggable>
      </v-list>


      <RButton
        icon="mdi-plus"
        class="ma-1 mx-auto d-block"
        @click="addRepeaterItem"
      >
      הוספת שדה
      </RButton>

      <br />

      <report-signatures
        label="חתימות"
        :load-item="localItem"
        update-key="rows"
        @do-update="doUpdateRepeater"
        @do-update-many="doUpdateManyRepeater"
      />
    </v-card>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { dragOptions, reportTemplateItemTypes } from "@/common/settings";
import { Utils } from "@/common/Utils";
import ReportSignatures from "@/views/reports/components/ReportSignatures.vue";

export default {
  components: {
    ReportSignatures,
    draggable,
  },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    editContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localItem: null,
      dragOptions,
      itemTypes: reportTemplateItemTypes,
      repeaterItemModal: {
        isOpen: false,
        item: {
          settings: {},
        },
      },
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
    addRepeaterItem() {
      let newItem = {
        id: Utils.guid(),
        type: "text",
        settings: {
          label: "",
        },
      };

      this.localItem.settings.columns.push(newItem);
    },
    removeRepeaterItem(itemId) {
      if (confirm("בטוח למחוק את השדה?")) {
        this.localItem.settings.columns =
          this.localItem.settings.columns.filter((item) => {
            return item.id !== itemId;
          });
      }
    },
    async doUpdateRepeater({ key, value }) {
      let cleanData = JSON.parse(
        JSON.stringify({
          [key]: value,
        })
      );

      let dataUploaded = await this.uploadObjectImages(cleanData);
      dataUploaded = Utils.UnFlattenObject(dataUploaded).rows.settings;

      let settingsNew = Utils.flatAndMergeObjects(
        this.localItem.settings,
        dataUploaded
      );

      let settingsOld = this.localItem.settings;

      this.localItem.settings.rows = Utils.flatAndMergeObjects(
        settingsOld.rows,
        settingsNew.rows
      );
    },
    async doUpdateManyRepeater({ data }) {
      let cleanData = JSON.parse(JSON.stringify(data));

      let dataUploaded = await this.uploadObjectImages(cleanData);
      dataUploaded = Utils.UnFlattenObject(dataUploaded).rows.settings;

      let settingsNew = Utils.flatAndMergeObjects(
        this.localItem.settings,
        dataUploaded
      );

      let settingsOld = this.localItem.settings;

      this.localItem.settings.rows = Utils.flatAndMergeObjects(
        settingsOld.rows,
        settingsNew.rows
      );
    },
    async uploadObjectImages(obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (value && Utils.isBase64Image(value)) {
          let loader = this.$loading.show();
          let { data } = await Utils.uploadImageBase64(value);
          loader.hide();
          obj[key] = data.path;
        }
      }

      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  &.disableDelete {
    .v-list-item__action {
      opacity: 0;
      pointer-events: none;
    }
    .v-list-item__content {
      pointer-events: none;
    }
  }
}
</style>
