<template>
  <div class="r-layout-login">
    <VApp>
      <RouterView />
    </VApp>
  </div>
</template>
<script>
export default {
  name: "LayoutLogin",
};
</script>
