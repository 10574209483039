<template>
  <RSimpleCard :title="`הוספת אזור ל${currentLevelName}`">
    <RFields>
      <RFieldText v-model="addAreaText" label="שם האזור" />

      <div class="mb-2">
        סוג אזור
        <small>(זה בעצם יוסיף את הצ׳קליסט)</small>
        :
      </div>

      <RFieldRadio v-model="addAreaTypeId" :items="areaTypesOptions" />
      <RButton
        lg
        block
        :disabled="!addAreaText || !addAreaTypeId"
        @click="addArea"
        >הוספה</RButton
      >
    </RFields>
  </RSimpleCard>
</template>

<script>
export default {
  data() {
    return {
      currentLevelId: this.$route.params.id,
      addAreaText: "",
      addAreaTypeId: null,
    };
  },
  computed: {
    site() {
      return this.$store.state.site.site;
    },
    currentLevel() {
      return this.site.levels.find((l) => l.id === this.currentLevelId);
    },
    currentLevelName() {
      return this.currentLevel.text;
    },
    areaTypesOptions() {
      return this.site.areaTypes.map((areaType) => ({
        text: areaType.name,
        value: areaType.id,
      }));
    },
  },
  methods: {
    addArea() {
      this.$store.dispatch("site/addArea", {
        text: this.addAreaText,
        type_id: this.addAreaTypeId,
        levelId: this.currentLevelId,
      });
      this.$router.push("/sites/level/" + this.currentLevelId);
    },
  },
};
</script>
