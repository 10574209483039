<template>
  <div class="app-field">
    <label v-if="label" class="app-field__label"> {{ label }} </label>
    <v-text-field
      v-model="inputText"
      density="compact"
      variant="outlined"
      style="background: #fff"
      hide-details
      clearable
      v-bind="$attrs"
    >
    </v-text-field>

    <div v-if="choices" class="mt-3">
      <v-btn
        v-for="choice in choicesFormatted"
        :key="choice.id"
        :color="choice.color"
        depressed
        :variant="choice.value === inputText ? 'flat' : 'outlined'"
        style="min-width: auto;padding-block: 8px;height: auto;"
        class="ml-1 mb-2"
        @click="choiceClicked(choice)"
      >
        {{ choice.label }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    choices: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      inputText: this.modelValue,
    };
  },
  computed: {
    choicesFormatted() {
      let choicesFormatted = [];

      for (const [, choice] of Object.entries(this.choices)) {
        choicesFormatted.push({
          value: choice.value ? choice.value : choice.label,
          label: choice.label,
          color:
            choice.color &&
            choice.color !== "#ffffff" &&
            choice.color !== "#fff"
              ? choice.color
              : "black",
        });
      }

      return choicesFormatted;
    },
  },
  watch: {
    inputText(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.inputText = val;
    },
  },
  methods: {
    choiceClicked(choice) {
      if (!this.multiple) {
        this.inputText = choice.value;
      } else {
        // add comma separated values if multiple, and don't remove values if already present
        if (this.inputText) {
          this.inputText += this.inputText ? `, ${choice.value}` : choice.value;
        } else {
          this.inputText = choice.value;
        }
      }
    },
    updateParentValue(val) {
      this.inputText = val;
    },
  },
};
</script>
