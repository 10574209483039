<template>
  <div v-if="isLoaded">
    <div class="r-page-table">
      <div class="r-page-table-header">
        <div class="r-page-table-header-content">
          <h3 class="r-page-table-header-title">
            🎨 עיצובים (לוגו עליון ותחתון)
            <span v-if="preferences && preferences.length"
              >({{ preferences.length }})</span
            >
          </h3>
        </div>
        <div class="r-page-table-header-actions">
          <RSearchCompact v-model="searchQuery" :initial-open="true" />
          <RButton
            color="purple"
            :to="{
              name: 'preferences.add',
            }"
          >
            עיצוב חדש
          </RButton>
        </div>
      </div>

      <div class="r-page-table-body">
        <v-data-table
          v-if="isLoaded && preferences.length"
          :headers="headers"
          :items="preferences"
          :hide-default-footer="true"
          :items-per-page="-1"
          :search="searchQuery"
          no-results-text="לא נמצאו עיצובים לחיפוש"
          class="elevation-0 preferences-table"
          mobile-breakpoint="0"
        >
          <template #[`item.name`]="{ item }">
            <router-link
              :to="{
                name: 'preferences.edit',
                params: { id: item.id },
              }"
              class="text--primary"
              style="text-decoration: underline; text-underline-position: under"
            >
              {{ item.name }}
            </router-link>
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ $Utils.presentHebrewDate(item.created_at) }}
          </template>
          <template #[`item.actions`]="{ item }">
            <RActions>
              <RAction
                :to="{
                  name: 'preferences.edit',
                  params: { id: item.id },
                }"
                icon="mdi-pencil"
                text="עריכה"
              ></RAction>
              <RAction
                icon="mdi-export"
                text="ייצוא עיצוב"
                @click="exportPreference(item.id)"
              ></RAction>
            </RActions>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
  <page-loader v-else />
</template>

<script>
import PreferencesApi from "@/api/PreferencesApi";
import PageLoader from "@/components/PageLoader.vue";

export default {
  components: { PageLoader },
  data() {
    return {
      searchQuery: null,
      isLoaded: false,
      fileToImport: null,
      preferences: [],
    };
  },
  computed: {
    headers() {
      let headers = [];

      headers.push({
        title: "שם עיצוב",
        value: "name",
        sortable: true,
      });

      if (window.innerWidth > 767) {
        headers.push({
          title: "תאריך יצירה",
          value: "created_at",
          width: "180px",
          sortable: true,
        });
      }

      headers.push({
        title: "פעולות",
        value: "actions",
        width: "80px",
        sortable: false,
      });

      return headers;
    },
  },
  async created() {
    await this.fetchPreferences();
    this.isLoaded = true;
  },
  methods: {
    selectImportFile(file) {
      this.fileToImport = file;
    },
    async importPreference() {
      let loader = this.$loading.show();

      try {
        await PreferencesApi.importPreference(this.fileToImport);
        this.isLoaded = false;
        await this.fetchPreferences();
        this.isLoaded = true;
        this.$toast.success(`ייבוא העיצוב הסתיים בהצלחה.`);
        loader.hide();
        this.fileToImport = null;
      } catch (error) {
        this.$toast.error("שגיאה בתהליך היבוא");
        loader.hide();
      }
    },
    async exportPreference(templateId) {
      if (!this.$Utils.isDesktop()) {
        this.$toast.error("ייצוא עיצובים אפשרי רק דרך מחשב");
        return;
      }
      let loader = this.$loading.show();

      await PreferencesApi.exportPreference(templateId);

      loader.hide();
    },
    async fetchPreferences() {
      const { data } = await PreferencesApi.getPreferences();
      this.preferences = data;
    },
  },
};
</script>
