<template>
  <div class="app-field app-field--checkbox">
    <v-checkbox
      v-model="inputText"
      :ripple="false"
      :label="label"
      class="custom-checkbox my-0 max-h-8"
      hide-details
      color="primary"
      density="compact"
    >
    </v-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputText: false,
    };
  },
  watch: {
    inputText(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.inputText = val;
    },
  },
  created() {
    this.inputText = this.modelValue;
  },
  methods: {
    updateParentValue(val) {
      this.inputText = val;
    },
  },
};
</script>

<style lang="scss">
.custom-checkbox {
  max-height: 28px;

  .v-label {
    color: black !important;
    opacity: 1;
    margin-right: 0.52em;
  }
}
</style>
