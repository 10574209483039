<template>
  <RModal
    v-if="isNoTemplatesModalOpen"
    v-model="isNoTemplatesModalOpen"
    size="lg"
    title="זה הזמן להפיק את הדוח הראשון שלכם 💪"
  >
    <div class="nt-steps">
      <div class="nt-step">
        <div class="nt-step__title">1. מוסיפים תבנית חדשה</div>
        <div class="nt-step__text">
          מתוך ספריית התבניות שיצרנו עבורכם, או שאתם יכולים להעלות את אחד הדוחות
          שאתם כבר עושים היום.
        </div>
        <img
          src="@/assets/images/create-template.png"
          srcset="@/assets/images/create-template@2x.png 2x"
          alt="מוסיפים תבנית חדשה"
        />
        <div class="nt-step__bottomText">
          <b>תבנית</b>
          זה בעצם
          <b>המבנה(השלד) של הדוח.</b>
          <br />
          בונים פעם אחת את המבנה של הכותרות, הטבלאות, טקסטים ואז מתוך התבנית כל
          פעם יוצרים דוח.
        </div>
      </div>
      <div class="nt-step">
        <div class="nt-step__title">2. יוצרים דוח חדש</div>
        <div class="nt-step__text">
          לוחצים על ״יצירת דוח חדש״, בוחרים את התבנית הרצויה וממלאים את הפרטים
          בדוח.
        </div>
        <img
          src="@/assets/images/create-report.png"
          srcset="@/assets/images/create-report@2x.png 2x"
          alt="מוסיפים תבנית חדשה"
        />
        <div class="nt-step__bottomText">
          <b>דוח</b>
          זה בעצם
          <b>התוכן של הדוח.</b>
          <br />
          לוקחים את התבנית ומתחילים למלא בה תמונות, מלל, ממצאים וכו׳...
          <br />
        </div>
      </div>
    </div>

    <div
      class="text-center mt-8 mb-4 d-flex flex-column justify-center align-center"
    >
      <RButton color="primary" @click="redirectToCreateNewTemplate">
        להוספת התבנית הראשונה >>
      </RButton>
    </div>
  </RModal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isNoTemplatesModalOpen: false,
    };
  },
  computed: {
    ...mapGetters("reportTemplates", ["reportTemplates"]),
    hasSeenNoTemplatesModal() {
      return window.sessionStorage.getItem("cache/hasSeenNoTemplatesModal");
    },
    hasReportTemplates() {
      return this.reportTemplates.length > 0;
    },
  },
  watch: {
    isNoTemplatesModalOpen(val) {
      if (!val) {
        window.sessionStorage.setItem("cache/hasSeenNoTemplatesModal", "yes");
      }
    },
  },
  async created() {
    if (!this.hasSeenNoTemplatesModal && !this.hasReportTemplates) {
      setTimeout(() => {
        this.isNoTemplatesModalOpen = true;
      }, 2000);
    }
  },
  methods: {
    redirectToCreateNewTemplate() {
      window.sessionStorage.setItem("cache/hasSeenNoTemplatesModal", "yes");
      this.$router.push("/report-templates/?new=1");
    },
  },
};
</script>

<style lang="scss">
.nt-steps {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
}
.nt-step {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 5px;
  color: #000;

  img {
    max-width: 100%;
    height: auto;
  }

  &__title {
    color: var(--rp-color-purple);
    font-size: 18px;
    font-weight: 600;
  }
  &__text {
    font-size: 14px;
    max-width: 330px;
    margin-bottom: 10px;
  }
  &__bottomText {
    font-size: 13px;
    max-width: 330px;
    b {
      font-weight: 600;
    }
  }
}
</style>
