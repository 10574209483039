<template>
  <div v-if="isLoaded">
    <div class="r-page-table">
      <div class="r-page-table-header">
        <div class="r-page-table-header-content">
          <h3 class="r-page-table-header-title">
            🗂️ תבניות דוחות
            <span v-if="reportTemplates && reportTemplates.length"
              >({{ reportTemplates.length }})</span
            >
          </h3>
        </div>
        <div class="r-page-table-header-actions">
          <RSearchCompact v-model="searchQuery" :initial-open="true" />
          <div class="d-flex u-gap-2 align-center">
            <RButton color="purple" @click="createTemplateModal.isOpen = true">
              תבנית חדשה
            </RButton>
            <RActions icon="mdi-cog">
              <RAction
                icon="mdi-sort"
                text="שינוי סדר התבניות"
                @click="orderTemplatesModal.isOpen = true"
              ></RAction>
              <RAction
                icon="mdi-cloud-upload"
                text="ייבוא תבנית של רפורטו"
                @click="importTemplateModal.isOpen = true"
              ></RAction>
              <RAction
                icon="mdi-folder-plus"
                text="יצירת תיקייה חדשה"
                @click="openCreateFolderModal(null)"
              ></RAction>
            </RActions>
          </div>
        </div>
      </div>

      <div class="r-page-table-body">
        <RSimpleCard
          :loading="isLoading"
          :disabled="isLoading"
          class="px-0 py-0 templates-tree-card"
        >
          <VTreeview
            v-model="tree"
            :items="filteredFoldersTree"
            item-key="id"
            open-on-click
          >
            <template #prepend="{ item, open }">
              <RIcon
                v-if="item.type === 'template'"
                class="ml-2"
                @click="rowCheckboxClicked(item)"
              >
                <template v-if="isRowSelected(item)"
                  >mdi-checkbox-marked</template
                >
                <template v-else>mdi-checkbox-blank-outline</template>
              </RIcon>
              <RIcon v-if="item.type === 'folder'">
                {{ open ? "mdi-folder-open" : "mdi-folder" }}
              </RIcon>
              <RIcon v-else color="#2e90fa">mdi-view-compact-outline</RIcon>
            </template>
            <template #title="{ item }">
              <RouterLink
                v-if="item.type === 'template'"
                :to="`/report-templates/edit/${item.id}`"
              >
                {{ item.name }}
              </RouterLink>
              <span v-if="item.type === 'folder'"
                >{{ item.name }}
                <small>({{ item.children.length }})</small></span
              >
            </template>
            <template #append="{ item }">
              <!--
              <small>
                תאריך יצירה:
                {{ $Utils.presentHebrewDate(item.created_at, "DD/MM/YYYY") }}
              </small>
              -->
              <!--
              <RButton
                v-if="item.type === 'folder'"
                color="black"
                xs
                outlined
                icon-before="mdi-plus"
                class="ml-2 u-mobile-hidden tree-action-button"
                @click.stop="openCreateFolderModal(item.id)"
              >
                תת תיקייה
              </RButton>
              -->

              <RActions v-if="item.type === 'template'">
                <RAction
                  icon="mdi-pencil"
                  text="עריכה"
                  :to="{
                    name: 'report-templates.edit',
                    params: { id: item.id },
                  }"
                ></RAction>
                <RAction
                  icon="mdi-key"
                  text="הרשאות"
                  :to="{
                    name: 'report-templates.permissions',
                    params: { id: item.id },
                  }"
                ></RAction>
                <RAction
                  icon="mdi-content-copy"
                  text="שכפול"
                  @click="duplicateReportTemplate(item.id)"
                ></RAction>
                <RAction
                  icon="mdi-export"
                  text="ייצוא תבנית"
                  @click="exportTemplate(item.id)"
                ></RAction>
                <RAction
                  icon="mdi-folder"
                  text="העברת תבנית לתיקייה"
                  @click="
                    openMoveFolderModal(
                      'template',
                      item.id,
                      item.name,
                      item.folder_parent_id
                    )
                  "
                />
              </RActions>

              <RActions v-if="item.type === 'folder'">
                <!--
                <RAction
                  icon="mdi-plus"
                  class="u-mobile-only"
                  text="הוספת תת תיקייה"
                  @click="openCreateFolderModal(item.id)"
                />
                -->
                <RAction
                  icon="mdi-pencil"
                  text="עריכת תיקייה"
                  @click="openUpdateFolderModal(item)"
                />
                <!--
                <RAction
                  icon="mdi-folder"
                  text="העברת תיקייה לתיקייה"
                  @click="
                    openMoveFolderModal(
                      'folder',
                      item.id,
                      item.name,
                      item.folder_parent_id
                    )
                  "
                />
                -->
                <RAction
                  icon="mdi-delete"
                  text="מחיקת תיקייה"
                  @click="removeFolder(item.id)"
                />
              </RActions>
            </template>
          </VTreeview>

          <div class="py-5 px-5">
            <RButton
              class="ml-2"
              color="grey"
              icon-before="mdi-folder-plus"
              @click="openCreateFolderModal(null)"
            >
              יצירת תיקייה חדשה
            </RButton>
          </div>

          <div v-if="totalSelectedRows > 0" class="templates__floatingSelected">
            <div
              class="d-flex align-center u-gap-2 justify-space-between templates__floatingSelected__inner"
            >
              <div class="templates__floatingSelected__title">
                {{ totalSelectedRows }}
                תבניות נבחרו
              </div>
              <div class="d-flex justify-end u-gap-2">
                <RButton
                  icon-before="mdi-folder"
                  sm
                  color="purple"
                  outlined
                  @click="
                    openMoveFolderModal(
                      'templates',
                      selectedRowsIds,
                      totalSelectedRows + ' תבניות נבחרו'
                    )
                  "
                >
                  העברת תבניות לחיקייה
                </RButton>
                <RButtonIcon
                  class="templates__floatingSelected__close"
                  icon="mdi-close"
                  @click="clearSelectedRows"
                />
              </div>
            </div>
          </div>
        </RSimpleCard>

        <RSimpleCard class="mt-6" title="צרו תבנית חדשה עם אחת מהאופציות כאן.">
          <div class="add-template-banner">
            <div
              class="add-template-banner-item"
              @click="createTemplateModal.isOpen = true"
            >
              <RIcon size="30">mdi-magnify</RIcon>
              <div class="add-template-banner-item-title">
                חפשו מהתבניות המוכנות שלנו
              </div>
              <div class="add-template-banner-item-text">
                הכנו לכם יותר מ-50 תבניות מקצועיות שאפשר גם להתאים אותן בדיוק
                כמו שתרצו.
              </div>
            </div>

            <div
              class="add-template-banner-item"
              @click="
                uploadTemplateModal.isOpen = true;
                closeCreateTemplateModal();
              "
            >
              <RIcon size="30">mdi-cloud-upload</RIcon>
              <div class="add-template-banner-item-title">העלאת הדוח שלכם</div>
              <div class="add-template-banner-item-text">
                נכניס את הדוח שלכם למערכת כדי שתוכלו להתחיל להשתמש בו כמו שאתם
                כבר מכירים.
              </div>
            </div>
          </div>
        </RSimpleCard>
      </div>
    </div>

    <div
      class="d-flex justify-center mt-9 flex-md-row flex-column u-gap-3 align-center mb-5"
    >
      <video-button
        video-id="WQ5EZC0X7Sk"
        button-text="מה ההבדל בין תבנית לדוח?"
      />
      <video-button video-id="to5iuRm7qFw" button-text="איך בונים תבנית חדשה" />
    </div>

    <v-dialog
      v-model="createTemplateModal.isOpen"
      :max-width="createTemplateModal.selectedTemplate ? 1100 : 600"
      persistent
    >
      <v-card>
        <v-card-title
          class="d-flex align-center justify-space-between flex-wrap mb-0 add-report-template__modal__head"
        >
          <span class="add-report-template__modal__title">
            הוספת תבנית חדשה
          </span>
          <RButtonIcon icon="mdi-close" @click="closeCreateTemplateModal" />
        </v-card-title>

        <div class="add-report-template__layout">
          <div class="add-report-template__layout__main">
            <div class="add-template-banner">
              <div
                class="add-template-banner-item"
                @click="
                  newTemplateModal.isOpen = true;
                  closeCreateTemplateModal();
                "
              >
                <RIcon size="30">mdi-text-box-plus</RIcon>
                <div class="add-template-banner-item-title">
                  יצירת תבנית חדשה ריקה
                </div>
                <div class="add-template-banner-item-text">
                  מתחילים מתבנית ריקה לגמרי ובונים הכל בעצמכם.
                </div>
              </div>
              <div
                class="add-template-banner-item"
                @click="
                  uploadTemplateModal.isOpen = true;
                  closeCreateTemplateModal();
                "
              >
                <RIcon size="30">mdi-cloud-upload</RIcon>
                <div class="add-template-banner-item-title">
                  העלאת הדוח שלכם
                </div>
                <div class="add-template-banner-item-text">
                  רוצים שנכניס אותו למערכת כדי שתוכלו להשתמש בו?
                </div>
              </div>
            </div>

            <div class="subtitle-1 font-weight-medium mb-3 mt-8">
              חפשו תבנית מהספרייה והתאימו אותה בדיוק כמו שתרצו
            </div>

            <VExpansionPanels>
              <VExpansionPanel
                v-for="(category, categoryIndex) in categories"
                :key="categoryIndex"
              >
                <VExpansionPanelTitle>
                  <div class="d-flex align-center">
                    <img
                      style="width: 20px; height: 20px"
                      class="ml-3"
                      :src="getCategoryIconPath(category)"
                    />
                    <span class="font-weight-medium">
                      <template v-if="category">
                        {{ category }}
                      </template>
                      <template v-else> ללא קטגוריה </template>
                    </span>
                  </div>
                </VExpansionPanelTitle>

                <VExpansionPanelText class="inner-list">
                  <VList class="rp-vuetify-list py-0">
                    <VListItem
                      v-for="(template, templateIndex) in templatesByCategory(
                        category
                      )"
                      :key="templateIndex"
                      link
                      :active="
                        createTemplateModal.selectedTemplate &&
                        createTemplateModal.selectedTemplate.id === template.id
                      "
                      @click="selectTemplate(template)"
                    >
                      <template #prepend>
                        <VListItemAction start>
                          <VAvatar color="grey-lighten-4" size="25">
                            <span
                              class="font-weight-medium"
                              style="font-size: 12px"
                            >
                              {{ templateIndex + 1 }}
                            </span>
                          </VAvatar>
                        </VListItemAction>
                      </template>

                      <VListItemTitle>
                        {{ template.name }}
                      </VListItemTitle>

                      <template #append>
                        <VIcon size="18" class="u-pointer-events-none">
                          mdi-chevron-left
                        </VIcon>
                      </template>
                    </VListItem>
                  </VList>
                </VExpansionPanelText>
              </VExpansionPanel>
            </VExpansionPanels>
          </div>
          <div
            v-if="createTemplateModal.selectedTemplate"
            class="add-report-template__layout__preview"
          >
            <div
              class="add-report-template__preview"
              :class="{ 'is-loading': createTemplateModal.isLoadingPreview }"
            >
              <span
                class="add-report-template__preview__backButton u-mobile-only"
                @click="createTemplateModal.selectedTemplate = null"
                >חזרה לכל התבניות</span
              >

              <div
                v-if="createTemplateModal.isLoadingPreview"
                class="d-flex justify-center py-10 px-4 position-absolute h-100 align-center"
                style="width: 100%; height: 100%; z-index: 10"
              >
                טוען...
              </div>
              <div class="add-report-template__previewImage">
                <img
                  :src="
                    createTemplateModal.selectedTemplate.previewPdfImage
                      ? media_url +
                        createTemplateModal.selectedTemplate.previewPdfImage
                      : 'https://media.reporto.co.il/app/pdf-previews/preview-404.png'
                  "
                  style="max-width: 100%; height: auto"
                />
                <div class="add-report-template__previewBottom">
                  <div class="add-report-template__previewText">
                    <div
                      style="
                        font-weight: 500;
                        font-size: 15px;
                        margin-bottom: 2px;
                      "
                    >
                      {{ createTemplateModal.selectedTemplate.name }}
                      <RChip sm color="grey" class="mr-1">{{
                        createTemplateModal.selectedTemplate.category
                      }}</RChip>
                      <RButton
                        v-if="createTemplateModal.selectedTemplate.pdf"
                        :href="
                          media_url + createTemplateModal.selectedTemplate.pdf
                        "
                        target="_blank"
                        xs
                        class="mr-1"
                        color="red"
                      >
                        PDF
                      </RButton>
                    </div>
                    <template
                      v-if="createTemplateModal.selectedTemplate.description"
                    >
                      {{ createTemplateModal.selectedTemplate.description }}
                    </template>

                    <RAlert
                      v-if="
                        hasTemplateName(
                          createTemplateModal.selectedTemplate.name
                        )
                      "
                      type="info"
                      class="mb-5 mt-2 mb-n1"
                    >
                      <div>
                        שמנו לב שתבנית עם השם
                        <span class="font-weight-medium"
                          >"{{
                            createTemplateModal.selectedTemplate.name
                          }}"</span
                        >
                        כבר קיימת בתבניות שלכם.
                      </div>
                    </RAlert>
                  </div>
                  <div class="add-report-template__previewButton">
                    <RButton
                      depressed
                      block
                      lg
                      icon-after="mdi-chevron-left"
                      @click="
                        addReportTemplate(createTemplateModal.selectedTemplate)
                      "
                    >
                      הוספה לתבניות שלי
                    </RButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <RModal v-model="newTemplateModal.isOpen" title="יצירת תבנית חדשה ריקה">
      <RAlert type="info" class="mt-4">
        <div class="body-2 blue-grey--text text--darken-4">
          <div class="mb-2">
            אם אתם רוצים ליצור תבנית חדשה לגמרי, להתחיל ממשהו ריק, זה המקום 💪
          </div>
          <video-button
            video-id="to5iuRm7qFw"
            button-text="איך בונים תבנית חדשה"
          />
        </div>
      </RAlert>

      <RFields>
        <RFieldText v-model="newTemplateModal.name" label="שם התבנית החדשה" />
        <RButton
          :disabled="!newTemplateModal.name"
          lg
          block
          @click="createNewTemplate"
        >
          יצירת תבנית חדשה
        </RButton>
      </RFields>
    </RModal>

    <RModal v-model="uploadTemplateModal.isOpen" title="העלאת הדוח שלכם">
      <RAlert type="info" class="mt-1 mb-4">
        <div class="mb-1" style="font-weight: 600">איך זה עובד?</div>
        <div>
          אתם מעלים קובץ מסוג pdf או word ואנחנו נמיר לכם אותו לתבנית דוח במערכת
          שלנו.
          <br />
          נעדכן אתכם ב-Whatsapp, במייל או בטלפון כשהכל מוכן!
        </div>
      </RAlert>

      <RFields>
        <input
          type="file"
          placeholder="בחירת קובץ להעלאה"
          class="mb-3"
          @change="selectUploadFile"
        />

        <RButton
          :disabled="!uploadTemplateModal.fileToUpload"
          lg
          block
          @click="uploadTemplate"
        >
          שליחת הקובץ
        </RButton>
      </RFields>
    </RModal>

    <RModal v-model="importTemplateModal.isOpen" title="ייבוא תבנית של רפורטו">
      <RAlert type="info" class="mt-1 mb-3">
        <div class="mb-1" style="font-weight: 600">איך זה עובד?</div>
        <div>
          בחלון הזה מייבאים תבנית של רפורטו (קובץ מסוג JSON)
          <ol>
            <li>
              מייצאים את התבנית בעמוד תבניות ע״י לחיצה על הכפתור ״ייצוא תבנת״
            </li>
            <li>מכניסים לשדה כאן את הקובץ</li>
            <li>אח״כ תוכלו לראות את התבנית ברשימת התבניות שלכם.</li>
          </ol>
        </div>
      </RAlert>

      <RFields>
        <input
          type="file"
          placeholder="בחירת קובץ לייבוא"
          class="mb-4"
          @change="selectImportFile"
        />
        <RButton
          :disabled="!importTemplateModal.fileToImport"
          lg
          block
          @click="importTemplate"
        >
          התחלת ייבוא
        </RButton>
      </RFields>
    </RModal>

    <RModal v-model="orderTemplatesModal.isOpen" title="שינוי הסדר של התבניות">
      <div v-if="reportTemplates.length" class="rp-vuetify-list">
        <draggable
          v-model="reportTemplates"
          handle=".list-item-drag"
          v-bind="dragOptions"
          @end="onDragEnd"
          itemKey="id"
        >
          <template #item="{ element: reportTemplate }">
            <div
              :key="reportTemplate.id"
              class="d-flex u-gap-2 mb-3"
              style="
                padding: 8px 10px;
                color: #000;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
              "
            >
              <RIcon class="ml-1 u-cursor-move list-item-drag">mdi-drag</RIcon>

              <div>
                <span v-if="reportTemplate.name">
                  {{ reportTemplate.name }}
                </span>
                <span v-else> תבנית ללא שם </span>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </RModal>

    <RModal
      v-if="createFolderModal.isOpen"
      v-model="createFolderModal.isOpen"
      title="יצירת תיקייה"
      :disabled="createFolderModal.isLoading"
    >
      <RFields>
        <RFieldText v-model="createFolderModal.folderName" label="שם התיקייה" />
        <RButton
          :disabled="!createFolderModal.folderName"
          color="primary"
          lg
          block
          :loading="createFolderModal.isLoading"
          @click="createFolder"
        >
          צרו תיקייה חדשה
        </RButton>
      </RFields>
    </RModal>

    <RModal
      v-if="updateFolderModal.isOpen"
      v-model="updateFolderModal.isOpen"
      :disabled="updateFolderModal.isLoading"
      title="עדכון תיקייה"
    >
      <RFields>
        <RFieldText v-model="updateFolderModal.folderName" label="שם התיקייה" />
        <RButton
          :disabled="!updateFolderModal.folderName"
          color="primary"
          lg
          block
          :loading="updateFolderModal.isLoading"
          @click="updateFolder"
        >
          עדכון תיקייה
        </RButton>
      </RFields>
    </RModal>

    <RModal
      v-if="moveFolderModal.isOpen"
      v-model="moveFolderModal.isOpen"
      :disabled="moveFolderModal.isLoading"
      :title="moveFolderModalTitle"
    >
      <RFields>
        <RFieldText
          v-model="moveFolderModal.name"
          label="שם"
          realonly
          disabled
        />
        <RFieldSelect
          v-model="moveFolderModal.toFolderId"
          :items="moveToFolderOptions"
          label="העברה לתיקייה"
          clearable
        >
        </RFieldSelect>

        <RButton
          color="primary"
          lg
          block
          :loading="moveFolderModal.isLoading"
          @click="moveFolder"
        >
          העברה לתיקייה
        </RButton>
      </RFields>
    </RModal>
  </div>
  <page-loader v-else />
</template>

<script>
import ReportTemplatesApi from "@/api/ReportTemplatesApi";
import PageLoader from "@/components/PageLoader.vue";
import VideoButton from "@/components/VideoButton.vue";
import draggable from "vuedraggable";
import { dragOptions } from "@/common/settings";
import ReportTemplateFoldersApi from "@/api/ReportTemplateFoldersApi";
import { VTreeview } from "vuetify/labs/VTreeview";
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    draggable,
    VideoButton,
    PageLoader,
    VTreeview,
  },
  data() {
    return {
      selectedRowsIds: [],
      dragOptions,
      searchQuery: null,
      categoriesIcons: [
        {
          title: "כללי",
          icon: "document.svg",
        },
        {
          title: "שמאי רכוש",
          icon: "house-money.svg",
        },
        {
          title: "ביצוע",
          icon: "excavator.svg",
        },
        {
          title: "מאתר נזילות",
          icon: "plumbing.svg",
        },
        {
          title: "אדריכלות",
          icon: "architect.svg",
        },
        {
          title: "בדק בית",
          icon: "property-1.svg",
        },
        {
          title: "קונסטרוקציה",
          icon: "cons.svg",
        },
        {
          title: "פיקוח דיירים",
          icon: "pikuah.svg",
        },
        {
          title: "בקרת איכות",
          icon: "medal.svg",
        },
        {
          title: "בטיחות",
          icon: "helmet.svg",
        },
        {
          title: "פיקוח בניה",
          icon: "file.svg",
        },
        {
          title: "פרוטוקול מסירת דירה",
          icon: "key.svg",
        },
        {
          title: "------",
          icon: "key.svg",
        },
      ],
      reportTemplates: [],
      isLoadingReportTemplates: true,
      isLoaded: false,
      templates: [],
      isLoading: false,
      orderTemplatesModal: {
        isOpen: false,
      },
      createTemplateModal: {
        isOpen: false,
        selectedTemplate: null,
        isLoadingPreview: false,
      },
      uploadTemplateModal: {
        isOpen: false,
        fileToUpload: null,
      },
      newTemplateModal: {
        isOpen: false,
        name: null,
      },
      importTemplateModal: {
        isOpen: false,
        fileToImport: null,
      },
      reportTemplateFolders: [],
      createFolderModal: {
        isOpen: false,
        isLoading: false,
        folderName: null,
        parentFolderId: null,
      },
      updateFolderModal: {
        isOpen: false,
        folderId: null,
        isLoading: false,
        folderName: null,
      },
      moveFolderModal: {
        isOpen: false,
        type: null,
        id: null,
        name: null,
        toFolderId: null,
        isLoading: false,
      },
      tree: [],
    };
  },
  computed: {
    totalSelectedRows() {
      return this.selectedRowsIds.length;
    },
    moveToFolderOptions() {
      let folders = this.reportTemplateFolders.filter(
        (folder) => folder.id !== this.moveFolderModal.id
      );

      folders = folders.map((folder) => ({
        value: folder.id,
        text: folder.name,
      }));

      folders.unshift({ value: null, text: "ללא תיקייה" }); // Adjust the text as needed

      return folders;
    },
    moveFolderModalTitle() {
      return this.moveFolderModal.type === "folder"
        ? "העברת תיקייה לתיקייה"
        : "העברת תבנית לתיקייה";
    },
    foldersTree() {
      // Create a map of folders with their IDs
      const foldersById = this.reportTemplateFolders.reduce((map, folder) => {
        map[folder.id] = {
          ...folder,
          type: "folder",
          children: [],
          // Add required VTreeview properties
          title: folder.name,
          selectable: false,
        };
        return map;
      }, {});

      const rootItems = [];

      // Build folder hierarchy
      this.reportTemplateFolders.forEach((folder) => {
        if (folder.parent_id === null) {
          foldersById[folder.id].folder_parent_id = null;
          rootItems.push(foldersById[folder.id]);
        } else {
          if (foldersById[folder.parent_id]) {
            foldersById[folder.id].folder_parent_id = folder.parent_id;
            foldersById[folder.parent_id].children.push(foldersById[folder.id]);
          }
        }
      });

      // Add templates to their respective folders or root
      this.reportTemplates.forEach((template) => {
        const templateNode = {
          ...template,
          id: template.id,
          type: "template",
          folder_parent_id: template.report_template_folder_id,
          // Add required VTreeview properties
          title: template.name,
          //children: [], // Templates don't have children but VTreeview expects this
          selectable: true,
        };

        if (template.report_template_folder_id) {
          if (foldersById[template.report_template_folder_id]) {
            foldersById[template.report_template_folder_id].children.push(
              templateNode
            );
          }
        } else {
          rootItems.push(templateNode);
        }
      });

      return rootItems;
    },
    headers() {
      let headers = [];
      headers.push({
        text: "שם תבנית",
        align: "start",
        value: "name",
      });
      if (window.innerWidth > 767) {
        headers.push({
          text: "תאריך יצירה",
          value: "created_at",
          width: "180px",
        });
      }
      headers.push({
        text: "פעולות",
        value: "actions",
        width: "145px",
        sortable: false,
      });
      return headers;
    },
    media_url() {
      return this.$Reporto.globals.MEDIA_URL;
    },
    categories() {
      return this.templates
        .map((a) => a.category)
        .filter((v, i, a) => a.indexOf(v) === i);
    },
    filteredFoldersTree() {
      if (!this.searchQuery) {
        return this.foldersTree;
      }

      const searchLower = this.searchQuery.toLowerCase();

      // Helper function to search through items
      const filterItems = (items) => {
        return items.filter(item => {
          if (item.type === 'template') {
            return item.name.toLowerCase().includes(searchLower);
          } else if (item.type === 'folder') {
            // Check folder name
            const nameMatch = item.name.toLowerCase().includes(searchLower);
            
            // Filter children
            const filteredChildren = filterItems(item.children || []);
            
            // Keep folder if name matches or has matching children
            if (nameMatch || filteredChildren.length > 0) {
              item.children = filteredChildren;
              return true;
            }
            return false;
          }
          return false;
        });
      };

      return filterItems(this.foldersTree);
    }
  },
  async created() {
    await this.fetchReportTemplatesAndFolders();
    const response = await ReportTemplatesApi.getReportTemplatesLibrary();
    this.templates = response.data;

    if (this.$route.query["new"] && this.$route.query["new"] === "1") {
      this.createTemplateModal.isOpen = true;
    }

    this.isLoaded = true;
  },
  methods: {
    clearSelectedRows() {
      this.selectedRowsIds = [];
    },
    rowCheckboxClicked(row) {
      if (this.userCanDragRows) {
        return;
      }
      if (this.selectedRowsIds.includes(row.id)) {
        this.selectedRowsIds = this.selectedRowsIds.filter(
          (id) => id !== row.id
        );
      } else {
        this.selectedRowsIds.push(row.id);
      }
    },
    isRowSelected(row) {
      return this.selectedRowsIds.includes(row.id);
    },
    async createNewTemplate() {
      let EMPTY_REPORT_TEMPLATE_ID = "76517b4a-ad0d-44b4-b41c-f4aad38398aa";

      let loader = this.$loading.show();

      this.isLoading = true;

      try {
        const data = await ReportTemplatesApi.createReportTemplate({
          id: EMPTY_REPORT_TEMPLATE_ID,
          name: this.newTemplateModal.name,
        });
        this.$toast.success("יצרנו תבנית חדשה!", {
          timeout: 8000,
        });
        await this.$router.push({
          name: "report-templates.edit",
          params: { id: data.id },
        });
        loader.hide();
        this.isLoading = false;
      } catch (error) {
        this.$toast.error("שגיאה ביצירת התבנית החדשה");
        loader.hide();
        this.isLoading = false;
      }
    },
    hasTemplateName(templateName) {
      return this.reportTemplates.some(
        (reportTemplate) => reportTemplate.name === templateName
      );
    },
    closeCreateTemplateModal() {
      this.createTemplateModal.isOpen = false;
      this.createTemplateModal.selectedTemplate = null;
    },
    getCategoryIcon(categoryTitle) {
      let categoryIcon = this.categoriesIcons.find(
        (categoryIcon) => categoryIcon.title === categoryTitle
      );

      if (!categoryIcon) {
        return "custom.svg";
      }

      return categoryIcon.icon;
    },
    async uploadTemplate() {
      let loader = this.$loading.show();

      try {
        await ReportTemplatesApi.uploadTemplate(
          this.uploadTemplateModal.fileToUpload
        );
        this.uploadTemplateModal.isOpen = false;
        this.$toast.success(
          `העלאת הקובץ הסתיימה בהצלחה! נהיה איתכם בקשר בקרוב 🙂`,
          {
            timeout: 12000,
          }
        );
        loader.hide();
        this.importTemplateModal.fileToImport = null;
      } catch (error) {
        this.$toast.error("שגיאה בתהליך העלאת הקובץ");
        loader.hide();
      }
    },
    selectTemplate(template) {
      this.createTemplateModal.isLoadingPreview = true;
      let timeout = 10;
      if (this.createTemplateModal.selectedTemplate) {
        timeout = 400;
      }
      setTimeout(() => {
        this.createTemplateModal.isLoadingPreview = false;
        this.createTemplateModal.selectedTemplate = template;
      }, timeout);
    },
    selectUploadFile(file) {
      this.uploadTemplateModal.fileToUpload = file;
    },
    selectImportFile(event) {
      let files = event.target.files;
      this.importTemplateModal.fileToImport = files[0];
    },
    async importTemplate() {
      let loader = this.$loading.show();

      try {
        await ReportTemplatesApi.importTemplate(
          this.importTemplateModal.fileToImport
        );
        this.isLoaded = false;
        await this.fetchReportTemplatesAndFolders();
        this.isLoaded = true;
        this.$toast.success(`ייבוא התבנית הסתיים בהצלחה.`, {
          timeout: 4000,
        });
        loader.hide();
        this.importTemplateModal.fileToImport = null;
        this.importTemplateModal.isOpen = false;
      } catch (error) {
        this.$toast.error("שגיאה בתהליך היבוא");
        loader.hide();
      }
    },
    async exportTemplate(templateId) {
      if (!this.$Utils.isDesktop()) {
        this.$toast.error("ייצוא תבניות אפשרי רק דרך מחשב");
        return;
      }
      let loader = this.$loading.show();

      await ReportTemplatesApi.exportTemplate(templateId);

      loader.hide();
    },
    templatesByCategory(category) {
      if (!this.templates) {
        return false;
      }

      let templates = [];

      this.templates.forEach((reportTemplate) => {
        if (reportTemplate.category === category) {
          templates.push(reportTemplate);
        }
      });

      return templates;
    },
    async addReportTemplate(reportTemplate) {
      if (
        this.hasTemplateName(reportTemplate.name) &&
        !confirm(
          `שמנו לב שתבנית עם השם  ״${reportTemplate.name}״ כבר קיימת בתבניות שלכם, רוצים להוסיף בכל זאת?`
        )
      ) {
        return false;
      }

      let loader = this.$loading.show();

      this.isLoading = true;

      try {
        const data = await ReportTemplatesApi.createReportTemplate({
          id: reportTemplate.id,
        });
        this.$toast.success("התבנית נוספה בהצלחה למאגר התבניות");
        await this.$router.push({
          name: "report-templates.edit",
          params: { id: data.id },
        });
        loader.hide();
        this.isLoading = false;
      } catch (error) {
        this.$toast.error("שגיאה בהוספת התבנית");
        loader.hide();
        this.isLoading = false;
      }
    },
    async onDragEnd() {
      let loader = this.$loading.show();

      let reportTemplatesIdsOrder = this.reportTemplates.map(
        (template) => template.id
      );

      await ReportTemplatesApi.updateReportTemplatesOrder(
        reportTemplatesIdsOrder
      );

      loader.hide();
    },
    async fetchReportTemplatesAndFolders() {
      this.isLoadingReportTemplates = true;
      await this.$store.dispatch("reportTemplates/fetchReportTemplates");
      this.reportTemplates = this.$store.state.reportTemplates.reportTemplates;
      this.reportTemplateFolders = await ReportTemplateFoldersApi.getFolders();
      this.isLoadingReportTemplates = false;
    },
    async duplicateReportTemplate(reportTemplateIdToDuplicate) {
      if (confirm("בטוח לשכפל את התבנית?")) {
        let loader = this.$loading.show();

        await ReportTemplatesApi.duplicateReportTemplate(
          reportTemplateIdToDuplicate
        );
        await this.fetchReportTemplatesAndFolders();

        this.$toast.success("התבנית שוכפלה בהצלחה");

        loader.hide();
      }
    },
    openMoveFolderModal(type, id, name, currentFolderId = null) {
      this.moveFolderModal.isOpen = true;
      this.moveFolderModal.type = type;
      this.moveFolderModal.id = id;
      this.moveFolderModal.name = name;
      this.moveFolderModal.toFolderId = currentFolderId;
      this.moveFolderModal.isLoading = false;
    },
    openUpdateFolderModal(folder) {
      this.updateFolderModal.isOpen = true;
      this.updateFolderModal.folderId = folder.id;
      this.updateFolderModal.isLoading = false;
      this.updateFolderModal.folderName = folder.name;
    },
    openCreateFolderModal(parentFolderId = null) {
      this.createFolderModal.isOpen = true;
      this.createFolderModal.isLoading = false;
      this.createFolderModal.folderName = null;
      this.createFolderModal.parentFolderId = parentFolderId;
    },
    async moveFolder() {
      this.moveFolderModal.isLoading = true;

      try {
        if (this.moveFolderModal.type === "folder") {
          await ReportTemplatesApi.updateFolder(this.moveFolderModal.id, {
            parent_id: this.moveFolderModal.toFolderId,
          });
        }

        if (this.moveFolderModal.type === "template") {
          await ReportTemplatesApi.updateFolder(
            this.moveFolderModal.id,
            this.moveFolderModal.toFolderId
          );
        }

        if (this.moveFolderModal.type === "templates") {
          for (let templateId of this.moveFolderModal.id) {
            await ReportTemplatesApi.updateFolder(
              templateId,
              this.moveFolderModal.toFolderId
            );
          }
        }

        await this.fetchReportTemplatesAndFolders();
        this.$toast.success("הועבר בהצלחה לתיקייה");
        this.moveFolderModal.isLoading = false;
        this.moveFolderModal.isOpen = false;
        this.clearSelectedRows();
      } catch (error) {
        this.$toast.error("שגיאה בהעברה לתיקייה");
        this.moveFolderModal.isLoading = false;
      }
    },
    async updateFolder() {
      if (!this.updateFolderModal.folderName) {
        this.$toast.error("יש להזין שם לתיקייה");
        return;
      }

      this.updateFolderModal.isLoading = true;

      try {
        await ReportTemplateFoldersApi.updateReportTemplateFolder(
          this.updateFolderModal.folderId,
          {
            name: this.updateFolderModal.folderName,
          }
        );
        await this.fetchReportTemplatesAndFolders();
        this.$toast.success("התיקייה עודכנה בהצלחה");
        this.updateFolderModal.isLoading = false;
        this.updateFolderModal.isOpen = false;
      } catch (error) {
        this.$toast.error("שגיאה בעדכון תיקייה");
        this.updateFolderModal.isLoading = false;
      }
    },
    async removeFolder(folderId) {
      if (!confirm("בטוח למחוק את התיקייה?")) {
        return;
      }

      if (!this.folderIsEmpty(folderId)) {
        this.$toast.error("צריך שהתיקייה תהיה ריקה כדי למחוק אותה");
        return;
      }

      this.isLoading = true;
      try {
        await ReportTemplateFoldersApi.removeReportTemplateFolder(folderId);
        await this.fetchReportTemplatesAndFolders();
        this.$toast.success("התיקייה נמחקה בהצלחה");
        this.isLoading = false;
      } catch (error) {
        this.$toast.error("שגיאה במחיקת התיקייה");
        this.isLoading = false;
      }
    },
    async createFolder() {
      if (!this.createFolderModal.folderName) {
        this.$toast.error("יש להזין שם לתיקייה");
        return;
      }

      this.createFolderModal.isLoading = true;

      try {
        await ReportTemplateFoldersApi.createReportTemplateFolder(
          this.createFolderModal.folderName,
          this.createFolderModal.parentFolderId
        );
        await this.fetchReportTemplatesAndFolders();
        this.$toast.success("התיקייה נוצרה בהצלחה");
        this.createFolderModal.isLoading = false;
        this.createFolderModal.isOpen = false;
        window.scrollTo(0, 0);
      } catch (error) {
        console.error(error);
        this.$toast.error("שגיאה ביצירת תיקייה");
        this.createFolderModal.isLoading = false;
      }
    },
    folderIsEmpty(folderId) {
      let hasTemplates = this.reportTemplates.some(
        (reportTemplate) =>
          reportTemplate.report_template_folder_id === folderId
      );
      let hasSubFolders = this.reportTemplateFolders.some(
        (folder) => folder.parent_id === folderId
      );
      return !hasTemplates && !hasSubFolders;
    },
    getCategoryIconPath(categoryTitle) {
      let categoryIcon = this.categoriesIcons.find(
        (categoryIcon) => categoryIcon.title === categoryTitle
      );

      const iconName = categoryIcon ? categoryIcon.icon : 'custom.svg';
      
      // Option 1: Using dynamic imports (recommended)
      return new URL(`../../assets/images/industries/${iconName}`, import.meta.url).href;
      
      // Option 2: Alternative using public folder
      // return `/images/industries/${iconName}`;
    },
  },
};
</script>

<style lang="scss">
.search-input {
  width: 100%;
  max-width: 300px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
}
.templates-table {
  th:nth-child(1) {
    min-width: 180px;
  }
  th:last-child {
    min-width: 200px !important;

    @media (max-width: 767px) {
      min-width: 180px !important;
    }
  }
}
.v-expansion-panel-content__wrap {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.add-report-template {
  &__layout {
    background: #f8f9fc;
    display: flex;
    position: relative;
    &__main {
      flex: 1;
      padding: 30px;
      @media (max-width: 767px) {
        padding: 15px;
      }
    }
    &__preview {
      position: relative;
      flex: 1.2;
      padding: 30px;

      @media (max-width: 991px) {
        position: fixed;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        flex: 1;
        top: 0;
        bottom: 0;
        z-index: 120;
        padding: 15px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -1px;
        width: 1px;
        height: 100%;
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }
  &__preview {
    position: sticky;
    top: 70px;
    @media (max-width: 991px) {
      top: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding-top: 50px;
    }

    &__backButton {
      color: #000;
      background: #fff;
      padding: 3px 7px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }

    &.is-loading {
      .add-report-template__previewImage {
        position: relative;
        opacity: 0.6;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.8);
          z-index: 1;
        }
      }
    }

    &Image {
      border-radius: 6px;
      width: 500px;
      margin: 0 auto;
      height: calc(100vh - 200px);
      overflow: auto;
      border: 1px solid rgba(0, 0, 0, 0.12);

      @media (max-width: 767px) {
        max-width: 100%;
        height: calc(100vh - 220px);
      }
    }

    &Bottom {
      position: sticky;
      bottom: -1px;
      background: #fff;
      &:before {
        content: "";
        position: absolute;
        top: -50px;
        z-index: 10;
        left: 0;
        right: 0;
        height: 50px;
        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.09),
          rgba(255, 255, 255, 0)
        );
        pointer-events: none;
      }
    }
    &Button {
      .v-btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &Text {
      font-size: 13px;
      font-weight: 400;
      background: #fafafa;
      padding: 12px 12px;
      border-top: 2px dashed rgba(0, 0, 0, 0.22);
    }
  }
}

.v-treeview .v-list-item {
  border-bottom: 1px solid var(--color-gray-300);
}

.v-list-item.is-active {
  background: var(--color-purple-lightest) !important;
  color: var(--rp-color-purple) !important;
}
.v-list-item:not(.is-active) {
  color: rgba(0, 0, 0, 0.87) !important;
}
.add-template-banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  &-item {
    --background: #dbf8ff;
    --color: #0d75b5;
    background: var(--background);
    text-align: center;
    color: var(--color);
    padding: 12px 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    cursor: pointer;

    &:nth-child(2) {
      --background: #fff1cc;
      --color: #9e4a00;
    }

    .v-icon.v-icon {
      color: var(--color);
    }

    &-title {
      font-weight: 600;
      font-size: 16px;
    }
    &-text {
    }
  }
}

.templates__floatingSelected {
  background: #fff;
  position: sticky;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  min-width: 500px;
  padding: 12px 15px;
  border-radius: 6px;
  bottom: 20px;
  box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  border: 1px solid var(--color-gray-300);
  margin-top: 13px;

  @media (max-width: 767px) {
    min-width: auto;
    width: 100%;
    max-width: calc(100% - 20px);
    padding: 12px 26px 12px 12px;
  }

  @media (max-width: 550px) {
    padding: 12px;
  }

  .r-button {
    @media (max-width: 350px) {
      .v-icon {
        display: none;
      }
    }
  }

  &__inner {
    width: 100%;
    @media (max-width: 550px) {
      flex-direction: column;
    }
  }

  &__title {
    font-size: 15px;
    font-weight: 600;
    color: var(--color-gray-700);
    @media (max-width: 550px) {
      font-size: 14px;
      margin-bottom: 3px;
    }
  }

  &__close {
    @media (max-width: 550px) {
      position: absolute;
      left: 5px;
      top: 5px;
    }
  }

  //&:before {
  //  content: "";
  //  width: 9px;
  //  height: 100%;
  //  background: #0173EA;
  //  position: absolute;
  //  top: 0;
  //  right: 0;
  //  border-radius: 0 10px 10px 0;
  //}
}

.r-simple-card.templates-tree-card {
  @media (max-width: 767px) {
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  }

  a {
    color: currentColor;
  }
}

.inner-list .v-expansion-panel-text__wrapper {
  padding-inline: 0;
}
</style>
