<template>
  <v-menu location="bottom" offset-y>
    <template #activator="{ props: menuProps }">
      <slot name="activator" :props="menuProps">
        <v-btn
          density="compact"
          variant="text"
          icon
          size="24"
          v-bind="menuProps"
          @click="$emit('click')"
        >
          <v-icon size="18">{{ icon }}</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-list class="custom-dropdown">
      <slot></slot>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "RActions",
  props: {
    icon: {
      type: String,
      default: "mdi-dots-vertical",
    },
  },
  emits: ["click"],
};
</script>
