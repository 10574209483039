<template>
  <div class="r-field r-field--textChoices">
    <label v-if="label" class="r-field-label"> {{ label }} </label>
    <div class="r-field-input">
      <input v-model="inputText" v-bind="$attrs" />
      <div v-if="choices" class="mt-3">
        <RButton
          v-for="choice in choicesFormatted"
          :key="choice.id"
          :color="choice.color"
          :outlined="choice.value !== inputText"
          class="ml-1 mb-2"
          sm
          @click="inputText = choice.value"
        >
          {{ choice.label }}
        </RButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    choices: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      inputText: this.modelValue,
    };
  },
  computed: {
    choicesFormatted() {
      let choicesFormatted = [];

      for (const [, choice] of Object.entries(this.choices)) {
        choicesFormatted.push({
          value: choice.value ? choice.value : choice.label,
          label: choice.label,
          color:
            choice.color &&
            choice.color !== "#ffffff" &&
            choice.color !== "#fff"
              ? choice.color
              : "black",
        });
      }

      return choicesFormatted;
    },
  },
  watch: {
    inputText(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.inputText = val;
    },
  },
  methods: {
    updateParentValue(val) {
      this.inputText = val;
    },
  },
};
</script>
