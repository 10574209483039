<template>
  <div>
    <AppFieldText v-model="localItem.settings.headline" label="כותרת" />

    <AppFieldAlign
      v-model="localItem.settings.headlineAlign"
      label="ישור כותרת"
    />

    <AppFieldSwitch
      v-model="localItem.settings.withIndexColumn"
      label="הוספת עמודת מספור"
    />

    <div class="app-field">
      <label class="app-field__label"> עריכת עמודות </label>
      <v-list class="pa-0 transparent">
        <v-list-item
          v-for="column in localItem.settings.columns"
          :key="column.id"
          dense
          border
          class="mb-2 rounded"
        >
          <div class="d-flex align-center w-100">
            <v-text-field
              v-model="column.text"
              :label="column.name"
              hide-details
              density="compact"
              variant="outlined"
              style="width: 150px"
              class="mr-2"
            ></v-text-field>
            <span class="mx-2">%</span>
            <input
              v-model="column.columnWidth"
              type="number"
              placeholder="רוחב"
              style="width: 50px"
              class="mr-2"
            />
            <v-switch
              v-model="column.isActive"
              color="var(--color-green)"
              hide-details
              density="compact"
              inset
              class="ma-0"
              :ripple="false"
            ></v-switch>
          </div>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      localItem: null,
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
  },
};
</script>
