<template>
  <div v-if="isLoaded">
    <div class="r-page-table">
      <div class="r-page-table-header">
        <div class="r-page-table-header-content">
          <h3 class="r-page-table-header-title">
            📓 פרויקטים
            <span v-if="projects && projects.length"
              >({{ projects.length }})</span
            >
          </h3>
        </div>
        <div class="r-page-table-header-actions">
          <RSearchCompact v-model="searchQuery" :initial-open="true" />
          <RButton
            v-if="$checkRole(['admin', 'owner'])"
            color="purple"
            :to="{
              name: 'projects.add',
            }"
          >
            פרויקט חדש
          </RButton>
        </div>
      </div>

      <div class="r-page-table-body">
        <v-data-table
          v-if="isLoaded && projects.length"
          :headers="headers"
          :items="projects"
          :hide-default-footer="true"
          :items-per-page="-1"
          :search="searchQuery"
          no-results-text="לא נמצאו תוצאות לחיפוש"
          class="elevation-0 projects-table"
          mobile-breakpoint="0"
        >
          <template #[`item.number`]="{ item }">
            <router-link
              :to="{
                name: 'projects.edit',
                params: { id: item.id },
              }"
              class="text--primary"
              style="text-decoration: underline; text-underline-position: under"
            >
              {{ item.number }}
              <RIcon v-if="item.visibility === 'private'" class="mr-1" size="14"
                >mdi-lock</RIcon
              >
            </router-link>
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ $Utils.presentHebrewDate(item.created_at) }}
          </template>
          <template #[`item.actions`]="{ item }">
            <RActions>
              <RAction
                :to="{ name: 'projects.edit', params: { id: item.id } }"
                icon="mdi-pencil"
                text="עריכה"
              ></RAction>
            </RActions>
          </template>
        </v-data-table>

        <div
          v-if="isLoaded && !projects.length"
          class="r-page-table-empty-state"
        >
          <div class="r-page-table-empty-state-icon">📁</div>
          <div class="r-page-table-empty-state-title">
            נראה שלא יצרתם פרויקטים עדיין
          </div>
          <div class="r-page-table-empty-state-text">
            ב-2 קליקים יוצרים פרויקט ורואים את כל המידע והדוחות של הפרויקט במקום
            אחד.
          </div>
        </div>
      </div>
      <div class="d-flex justify-center mt-9">
        <video-button video-id="mFg60IxJ37A" button-text="הסבר על פרויקטים" />
      </div>
    </div>
  </div>
  <page-loader v-else />
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
import VideoButton from "@/components/VideoButton.vue";

export default {
  components: {
    VideoButton,
    PageLoader,
  },
  data() {
    return {
      searchQuery: null,
      projects: [],
      isLoaded: false,
    };
  },
  computed: {
    headers() {
      let headers = [];

      headers.push({
        title: "מספר",
        value: "number",
        width: "100px",
        sortable: true,
      });

      headers.push({
        title: "שם פרויקט",
        value: "name",
        sortable: true,
      });

      headers.push({
        title: "כתובת",
        value: "address",
        sortable: true,
      });

      headers.push({
        title: "לקוח",
        value: "customer_name",
        sortable: true,
      });

      headers.push({
        title: "תווית",
        value: "tag",
        sortable: true,
      });

      if (window.innerWidth > 767) {
        headers.push({
          title: "תאריך יצירה",
          value: "created_at",
          width: "180px",
          sortable: true,
        });
      }

      headers.push({
        title: "פעולות",
        value: "actions",
        width: "80px",
        sortable: false,
      });

      return headers;
    },
  },
  async created() {
    await this.fetchProjects();
    this.isLoaded = true;
  },
  methods: {
    async fetchProjects() {
      await this.$store.dispatch("projects/fetchProjects");
      this.projects = this.$store.state.projects.projects;
    },
  },
};
</script>

<style lang="scss">
.projects-table {
  th:nth-child(2) {
    min-width: 180px;
  }
  th:nth-child(3) {
    min-width: 180px;
  }
  th:nth-child(4) {
    min-width: 180px;
  }
}
</style>
