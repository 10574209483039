<template>
  <div>
    <report-label
      :label="item.settings.label"
      :total="$ItemsUtils.getTotalValidItems(rows)"
    />

    <div class="rp-repeater">
      <draggable
        :list="rowsArray"
        handle=".rp-repeater__item__avatar"
        v-bind="dragOptions"
        @change="onDragChange"
        itemKey="id"
        tag="div"
      >
        <template #item="{ element: row }">
          <div
            :key="row.id"
            class="rp-repeater__item"
            :class="{
              deleted: row.deletedAt,
              disabled:
                row.hasOwnProperty('isActive') && row.isActive === false,
              'is-open': isOpen.hasOwnProperty(row.id) && isOpen[row.id],
            }"
          >
            <div
              class="rp-repeater__item__header"
              @click="toggleOpen(row.id)"
            >
              <div class="rp-repeater__item__avatar">
                <RIcon small class="mr-1 ml-2">mdi-drag</RIcon>
                <span>{{ getRowIndex(row) }}</span>
              </div>

              <div class="rp-h5 rp-repeater__item__title">
                {{ getRowPreviewText(row) }}
              </div>

              <div class="mt-2 ml-n2">
                <RActions>
                  <RAction
                    icon="mdi-content-copy"
                    text="שכפול"
                    @click="duplicateRow(row)"
                  />
                  <RAction
                    icon="mdi-delete"
                    text="מחיקה"
                    @click="removeRow(row)"
                  />
                  <RAction
                    v-if="!row.isActive && !row.deletedAt"
                    icon="mdi-eye"
                    text="הדלקה"
                    color="green"
                    @click="setRowActive(row, true)"
                  />
                  <RAction
                    v-if="row.isActive && !row.deletedAt"
                    icon="mdi-eye-off"
                    text="כיבוי"
                    color="grey"
                    @click="setRowActive(row, false)"
                  />
                </RActions>
              </div>

              <div class="rp-repeater__item__arrow">
                <RIcon small>mdi-chevron-down</RIcon>
              </div>
            </div>
            <transition name="slide">
              <div
                v-if="isOpen.hasOwnProperty(row.id) && isOpen[row.id]"
                class="rp-repeater__item__body"
              >
                <div
                  v-for="column in item.settings.columns"
                  :key="column.id"
                  class="rp-report__item"
                >
                  <template v-if="column.type === 'headline'">
                    <report-label :label="column.settings.label" />
                    <AppFieldText
                      v-model="row.columns[column.id]"
                      @input="
                        localDoUpdate({
                          key: `${updateKey}.settings.rows.${row.id}.columns.${column.id}`,
                          value: $event.target.value,
                        })
                      "
                    />
                  </template>

                  <template v-if="column.type === 'text'">
                    <report-label :label="column.settings.label" />
                    <AppFieldText
                      v-model="row.columns[column.id]"
                      @input="
                        localDoUpdate({
                          key: `${updateKey}.settings.rows.${row.id}.columns.${column.id}`,
                          value: $event.target.value,
                        })
                      "
                    />
                  </template>

                  <template v-if="column.type === 'wysiwyg'">
                    <report-label :label="column.settings.label" />
                    <AppFieldWysiwyg
                      v-model="row.columns[column.id]"
                      @on-user-type="
                        localDoUpdate({
                          key: `${updateKey}.settings.rows.${row.id}.columns.${column.id}`,
                          value: $event,
                        })
                      "
                    />
                  </template>

                  <template v-if="column.type === 'textChoices'">
                    <report-label :label="column.settings.label" />
                    <AppFieldTextChoices
                      :value="row.columns[column.id]"
                      :choices="column.settings.options"
                      :multiple="column.settings.multiple"
                      @input="
                        localDoUpdate({
                          key: `${updateKey}.settings.rows.${row.id}.columns.${column.id}`,
                          value: $event,
                        })
                      "
                    />
                  </template>

                  <template v-if="column.type === 'table'">
                    <report-table
                      :label="column.settings.label"
                      :load-rows="row.columns[column.id]"
                      :columns="column.settings.columns"
                      :update-key="`${updateKey}.settings.rows.${row.id}.columns.${column.id}`"
                      @do-update="localDoUpdate"
                      @do-update-many="localDoUpdateMany"
                    />
                  </template>

                  <template v-if="column.type === 'images'">
                    <report-images
                      :label="column.settings.label"
                      :load-list="row.columns[column.id]"
                      :update-key="`${updateKey}.settings.rows.${row.id}.columns.${column.id}`"
                      :edit-image-mode="column.settings.editImage"
                      @do-update="localDoUpdate"
                      @do-update-many="localDoUpdateMany"
                  /></template>

                  <template v-if="column.type === 'image'">
                    <report-image
                      :label="column.settings.label"
                      :load-image-url="row.columns[column.id]"
                      :update-key="`${updateKey}.settings.rows.${row.id}.columns.${column.id}`"
                    />
                  </template>
                </div>
              </div>
            </transition>
          </div>
        </template>
      </draggable>
    </div>

    <RButton
      outlined
      color="black"
      class="mt-3"
      icon-before="mdi-plus"
      @click="addRow()"
    >
      הוספת שורה
    </RButton>
  </div>
</template>

<script>
import { dragOptions } from "@/common/settings";
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";
import ReportImages from "@/views/reports/components/ReportImages";
import ReportImage from "@/views/reports/components/ReportImage";
import ReportTable from "@/views/reports/components/ReportTable";
import ReportLabel from "@/views/reports/components/ReportLabel";
import { Utils } from "@/common/Utils";

export default {
  components: {
    draggable,
    ReportLabel,
    ReportImage,
    ReportImages,
    ReportTable,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    updateKey: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      isOpen: {},
      dragOptions,
    };
  },
  computed: {
    ...mapGetters("report", ["report"]),
    rowsArray() {
      if (!this.item.settings.rows) {
        return [];
      }

      let rows = this.item.settings.rows;

      rows = Object.values(rows);

      // 0) find max 'order'
      let maxOrder = Math.max(
        ...rows
          .map((o) => o.order)
          .filter(function (el) {
            return el != null;
          })
      );
      if (maxOrder === -Infinity) {
        maxOrder = 0;
      }
      maxOrder++;

      // 1) add 'order' to missing rows.
      rows.forEach((row) => {
        if (!row.order) {
          row.order = maxOrder;
          maxOrder++;
        }
      });

      // 2) add 'columns' to missing rows.
      rows.forEach((row) => {
        if (!row.columns) {
          row.columns = {};
        }
      });

      // 3) sort the array
      rows = rows.sort((a, b) => a.order - b.order);

      return rows;
    },
    rows() {
      if (!this.item.settings.rows) {
        return {};
      }
      return this.item.settings.rows;
    },
    hasCustomDoUpdate() {
      return this.$attrs && this.$attrs['onDoUpdate'];
    },
    hasCustomDoUpdateMany() {
      return this.$attrs && this.$attrs['onDoUpdateMany'];
    },
  },
  created() {
    // this.initRows(this.item.settings.rows);
  },
  methods: {
    ...mapActions("report", ["doUpdate", "doUpdateMany"]),
    // initRows(rows) {
    //   if (!rows) {
    //     return;
    //   }
    //   this.rows = Object.values(rows);
    //
    //   // 0) find max 'order'
    //   let maxOrder = Math.max(
    //     ...this.rows
    //       .map((o) => o.order)
    //       .filter(function (el) {
    //         return el != null;
    //       })
    //   );
    //   if (maxOrder === -Infinity) {
    //     maxOrder = 0;
    //   }
    //   maxOrder++;
    //
    //   // 1) add 'order' to missing rows.
    //   this.rows.forEach((row) => {
    //     if (!row.order) {
    //       row.order = maxOrder;
    //       maxOrder++;
    //     }
    //   });
    //
    //   // 2) add 'columns' to missing rows.
    //   this.rows.forEach((row) => {
    //     if (!row.columns) {
    //       row.columns = {};
    //     }
    //   });
    //
    //   // 3) sort the array
    //   this.rows = this.rows.sort((a, b) => a.order - b.order);
    // },
    toggleOpen(id) {
      this.isOpen[id] = !this.isOpen[id];
      this.$forceUpdate();
    },
    getRowIndex(rowToCheck) {
      let i = 1;
      let indexFound = false;
      this.rowsArray.forEach((row) => {
        if (row.id === rowToCheck.id && !indexFound) {
          indexFound = i;
        }
        if (!row.deletedAt) {
          i++;
        }
      });
      return indexFound;
    },
    getRowPreviewText(row) {
      let rowIndex = this.getRowIndex(row);
      let previewText = `שורה ${rowIndex}`;

      if (this.item.settings.columns) {
        for (const [, column] of Object.entries(this.item.settings.columns)) {
          if (
            column.type === "headline" ||
            column.type === "wysiwyg" ||
            column.type === "text"
          ) {
            if (row.columns) {
              previewText = this.$Utils.stripHtmlTags(row.columns[column.id]);
              break;
            }
          }
        }
      }
      return previewText;
    },
    async onDragChange() {
      let rowsArray = [...this.rowsArray];

      rowsArray = [...rowsArray].map((item, index) => {
        const newSort = index + 1;
        let hasChanged = item.order !== newSort;
        if (hasChanged) {
          item.order = newSort;
        }
        return item;
      });
      let data = {};
      for (const row of rowsArray) {
        data[`${this.updateKey}.settings.rows.${row.id}.order`] = row.order;
      }
      await this.localDoUpdateMany({ data });
    },
    async setRowActive(row, isActive) {
      row.isActive = isActive;

      await this.localDoUpdate({
        key: `${this.updateKey}.settings.rows.${row.id}.isActive`,
        value: row.isActive,
      });

      this.$forceUpdate();
    },

    removeRow(row) {
      if (confirm("בטוח למחוק את השורה?")) {
        row.deletedAt = this.$Utils.currentTimestamp();

        this.localDoUpdate({
          key: `${this.updateKey}.settings.rows.${row.id}.deletedAt`,
          value: row.deletedAt,
        });

        this.$toast.success("השורה נמחקה בהצלחה");

        this.$forceUpdate();
      }
    },
    async duplicateRow(row) {
      let newRow = JSON.parse(JSON.stringify(row));
      newRow.id = Utils.guid();

      // Find the index of the object after which the new object should be added
      const index = this.rowsArray.findIndex(
        (iterateRow) => iterateRow.id === row.id
      );

      let rowsArray = [...this.rowsArray];

      // Add the new object after the object with the given id
      rowsArray.splice(index + 1, 0, newRow);

      // Reorder the rows
      rowsArray = rowsArray.map((row, i) => {
        return {
          ...row,
          order: i + 1,
        };
      });

      // update server object
      let newRowFlat = Utils.flattenObject(newRow);
      let data = {};
      for (const [key, value] of Object.entries(newRowFlat)) {
        data[`${this.updateKey}.settings.rows.${newRow.id}.${key}`] = value;
      }

      await this.localDoUpdateMany({ data });

      // update server order
      data = {};
      for (const row of rowsArray) {
        data[`${this.updateKey}.settings.rows.${row.id}.order`] = row.order;
      }

      await this.localDoUpdateMany({ data });
    },
    async addRow() {
      let maxOrder = Utils.findMaxOrderInObject(this.rows);
      let newRow = {
        id: Utils.guid(),
        columns: {},
        isActive: true,
        order: maxOrder + 1,
      };

      // update server
      let data = {};
      for (const [key, value] of Object.entries(newRow)) {
        data[`${this.updateKey}.settings.rows.${newRow.id}.${key}`] = value;
      }
      await this.localDoUpdateMany({ data });

      // Update locally
      // this.rows.push(JSON.parse(JSON.stringify(newRow)));

      this.toggleOpen(newRow.id);
    },
    async localDoUpdate({ key, value }) {
      if (this.hasCustomDoUpdate) {
        this.$emit('doUpdate', {
          key: key,
          value: value,
        });
      } else {
        await this.doUpdate({
          key: key,
          value: value,
        });
      }
    },
    async localDoUpdateMany({ data }) {
      if (this.hasCustomDoUpdateMany) {
        this.$emit('doUpdateMany', { data });
      } else {
        await this.doUpdateMany({ data });
      }
    },
  },
};
</script>
