import * as types from "./mutation-types";
import Cookies from "js-cookie";

export default {
  [types.SET_TOKEN](state, token) {
    state.token = token;
    if (!state.isImpersonate) {
      Cookies.set("token", token, { expires: 365 });
    }
  },

  [types.REMOVE_TOKEN](state) {
    state.token = null;
    Cookies.remove("token");
  },

  [types.SET_IS_LOGGED_IN](state, data) {
    state.isLoggedIn = data;
  },

  [types.SET_USER](state, data) {
    state.user = data;
  },

  [types.SET_COMPANIES](state, data) {
    state.companies = data;
  },

  [types.SET_COMPANY](state, data) {
    state.company = data;
  },

  [types.SET_LOGOUT](state) {
    state.isLoggedIn = false;
    state.token = null;
    Cookies.remove("token");
  },
};
