async function getReportTemplatesLibrary() {
  const response = await window.axios.get(`/api/v1/report-templates/library`);
  return response.data;
}

async function updateReportTemplatesOrder(reportTemplatesIdsOrder) {
  const response = await window.axios.put(
    `/api/v1/report-templates/update-order`,
    {
      reportTemplatesIdsOrder: reportTemplatesIdsOrder,
    }
  );
  return response.data;
}

async function getTemplate(id) {
  const response = await window.axios.get(`/api/v1/report-templates/${id}`);
  return response.data;
}

async function updateTemplate(id, data) {
  const response = await window.axios.put(
    `/api/v1/report-templates/${id}`,
    data
  );
  return response.data;
}

async function updateFolder(templateId, folderId = null) {
  const response = await window.axios.put(
    `/api/v1/report-templates/${templateId}/update-folder`,
    {
      folderId: folderId,
    }
  );
  return response.data;
}

async function attachUserToTemplate(templateId, userId) {
  const response = await window.axios.put(
    `/api/v1/report-templates/${templateId}/attach-user/${userId}`
  );
  return response.data;
}

async function detachUserFromTemplate(templateId, userId) {
  const response = await window.axios.put(
    `/api/v1/report-templates/${templateId}/detach-user/${userId}`
  );
  return response.data;
}

async function exportTemplate(templateId) {
  await window.axios
    .get(`/api/v1/report-templates/${templateId}/export`, {
      responseType: "blob",
    })
    .then((response) => {
      const headerval = response.headers["content-disposition"];
      if (headerval != null) {
        let filename = headerval
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");
        filename = decodeURIComponent(filename.replace(/\+/g, " "));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        // link.dispatchEvent(new MouseEvent("click"));
        window.URL.revokeObjectURL(url);
        link.remove();
      } else {
        console.error(response);
      }
    })
    .catch((error) => {
      console.error("Fail to download template", error);
    });
}

async function importTemplate(file) {
  let fd = new FormData();
  fd.append("file", file);
  const response = await window.axios.post(
    `/api/v1/report-templates/import`,
    fd
  );
  return response.data;
}

async function uploadTemplate(file) {
  let fd = new FormData();
  fd.append("file", file);
  const response = await window.axios.post(
    `/api/v1/report-templates/upload`,
    fd
  );
  return response.data;
}

async function getReportTemplates() {
  const response = await window.axios.get(`/api/v1/report-templates`);
  return response.data;
}

async function removeReportTemplate(id) {
  const response = await window.axios.delete(`/api/v1/report-templates/${id}`);
  return response.data;
}

async function duplicateReportTemplate(id) {
  const response = await window.axios.put(
    `/api/v1/report-templates/${id}/duplicate`
  );
  return response.data;
}

async function createReportTemplate(data) {
  const response = await window.axios.post(`/api/v1/report-templates`, data);
  return response.data;
}

export default {
  getReportTemplatesLibrary,
  updateReportTemplatesOrder,
  getTemplate,
  updateTemplate,
  updateFolder,
  attachUserToTemplate,
  detachUserFromTemplate,
  exportTemplate,
  importTemplate,
  uploadTemplate,
  getReportTemplates,
  removeReportTemplate,
  duplicateReportTemplate,
  createReportTemplate,
};
