async function emailResendVerification(email) {
  const response = await window.axios.post("/api/v1/auth/email/resend", {
    email,
  });
  return response.data;
}

async function sendOtp(phone) {
  const response = await window.axios.post("/api/v1/auth/login/phone", {
    phone,
  });
  return response.data;
}

async function resetPasswordOtpToPhone(phone) {
  const response = await window.axios.post(
    "/api/v1/auth/reset-password/otp-to-phone",
    {
      phone,
    }
  );
  return response.data;
}

async function resetPasswordOtpToEmail(email) {
  const response = await window.axios.post(
    "/api/v1/auth/reset-password/otp-to-email",
    {
      email,
    }
  );
  return response.data;
}

async function verifyOtp(otp) {
  const response = await window.axios.post("/api/v1/auth/login/phone/verify", {
    otp,
    key: window.localStorage.getItem("otp_key"),
  });
  return response.data;
}

async function validateOtp({ otpCode, otpKey }) {
  const response = await window.axios.post("/api/v1/auth/validate-otp", {
    otpCode,
    otpKey,
  });
  return response.data;
}

async function resetPasswordWithOtp({ otpCode, otpKey, password }) {
  const response = await window.axios.post(
    "/api/v1/auth/reset-password-with-otp",
    {
      otpCode,
      otpKey,
      password,
    }
  );
  return response.data;
}

async function updateMe(params) {
  const response = await window.axios.put("/api/v1/me", params);
  return response.data;
}

async function changeCompany(companyId) {
  const response = await window.axios.put(
    "/api/v1/me/change-company/" + companyId
  );
  return response.data;
}

async function logout() {
  const response = await window.axios.post("/api/v1/auth/logout");
  return response.data;
}

async function getMe() {
  return await window.axios.get("/api/v1/me");
}

async function login(params) {
  return await window.axios.post("/api/v1/auth/login", params);
}

export default {
  emailResendVerification,
  sendOtp,
  resetPasswordOtpToPhone,
  resetPasswordOtpToEmail,
  verifyOtp,
  validateOtp,
  resetPasswordWithOtp,
  updateMe,
  changeCompany,
  logout,
  getMe,
  login,
};
