export const fetchPreferences = async (context) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/preferences`)
      .then((response) => {
        context.commit("SET_PREFERENCES", response.data.data);
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
