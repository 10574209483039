<template>
  <!-- eslint-disable -->
  <div>
    <div class="r-page-report-header">
      <div class="r-page-report-header-content">
        <div class="r-page-report-header-title">
          <span class="r-page-report-header-title-title"
            >כתובת: {{ report.address }}</span
          >
          <div
            v-if="report.tag || report.is_draft"
            class="r-page-report-header-tags"
          >
            <span v-if="report.tag" class="r-page-report-header-tag">{{
              report.tag.trim()
            }}</span>
            <span v-if="report.is_draft" class="r-page-report-header-draft"
              >טיוטה</span
            >
          </div>
        </div>
        <div class="r-page-report-header-subtitle">
          <span v-if="report.number"> מספר דוח: {{ report.number }}</span>
          <span v-if="report.customerName"
            >לקוח: {{ report.customerName }}</span
          >
          <span v-if="report.name">תבנית: {{ report.name }}</span>
          <span v-if="report.project_id && report.project">
            פרויקט:
            <router-link
              :to="{
                name: 'projects.edit',
                params: { id: report.project.id },
              }"
            >
              {{ report.project.name }}
            </router-link>
          </span>
        </div>
      </div>
      <div class="r-page-report-header-actions">
        <RButton
          :disabled="isOffline || isSyncing"
          v-show="
            !user.permissionDisableSendReportToEmail &&
            report.id !== '00b5c320-c96e-46ee-ae56-9651ee0b3bf7'
          "
          icon-before="mdi-email"
          outlined
          color="green"
          @click="openSendModal"
        >
          שליחה למייל
        </RButton>
        <RButton
          :disabled="isOffline || isSyncing"
          v-show="
            !user.permissionDisableSendReportToEmail &&
            report.id !== '00b5c320-c96e-46ee-ae56-9651ee0b3bf7'
          "
          icon-before="mdi-download"
          outlined
          color="blue"
          @click="openShareReportModal"
        >
          הורדה
        </RButton>

        <RActions>
          <template #activator="{ props }">
            <button
              v-bind="props"
              role="button"
              class="r-button r-button--black r-button--outlined"
            >
              פעולות נוספות
              <i
                aria-hidden="true"
                class="v-icon notranslate mdi mdi-dots-vertical theme--light"
              ></i>
            </button>
          </template>

          <RAction
            @click="openSettingsModal"
            icon="mdi-cog"
            text="הגדרות דוח"
          ></RAction>
          <RAction
            @click="openPreviewModal"
            icon="mdi-eye"
            text="תצוגה מקדימה"
            :disabled="isOffline || isSyncing"
          ></RAction>
          <RAction
            @click="openCloneModal"
            icon="mdi-content-copy"
            text="שכפול דוח"
            :disabled="isOffline || isSyncing"
          ></RAction>
          <RAction
            link
            :to="{
              name: 'reports.editTemplate',
              params: { id: this.reportId },
            }"
            icon="mdi-view-dashboard"
            text="עריכת התבנית"
            :disabled="isOffline || isSyncing"
          ></RAction>
        </RActions>
      </div>
    </div>

    <ReportSendModal ref="sendModalRef" :report-id="reportId" />

    <ReportSettingsModal ref="settingsModalRef" />

    <ReportCloneModal ref="cloneModalRef" :report-id="reportId" />

    <ReportPreviewModal ref="previewModalRef" :report-id="reportId" />

    <RModal v-model="isShareReportModalOpen" title="שיתוף והורדת הדוח">
      <p class="text-black/60 mb-4">
        * במידה ותעשו שינויים נוספים בדוח תצטרכו ליצור קישור חדש לשיתוף והורדה.
      </p>
      <RFieldText
        id="short-report-link"
        ref="shortReportLink"
        v-model="shortReportLink"
        label="קישור לשיתוף הדוח"
        readonly=""
        @click="copyReportLink"
      />
      <div class="share-report-buttons mt-3">
        <RButton
          lg
          icon-before="mdi-whatsapp"
          color="green"
          @click="shareReportLinkOnWhatsapp"
        >
          שיתוף ב-Whatsapp
        </RButton>
        <RButton
          lg
          icon-before="mdi-download"
          color="blue"
          @click="downloadReportLink"
        >
          הורדת הדוח PDF
        </RButton>
        <RButton
          lg
          icon-before="mdi-content-copy"
          color="purple"
          @click="copyReportLink"
        >
          העתקת קישור הדוח
        </RButton>
      </div>
    </RModal>
  </div>
</template>

<script>
import { Utils } from "@/common/Utils";
import ReportSendModal from "./ReportSendModal";
import ReportCloneModal from "./ReportCloneModal";
import ReportPreviewModal from "./ReportPreviewModal";
import ReportsApi from "@/api/ReportsApi";
import { mapGetters } from "vuex";
import { trackEvent } from "@/services/TrackingService";
import ReportSettingsModal from "./ReportSettingsModal.vue";

export default {
  components: {
    ReportSettingsModal,
    ReportSendModal,
    ReportCloneModal,
    ReportPreviewModal,
  },
  data() {
    return {
      reportId: null,
      Utils,
      isCloneReportModalOpen: false,
      isPreviewReportModalOpen: false,
      isSettingsReportModalOpen: false,
      isShareReportModalOpen: false,
      shortReportLink: null,
    };
  },
  computed: {
    ...mapGetters("reportQue", ["isSyncing", "isOffline"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("report", ["report"]),
  },
  created() {
    this.reportId = this.report.id;
  },
  methods: {
    trackEvent,
    openSendModal() {
      this.$refs.sendModalRef.openModal();
    },
    openSettingsModal() {
      this.$refs.settingsModalRef.openModal();
    },
    openCloneModal() {
      this.$refs.cloneModalRef.openModal();
    },
    openPreviewModal() {
      this.$refs.previewModalRef.openModal();
    },
    shareReportLinkOnWhatsapp() {
      if (!this.shortReportLink) {
        this.$toast.error("שגיאה בשיתוף הדוח ל-WhatsApp");
        return;
      }

      if (window.innerWidth > 1200) {
        window.open(
          `https://api.whatsapp.com/send?text=${this.shortReportLink}`,
          "_blank"
        );
      } else {
        const anchor = document.createElement("a");
        anchor.href = `https://api.whatsapp.com/send?text=${this.shortReportLink}`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }
    },
    downloadReportLink() {
      if (!this.shortReportLink) {
        this.$toast.error("שגיאה בהורדת הדוח");
        return;
      }

      const anchor = document.createElement("a");
      anchor.href = this.shortReportLink;
      if (!Utils.isIOSDevice() && window.innerWidth > 1100) {
        anchor.target = "_blank"; // TODO: it's not working on the iOS App so we disabled it for iOS for now.
      }

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
    copyReportLink() {
      if (!this.shortReportLink) {
        this.$toast.error("שגיאה בהעתקת הדוח");
        return;
      }

      navigator.clipboard.writeText(this.shortReportLink)
        .then(() => {
          this.$toast.success("הקישור הועתק בהצלחה");
        })
        .catch(() => {
          this.$toast.error("שגיאה בהעתקת הקישור");
        });
    },
    async openShareReportModal() {
      let loader = this.$loading.show();

      try {
        this.shortReportLink = await ReportsApi.getDownloadReportShortLink(
          this.reportId
        );
        this.isShareReportModalOpen = true;
      } catch (e) {
        this.$toast.error("שגיאה בשיתוף הדוח");
      }

      loader.hide();
    },
    async shareOnWhatsapp() {
      let loader = this.$loading.show();

      let downloadReportLink = await ReportsApi.getDownloadReportShortLink(
        this.reportId
      );

      if (window.innerWidth > 1200) {
        window.open(
          `https://api.whatsapp.com/send?text=${downloadReportLink}`,
          "_blank"
        );
      } else {
        const anchor = document.createElement("a");
        anchor.href = `https://api.whatsapp.com/send?text=${downloadReportLink}`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }

      loader.hide();
    },
  },
};
</script>

<style scoped lang="scss">
.share-report-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
}
.share-report-button {
  @media (max-width: 767px) {
    width: 100%;
  }
}
</style>
