<template>
  <div class="element-btn">
    <RIcon>{{ icon }}</RIcon>
    <div>{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.element-btn {
  --primary-color: #556068;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: var(--primary-color);
  width: 48%;
  height: 70px;
  border-radius: 3px;
  cursor: move;

  > div,
  i {
    transition: 0.2s;
  }

  .v-icon {
    color: var(--primary-color);
  }

  &:hover {
    --primary-color: var(--color-purple);
    box-shadow: 0 2px 6px rgb(0, 0, 0, 0.2);
  }
}
</style>
