<template>
  <div
    v-if="show"
    class="r-alert"
    :class="{
      [alertClass]: true,
    }"
  >
    <div class="r-alert-close">
      <RButtonIcon v-if="dismissible" icon="mdi-close" @click="dismiss" />
    </div>
    <div class="r-alert-icon">
      <RIcon>{{ alertIcon }}</RIcon>
    </div>
    <div class="r-alert-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dismissible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "info",
      validator: (value) =>
        ["success", "info", "warning", "error"].includes(value),
    },
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    alertClass() {
      return `r-alert-${this.type}`;
    },
    alertIcon() {
      if (this.type === "success") {
        return "mdi-check-circle-outline";
      } else if (this.type === "info") {
        return "mdi-information-outline";
      } else if (this.type === "warning") {
        return "mdi-alert-outline";
      } else if (this.type === "error") {
        return "mdi-close-octagon";
      }
      return "mdi-information-outline";
    },
  },
  methods: {
    dismiss() {
      this.show = false;
      this.$emit("dismiss");
    },
  },
};
</script>
