<template>
  <RModal v-model="isDeleteModalOpen" title="מחיקת דוח">
    <div style="color: #000">
      הינך עומד למחוק דוח זה
      <span v-if="report.number">(מספר {{ report.number }})</span>
      מהמערכת, שים לב שלא ניתן לשחזר פעולה זו.
      <br />
      אנא הקלד
      <b>״מחיקה״</b>
      לאישור
    </div>
    <RFieldText v-model="deleteInput" class="mt-3 mb-3" />
    <RButton
      :disabled="deleteInput !== 'מחיקה'"
      color="red"
      block
      lg
      type="submit"
      @click="deleteReport"
    >
      מחיקה
    </RButton>
  </RModal>
</template>

<script>
import { mapGetters } from "vuex";
import { trackEvent } from "@/services/TrackingService";
import ReportsApi from "@/api/ReportsApi";

export default {
  data() {
    return {
      isDeleteModalOpen: false,
      deleteInput: null,
    };
  },
  computed: {
    ...mapGetters("report", ["report"]),
  },
  created() {},
  methods: {
    openModal() {
      this.isDeleteModalOpen = true;
    },
    async deleteReport() {
      if (confirm("בטוח למחוק את הדוח?")) {
        trackEvent("Report Action", {
          "Action Name": "Deleted",
        });

        let loader = this.$loading.show();
        try {
          await ReportsApi.removeReport(this.$store.state.report.report.id);
          this.$toast.success("הדוח נמחק בהצלחה");
          this.$router.push({ name: "reports" });
        } catch (err) {
          this.$toast.error(err);
        } finally {
          loader.hide();
        }
      }
    },
  },
};
</script>
