<template>
  <ReportItemCard
    :label="label"
    :label-total="$ItemsUtils.getTotalValidItems(list)"
  >
    <template #actions> </template>

    <template #default>
      <div class="report-item-card__body pt-0 pb-0 px-0">
        <draggable
          :list="list"
          v-bind="dragOptions"
          tag="div"
          class="app-field--files__list"
          handle=".sort-handler"
          item-key="id"
          @change="onDragChange"
        >
          <template #item="{ element: file }">
            <div
              class="app-field--files__file"
              :class="{
                'is-hidden': file.deletedAt,
              }"
            >
              <div
                class="sort-handler app-field--files__file-drag"
                style="cursor: grab"
              >
                <RIcon>mdi-drag</RIcon>
              </div>

              <a
                target="_blank"
                :href="getFileUrl(file.path)"
                :download="file.name"
                class="app-field--files__file-icon"
              >
                <RIcon :color="getFileIcon(file).color">
                  {{ getFileIcon(file).icon }}
                </RIcon>
              </a>

              <div class="app-field--files__file-details">
                <a
                  target="_blank"
                  :href="getFileUrl(file.path)"
                  :download="file.name"
                  class="app-field--files__file-name"
                  style="color: #000"
                >
                  {{ file.name }}
                </a>

                <input
                  v-model="file.title"
                  type="text"
                  class="app-field--files__file-input"
                  placeholder="שם/הערה לקובץ"
                  maxlength="50"
                  @input="updateFileTitle(file, $event)"
                />
              </div>

              <div class="app-field--files__file-actions">
                <RActions>
                  <RAction
                    target="_blank"
                    :href="getFileUrl(file.path)"
                    :download="file.name"
                    icon="mdi-download"
                    text="הורדת הקובץ"
                  />
                  <RAction
                    icon="mdi-delete"
                    text="מחיקת הקובץ"
                    @click="removeFile(file)"
                  />
                </RActions>
              </div>
            </div>
          </template>
        </draggable>
      </div>

      <div class="report-item-card__body pt-0">
        <div class="d-flex align-center mt-4">
          <div
            class="app-field--files-addMediaButtonWrapper"
            :class="{
              'u-disabled': isUploading,
            }"
          >
            <RButton
              :loading="isUploading"
              outlined
              color="black"
              icon-before="mdi-upload"
            >
              העלאת קבצים
            </RButton>
            <input
              ref="gallery"
              class="app-field--files-addMediaInput"
              type="file"
              multiple
              @change="uploadFiles"
            />
          </div>
        </div>
      </div>
    </template>
  </ReportItemCard>
</template>

<script>
import draggable from "vuedraggable";
import { dragOptions } from "@/common/settings";
import { mapActions } from "vuex";
import MediaApi from "@/api/MediaApi";
import ReportItemCard from "@/views/reports/components/ReportItemCard.vue";
import { reactive } from 'vue'
import { Utils } from "@/common/Utils";

export default {
  components: { ReportItemCard, draggable },
  props: {
    loadList: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    label: {
      type: String,
      default: "",
    },
    updateKey: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      isUploading: false,
      isCardOpen: true,
      list: [],
      dragOptions, 
    };
  },
  computed: {
    hasCustomDoUpdate() {
      return this.$attrs && this.$attrs['onDoUpdate'];
    },
    hasCustomDoUpdateMany() {
      return this.$attrs && this.$attrs['onDoUpdateMany'];
    },
    sortedList() {
      return [...this.list].sort((a, b) => a.order - b.order);
    },
  },
  created() {
    this.initFiles();
  },
  methods: {
    ...mapActions("report", ["doUpdate", "doUpdateMany"]),
    getFileUrl(path) {
      if (!path) {
        return null;
      }
      return Utils.getImageUrl(path);
    },
    getFileIcon(file) {
      const extension = this.getFileExtension(file);
      let icon, color;

      switch (extension) {
        case "png":
        case "jpeg":
        case "jpg":
          icon = "mdi-file-image";
          color = "primary";
          break;
        case "mp4":
        case "mov":
          icon = "mdi-file-video";
          color = "green";
          break;
        case "docx":
        case "doc":
          icon = "mdi-file-word";
          color = "blue";
          break;
        case "xlsx":
        case "xls":
          icon = "mdi-file-excel";
          color = "green";
          break;
        case "pdf":
          icon = "mdi-file-pdf";
          color = "red";
          break;
        default:
          icon = "mdi-file";
          color = "grey";
      }

      return { icon, color };
    },
    initFiles() {
      if (!this.loadList) {
        this.list = [];
      } else if (Array.isArray(this.loadList) && !this.loadList.length) {
        this.list = [];
      } else {
        this.list = Object.values(this.loadList);
      }

      let maxOrder = this.$Utils.findMaxOrderInObject(this.list);
      maxOrder++;

      // Add 'order' to missing rows
      this.list = this.list.map(row => {
        if (!row.order) {
          return { ...row, order: maxOrder++ };
        }
        return row;
      });

      // Sort the array
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
    async removeFile(file) {
      if (confirm("בטוח למחוק?")) {
        // Replace Vue.set with direct assignment
        file.deletedAt = this.$Utils.currentTimestamp();

        await this.localDoUpdate({
          key: `${this.updateKey}.${file.id}.deletedAt`,
          value: file.deletedAt,
        });

        // Force a re-render if needed
        this.$forceUpdate();
      }
    },
    async uploadFiles(event) {
      let files = event.target.files;
      this.isUploading = true;
      for (const file of files) {
        try {
          await this.uploadFile(file);
        } catch (error) {
          this.$toast.error("שגיאה בהעלאת הקובץ");
        }
      }
      this.isUploading = false;
    },
    async uploadFile(file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop();

      // Validate Allowed File Extensions
      let allowedExtensions = [
        "pdf",
        "jpeg",
        "jpg",
        "png",
        "mp4",
        "mov",
        "doc",
        "docx",
        "xls",
        "xlsx",
      ];

      if (!allowedExtensions.includes(fileExtension)) {
        this.$toast.error(
          "סוג הקובץ אינו מורשה. מורשים: excel, word, pdf, images, videos"
        );
        return;
      }

      // Validate File Size
      const fileSize = file.size;
      const fileSizeInMB = (fileSize / (1024 * 1024)).toFixed(2);
      if (fileSizeInMB > 40) {
        this.$toast.error("הקובץ גדול מדי, מותר עד 40 מגה");
        return;
      }

      try {
        let responseData = await MediaApi.uploadFile(file);

        let fileId = this.$Utils.randomString(8);
        let maxOrder = this.$Utils.findMaxOrderInObject(this.list);
        maxOrder++;

        let fileObject = {
          id: fileId,
          path: responseData.data.path,
          title: "",
          uploadedAt: this.$Utils.currentTimestamp(),
          name: fileName,
          order: maxOrder,
        };

        let data = {};
        for (let [key, value] of Object.entries(fileObject)) {
          data[`${this.updateKey}.${fileId}.${key}`] = value;
        }

        // Update list reactively
        this.list = [...this.list, fileObject];
        await this.localDoUpdateMany({ data });
        
        // Force a re-render if needed
        this.$forceUpdate();
        this.$toast.success("הקובץ נוסף בהצלחה");
      } catch (error) {
        this.$toast.error("שגיאה בהעלאת הקובץ");
      }
    },
    getFileExtension(file) {
      return file.path.split(".").pop();
    },
    async updateFileTitle(file, $event) {
      // Update list reactively using map
      this.list = this.list.map(fileInner => {
        if (fileInner.id === file.id) {
          return { ...fileInner, title: $event.target.value };
        }
        return fileInner;
      });

      await this.localDoUpdate({
        key: `${this.updateKey}.${file.id}.title`,
        value: $event.target.value,
      });
    },
    async onDragChange() {
      // Update list reactively using map
      this.list = this.list.map((item, index) => {
        const newSort = index + 1;
        return { ...item, order: newSort };
      });

      let data = {};
      for (const file of this.list) {
        data[`${this.updateKey}.${file.id}.order`] = file.order;
      }
      await this.localDoUpdateMany({ data });
    },
    async localDoUpdate({ key, value }) {
      if (this.hasCustomDoUpdate) {
        this.$emit('doUpdate', {
          key: key,
          value: value,
        });
      } else {
        await this.doUpdate({
          key: key,
          value: value,
        });
      }
    },
    async localDoUpdateMany({ data }) {
      if (this.hasCustomDoUpdateMany) {
        this.$emit('doUpdateMany', { data });
      } else {
        await this.doUpdateMany({ data });
      }
    },
    updateComplexData(data) {
      return reactive({
        ...data,
        // additional properties
      });
    }
  },
};
</script>
