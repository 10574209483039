<template>
  <div class="app-field app-field--image">
    <label class="mb-2 d-block subtitle-2">{{ label }}</label>

    <div v-if="filePath" class="d-flex align-start flex-column mt-3">
      <span v-if="isImage" style="max-height: 300px; overflow: auto">
        <img :src="getFileUrl(filePath)" class="preview-image" />
      </span>

      <v-text-field
        v-else
        v-model="filePath"
        density="compact"
        variant="outlined"
        style="background: #fff; width: 100%"
        hide-details
        readonly
      >
      </v-text-field>

      <v-btn
        class="mt-3"
        color="error"
        depressed
        outlined
        small
        style="background: #fff"
        @click="removeFile"
      >
        <v-icon left> mdi-delete </v-icon>
        מחיקת קובץ
      </v-btn>
    </div>

    <div v-if="!filePath" class="d-flex flex-wrap align-center">
      <div class="app-field--image-addMediaButtonWrapper">
        <v-btn
          outlined
          color="primary"
          depressed
          style="background-color: #fff"
        >
          <v-icon size="18" class="ml-2"> mdi-file-upload</v-icon>
          <span>העלאת קובץ</span>
        </v-btn>

        <input
          ref="fileInput"
          class="app-field--image-addMediaInput"
          type="file"
          :accept="accept"
          @change="uploadFile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MediaApi from "@/api/MediaApi";

export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [String, Boolean],
      default: "",
    },
    accept: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      filePath: "",
      canChange: false,
    };
  },
  computed: {
    media_url() {
      return this.$Reporto.globals.MEDIA_URL;
    },
    isImage() {
      if (!this.filePath) {
        return false;
      }
      return this.filePath.match(/\.(jpeg|jpg|gif|png)$/) != null;
    },
  },
  watch: {
    filePath(val) {
      if (this.canChange) {
        this.$emit("update:modelValue", val);
      }
    },
    modelValue(val) {
      this.filePath = val;
    },
  },
  created() {
    this.filePath = this.modelValue;
    setTimeout(() => {
      this.canChange = true;
    }, 10);
  },
  methods: {
    getFileUrl() {
      return this.media_url + this.filePath;
    },
    removeFile() {
      if (confirm("בטוח למחוק?")) {
        this.filePath = "";
      }
    },
    async uploadFile(event) {
      if (!event.target.files || !event.target.files.length) {
        return;
      }

      let file = event.target.files[0];

      let loader = this.$loading.show();

      try {
        let responseData = await MediaApi.uploadFile(file);

        this.filePath = responseData.data.path;
        this.$forceUpdate();
        loader.hide();
      } catch (error) {
        this.$toast.error(
          this.$Utils.toastMessageFromResponse(error, "שגיאה בעדכון הפרויקט")
        );
        loader.hide();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-image {
  max-width: 100%;
  width: 240px;
  // height: 150px;
  background: #fff;
  padding: 5px;
  border-radius: 4px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app-field--image-addMediaButtonWrapper {
  position: relative;

  &.with-camera-button {
    @media (min-width: 1440px) {
      display: none;
    }
  }
}
.app-field--image {
  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
}
.app-field--image-addMediaInput {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
</style>
