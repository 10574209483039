// import Vue from "vue";
import { Utils } from "@/common/Utils";

export const getInlineCssOld = (type, style) => {
  let cssInline = "";

  if (!style) {
    return cssInline;
  }

  for (let key in style) {
    let styleKey = key;
    let styleValue = style[key];

    if (!Object.prototype.hasOwnProperty.call(style, key)) {
      continue;
    }

    if (styleKey === "width") {
      cssInline += `width:${styleValue}%;`;
    }

    if (styleKey === "backgroundColor") {
      cssInline += `background-color:${styleValue};`;
    }

    if (styleKey === "color") {
      cssInline += `color:${styleValue};`;
    }

    // if (styleKey === "backgroundImagePath") {
    //   cssInline += `background-image:url(${mediaUrl}${styleValue});`;
    // }

    if (styleKey === "textAlign") {
      cssInline += `text-align:${styleValue};`;
    }

    if (styleKey === "fontWeight") {
      cssInline += `font-weight:${styleValue};`;
    }

    if (styleKey === "fontSize") {
      cssInline += `font-size:${styleValue}px;`;
    }

    if (styleKey === "verticalAlign") {
      if (styleValue === "bottom") {
        cssInline += `align-content:flex-end;align-items:flex-end`;
      }
      if (styleValue === "top") {
        cssInline += `align-content:flex-start;align-items:flex-start`;
      }
      if (styleValue === "middle") {
        cssInline += `align-content:center;align-items:center`;
      }
    }

    if (styleKey === "padding") {
      let paddingTop = styleValue.top ? styleValue.top : 0;
      let paddingBottom = styleValue.bottom ? styleValue.bottom : 0;
      let paddingLeft = styleValue.left ? styleValue.left : 0;
      let paddingRight = styleValue.right ? styleValue.right : 0;

      cssInline += `padding: ${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px;`;
    }

    if (styleKey === "border" && styleValue.width) {
      const { top, right, bottom, left } = styleValue.width;
      const borderStyle = styleValue.style || "solid";
      const borderColor = styleValue.color || "#000";
      if (top) {
        cssInline += `border-top: ${top}px ${borderStyle} ${borderColor};`;
      }

      if (right) {
        cssInline += `border-right: ${right}px ${borderStyle} ${borderColor};`;
      }

      if (bottom) {
        cssInline += `border-bottom: ${bottom}px ${borderStyle} ${borderColor};`;
      }

      if (left) {
        cssInline += `border-left: ${left}px ${borderStyle} ${borderColor};`;
      }
    }
  }

  return cssInline;
};

export const initializeElement = () => {
  const id = `field_${Utils.guid()}`;
  return {
    textField: {
      id,
      elementType: "field",
      type: "text",
      text: "Text",
      style: {
        textAlign: "right",
        color: "#000000",
        fontSize: 16,
      },
    },
    imageField: {
      id,
      elementType: "field",
      type: "image",
      imageUrl: null,
      style: {
        width: 100,
      },
    },
  };
};

export const initializeColumn = ({ width }) => {
  return {
    id: `column_${Utils.guid()}`,
    elementType: "column",
    style: {
      width,
      verticalAlign: "middle",
    },
    fields: [],
  };
};

export const initializeSection = ({ columns }) => {
  return {
    id: `section_${Utils.guid()}`,
    elementType: "section",
    style: {
      padding: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
      border: {
        direction: {
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        },
        color: "#000",
      },
    },
    columns: columns || [],
  };
};

export const initializeTemplatePart = (name) => {
  return {
    name,
    id: `template_${Utils.guid()}`,
    elementType: "paper",
    style: {
      padding: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
    sections: [
      initializeSection({
        columns: [initializeColumn({ width: 100 })],
      }),
    ],
  };
};

export const initializeTemplate = () => {
  return {
    header: initializeTemplatePart("header"),
    footer: initializeTemplatePart("footer"),
  };
};

export const initializeTemplatePartHeader = () => {
  return {
    id: `template_${Utils.guid()}`,
    name: "header",
    style: {
      width: 100,
      border: {
        color: "#000",
        direction: { top: 0, left: 0, right: 0, bottom: 0 },
      },
      _height: 86,
      padding: { top: 25, left: 25, right: 25, bottom: 25, isLocked: true },
    },
    sections: [
      {
        id: "section_fe001621-1389-4cfd-abc5-5e947496dbfb",
        style: {
          border: {
            color: "#000",
            width: { bottom: 1 },
            direction: { top: 0, left: 0, right: 0, bottom: 0 },
          },
          padding: { top: 0, left: 0, right: 0, bottom: 5 },
        },
        columns: [
          {
            id: "column_2e3d3a59-3d52-4744-80a1-b7269e7ffa7a",
            style: { width: 25, verticalAlign: "middle" },
            fields: [
              {
                id: "field_26501004-43a7-4962-a4ce-7b809090f4d3",
                type: "image",
                style: { width: 100, imageAlign: "right" },
                imageUrl: "images/uploads/627179e0641e7.png",
                elementType: "field",
              },
            ],
            elementType: "column",
          },
          {
            id: "column_1530b363-2573-4130-9457-fe4cfaa8024a",
            style: { width: 75, verticalAlign: "bottom" },
            fields: [
              {
                id: "field_b8be743f-2493-4cbc-90d8-042271a9b44e",
                text: "<p><strong>שם החברה</strong> | 050-000-0000 | youremail@gmail.com</p>",
                type: "text",
                style: { color: "#000000", fontSize: 16, textAlign: "left" },
                elementType: "field",
              },
            ],
            elementType: "column",
          },
        ],
        elementType: "section",
      },
    ],
    elementType: "paper",
  };
};

export const initializeTemplatePartFooter = () => {
  return {
    id: `template_${Utils.guid()}`,
    name: "footer",
    style: {
      width: 100,
      border: {
        color: "#000",
        direction: { top: 0, left: 0, right: 0, bottom: 0 },
      },
      _height: 78,
      padding: { top: 25, left: 25, right: 25, bottom: 0 },
    },
    sections: [
      {
        id: "section_1fe2e555-3a00-49e7-b356-30713256b765",
        style: {
          width: 100,
          border: {
            top: 3,
            left: 5,
            color: "#000",
            style: "solid",
            width: { top: 1, left: 0, right: 0, bottom: 0, isLocked: false },
            isLocked: false,
            direction: { top: 3, left: 3, right: 3, bottom: 3 },
          },
          padding: { top: 5, left: 5, right: 5, bottom: 25, isLocked: false },
          backgroundColor: null,
        },
        columns: [
          {
            id: "column_084708a0-797a-4fba-a621-a561f85f34b4",
            style: {
              width: 33,
              border: {
                color: "#000",
                direction: { top: 0, left: 0, right: 0, bottom: 0 },
              },
              padding: { top: 0, left: 0, right: 0, bottom: 0 },
              verticalAlign: "top",
            },
            fields: [
              {
                id: "field_fa6aa338-e322-4017-bded-ba7a3403c353",
                text: "<p>[עמוד]</p>",
                type: "text",
                style: {
                  color: "#000000",
                  fontSize: "14",
                  textAlign: "right",
                  fontWeight: "normal",
                },
                elementType: "field",
              },
            ],
            elementType: "column",
          },
          {
            id: "column_f1dc897d-5e69-4763-92aa-4554653e15f7",
            style: {
              width: 36.35917312661494,
              border: {
                color: "#000",
                direction: { top: 0, left: 0, right: 0, bottom: 0 },
              },
              padding: { top: 0, left: 0, right: 0, bottom: 0 },
            },
            fields: [
              {
                id: "field_1b5d8b5a-bfd6-4418-9fa5-b58843a75628",
                text: "<p><strong>שם החברה</strong></p>",
                type: "text",
                style: {
                  color: "#000000",
                  fontSize: "14",
                  textAlign: "center",
                  fontWeight: "normal",
                },
                elementType: "field",
              },
            ],
            elementType: "column",
          },

          {
            id: "column_09567473-4623-4985-a688-b6a1bbc12e8d",
            style: {
              width: 30.640826873385027,
              border: {
                color: "#000",
                direction: { top: 0, left: 0, right: 0, bottom: 0 },
              },
              padding: { top: 0, left: 0, right: 0, bottom: 0 },
            },
            fields: [
              {
                id: "field_db18f9af-5a2a-4495-8cfa-713eee06c048",
                text: "<p>[תאריך_נוכחי]</p>",
                type: "text",
                style: {
                  color: "#000000",
                  fontSize: "14",
                  textAlign: "left",
                  fontWeight: "normal",
                },
                elementType: "field",
              },
            ],
            elementType: "column",
          },
        ],
        elementType: "section",
      },
    ],
    elementType: "paper",
  };
};

export const initializePreferenceStyle = () => {
  return {
    fontFamily: "Assistant",
    backgroundImagePath: null,
  };
};

/* All the code below is copied from the PHP to calculate and create with the same logic */
const TOTAL_PAGE_FULL_WIDTH = 793;

export const getPaperWidth = () => {
  let width = TOTAL_PAGE_FULL_WIDTH;

  // Disabled on Vue-Frontend
  // if (paperStyle.padding.left) {
  //   width -= paperStyle.padding.left;
  // }
  // if (paperStyle.padding.right) {
  //   width -= paperStyle.padding.right;
  // }

  return width;
};

export const getSectionWidth = (paperStyle) => {
  // Different on Vue-Frontend
  // return getPaperWidth(paperStyle);

  let width = TOTAL_PAGE_FULL_WIDTH;

  if (paperStyle.padding.left) {
    width -= paperStyle.padding.left;
  }
  if (paperStyle.padding.right) {
    width -= paperStyle.padding.right;
  }

  return width;
};

export const getColumnWidth = (paperStyle, sectionStyle, columnStyle) => {
  let sectionWidth = getSectionWidth(paperStyle, sectionStyle);

  if (sectionStyle.padding.left) {
    sectionWidth -= sectionStyle.padding.left;
  }

  if (sectionStyle.padding.right) {
    sectionWidth -= sectionStyle.padding.right;
  }

  return (columnStyle["width"] / 100) * sectionWidth;
};

export const getFieldImageWidth = (
  paperStyle,
  sectionStyle,
  columnStyle,
  fieldStyle
) => {
  let columnWidth = getColumnWidth(paperStyle, sectionStyle, columnStyle);

  return (fieldStyle.width / 100) * columnWidth;
};

export const getInlineCss = (type, style, extraArgs = {}) => {
  let cssInline = "";

  if (!style) {
    return cssInline;
  }

  // manipulate for each type
  if (type === "paper") {
    // padding
    if (style.padding) {
      cssInline += getInlineCssPadding(style.padding);
    }

    // Extra css
    cssInline += "width:" + getPaperWidth(style) + "px;";
  }

  if (type === "section") {
    // padding
    if (style.padding && getInlineCssPadding(style.padding)) {
      cssInline += getInlineCssPadding(style.padding);
    }

    // border
    if (style.border && getInlineCssBorder(style.border)) {
      cssInline += getInlineCssBorder(style.border);
    }

    // backgroundColor
    if (style.backgroundColor) {
      cssInline += `background-color: ${style.backgroundColor};`;
    }

    // Extra css
    cssInline +=
      "width:" + getSectionWidth(extraArgs.paper.style, style) + "px;";
    cssInline += "border-spacing:0;";
    cssInline += "border-collapse: separate;";
  }

  if (type === "column") {
    // verticalAlign
    if (style.verticalAlign) {
      cssInline += `vertical-align:${style.verticalAlign};`;
    }

    // Extra css
    cssInline +=
      "width:" +
      getColumnWidth(extraArgs.paper.style, extraArgs.section.style, style) +
      "px;";
    cssInline += "padding:0;";
    cssInline += "border-spacing:0;";
  }

  if (type === "fieldWrapper-image") {
    // imageAlign
    if (style.imageAlign) {
      cssInline += `text-align:${style.imageAlign};`;
    }

    cssInline += "padding:0;";
  }

  if (type === "field-image") {
    cssInline +=
      "width:" +
      getFieldImageWidth(
        extraArgs.paper.style,
        extraArgs.section.style,
        extraArgs.column.style,
        style
      ) +
      "px;";
    cssInline += "height: auto;";
  }

  if (type === "field-text") {
    // fontSize
    if (style.fontSize) {
      cssInline += `font-size: ${style.fontSize}px;`;
    }

    // textAlign
    if (style.textAlign) {
      cssInline += `text-align: ${style.textAlign};`;
    }

    // color
    if (style.color) {
      cssInline += `color:${style.color};`;
    }

    cssInline += "padding:0;";
  }

  return cssInline;
};

export const getInlineCssPadding = (padding) => {
  let cssInline = "";

  let paddingTop = padding.top ? padding.top : 0;
  let paddingBottom = padding.bottom ? padding.bottom : 0;
  let paddingLeft = padding.left ? padding.left : 0;
  let paddingRight = padding.right ? padding.right : 0;

  cssInline += `padding: ${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px;`;

  return cssInline;
};

export const getInlineCssBorder = (border) => {
  let cssInline = "";

  let borderWidthTop = border.width && border.width.top ? border.width.top : 0;
  let borderWidthRight =
    border.width && border.width.right ? border.width.right : 0;
  let borderWidthBottom =
    border.width && border.width.bottom ? border.width.bottom : 0;
  let borderWidthLeft =
    border.width && border.width.left ? border.width.left : 0;
  let borderStyle = border.style ? border.style : "solid";
  let borderColor = border.color ? border.color : "#000000";

  if (
    borderWidthTop === 0 &&
    borderWidthRight === 0 &&
    borderWidthBottom === 0 &&
    borderWidthLeft === 0
  ) {
    return false;
  }

  if (
    borderWidthTop === borderWidthBottom &&
    borderWidthBottom === borderWidthRight &&
    borderWidthRight === borderWidthLeft
  ) {
    cssInline += `border: ${borderWidthTop}px ${borderStyle} ${borderColor};`;
  } else {
    if (borderWidthTop) {
      cssInline += `border-top: ${borderWidthTop}px ${borderStyle} ${borderColor};`;
    }

    if (borderWidthRight) {
      cssInline += `border-left: ${borderWidthRight}px ${borderStyle} ${borderColor};`;
      if (!borderWidthLeft) {
        cssInline += `border-right: 0px ${borderStyle} ${borderColor};`;
      }
    }

    if (borderWidthBottom) {
      cssInline += `border-bottom: ${borderWidthBottom}px ${borderStyle} ${borderColor};`;
    }

    if (borderWidthLeft) {
      cssInline += `border-left: ${borderWidthLeft}px ${borderStyle} ${borderColor};`;
      if (!borderWidthRight) {
        cssInline += `border-left: 0px ${borderStyle} ${borderColor};`;
      }
    }
  }

  return cssInline;
};

export const addColumnHandler = (section) => {
  const columns = [...section.columns];
  let colWidth = 0;
  columns.forEach((col) => {
    if (!colWidth) {
      const width = col.style.width / 2;
      if (width >= 1) {
        colWidth = col.style.width = width;
      }
    }
  });

  !!colWidth &&
    columns.unshift(
      initializeColumn({
        width: colWidth,
      })
    );

  return { ...section, columns };
};
