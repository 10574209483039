<template>
  <div>
    <AppFieldText
      v-if="editContent"
      v-model="localItem.settings.headline"
      label="כותרת"
    />

    <AppFieldAlign
      v-if="editContent"
      v-model="localItem.settings.headlineAlign"
      label="ישור כותרת"
    />

    <AppFieldAlign
      v-model="localItem.settings.imageAlign"
      label="יישור תמונה"
    />

    <AppFieldSlider
      v-model="localItem.settings.imageWidth"
      label="גודל תמונה"
      :min="1"
      :max="700"
    />

    <AppFieldImage
      v-if="editContent"
      v-model="localItem.settings.imageUrl"
      label="תמונה"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    editContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localItem: null,
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
  },
};
</script>
