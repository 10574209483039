<template>
  <div style="max-width: 600px; margin: 0 auto">
    <RButton
      class="mb-2"
      color="black"
      icon-before="mdi-arrow-right"
      @click="$router.go(-1)"
    >
      חזור
    </RButton>
    <RSimpleCard :title="`עריכת ממצא`">
      <RFields>
        <RFieldSelect
          v-model="finding.category"
          label="קטגוריה"
          :items="site.categories"
        />

        <RFieldText v-model="finding.description" label="תיאור" />
        <RFieldSelect
          v-model="finding.status"
          label="סטטוס"
          :items="site.statuses"
        />
        <RFieldText v-model="finding.user" label="משתמש" />
        <RFieldSelect
          v-model="finding.responsibility"
          :items="site.responsibilities"
          label="לטיפול"
        />

        <RFieldSelect
          v-model="finding.building"
          label="בניין"
          :items="site.buildings"
        />
        <RFieldSelect
          v-model="finding.level"
          label="מפלס"
          :items="levelsOptions"
        />
        <RFieldSelect
          v-model="finding.area"
          label="אזור"
          :items="rowModalLevelAreas"
        />
        <RFieldSelect
          v-model="finding.location"
          label="מיקום"
          :items="locationsOptions"
        />

        <RFieldText v-model="finding.notes" label="הערות" />
        <RButton class="mt-5" lg block @click="updateFinding">שמירה</RButton>
      </RFields>
    </RSimpleCard>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentFindingId: null,
      finding: {
        category: "",
        description: "",
        status: "",
        user: "",
        responsibility: "",
        building: "",
        level: "",
        area: "",
        location: "",
        notes: "",
      },
    };
  },
  computed: {
    site() {
      return this.$store.state.site.site;
    },
    levelsOptions() {
      return this.site.levels.map((level) => ({
        value: level.text,
        text: level.text,
      }));
    },
    locationsOptions() {
      const currentArea = this.site.areas.find(
        (area) => area.text === this.finding.area
      );

      let currentAreaTypeId = currentArea.type_id;
      let currentAreaType = this.site.areaTypes.find(
        (areaType) => areaType.id === currentAreaTypeId
      );

      let currentAreaTypeLocations = currentAreaType.locations;

      // convert to options
      if (currentAreaTypeLocations) {
        return currentAreaTypeLocations.map((location) => ({
          value: location.name,
          text: location.name,
        }));
      }

      return [];
    },
    rowModalLevelAreas() {
      let areas = [];

      // get current level id
      const currentLevelText = this.finding.level;
      const currentLevel = this.site.levels.find(
        (l) => l.text === currentLevelText
      );

      if (!currentLevel) return [];

      this.site.levels_areas.forEach((level_area) => {
        if (level_area.level_id === currentLevel.id) {
          // get area object from areas
          const area = this.site.areas.find((a) => a.id === level_area.area_id);
          areas.push({
            value: area.text,
            text: area.text,
          });
        }
      });

      return areas;
    },
  },
  async created() {
    this.currentFindingId = this.$route.params.id;
    this.finding = this.getFindingById(this.currentFindingId);
  },
  methods: {
    getFindingById(id) {
      let finding = this.site.findings.find((finding) => finding.id === id);
      return JSON.parse(JSON.stringify(finding));
    },
    getAreaById(id) {
      return this.site.areas.find((area) => area.id === id);
    },
    getLevelById(id) {
      return this.site.levels.find((level) => level.id === id);
    },
    getLevelIdByAreaId() {
      let level_area = this.site.levels_areas.find(
        (level_area) => level_area.area_id === this.currentAreaId
      );
      return level_area.level_id;
    },
    updateFinding() {
      this.$store.dispatch("site/updateFinding", this.finding);
      this.$toast.success("הממצא עודכן בהצלחה");
      this.$router.go(-1);
    },
  },
};
</script>
