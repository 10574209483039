<template>
  <div class="r-page-edit">
    <div class="r-page-edit-header">
      <div class="r-page-edit-header-content">
        <div class="r-page-edit-header-title">▶️ סרטוני הדרכה על המערכת</div>
      </div>
    </div>
    <div class="r-page-edit-nav">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{
          'is-active': currentTabItem === tab.name,
        }"
        class="r-page-edit-nav-item"
        @click="currentTabItem = tab.name"
      >
        {{ tab.title }}
      </button>
    </div>

    <div class="r-page-edit-body">
      <div class="r-page-edit-body-container">
        <div class="r-page-edit-body-content">
          <div class="videos-grid">
            <div
              v-for="(video, index) in videos"
              :key="index"
              class="videos-grid__item"
              :class="{
                'is-hidden': !video.categories.includes(currentTabItem),
              }"
            >
              <video-card :video-id="video.id" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { supportVideos } from "@/common/settings";
import VideoCard from "@/components/VideoCard.vue";

export default {
  components: { VideoCard },
  data() {
    return {
      currentTabItem: "all",
      videos: supportVideos,
    };
  },
  computed: {
    tabs() {
      let tabs = [
        {
          name: "all",
          title: "כל הסרטונים",
        },
      ];

      this.videos.forEach((video) => {
        video.categories.forEach((category) => {
          if (!tabs.find((tab) => tab.name === category)) {
            tabs.push({
              name: category,
              title: category,
            });
          }
        });
      });

      return tabs;
    },
  },
};
</script>
<style lang="scss">
.videos-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
}
</style>
