<template>
  <v-card class="report-edit-defect r-defectModal" red="reportEditRef">
    <v-card-title
        class="d-flex align-center justify-space-between flex-wrap px-4 py-0 pa-sm-6"
    >
      <span class="text-base">{{ modalTitle }}</span>
      <v-btn
          icon
          flat
          xs
          @click="$emit('close')"
          class="bg-transparent main-btn close-icon"
      >
        <v-icon size="20" color="grey">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pa-4 pa-sm-6 pt-0 pt-sm-0">
      <div v-show="report.enableDefectRecheckStatus" class="app-field">
        <label class="app-field__label">
          <span>
            מצב ממצא חוזר
            <RChip
                v-if="invalidFields.includes('defectRecheckStatus')"
                color="red"
                class="mr-1"
            >
              חסר
            </RChip>
          </span>
        </label>

        <RButton
            v-for="(
            defectRecheckStatus, defectRecheckStatusIndex
          ) in defectRecheckStatuses"
            :key="defectRecheckStatusIndex"
            :small="isMobile"
            :color="defectRecheckStatus.color"
            :outlined="defect.defectRecheckStatus !== defectRecheckStatus.text"
            style="min-width: auto"
            class="ml-1"
            @click="setDefectRecheckStatus(defectRecheckStatus.text)"
        >
          {{ defectRecheckStatus.text }}
        </RButton>
      </div>

      <div v-show="report.enableDefectStatus" class="app-field">
        <label class="app-field__label">
          <span>
            מצב ממצא
            <RChip
                v-if="invalidFields.includes('defectStatus')"
                color="red"
                class="mr-1"
            >
              חסר
            </RChip>
          </span>
        </label>

        <v-btn
            v-for="(defectStatus, defectStatusIndex) in defectStatuses"
            :key="defectStatusIndex"
            :size="isMobile ? 'small' : 'default'"
            :color="defectStatus.color"
            :variant="defect.defectStatus === defectStatus.text ? 'flat' : 'outlined'"
            style="min-width: auto"
            class="ml-1 mb-1 pa-2"
            @click="setDefectStatus(defectStatus.text)"
        >
          {{ defectStatus.text }}
        </v-btn>
      </div>

      <div
          v-click-outside="hideCategoryAutocomplete"
          class="app-field"
          ref="categoryInput"
      >
        <label class="app-field__label" for="category">
          פרק
          <div>
            <RButton
                v-if="
                $checkRole(['admin', 'owner']) &&
                !isOffline &&
                displayAddCategory
              "
                color="grey"
                xs
                icon-before="mdi-plus"
                @click="addCategory(defect.category)"
            >
              הוספה למאגר
            </RButton>

            <RIcon
                v-if="defect.category"
                class="mr-2 u-cursor-pointer close-icon"
                @click="removeDefectCategory"
            >mdi-close
            </RIcon
            >
          </div>
        </label>
        <v-textarea
            id="category"
            v-model="defect.category"
            hide-details
            rows="1"
            auto-grow
            density="compact"
            variant="outlined"
            @keyup="defect.category = $event.target.value"
            @focus="openCategoryAutocomplete"
        />

        <div
            v-if="showCategoryAutocomplete && categoriesToShow.length"
            class="app-field__autocomplete"
        >
          <ul>
            <li
                v-for="(category, categoryIndex) in categoriesToShow"
                :key="categoryIndex"
                @click="setDefectCategory(category.name)"
            >
              {{ category.name }}
            </li>
          </ul>
        </div>
      </div>

      <div v-click-outside="hideSubCategoryAutocomplete" class="app-field">
        <label class="app-field__label">
          <span>
            ממצא
            <RChip
                v-if="invalidFields.includes('description')"
                color="red"
                class="mr-1"
            >
              חסר
            </RChip>
          </span>

          <div>
            <RButton
                v-if="
                $checkRole(['admin', 'owner']) &&
                !isOffline &&
                displayAddSubCategory
              "
                xs
                color="grey"
                icon-before="mdi-plus"
                @click="addSubCategory(defect.category, defect.description)"
            >
              הוספה למאגר
            </RButton>

            <RIcon
                v-if="defect.description"
                class="mr-2 u-cursor-pointer close-icon"
                @click="removeDefectDescription"
            >mdi-close
            </RIcon
            >
          </div>
        </label>
        <v-textarea
            id="description"
            v-model="defect.description"
            hide-details
            rows="1"
            auto-grow
            ref="descriptionInput"
            density="compact"
            variant="outlined"
            @keyup="defect.description = $event.target.value"
            @focus="openSubCategoryAutocomplete"
        />

        <div
            v-if="showSubCategoryAutocomplete && subCategoriesToShow.length"
            class="app-field__autocomplete"
        >
          <ul>
            <li
                v-for="(subCategory, subCategoryIndex) in subCategoriesToShow"
                :key="subCategoryIndex"
                @click="setDefectSubCategory(subCategory)"
            >
              {{ subCategory.name }}
              <RChip
                  v-if="defectSubCategoryExists(subCategory.name)"
                  class="in-use mr-1"
              >
                בשימוש
                <small
                    v-if="defectSubCategoryExists(subCategory.name) > 1"
                    class="mr-1"
                >
                  ({{ defectSubCategoryExists(subCategory.name) }})
                </small>
              </RChip>
            </li>
          </ul>
        </div>
      </div>

      <div v-click-outside="hideLocationsHints" class="app-field">
        <label class="app-field__label">
          <span>
            מיקום
            <RChip
                v-if="invalidFields.includes('location')"
                color="red"
                class="mr-1"
            >
              חסר
            </RChip>
          </span>
          <div>
            <RIcon
                v-if="defect.location"
                class="mr-2 u-cursor-pointer close-icon"
                @click="defect.location = ''"
            >mdi-close
            </RIcon
            >
          </div>
        </label>
        <v-text-field
            id="location"
            v-model="defect.location"
            hide-details
            density="compact"
            variant="outlined"
            @focus="openLocationsHints"
        ></v-text-field>

        <ul v-if="showLocationHints" class="mt-2 pa-0">
          <RButton
              v-for="location in locationsHintsToShow"
              :key="location.name"
              class="ml-2 mb-2"
              outlined
              sm
              color="black"
              @click="setLocationFromHints(location.name)"
          >
            {{ location.name }}
          </RButton>
        </ul>
      </div>

      <div class="app-field">
        <label class="app-field__label">
          <span>
            המלצה
            <RChip
                v-if="invalidFields.includes('recommendation')"
                color="red"
                class="mr-1"
            >
              חסר
            </RChip>
          </span>

          <div>
            <RButton
                v-if="
                $checkRole(['admin', 'owner']) &&
                !isOffline &&
                displaySaveRecommendation
              "
                xs
                color="grey"
                @click="
                updateCurrentSubCategoryField(
                  'default_defect_recommendation',
                  defect.recommendation
                )
              "
            >
              שמור למאגר
            </RButton>

            <RIcon
                v-if="defect.recommendation"
                class="mr-2 u-cursor-pointer close-icon"
                @click="defect.recommendation = ''"
            >mdi-close
            </RIcon
            >
          </div>
        </label>
        <v-textarea
            id="recommendation"
            v-model="defect.recommendation"
            hide-details
            rows="1"
            auto-grow
            density="compact"
            variant="outlined"
            @keyup.native="defect.recommendation = $event.target.value"
        />
      </div>

      <div v-if="currentInternalNote" class="r-defectModal-internalNote">
        <div class="r-defectModal-internalNote-title">הערה לבודק:</div>
        <div class="r-defectModal-internalNote-text">
          {{ currentInternalNote }}
        </div>
      </div>

      <div class="app-field">
        <label class="app-field__label" for="note">
          <span>
            הערה
            <RChip
                v-if="invalidFields.includes('note')"
                color="red"
                class="mr-1"
            >
              חסר
            </RChip>
          </span>
          <div>
            <RButton
                v-if="
                $checkRole(['admin', 'owner']) && !isOffline && displaySaveNote
              "
                color="grey"
                xs
                @click="
                updateCurrentSubCategoryField(
                  'default_defect_note',
                  defect.note
                )
              "
            >
              שמור למאגר
            </RButton>

            <RIcon
                v-if="defect.note"
                class="mr-2 u-cursor-pointer close-icon"
                @click="defect.note = ''"
            >mdi-close
            </RIcon
            >
          </div>
        </label>
        <v-textarea
            id="note"
            v-model="defect.note"
            hide-details
            rows="1"
            auto-grow
            density="compact"
            variant="outlined"
            @keyup.native="defect.note = $event.target.value"
        />
      </div>

      <div v-click-outside="hideStandardAutocomplete" class="app-field">
        <label class="app-field__label">
          <span>
            תקן
            <RChip
                v-if="invalidFields.includes('standard')"
                color="red"
                class="mr-1"
            >
              חסר
            </RChip>
          </span>
          <div>
            <RButton
                v-if="
                $checkRole(['admin', 'owner']) &&
                !isOffline &&
                displaySaveStandard
              "
                xs
                color="grey"
                @click="
                updateCurrentSubCategoryField(
                  'default_defect_standard',
                  defect.standard
                )
              "
            >
              שמור למאגר
            </RButton>
            <RIcon
                v-if="defect.standard"
                class="mr-2 u-cursor-pointer close-icon"
                @click="removeDefectStandard"
            >mdi-close
            </RIcon
            >
          </div>
        </label>
        <v-textarea
            id="standard"
            v-model="defect.standard"
            hide-details
            rows="1"
            density="compact"
            variant="outlined"
            auto-grow
            @keyup="defect.standard = $event.target.value"
            @focus="openStandardAutocomplete"
        />

        <div
            v-if="showStandardAutocomplete && standardsToShow.length"
            class="app-field__autocomplete"
        >
          <ul>
            <li
                v-for="(node, nodeIndex) in standardsToShow"
                :key="nodeIndex"
                @click="setDefectStandard(node)"
            >
              {{ node.default_defect_standard }}
            </li>
          </ul>
        </div>
      </div>

      <div class="app-field ma-n1 d-flex u-gap-1">
        <div class="pa-1 u-flex-1">
          <label class="app-field__label" for="unitPrice">
            <span>
              מחיר ליח׳ (₪)

              <RChip
                  v-if="invalidFields.includes('unitPrice')"
                  color="red"
                  class="mr-1"
              >
                חסר
              </RChip>
            </span>
          </label>

          <v-text-field
              id="unitPrice"
              v-model="defect.unitPrice"
              min="0"
              max="99999"
              type="number"
              hide-details
              density="compact"
              variant="outlined"
          ></v-text-field>
          <div>
            <RButton
                v-if="
                $checkRole(['admin', 'owner']) &&
                !isOffline &&
                displaySaveUnitPrice
              "
                xs
                color="grey"
                class="mt-1"
                @click="
                updateCurrentSubCategoryField(
                  'default_defect_unit_price',
                  defect.unitPrice
                )
              "
            >
              שמור למאגר
            </RButton>
          </div>
        </div>

        <div class="pa-1 u-flex-1 unit-quantity">
          <label class="app-field__label" for="unitQuantity">
            <span>
              כמות יח׳
              <RChip
                  v-if="invalidFields.includes('unitQuantity')"
                  color="red"
                  class="mr-1"
              >
                חסר
              </RChip>
            </span>
          </label>
          <v-text-field
              id="unitQuantity"
              v-model="defect.unitQuantity"
              min="0"
              max="99999"
              type="number"
              hide-details
              density="compact"
              variant="outlined"
          ></v-text-field>

          <div>
            <RButton
                v-if="
                $checkRole(['admin', 'owner']) &&
                !isOffline &&
                displaySaveUnitQuantity
              "
                xs
                color="grey"
                class="mt-1"
                @click="
                updateCurrentSubCategoryField(
                  'default_defect_unit_quantity',
                  defect.unitQuantity
                )
              "
            >
              שמור למאגר
            </RButton>
          </div>
        </div>

        <div class="pa-1 u-flex-1">
          <label class="app-field__label" for="unitType">
            <span>
              יחידת מידה
              <RChip
                  v-if="invalidFields.includes('unitType')"
                  color="red"
                  class="mr-1"
              >
                חסר
              </RChip>
            </span>
          </label>

          <v-select
              id="unitType"
              v-model="defect.unitType"
              :items="unitTypes"
              density="compact"
              variant="outlined"
              hide-details
          ></v-select>

          <div>
            <RButton
                v-if="
                $checkRole(['admin', 'owner']) &&
                !isOffline &&
                displaySaveUnitType
              "
                xs
                color="grey"
                class="mt-1"
                @click="
                updateCurrentSubCategoryField(
                  'default_defect_unit_type',
                  defect.unitType
                )
              "
            >
              שמור למאגר
            </RButton>
          </div>
        </div>
      </div>

      <div class="app-field">
        <ReportImages
            :key="componentKey"
            label="תמונות הממצא"
            :load-list="defect.uploads"
            :image-upload-prefix="'r-' + report.id + '-'"
            :edit-image-mode="true"
            update-key="uploads"
            @do-update="doUpdateImages"
            @do-update-many="doUpdateManyImages"
        />
      </div>

      <div class="app-field">
        <ReportImages
            :key="componentKey"
            label="נספחים"
            :load-list="defect.attachments"
            :image-upload-prefix="'r-' + report.id + '-'"
            :edit-image-mode="false"
            update-key="attachments"
            @do-update="doUpdateImages"
            @do-update-many="doUpdateManyImages"
        />

        <RButton
            v-if="
            $checkRole(['admin', 'owner']) &&
            !isOffline &&
            displaySaveAttachments
          "
            xs
            color="grey"
            class="mt-1"
            @click="saveAttachmentsToStock"
        >
          שמור נספחים למאגר
        </RButton>
      </div>

      <div v-if="mode === 'edit'" class="d-flex flex-wrap mt-6 mb-2 u-gap-2">
        <RButton
            v-if="!defect.isDisabled"
            color="grey"
            icon-before="mdi-pause"
            outlined
            @click="saveAndDisableDefect"
        >
          כיבוי ממצא
        </RButton>

        <RButton
            v-if="defect.isDisabled"
            color="green"
            icon-before="mdi-toggle-switch"
            outlined
            @click="saveAndEnableDefect"
        >
          הפעלת ממצא
        </RButton>

        <br/>

        <RButton
            icon-before="mdi-delete"
            color="red"
            outlined
            @click="removeDefect"
        >
          מחיקת ממצא
        </RButton>
      </div>

      <br/>

      <RButton
          block
          type="submit"
          lg
          :disabled="isSubmitLoading"
          @click="submitDefect"
      >
        {{ submitButtonText }}
      </RButton>
    </v-card-text>
  </v-card>
</template>

<script>
import ClickOutside from "vue-click-outside";
import {unitTypes} from "@/common/settings";
import {Utils, StockUtils} from "@/common/Utils";
import {mapGetters} from "vuex";
import store from "@/store";
import ReportImages from "@/views/reports/components/ReportImages";
import StocksApi from "@/api/StocksApi";

export default {
  name: "ReportDefectModal",
  components: {
    ReportImages,
  },
  directives: {
    ClickOutside,
  },
  props: {
    getDefectInvalidFields: {
      type: Function,
      default: function () {
      },
    },
    initialDefect: {
      type: Object,
      default: function () {
        return {
          isDisabled: false,
          attachments: {},
          uploads: {},
        };
      },
    },
    mode: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      componentKey: 0,
      isSubmitLoading: false,
      windowWidth: window.innerWidth,
      unitTypes: unitTypes,
      showLocationHints: false,
      showCategoryAutocomplete: false,
      showSubCategoryAutocomplete: false,
      showStandardAutocomplete: false,
      defect: this.initialDefect,
      isClickOutsideDisabled: false,
    };
  },
  computed: {
    ...mapGetters("report", ["report"]),
    ...mapGetters("reportQue", ["isOffline"]),
    ...mapGetters("stocks", ["stocks"]),
    currentInternalNote() {
      let currentNode = StockUtils.getNodeByName(
          this.stock,
          this.defect.description
      );
      if (currentNode && currentNode.internal_note) {
        return currentNode.internal_note;
      }
      return null;
    },
    invalidFields() {
      let invalidFields = [];
      if (this.getDefectInvalidFields(this.defect)) {
        invalidFields = this.getDefectInvalidFields(this.defect);
      }

      return invalidFields;
    },
    defectRecheckStatuses() {
      return [
        {
          text: "תוקן",
          color: "green",
        },
        {
          text: "לא תוקן",
          color: "red",
        },
        {
          text: "תוקן חלקית",
          color: "orange",
        },
      ];
    },

    defectStatuses() {
      return [
        {
          text: "תקין",
          color: "green",
        },
        {
          text: "לא תקין",
          color: "red",
        },
        {
          text: "אזהרה",
          color: "orange",
        },
        {
          text: "ממצא קל / אסתטי",
          color: "blue",
        },
        {
          text: "ממצא חדש",
          color: "blue",
        },
        {
          text: "עבודה שטרם הושלמה",
          color: "blue",
        },
        {
          text: "ליקוי בטיחותי",
          color: "red",
        },
        {
          text: "סכנה תברואתית",
          color: "orange",
        },
        {
          text: "סכנת התחשמלות",
          color: "red",
        },
        {
          text: "LIFE SAFETY SYSTEM",
          color: "orange",
        },
      ];
    },

    stock() {
      let stockFound = false;

      if (!this.report.stock_id) {
        return;
      }
      if (!this.stocks) {
        return;
      }

      this.stocks.forEach((stock) => {
        if (stock.id === this.report.stock_id) {
          stockFound = stock;
        }
      });

      return stockFound;
    },

    isMobile() {
      return this.windowWidth < 768;
    },
    locationsHintsToShow() {
      let locations = StockUtils.getStockLocations(this.stock);

      // this filter is disabled because we've enabled the multiple selection of locations
      if (
          !this.defect ||
          !this.defect.location ||
          this.defect.location.length === 0
      ) {
        return locations;
      } else {
        return locations.filter(
            (location) =>
                location.name && location.name.includes(this.defect.location)
        );
      }
    },
    categoriesToShow() {
      let categories = [];

      let stockCategories = StockUtils.getStockCategories(this.stock);

      Object.keys(stockCategories).forEach((key) => {
        if (
            stockCategories[key].name &&
            stockCategories[key].name.includes(this.defect.category)
        ) {
          categories.push(stockCategories[key]);
        }
      });

      return categories;
    },
    subCategoriesToShow() {
      let description = this.defect.description;

      let subCategories = [];

      if (this.defect.category) {
        let category = StockUtils.getCategoryByName(
            this.stock,
            this.defect.category
        );

        if (category && category.nodes) {
          category.nodes.forEach(function (node) {
            if (!node.name) {
              return;
            }

            if (
                (description && node.name && node.name.includes(description)) ||
                !description ||
                description.length < 1
            ) {
              subCategories.push(node);
            }
          });
        }
      } else {
        let stockNodes = StockUtils.getStockNodes(this.stock);

        stockNodes.forEach((node) => {
          if (
              (description && node.name && node.name.includes(description)) ||
              !description ||
              description.length < 1
          ) {
            subCategories.push(node);
          }
        });
      }

      return subCategories;
    },
    standardsToShow() {
      let standard = this.defect.standard;

      let standards = [];

      if (this.defect.category) {
        let category = StockUtils.getCategoryByName(
            this.stock,
            this.defect.category
        );

        if (category && category.nodes) {
          category.nodes.forEach(function (node) {
            if (!node.default_defect_standard) {
              return;
            }

            if (
                (standard &&
                    node.default_defect_standard &&
                    node.default_defect_standard.includes(standard)) ||
                !standard ||
                standard.length < 1
            ) {
              standards.push(node);
            }
          });
        }
      } else {
        let stockNodes = StockUtils.getStockNodes(this.stock);

        stockNodes.forEach((node) => {
          if (!node.default_defect_standard) {
            return;
          }
          if (
              (standard && node.default_defect_standard.includes(standard)) ||
              !standard ||
              standard.length < 1
          ) {
            standards.push(node);
          }
        });
      }

      return standards;
    },
    displayAddCategory() {
      return (
          this.stock &&
          this.defect.category &&
          this.defect.category.trim() &&
          !StockUtils.getCategoryByName(this.stock, this.defect.category)
      );
    },
    displayAddSubCategory() {
      return (
          this.stock &&
          !this.displayAddCategory &&
          this.defect.category &&
          this.defect.category.trim() &&
          this.defect.description &&
          this.defect.description.trim() &&
          !StockUtils.getNodeByName(this.stock, this.defect.description)
      );
    },
    displaySaveRecommendation() {
      return (
          this.stock &&
          !this.displayAddCategory &&
          this.defect.description &&
          this.defect.description.trim() &&
          !this.displayAddSubCategory &&
          this.defect.recommendation &&
          !(
              this.getCurrentSubCategoryField("default_defect_recommendation") ===
              this.defect.recommendation.trim()
          )
      );
    },
    displaySaveAttachments() {
      let isSameAttachments = this.isAttachmentsSame();
      return (
          this.stock &&
          !this.displayAddCategory &&
          this.defect.description &&
          this.defect.description.trim() &&
          !this.displayAddSubCategory &&
          this.defect.attachments &&
          !isSameAttachments
      );
    },
    displaySaveNote() {
      return (
          this.stock &&
          !this.displayAddCategory &&
          this.defect.description &&
          this.defect.description.trim() &&
          !this.displayAddSubCategory &&
          this.defect.note &&
          !(
              this.getCurrentSubCategoryField("default_defect_note") ===
              this.defect.note.trim()
          )
      );
    },
    displaySaveStandard() {
      return (
          this.stock &&
          !this.displayAddCategory &&
          this.defect.description &&
          this.defect.description.trim() &&
          !this.displayAddSubCategory &&
          this.defect.standard &&
          !(
              this.getCurrentSubCategoryField("default_defect_standard") ===
              this.defect.standard.trim()
          )
      );
    },
    displaySaveUnitPrice() {
      return (
          this.stock &&
          !this.displayAddCategory &&
          this.defect.description &&
          this.defect.description.trim() &&
          !this.displayAddSubCategory &&
          this.defect.unitPrice &&
          !(
              this.getCurrentSubCategoryField("default_defect_unit_price") ===
              this.defect.unitPrice
          )
      );
    },
    displaySaveUnitQuantity() {
      return (
          this.stock &&
          !this.displayAddCategory &&
          this.defect.description &&
          this.defect.description.trim() &&
          !this.displayAddSubCategory &&
          this.defect.unitQuantity &&
          !(
              this.getCurrentSubCategoryField("default_defect_unit_quantity") ===
              this.defect.unitQuantity
          )
      );
    },
    displaySaveUnitType() {
      return (
          this.stock &&
          !this.displayAddCategory &&
          this.defect.description &&
          this.defect.description.trim() &&
          !this.displayAddSubCategory &&
          this.defect.unitType &&
          !(
              this.getCurrentSubCategoryField("default_defect_unit_type") ===
              this.defect.unitType
          )
      );
    },
    media_url() {
      return window.reporto.globals.MEDIA_URL;
    },
    modalTitle() {
      if (this.mode === "add") {
        return "הוספת ממצא";
      } else {
        return "עריכת ממצא";
      }
    },
    submitButtonText() {
      if (this.mode === "add") {
        return "הוספת ממצא";
      } else {
        return "שמירת ממצא";
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  updated() {
    if (this.defect && !this.defect.uploads) {
      this.defect.uploads = {};
    }
  },
  methods: {
    isAttachmentsSame() {
      let isSameAttachments = true;

      if (!this.defect.attachments) {
        return true;
      }

      let stockAttachments = this.getCurrentSubCategoryField(
          "default_defect_attachments"
      );
      let currentAttachments = this.defect.attachments;
      if (currentAttachments) {
        currentAttachments =
            this.convertImagesToStockFormat(currentAttachments);
      }

      if (
          stockAttachments &&
          currentAttachments &&
          stockAttachments.length !== currentAttachments.length
      ) {
        isSameAttachments = false;
      } else {
        if (stockAttachments && stockAttachments.length) {
          for (let i = 0; i < stockAttachments.length; i++) {
            if (!currentAttachments[i]) {
              continue;
            }
            if (stockAttachments[i].path !== currentAttachments[i].path) {
              isSameAttachments = false;
              break;
            }
            if (
                (stockAttachments[i].title || "") !==
                (currentAttachments[i].title || "")
            ) {
              isSameAttachments = false;
              break;
            }
          }
        }
      }

      return isSameAttachments;
    },
    saveAttachmentsToStock() {
      this.updateCurrentSubCategoryField(
          "default_defect_attachments",
          this.convertImagesToStockFormat(this.defect.attachments)
      );
      this.forceRerender();
    },
    convertImagesToStockFormat(images) {
      if (!images) {
        return [];
      }
      return Object.values(images).map((image) => {
        const newImage = {...image};
        delete newImage.id;
        delete newImage.order;
        return newImage;
      });
    },

    defectSubCategoryExists(subCategory) {
      let subCategoryUsed = 0;
      let defects = this.report.data.defects || {};

      Object.keys(defects).forEach((key) => {
        if (defects[key].description === subCategory) {
          subCategoryUsed++;
        }
      });

      return subCategoryUsed;
    },
    async saveAndDisableDefect() {
      this.defect.isDisabled = true;
      await this.submitDefect();
    },
    async saveAndEnableDefect() {
      this.defect.isDisabled = false;
      await this.submitDefect();
    },
    forceRerender() {
      this.componentKey += 1;
    },
    doUpdateImages({key, value}) {
      this.defect[key] = value;
    },
    doUpdateManyImages({data}) {
      this.defect = Utils.flatAndMergeObjects(this.defect, data);
    },
    async addSubCategory(categoryName, nodeName) {
      let loader = this.$loading.show();

      let category = StockUtils.getCategoryByName(this.stock, categoryName);

      try {
        await StocksApi.createNode(this.stock.id, category.id, {
          name: nodeName,
        });
        this.$toast.success("הממצא נוסף בהצלחה למאגר");
        await store.dispatch("stocks/fetchStocks");

        loader.hide();
      } catch (err) {
        this.$toast.error(err);
        loader.hide();
      }
    },
    async submitDefect() {
      // validation
      if (!this.defect.category) {
        this.$toast.error("חובה לשייך פרק");
        return;
      }

      // fix unitQuantity before sending
      if (
          this.defect.unitPrice > 0 &&
          (!this.defect.unitQuantity || this.defect.unitQuantity < 1)
      ) {
        this.defect.unitQuantity = 1;
      }

      // submit to parent component
      this.isSubmitLoading = true;
      this.$emit("handle-submit", this.defect);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async removeDefect() {
      if (confirm("בטוח למחוק את הממצא?")) {
        this.$emit("handle-remove", this.defect);
      }
    },
    openLocationsHints() {
      this.showLocationHints = true;
    },
    hideLocationsHints() {
      this.showLocationHints = false;
    },
    setLocationFromHints(locationName) {
      this.defect.location = locationName;

      // if (this.defect.location) {
      //   this.defect.location += this.defect.location
      //     ? `, ${locationName}`
      //     : locationName;
      // } else {
      //   this.defect.location = locationName;
      // }

      this.hideLocationsHints(); // this is disabled because of multiple selection
    },
    openCategoryAutocomplete() {
      this.showCategoryAutocomplete = true;
      this.isClickOutsideDisabled = true;
      this.showSubCategoryAutocomplete = false;
      // document.getElementById("category").scrollIntoView();
      // document.querySelectorAll(
      //   ".report-edit-defect"
      // )[0].parentElement.scrollTop = 0;
      this.scrollToEl("categoryInput");
      setTimeout(() => {
        this.isClickOutsideDisabled = false;
      }, 100);
    },
    hideCategoryAutocomplete() {
      if (!this.isClickOutsideDisabled) {
        this.showCategoryAutocomplete = false;
      }
    },
    scrollToEl(elRef) {
      const el = this.$refs[elRef];
      const modal = this.$el;

      if (el) {
        const stickyHeaderHeight = 75; // Adjust this value to match the height of your sticky header
        const elementTop = el.getBoundingClientRect().top;
        const modalTop = modal.getBoundingClientRect().top;
        const offsetPosition = elementTop - stickyHeaderHeight;
        modal.scrollTop = offsetPosition;
      }
    },
    setDefectCategory(categoryName) {
      this.defect.category = categoryName;
      this.hideCategoryAutocomplete();
    },
    setDefectSubCategory(subCategory) {
      this.defect.description = subCategory.name;
      if (subCategory) {
        let parentCategories = StockUtils.getNodeCategoriesByName(
            this.stock,
            subCategory.name
        );
        if (parentCategories.length > 0) {
          if (!this.defect.category) {
            this.defect.category = parentCategories[0].name;
          } else {
            let isCurrentCategoryInParentCategories = false;
            parentCategories.forEach((parentCategory) => {
              if (parentCategory.name === this.defect.category) {
                isCurrentCategoryInParentCategories = true;
              }
            });
            if (!isCurrentCategoryInParentCategories) {
              this.defect.category = parentCategories[0].name;
            }
          }
        }

        if (subCategory.default_defect_recommendation) {
          this.defect.recommendation =
              subCategory.default_defect_recommendation;
        }
        if (subCategory.default_defect_note) {
          this.defect.note = subCategory.default_defect_note;
        }
        if (subCategory.default_defect_standard) {
          this.defect.standard = subCategory.default_defect_standard;
        }
        if (subCategory.default_defect_unit_price) {
          this.defect.unitPrice = subCategory.default_defect_unit_price;
          if (this.defect.unitQuantity < 1) {
            this.defect.unitQuantity = 1;
          }
        }
        if (subCategory.default_defect_unit_quantity) {
          this.defect.unitQuantity = subCategory.default_defect_unit_quantity;
        }
        if (
            !subCategory.default_defect_unit_quantity &&
            subCategory.default_defect_unit_price
        ) {
          if (this.defect.unitQuantity < 1) {
            this.defect.unitQuantity = 1;
          }
        }

        if (subCategory.default_defect_unit_type) {
          this.defect.unitType = subCategory.default_defect_unit_type;
        }

        if (subCategory.default_defect_attachments) {
          this.defect.attachments = this.convertImagesArrayToObject(
              subCategory.default_defect_attachments
          );

          this.forceRerender();
        }
      }

      this.hideSubCategoryAutocomplete();
    },
    convertImagesArrayToObject(imagesArray) {
      let imagesObject = {};

      if (imagesArray) {
        imagesArray.forEach((image) => {
          let imageId = Utils.randomString(8);
          imagesObject[imageId] = {
            id: imageId,
            ...image,
          };
        });
      }

      return imagesObject;
    },
    setDefectStandard(node) {
      this.defect.standard = node.default_defect_standard;

      // TODO: find the subCategory and fill it
      if (node) {
        let parentCategory = StockUtils.getNodeCategoryByName(
            this.stock,
            node.name
        );
        if (parentCategory) {
          this.defect.category = parentCategory.name;
        }

        if (node.default_defect_recommendation) {
          this.defect.recommendation = node.default_defect_recommendation;
        }
        if (node.default_defect_note) {
          this.defect.note = node.default_defect_note;
        }
        if (node.default_defect_standard) {
          this.defect.standard = node.default_defect_standard;
        }
        if (node.default_defect_unit_price) {
          this.defect.unitPrice = node.default_defect_unit_price;
          if (this.defect.unitQuantity < 1) {
            this.defect.unitQuantity = 1;
          }
        }
        if (node.default_defect_unit_quantity) {
          this.defect.unitQuantity = node.default_defect_unit_quantity;
        }
        if (
            !node.default_defect_unit_quantity &&
            node.default_defect_unit_price
        ) {
          if (this.defect.unitQuantity < 1) {
            this.defect.unitQuantity = 1;
          }
        }

        if (node.default_defect_unit_type) {
          this.defect.unitType = node.default_defect_unit_type;
        }

        if (node.default_defect_attachments) {
          this.defect.attachments = this.convertImagesArrayToObject(
              node.default_defect_attachments
          );

          this.forceRerender();
        }
      }
      this.hideStandardAutocomplete();
    },
    openSubCategoryAutocomplete() {
      this.showSubCategoryAutocomplete = true;
      this.isClickOutsideDisabled = true;
      this.showCategoryAutocomplete = false;
      this.scrollToEl("descriptionInput");
      setTimeout(() => {
        this.isClickOutsideDisabled = false;
      }, 100);
    },
    openStandardAutocomplete() {
      this.showStandardAutocomplete = true;
      this.isClickOutsideDisabled = true;
      // this.showStandardAutocomplete = false;
      // document.getElementById("standard").scrollIntoView();
      // document.querySelectorAll(".report-edit-defect")[0].parentElement.scrollTop -= 110;
      setTimeout(() => {
        this.isClickOutsideDisabled = false;
      }, 100);
    },
    hideSubCategoryAutocomplete() {
      if (!this.isClickOutsideDisabled) {
        this.showSubCategoryAutocomplete = false;
      }
    },
    hideStandardAutocomplete() {
      if (!this.isClickOutsideDisabled) {
        this.showStandardAutocomplete = false;
      }
    },
    getCurrentSubCategoryField(category_field_key) {
      var currentSubCategory = StockUtils.getNodeByName(
          this.stock,
          this.defect.description
      );

      if (currentSubCategory[category_field_key]) {
        return currentSubCategory[category_field_key];
      }
    },
    async updateCurrentSubCategoryField(fieldKey, fieldValue) {
      let loader = this.$loading.show();

      if (fieldKey === "default_defect_attachments") {
        for (let i = 0; i < fieldValue.length; i++) {
          let image = fieldValue[i];
          if (image.path && Utils.isBase64Image(image.path)) {
            let imagePathBase64 = image.path;
            let {data} = await Utils.uploadImageBase64(imagePathBase64);
            fieldValue[i].path = data.path;

            // iterate object: this.defects.attachments
            for (let key in this.defect.attachments) {
              if (this.defect.attachments[key].order === i + 1) {
                this.defect.attachments[key].path = data.path;
              }
            }
          }
          if (image.initialPath && Utils.isBase64Image(image.initialPath)) {
            let {data} = await Utils.uploadImageBase64(image.initialPath);
            fieldValue[i].initialPath = data.path;

            for (let key in this.defect.attachments) {
              if (this.defect.attachments[key].order === i + 1) {
                this.defect.attachments[key].initialPath = data.path;
              }
            }
          }
        }
      }

      let node = StockUtils.getNodeByName(this.stock, this.defect.description);

      try {
        await StocksApi.updateNode(this.stock.id, node.id, {
          [fieldKey]: fieldValue,
        });
        this.$toast.success("הממצא עודכן בהצלחה למאגר");
        await store.dispatch("stocks/fetchStocks");

        loader.hide();
      } catch (err) {
        this.$toast.error(err);
        loader.hide();
      }
    },
    setDefectStatus(defectStatus) {
      if (this.defect.defectStatus === defectStatus) {
        this.defect.defectStatus = null;
      } else {
        this.defect.defectStatus = defectStatus;
      }
      this.$forceUpdate();
    },
    setDefectRecheckStatus(defectRecheckStatus) {
      if (this.defect.defectRecheckStatus === defectRecheckStatus) {
        this.defect.defectRecheckStatus = null;
      } else {
        this.defect.defectRecheckStatus = defectRecheckStatus;
      }
      this.$forceUpdate();
    },
    async addCategory(categoryName) {
      let loader = this.$loading.show();
      try {
        await StocksApi.createCategory(this.stock.id, {
          name: categoryName,
        });
        this.$toast.success("הקטגוריה נוספה בהצלחה");
        await store.dispatch("stocks/fetchStocks");

        loader.hide();
      } catch (err) {
        this.$toast.error(err);
        loader.hide();
      }
    },
    removeDefectCategory() {
      this.defect.category = "";
      this.defect.description = "";
      this.defect.recommendation = "";
      this.defect.standard = "";
      this.defect.unitPrice = "";
      this.defect.unitQuantity = "";
      this.defect.attachments = {};
    },
    removeDefectDescription() {
      this.defect.description = "";
      this.defect.recommendation = "";
      this.defect.standard = "";
      this.defect.unitPrice = "";
      this.defect.unitQuantity = "";
      this.defect.attachments = {};
    },
    removeDefectStandard() {
      this.defect.standard = "";
    },
  },
};
</script>

<style lang="scss">
.report-edit-defect {
  scroll-behavior: smooth; /* Smooth scroll */

  textarea {
    resize: none !important;
  }

  .close-icon {
    font-size: 16px !important;
    color: grey !important;

    &.main-btn {
      font-size: 20px !important;
      position: relative;
      right: 6px;
      max-height: 28px !important;
      width: 30px;
    }
  }
}

.unit-quantity {
  @media (max-width: 500px) {
    max-width: 80px;
  }
}
</style>
