<template>
  <div>
    <AppFieldText v-model="localItem.settings.headline" label="כותרת" />

    <AppFieldAlign
      v-model="localItem.settings.headlineAlign"
      label="ישור כותרת"
    />

    <!-- deprecated -->
    <!--
    <AppFieldSelect
      v-model="localItem.settings.groupBy"
      label="איחוד ממצאים לפי"
      :items="[
        { text: 'פרק', value: 'category' },
        { text: 'מיקום', value: 'location' },
      ]"
    />
    -->
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      localItem: null,
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
  },
};
</script>
