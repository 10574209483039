async function getUsers() {
  const response = await window.axios.get(`/api/v1/users`);
  return response.data;
}

async function getUser(userId) {
  const response = await window.axios.get(`/api/v1/users/${userId}`);
  return response.data;
}

async function createUser(data) {
  const response = await window.axios.post(`/api/v1/users`, data);
  return response.data;
}

async function updateUser(userId, data) {
  const response = await window.axios.put(`/api/v1/users/${userId}`, data);
  return response.data;
}

async function deleteUser(userId) {
  const response = await window.axios.delete(`/api/v1/users/${userId}`);
  return response.data;
}

export default {
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
};
