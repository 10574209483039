<template>
  <div>
    <RButton color="grey" depressed @click="isPlaceholdersModalOpen = true">
      משתנים
    </RButton>
    <RModal v-model="isPlaceholdersModalOpen" title="משתנים">
      <div class="d-flex flex-column">
        <input id="textToCopy" type="hidden" :value="activeTagText" />

        <div
          v-for="placeholdersCategory in placeholdersCategories"
          :key="placeholdersCategory"
          class="mb-5"
        >
          <div class="subtitle-2 text--primary">
            {{ placeholdersCategory }}
          </div>
          <div>
            <RButton
              v-for="placeholder in getPlaceholdersByCategory(
                placeholdersCategory
              )"
              :key="placeholder.text"
              class="ma-1"
              color="grey"
              sm
              @click="copyToClipboard(placeholder.text)"
            >
              {{ placeholder.text }}
            </RButton>
          </div>
        </div>
      </div>
    </RModal>
  </div>
</template>
<script>
import { placeholders } from "@/common/settings";

export default {
  props: {
    isExistingReport: {
      type: Boolean,
      default: false,
    },
    reportTemplateParent: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isPlaceholdersModalOpen: false,
      activeTagText: "",
      placeholders,
    };
  },
  computed: {
    placeholdersCategories() {
      let placeholdersCategories = [];

      this.placeholders.forEach((placeholder) => {
        if (!placeholdersCategories.includes(placeholder.category)) {
          placeholdersCategories.push(placeholder.category);
        }
      });

      return placeholdersCategories;
    },
  },
  methods: {
    copyToClipboard(str) {
      this.activeTagText = str;

      setTimeout(() => {
        let testingCodeToCopy = document.querySelector("#textToCopy");
        testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
        testingCodeToCopy.select();

        try {
          var successful = document.execCommand("copy");
          if (successful) {
            this.$toast.success(`${str} הועתק!`);
            this.$emit("close");
          }
        } catch (err) {
          this.$toast.error("שגיאה בהעתקת טקסט");
        }

        /* unselect the range */
        testingCodeToCopy.setAttribute("type", "hidden");
        window.getSelection().removeAllRanges();
      }, 1);
    },
    getPlaceholdersByCategory(category) {
      return this.placeholders.filter(
        (placeholder) => placeholder.category === category
      );
    },
  },
};
</script>
