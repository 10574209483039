<template>
  <div class="px-0 pa-0 transparent">
    <RFieldText
      v-model="localItem.settings.headline"
      class="mb-3"
      label="כותרת"
    />

    <AppFieldAlign
      v-model="localItem.settings.headlineAlign"
      label="ישור כותרת"
    />

    <AppFieldSwitch
      v-model="localItem.settings.pageBreakBetweenRows"
      label="שבירת עמוד בין שורות"
    />

    <AppFieldSwitch
      v-model="localItem.settings.dontPageBreakBetweenRows"
      label="השאר כותרות עם הטבלה"
    />

    <AppFieldSwitch
      v-model="localItem.settings.withIndex"
      label="הוספת מספור לכל שורה"
    />

    <div>
      <draggable
        :list="localItem.settings.columns"
        class="items-list"
        handle=".items-list-item-drag"
        tag="div"
        itemKey="id"
      >
        <template #item="{ element: item, index: itemIndex }">
          <div
            :key="item.id"
            :class="{
              'is-item-disabled': item.settings.isDisabled,
              'items-list-item': true,
            }"
          >
            <RIcon class="items-list-item-drag">mdi-drag</RIcon>
            <RIcon>{{ getItemTypeIcon(item.type) }}</RIcon>
            <div
              class="items-list-item-content"
              @click="openRepeaterItemModal(item)"
            >
              {{ item.settings.label }}
            </div>

            <RActions>
              <RAction
                text="הוספת שדה מתחת"
                icon="mdi-plus"
                @click="openAddItemModal(itemIndex + 1)"
              />
              <RAction
                text="עריכה"
                icon="mdi-pencil"
                @click="$emit('open-edit-item', item)"
              />
              <RAction
                text="שכפול"
                icon="mdi-content-copy"
                @click="duplicateRepeaterItem(item, itemIndex)"
              />
              <RAction
                text="מחיקה"
                icon="mdi-delete"
                @click="removeRepeaterItem(item.id)"
              />
            </RActions>
          </div>
        </template>
      </draggable>

      <RModal v-model="isAddItemModalOpen" size="sm" title="הוספת שדה חדש">
        <div>
          <RButton
            v-for="(itemType, itemTypeId) in getItemTypes('repeater')"
            :key="itemTypeId"
            color="primary"
            class="ma-1 mx-1"
            :icon-before="getItemTypeIcon(itemType.type)"
            @click="addRepeaterItem(itemType.type, addItemAtIndex)"
          >
            {{ itemType.name }}
          </RButton>
        </div>
      </RModal>

      <RButton
        color="grey"
        class="mx-auto d-block mt-3"
        icon-before="mdi-plus"
        @click="openAddItemModal(localItem.settings.columns.length)"
      >
        הוספת שדה
      </RButton>
    </div>

    <RModal v-model="repeaterItemModal.isOpen" title="עריכת שדה">
      <div>
        <RFieldText
          v-model="repeaterItemModal.item.settings.label"
          label="שם השדה"
          class="mb-3"
        />

        <AppFieldSwitch
          v-model="repeaterItemModal.item.settings.showLabelOnReport"
          label="הצג שם שדה בדוח"
        />

        <FieldsText
          v-if="repeaterItemModal.item.type === 'text'"
          v-model="repeaterItemModal.item"
          :edit-content="false"
        />

        <FieldsWysiwyg
          v-if="repeaterItemModal.item.type === 'wysiwyg'"
          v-model="repeaterItemModal.item"
          :edit-content="false"
        />

        <FieldsImage
          v-if="repeaterItemModal.item.type === 'image'"
          v-model="repeaterItemModal.item"
          :edit-content="false"
        />

        <FieldsSignature
          v-if="repeaterItemModal.item.type === 'signature'"
          v-model="repeaterItemModal.item"
          :edit-content="false"
        />

        <FieldsImages
          v-if="repeaterItemModal.item.type === 'images'"
          v-model="repeaterItemModal.item"
          :edit-content="false"
        />

        <FieldsHeadline
          v-if="repeaterItemModal.item.type === 'headline'"
          v-model="repeaterItemModal.item"
          :edit-content="false"
        />

        <FieldsTable
          v-if="repeaterItemModal.item.type === 'table'"
          v-model="repeaterItemModal.item"
          :edit-content="false"
        />
      </div>

      <RButton
        color="primary"
        lg
        class="mt-3"
        block
        @click="submitRepeaterItemModal"
      >
        שמירה
      </RButton>
    </RModal>

    <br />

    <report-repeater
      label="שורות"
      :item="localItem"
      update-key="rows"
      @do-update="doUpdateRepeater"
      @do-update-many="doUpdateManyRepeater"
    />
  </div>
</template>

<script>
import { Utils } from "@/common/Utils";
import { reportTemplateItemTypes } from "@/common/settings";
import draggable from "vuedraggable";
// import ReportFieldRepeater from "@/views/reports/components/ReportFieldRepeater";
import FieldsSignature from "./FieldsSignature";
import FieldsImages from "./FieldsImages";
import FieldsImage from "./FieldsImage";
import FieldsWysiwyg from "./FieldsWysiwyg";
import FieldsText from "./FieldsText";
import FieldsTable from "./FieldsTable";
import FieldsHeadline from "./FieldsHeadline";
import ReportRepeater from "@/views/reports/components/ReportRepeater.vue";

export default {
  components: {
    ReportRepeater,
    draggable,
    FieldsHeadline,
    FieldsTable,
    FieldsText,
    FieldsWysiwyg,
    FieldsImages,
    FieldsImage,
    FieldsSignature,
  },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    editContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isAddItemModalOpen: false,
      addItemAtIndex: 0,
      localItem: null,
      itemTypes: reportTemplateItemTypes,
      repeaterItemModal: {
        isOpen: false,
        item: {
          settings: {},
        },
      },
    };
  },
  watch: {
    localItem: {
      handler(val) {
        this.$emit("update:modelValue", val);
      },
      deep: true,
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    getItemTypeIcon(type) {
      let itemType = this.getItemType(type);
      if (itemType && itemType.icon) {
        return "mdi-" + itemType.icon;
      }
      return "mdi-format-title";
    },
    openAddItemModal(addItemAtIndex) {
      this.addItemAtIndex = addItemAtIndex;
      this.isAddItemModalOpen = true;
    },
    updateParentValue(val) {
      this.localItem = val;
    },
    openRepeaterItemModal(item) {
      this.repeaterItemModal.item = JSON.parse(JSON.stringify(item));
      this.repeaterItemModal.isOpen = true;
    },
    closeRepeaterItemModal() {
      this.repeaterItemModal.isOpen = false;
    },
    submitRepeaterItemModal() {
      this.localItem.settings.columns.forEach((itemLoop) => {
        if (itemLoop.id === this.repeaterItemModal.item.id) {
          itemLoop.settings = Object.assign(
            {},
            this.repeaterItemModal.item.settings
          );
          itemLoop.settings.label = this.repeaterItemModal.item.settings.label;
        }
      });

      this.closeRepeaterItemModal();
    },
    removeRepeaterItem(itemId) {
      if (confirm("בטוח למחוק את השדה?")) {
        this.localItem.settings.columns =
          this.localItem.settings.columns.filter((item) => {
            return item.id !== itemId;
          });
      }
    },
    duplicateRepeaterItem(item, itemIndex) {
      let newItem = {
        ...item,
        id: Utils.guid(),
      };

      newItem = JSON.parse(JSON.stringify(newItem));

      this.localItem.settings.columns.splice(itemIndex, 0, newItem);
    },
    addRepeaterItem(itemType, addItemAtIndex) {
      this.isAddItemModalOpen = false;

      let itemTypeObject = this.getItemType(itemType);

      let newItem = {
        id: Utils.guid(),
        type: itemType,
        settings: { ...itemTypeObject.settings },
      };

      this.localItem.settings.columns.splice(addItemAtIndex, 0, newItem);
    },

    getItemTypes(supports = false) {
      if (!supports) {
        return this.itemTypes;
      }

      return this.itemTypes.filter((itemType) => {
        return itemType.supports.includes(supports);
      });
    },
    getItemType(type) {
      let itemTypeFound = false;

      this.itemTypes.forEach((itemType) => {
        if (itemType.type === type) {
          itemTypeFound = JSON.parse(JSON.stringify(itemType));
        }
      });

      return itemTypeFound;
    },
    async doUpdateRepeater({ key, value }) {
      let cleanData = JSON.parse(
        JSON.stringify({
          [key]: value,
        })
      );

      let dataUploaded = await this.uploadObjectImages(cleanData);
      dataUploaded = Utils.UnFlattenObject(dataUploaded).rows.settings;

      let settingsNew = Utils.flatAndMergeObjects(
        this.localItem.settings,
        dataUploaded
      );

      let settingsOld = this.localItem.settings;

      this.localItem.settings.rows = Utils.flatAndMergeObjects(
        settingsOld.rows,
        settingsNew.rows
      );
    },
    async doUpdateManyRepeater({ data }) {
      let cleanData = JSON.parse(JSON.stringify(data));

      let dataUploaded = await this.uploadObjectImages(cleanData);
      dataUploaded = Utils.UnFlattenObject(dataUploaded).rows.settings;

      let settingsNew = Utils.flatAndMergeObjects(
        this.localItem.settings,
        dataUploaded
      );

      let settingsOld = this.localItem.settings;

      this.localItem.settings.rows = Utils.flatAndMergeObjects(
        settingsOld.rows,
        settingsNew.rows
      );
    },
    async uploadObjectImages(obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (value && Utils.isBase64Image(value)) {
          let loader = this.$loading.show();
          let { data } = await Utils.uploadImageBase64(value);
          loader.hide();
          obj[key] = data.path;
        }
      }

      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.items-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  &-item {
    background: #fff;
    border: 1px solid var(--r-border-color);
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    padding: 8px 8px;
    &-content {
      flex: 1;
      cursor: pointer;
      margin-right: 8px;
    }
    &-drag {
      cursor: move;
    }
  }
}
</style>
