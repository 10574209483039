<template></template>
<script>
import { sendLog } from "@/services/LogService";

export default {
  name: "ForceRefresh",
  data() {
    return {
      timestampAppOpened: null,
      refreshEvery: 1000 * 60 * 60 * 24, // refresh every 24 hours
    };
  },
  watch: {
    $route(to) {
      if (to.name === "reports.edit") {
        return;
      }
      this.forceRefreshIfRequired(to);
    },
  },
  created() {
    this.timestampAppOpened = Date.now();
  },
  methods: {
    forceRefreshIfRequired(to) {
      let timePassed = Date.now() - this.timestampAppOpened;
      if (timePassed > this.refreshEvery) {
        sendLog(
          "[Force Refresh] Refreshing because time passed " +
            timePassed / 1000 +
            "seconds",
          { data: to }
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    },
  },
};
</script>
