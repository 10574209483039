<template>
  <div
    class="app-field app-field__list"
    :class="{
      'is-list-open': isListOpen,
    }"
  >
    <label class="app-field__label">
      {{ label }}
    </label>
    <div class="app-field__listInput" @click="toggleList">
      {{ selectedItem ? selectedItem.name : placeholder }}
      <div
        v-if="nullable && selectedItem"
        class="app-field__listInputClear"
        @click.stop="clearSelected"
      >
        <v-btn flat icon density="compact" style="height: 24px; width: 24px">
          <v-icon size="16">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>

    <div ref="listBox" class="app-field__listBox">
      <div class="app-field__listSearch">
        <input v-model="searchQuery" type="text" placeholder="חיפוש" />
      </div>

      <div class="app-field__listItems">
        <div v-if="!itemsFiltered.length" class="py-2 px-2 text-center">
          לא נמצאו תוצאות לחיפוש
        </div>
        <div
          v-for="item in itemsFiltered"
          :key="item.id"
          class="app-field__list-item"
          :class="{
            'is-selected': selectedItem && selectedItem.id === item.id,
          }"
          @click="selectListItem(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nullable: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: null,
      isListOpen: false,
      selectedItem: null,
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        this.selectedItem = val;
      },
    },
  },
  computed: {
    itemsFiltered() {
      if (this.searchQuery) {
        return this.items.filter((item) =>
          item.name.includes(this.searchQuery)
        );
      }
      return this.items;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutsideListBox);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutsideListBox);
  },
  methods: {
    handleClickOutsideListBox(event) {
      if (
        this.isListOpen &&
        this.$refs.listBox &&
        !this.$refs.listBox.contains(event.target)
      ) {
        this.isListOpen = false;
      }
    },
    toggleList() {
      if (!this.isListOpen) {
        setTimeout(() => {
          this.isListOpen = true;
          this.searchQuery = null;
        }, 1);
      } else {
        setTimeout(() => {
          this.isListOpen = false;
        }, 1);
      }
    },
    selectListItem(item) {
      this.selectedItem = item;
      this.$emit("update:modelValue", item);
      this.isListOpen = false;
    },
    clearSelected() {
      this.$emit("update:modelValue", null);
      this.selectedItem = null;
      this.isListOpen = false;
    },
  },
};
</script>

<style lang="scss">
.app-field__list {
  --list-max-height: 280px;
  --arrow-transform: rotate(0deg) translateY(-50%);
  @media (max-width: 767px) {
    --list-max-height: 320px;
  }
  .v-input input {
    font-size: 14px;
  }
  &.is-list-open {
    --arrow-transform: rotate(180deg) translateY(-50%);
    .app-field__listBox {
      max-height: var(--list-max-height);
      margin-top: 10px;
    }
    .app-field__listItems {
      border: thin solid rgba(0, 0, 0, 0.38);
      border-top: 0;
    }
  }
  &Input {
    padding: 6px 10px;
    border: thin solid rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    &Clear {
      position: absolute;
      left: 28px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    &:after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid rgba(0, 0, 0, 0.7);
      position: absolute;
      left: 10px;
      top: 50%;
      transform: var(--arrow-transform);
      transition: 0.2s;
      transform-origin: top;
    }
  }
  &Search {
    input {
      padding: 8px 10px;
      width: 100%;
      font-size: 13px;
      border: thin solid rgba(0, 0, 0, 0.38);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background: #fafafa;
      &:focus {
        outline: 0;
        background: #f5f5f5;
      }
    }
  }
  &Items {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: auto;
    max-height: calc(var(--list-max-height) - 50px);
  }
  &Box {
    position: relative;
    max-height: 0;
    transition: 0.4s;
    overflow: hidden;
  }
  &-item {
    padding: 8px 10px;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid rgb(243, 243, 243);
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.12);
    }
    &.is-selected {
      background-color: rgba(94, 33, 210, 0.1);
      color: #5e21d2;
    }
  }
}
</style>
