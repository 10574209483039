<template>
  <div v-if="isLoaded" class="py-0 px-0 px-mb-6 app-editor is-only-desktop">
    <div class="app-editor-wrapper">
      <div class="app-editor-notSupported">
        <RIcon size="40" color="primary">mdi-alert-circle-outline</RIcon>
        <div class="text-h6 mt-3 mb-2">עורך העיצובים לא נתמך ממכשיר זה</div>
        <div>
          <p>ניתן להיכנס דרך מחשב נייד.</p>
        </div>

        <RButton @click="$router.go(-1)"> אוקי, קחו אותי אחורה </RButton>
      </div>

      <div id="app-editor-panel" class="app-editor-panel">
        <tool-wrapper
          :title="isMainMenu ? 'הוספת אלמנט' : activeTool.title"
          :showback-btn="!isMainMenu"
          @onBack="() => changeToolHandler(null)"
          @update-preference="updatePreference"
          @global-settings-tool="() => changeToolHandler(tools.definitions)"
        >
          <tools-menu
            v-if="!activeTool"
            :value="activeTool"
            @change-active-tool="changeToolHandler"
            @set-current="setCurrentHandler"
          />
          <component
            :is="activeTool.component"
            v-else
            v-model="preference"
            :current="current"
            :template="template"
            :part="part"
            @update-current="updateCurrentHandler"
            @update="updateTemplateHandler"
            @delete="deleteElementHandler"
          />
        </tool-wrapper>
      </div>

      <div id="app-editor-preview" class="app-editor-preview">
        <div
          id="app-editor-preview-box"
          class="pa-0 d-flex"
          :style="pdfWrapperStyle"
        >
          <div class="pa-0 d-flex flex-wrap justify-space-between">
            <div class="align-self-start" style="width: 100%">
              <pdf-template-preview
                v-if="renderTemplate"
                :template="template.header"
                :preference="preference"
                :current="current"
                @update-template="
                  (template) => updateTemplateHandler('header', template)
                "
                @set-current="(curr) => setCurrentHandler(curr, 'header')"
                @change-active-tool="() => changeToolHandler(tools.header)"
                @create-section="
                  (colsCount) => createSectionHandler('header', colsCount)
                "
                @onBack="() => changeToolHandler(null)"
                @delete="deleteElementHandler"
                @update-current="updateCurrentHandler"
              />
            </div>

            <div
              class="pt-0 pl-6 pr-6 pb-0"
              style="width: 100%; margin-bottom: auto"
            >
              <div style="display: flex; flex-direction: column; gap: 8px">
                <div
                  style="
                    width: 100%;
                    height: 16px;
                    background-color: #e0e0e0;
                    border-radius: 4px;
                  "
                ></div>
                <div
                  style="
                    width: 60%;
                    height: 16px;
                    background-color: #e0e0e0;
                    border-radius: 4px;
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 16px;
                    background-color: #e0e0e0;
                    border-radius: 4px;
                  "
                ></div>
                <div
                  style="
                    width: 100%;
                    height: 16px;
                    background-color: #e0e0e0;
                    border-radius: 4px;
                  "
                ></div>
                <div
                  style="
                    width: 60%;
                    height: 16px;
                    background-color: #e0e0e0;
                    border-radius: 4px;
                  "
                ></div>
              </div>
            </div>

            <div class="align-self-end" style="width: 100%">
              <pdf-template-preview
                v-if="renderTemplate"
                :template="template.footer"
                :preference="preference"
                :current="current"
                @update-template="
                  (template) => updateTemplateHandler('footer', template)
                "
                @set-current="(curr) => setCurrentHandler(curr, 'footer')"
                @change-active-tool="() => changeToolHandler(tools.footer)"
                @create-section="
                  (colsCount) => createSectionHandler('footer', colsCount)
                "
                @onBack="() => changeToolHandler(null)"
                @delete="deleteElementHandler"
                @update-current="updateCurrentHandler"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToolsMenu from "./editTools/ToolsMenu.vue";
import DefinitionsTool from "./editTools/tools/DefinitionsTool.vue";
import SectionTool from "./editTools/tools/sectionTool/SectionTool";
import ColumnTool from "./editTools/tools/columnTool/ColumnTool.vue";
import FieldTool from "./editTools/tools/fieldTool/FieldTool.vue";
import PaperTool from "./editTools/tools/paperTool/PaperTool.vue";
import ToolWrapper from "./editTools/ToolWrapper.vue";
import PdfTemplatePreview from "./preview/PdfTemplatePreview.vue";
import { tools } from "./editTools/toolsData";
import {
  initializeColumn,
  initializeSection,
  initializeTemplatePartHeader,
  initializeTemplatePartFooter,
  initializePreferenceStyle,
} from "./editorHelpers";
import PreferencesApi from "@/api/PreferencesApi";

export default {
  components: {
    ToolsMenu,
    ToolWrapper,
    DefinitionsTool,
    SectionTool,
    ColumnTool,
    FieldTool,
    PdfTemplatePreview,
    PaperTool,
  },
  data: () => ({
    activeTool: null,
    template: null,
    renderTemplate: true,
    current: {},
    part: "header",
    isLoaded: false,
    preference: {},
    tools,
  }),
  computed: {
    isMainMenu() {
      return !this.activeTool;
    },
    pdfWrapperStyle() {
      let style = {
        width: "794px",
        height: "1123px",
        maxWidth: "none",
        backgroundPosition: "center",
        backgroundColor: "#fff",
        position: "relative",
        border: "1px solid #e0e0e0",
        borderRadius: "4px",
        backgroundSize: "cover",
      };

      if (
        this.preference &&
        this.preference.data &&
        this.preference.data.style
      ) {
        if (this.preference.data.style.backgroundImagePath) {
          style.backgroundImage = `url('${
            this.$Reporto.globals.MEDIA_URL +
            this.preference.data.style.backgroundImagePath
          }')`;
        }

        // if (this.preference.data.style.fontFamily) {
        //   style.fontFamily = `"${this.preference.data.style.fontFamily}", sans-serif`;
        // }
      }
      return style;
    },
  },
  watch: {
    activeTool: {
      immediate: true,
      handler: function (val) {

        if (
          val &&
          [tools.header.title, tools.footer.title].includes(val.title)
        ) {
          this.current = this.template[val.part];
          this.part = val.part;
        }
      },
    },
  },
  async created() {
    await this.fetchPreference();
    this.isLoaded = true;
    window.addEventListener("click", this.clickOutsideEditorEvent);
  },
  updated() {
    this.updateHeaderFooterHeight();
  },
  beforeUnmount() {
    window.removeEventListener("click", this.clickOutsideEditorEvent);
  },
  methods: {
    clickOutsideEditorEvent(e) {
      if (!e) {
        return;
      }
      if (
        (document.getElementById("app-editor-preview-box") &&
          document
            .getElementById("app-editor-preview-box")
            .contains(e.target)) ||
        (document.getElementById("app-editor-context-menu") &&
          document
            .getElementById("app-editor-context-menu")
            .contains(e.target)) ||
        (document.querySelector(
          ".v-menu__content:not([style*='display:none']):not([style*='display: none'])"
        ) &&
          document
            .querySelector(
              ".v-menu__content:not([style*='display:none']):not([style*='display: none'])"
            )
            .contains(e.target)) ||
        (document.getElementById("app-editor-panel") &&
          document.getElementById("app-editor-panel").contains(e.target))
      ) {
        // Clicked in box
      } else {
        this.current = {};
        this.changeToolHandler(null);
      }
    },
    updateHeaderFooterHeight() {
      let headerEl = document.querySelector(".pdfSectionsPreview-header");
      if (headerEl) {
        this.template.header.style._height = headerEl.clientHeight;
      }

      let footerEl = document.querySelector(".pdfSectionsPreview-footer");
      if (footerEl) {
        this.template.footer.style._height = footerEl.clientHeight;
      }
    },
    async fetchPreference() {
      let loader = this.$loading.show();
      const data = await PreferencesApi.getPreference(this.$route.params.id);
      this.preference = data;

      this.template = {};

      if (!this.preference.data.style) {
        this.preference.data.style = initializePreferenceStyle();
      } else {
        this.preference.data.style = {
          ...initializePreferenceStyle(),
          ...this.preference.data.style,
        };
      }

      if (data.data.header) {
        this.template.header = data.data.header;
        delete data.data.header;
      } else {
        this.template.header = initializeTemplatePartHeader();
      }

      if (data.data.footer) {
        this.template.footer = data.data.footer;
        delete data.data.footer;
      } else {
        this.template.footer = initializeTemplatePartFooter();
      }

      loader.hide();
    },
    async updatePreference() {
      let loader = this.$loading.show();
      await PreferencesApi.updatePreference(this.preference.id, {
        name: this.preference.name,
        data: {
          ...this.preference.data,
          header: this.template.header,
          footer: this.template.footer,
        },
      });

      loader.hide();
      this.$toast.success("העיצוב עודכן בהצלחה");
    },
    changeToolHandler(activeTool) {
      this.activeTool = null;
      this.$nextTick(function () {
        this.activeTool = activeTool ? { ...activeTool } : null;
      });
    },
    setCurrentHandler(current, part) {
      this.current = current;
      this.part = part;

      this.changeToolHandler(tools[current.elementType]);
    },
    updateTemplateHandler(partName, templatePart = null) {
      this.template[partName] = templatePart
        ? { ...templatePart }
        : { ...this.template[partName] };
      this.renderTemplate = false;
      this.$nextTick(() => {
        this.renderTemplate = true;
      });
    },
    updateCurrentHandler(current) {
      const template = { ...this.template[this.part] };
      const sections = [...template.sections];

      if (current.elementType === "paper") {
        this.template[current.name] = { ...current };
        this.current = { ...current };
        return;
      }

      if (current.elementType === "section") {
        const secIndex = sections.findIndex(
          (section) => section.id === current.id
        );
        secIndex !== -1 && (sections[secIndex] = current);
      }

      if (current.elementType === "column") {
        sections.forEach((section) => {
          const columns = [...section.columns];
          const colIndex = columns.findIndex(
            (column) => column.id === current.id
          );
          colIndex !== -1 && (columns[colIndex] = current);
          section.columns = columns;
        });
      }

      if (current.elementType === "field") {
        sections.forEach((section) => {
          const columns = [...section.columns];
          columns.forEach((column) => {
            const fields = [...column.fields];
            const fieldIndex = fields.findIndex(
              (field) => field.id === current.id
            );
            fieldIndex !== -1 && (fields[fieldIndex] = current);
            column.fields = fields;
          });
          section.columns = columns;
        });
      }
      template.sections = sections;
      this.template[this.part] = template;
      this.current = { ...current };
    },
    deleteElementHandler(el, part = null) {
      if (!part) {
        part = this.part;
      }

      const template = { ...this.template[part] };
      const sections = [...template.sections];

      if (el.elementType === "section") {
        const secIndex = sections.findIndex((section) => section.id === el.id);
        secIndex !== -1 && sections.splice(secIndex, 1);
      }

      if (el.elementType === "column") {
        sections.forEach((section) => {
          const columns = [...section.columns];
          const colIndex = columns.findIndex((column) => column.id === el.id);
          if (colIndex !== -1) {
            const deletedWidth = columns[colIndex].style.width;
            columns.splice(colIndex, 1);
            if (columns.length) {
              const addWidth = deletedWidth / columns.length;
              columns.forEach((col) => (col.style.width += addWidth));
            }
          }
          section.columns = columns;
        });
      }

      if (el.elementType === "field") {
        sections.forEach((section) => {
          const columns = [...section.columns];
          columns.forEach((column) => {
            const fields = [...column.fields];
            const fieldIndex = fields.findIndex((field) => field.id === el.id);
            fieldIndex !== -1 && fields.splice(fieldIndex, 1);
            column.fields = fields;
          });
          section.columns = columns;
        });
      }

      this.changeToolHandler(null);
      template.sections = sections;
      this.template[part] = template;
    },
    globalSettingsToolHandler() {
      this.setCurrentHandler(this.template[this.part], this.part);
    },
    createSectionHandler(partName, columnsCount) {
      const sectionWidth = 100;
      const columns = [];
      let count = 0;
      while (count < columnsCount) {
        columns.push(
          initializeColumn({
            width: sectionWidth / columnsCount,
          })
        );
        count++;
      }
      const section = initializeSection({
        columns,
      });
      this.template[partName].sections.push(section);
    },
  },
};
</script>
