<template>
  <RAlert v-if="shouldShow" type="info" dismissible @dismiss="alertClicked">
    <h3 class="mb-1 mt-2">הוספנו חלוקה ללשוניות</h3>
    <div class="text--primary">
      מעכשיו תוכלו לראות את הממצאים, הצ'קליסטים והטקסטים בלשוניות נפרדות.
      <br />
      כך תוכלו לעבוד בצורה נוחה יותר ולהתמקד בכל חלק בנפרד.
    </div>

    <RButton
      class="mt-2"
      target="_blank"
      color="blue"
      icon-before="mdi-thumb-up"
      @click="alertClicked"
    >
      הבנתי
    </RButton>
  </RAlert>
</template>
<script>
import { trackEvent } from "@/services/TrackingService";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      show: true,
    };
  },
  computed: {
    ...mapGetters("auth", ["displayFirstName", "company", "demoMeetingUrl"]),
    shouldShow() {
      return !this.getUserCache() && this.show;
    },
  },
  methods: {
    trackEvent,
    alertClicked() {
      this.show = false;
      this.addToUserCache();
    },
    getUserCache() {
      return window.localStorage.getItem("cache/hasSeenReportTabsAlert4");
    },
    addToUserCache() {
      window.localStorage.setItem("cache/hasSeenReportTabsAlert4", true);
    },
  },
};
</script>
