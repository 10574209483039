<template>
  <div>
    <div class="app-field">
      <label class="app-field__label"> ריווח </label>
      <field-paddings v-model="padding" />
    </div>

    <RFieldSelect
      v-model="borderStyle"
      class="mb-3"
      label="עיצוב מסגרת"
      :items="[
        { text: 'solid', value: 'solid' },
        { text: 'dashed', value: 'dashed' },
      ]"
    />

    <div class="app-field">
      <label class="app-field__label"> רוחב מסגרת </label>
      <field-border-width v-model="borderWidth" />
    </div>

    <div class="app-field">
      <label class="app-field__label"> צבע מסגרת </label>
      <input-color v-model="borderColor" />
    </div>

    <div class="app-field">
      <label class="app-field__label"> צבע רקע </label>
      <input-color v-model="backgroundColor" />
    </div>

    <div class="d-flex justify-space-between w-full mt-5 mb-2">
      <RButton color="primary" sm @click="addColumn">הוספת עמודה</RButton>
      <RButton color="red" sm @click="$emit('delete', current)"
        >מחיקת אזור</RButton
      >
    </div>
  </div>
</template>

<script>
import { addColumnHandler } from "../../../editorHelpers";
import FieldBorderWidth from "../settings/FieldBorderWidth.vue";
import FieldPaddings from "../settings/FieldPaddings.vue";
import InputColor from "../settings/InputColor.vue";
import { typography } from "@/common/settings";

export default {
  components: {
    FieldPaddings,
    FieldBorderWidth,
    InputColor,
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    current: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    panel: [0],
    typography,
  }),
  computed: {
    preference: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit("update:modelValue", val);
      },
    },
    padding: {
      get() {
        return this.current.style.padding;
      },
      set(val) {
        const current = { ...this.current };
        current.style.padding = val;
        this.$emit("update-current", current);
      },
    },
    borderWidth: {
      get() {
        return this.current.style.border.width;
      },
      set(val) {
        const current = { ...this.current };
        current.style.border.width = val;
        this.$emit("update-current", current);
      },
    },
    borderStyle: {
      get() {
        return this.current.style.border.style;
      },
      set(val) {
        const current = { ...this.current };
        current.style.border.style = val;
        this.$emit("update-current", current);
      },
    },
    borderColor: {
      get() {
        return this.current.style.border.color;
      },
      set(val) {
        const current = { ...this.current };
        current.style.border.color = val;
        this.$emit("update-current", current);
      },
    },
    backgroundColor: {
      get() {
        return this.current.style.backgroundColor;
      },
      set(val) {
        const current = { ...this.current };
        current.style.backgroundColor = val;
        this.$emit("update-current", current);
      },
    },
    backgroundImagePath: {
      get() {
        return this.current.style.backgroundImagePath;
      },
      set(val) {
        const current = { ...this.current };
        current.style.backgroundImagePath = val;
        this.$emit("update-current", current);
      },
    },
  },
  methods: {
    addColumn() {
      this.$emit("update-current", addColumnHandler(this.current));
    },
  },
};
</script>
