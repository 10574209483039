import { createStore } from "vuex";

import auth from "./modules/auth";
import report from "./modules/report";
import preferences from "./modules/preferences";
import reportQue from "./modules/reportQue";
import reportTemplates from "./modules/reportTemplates";
import stocks from "./modules/stocks";
import users from "./modules/users";
import updates from "./modules/updates";
import projects from "./modules/projects";
import site from "./modules/site";

const initialState = {};

// Create store using createStore instead of new Vuex.Store
export default createStore({
  state: initialState,
  getters: {},
  mutations: {},
  actions: {},

  modules: {
    auth,
    report,
    preferences,
    reportQue,
    reportTemplates,
    stocks,
    users,
    updates,
    projects,
    site,
  },
});
