async function getProjects() {
  const response = await window.axios.get(`/api/v1/projects/`);
  return response.data;
}

async function createProject(data) {
  const response = await window.axios.post(`/api/v1/projects`, data);
  return response.data;
}

async function removeProject(projectId) {
  const response = await window.axios.delete(`/api/v1/projects/${projectId}`);
  return response.data;
}

async function getProject(id) {
  const response = await window.axios.get(`/api/v1/projects/${id}`);
  return response.data;
}

async function updateProject(id, data) {
  const response = await window.axios.put(`/api/v1/projects/${id}`, data);
  return response.data;
}

async function attachTemplateToProject(projectId, templateId) {
  const response = await window.axios.put(
    `/api/v1/projects/${projectId}/attach-template/${templateId}`
  );
  return response.data;
}

async function detachTemplateFromProject(projectId, templateId) {
  const response = await window.axios.put(
    `/api/v1/projects/${projectId}/detach-template/${templateId}`
  );
  return response.data;
}

async function detachReportFromProject(projectId, reportId) {
  const response = await window.axios.put(
    `/api/v1/projects/${projectId}/detach-report/${reportId}`
  );
  return response.data;
}

async function attachReportToProject(projectId, reportId) {
  const response = await window.axios.put(
    `/api/v1/projects/${projectId}/attach-report/${reportId}`
  );
  return response.data;
}

async function attachUserToProject(projectId, userId) {
  const response = await window.axios.put(
    `/api/v1/projects/${projectId}/attach-user/${userId}`
  );
  return response.data;
}

async function detachUserFromProject(projectId, userId) {
  const response = await window.axios.put(
    `/api/v1/projects/${projectId}/detach-user/${userId}`
  );
  return response.data;
}

async function sendEmails(projectId, reportId) {
  const response = await window.axios.put(
    `/api/v1/projects/${projectId}/send-emails/${reportId}`
  );
  return response.data;
}

async function uploadMedia(projectId, file, folderId = null) {
  const formData = new FormData();
  formData.append("file", file);
  if (folderId) {
    formData.append("folder_id", folderId);
  }

  const response = await window.axios.post(
    `/api/v1/projects/${projectId}/media`,
    formData,
    {
      timeout: 20000,
    }
  );
  return response.data;
}

async function removeMedia(projectId, mediaId) {
  const response = await window.axios.delete(
    `/api/v1/projects/${projectId}/media/${mediaId}`
  );
  return response.data;
}

async function updateMediaFolder(projectId, mediaId, folderId = null) {
  const response = await window.axios.put(
    `/api/v1/projects/${projectId}/media/${mediaId}/move-folder`,
    {
      folder_id: folderId,
    }
  );
  return response.data;
}

async function createFolder(projectId, folderName, parentFolderId) {
  const response = await window.axios.post(
    `/api/v1/projects/${projectId}/folder`,
    {
      name: folderName,
      parent_id: parentFolderId,
    }
  );
  return response.data;
}

async function updateFolder(projectId, folderId, data) {
  const response = await window.axios.put(
    `/api/v1/projects/${projectId}/folder/${folderId}`,
    data
  );
  return response.data;
}

async function removeFolder(projectId, folderId) {
  const response = await window.axios.delete(
    `/api/v1/projects/${projectId}/folder/${folderId}`
  );
  return response.data;
}

export default {
  getProjects,
  createProject,
  removeProject,
  getProject,
  updateProject,
  attachTemplateToProject,
  detachTemplateFromProject,
  attachReportToProject,
  detachReportFromProject,
  attachUserToProject,
  detachUserFromProject,
  uploadMedia,
  removeMedia,
  updateMediaFolder,
  sendEmails,
  createFolder,
  updateFolder,
  removeFolder,
};
