<template>
  <div
    class="r-searchCompact"
    :class="{
      'is-open': isOpen,
    }"
  >
    <input
      v-if="isOpen"
      v-model="searchQuery"
      type="text"
      :placeholder="placeholder"
      class="r-searchCompact__input"
    />
    <RButtonIcon
      v-if="!isOpen || !searchQuery"
      class="r-searchCompact__icon"
      icon="mdi-magnify"
      @click="isOpen = true"
    />
    <RButtonIcon
      v-if="isOpen && searchQuery"
      class="r-searchCompact__icon"
      icon="mdi-close"
      @click="searchQuery = ''"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "חפש...",
    },
    initialOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.initialOpen,
      searchQuery: this.value,
    };
  },
  watch: {
    searchQuery(val) {
      this.$emit("update:modelValue", val);
    },

  },
};
</script>
