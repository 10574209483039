<template>
  <div class="r-page-site">
    <div class="r-page-site-header">
      <div class="r-page-site-header-content">
        <div class="r-page-site-header-title">
          <span class="r-page-site-header-title-title">📓 פרויקט: 1001</span>
        </div>
        <div class="r-page-site-header-subtitle">
          כתובת: דובנוב 30 תל אביב / לקוח: עליתא יזמות נדל"ן
        </div>
      </div>
    </div>
    <div class="r-page-site-nav">
      <router-link to="/sites/details/">פרטים כלליים</router-link>
      <router-link to="/sites/findings/">כל המצצאים</router-link>
      <!--      <router-link to="/sites/buildings/">צפיה לפי אזורים</router-link>-->
      <router-link to="/sites/checklists/">צ׳קליסטים לאזורים</router-link>
      <router-link to="/sites/building/">בניין A</router-link>
      <RButton sm icon-before="mdi-check" color="green" @click="saveSite"
        >שמירה</RButton
      >
      <RButton
        class="mr-6"
        sm
        icon-before="mdi-delete"
        color="red"
        @click="removeSite"
        >איפוס כל ההגדרות</RButton
      >
    </div>
    <div class="r-page-site-body">
      <RouterView v-if="isLoaded" />
      <PageLoader v-else />
    </div>
  </div>
</template>
<script>
import PageLoader from "../../components/PageLoader.vue";

export default {
  components: { PageLoader },
  data() {
    return {
      isLoaded: false,
    };
  },
  async created() {
    await this.$store.dispatch("site/fetchSite");
    this.isLoaded = true;
  },
  methods: {
    saveSite() {
      this.$store.dispatch("site/saveSite");
      this.$toast.success("האתר נשמר בהצלחה");
    },
    removeSite() {
      if (confirm("האם אתה בטוח שברצונך למחוק את האתר?")) {
        // remove from localStorage and refresh the page
        window.localStorage.removeItem("site");
        this.$toast.success("האתר נמחק בהצלחה");
        window.location.reload();
      }
    },
  },
};
</script>
