import { Utils } from "@/common/Utils";
import ReportsApi from "@/api/ReportsApi";
import { sendLog } from "@/services/LogService";
import { SET_TOTAL_MESSAGE_IMAGES_SYNCED, SET_TOTAL_MESSAGE_IMAGES_TO_SYNC } from "../reportQue/mutations";
import { SET_REPORT, UPDATE_REPORT_STATE } from "./mutations";

const debounceDoUpdateV2 = Utils.debounceV2(doUpdateV2)
const debounceDoUpdateManyV2 = Utils.debounceV2(doUpdateManyV2)

export const doUpdate = (context, ...args) => {
    if (context.rootGetters['reportQue/useOfflineSystemV2']) return debounceDoUpdateV2(context, ...args)
    return doUpdateV1(context, ...args)
}

export const doUpdateMany = (context, ...args) => {
    if (context.rootGetters['reportQue/useOfflineSystemV2']) return debounceDoUpdateManyV2(context, ...args)
    return doUpdateManyV1(context, ...args)
}

export const getReport = async ({ commit }, payload) => {
    try {
        const data = await ReportsApi.getReport(payload.reportId);

        if (!data.defectsGroupedBy) {
            data.defectsGroupedBy = 'category';
        }

        commit(SET_REPORT, data);
    } catch (err) {
        console.error('Had issues fetching report', err);
        throw err
    }
};

// V2 Maybe call this onReportChanged?
async function doUpdateV2({ commit, dispatch, state, rootState, rootGetters }, payload) {
    if (Utils.isObjectEmpty(payload.value)) {
        payload.value = null;
    }
    const data = { [payload.key]: payload.value }


    const message = _createMessage({ report: state.report, user: rootState.auth.user, data });
    // Optimsitically update the state
    commit(UPDATE_REPORT_STATE, { data: message.data });
    dispatch("reportQue/addMessageToQue", message, { root: true });

    const onlineStatus = rootGetters['reportQue/onlineStatus']
    // If is online and NOT syncing at the moment -> Live upload
    if (onlineStatus && !rootGetters['reportQue/isSyncing']) {
        // Maybe we want to also log the data(WITHOUT IMAGES)? Incase update fail and log may pass.
        const logMsg = JSON.parse(JSON.stringify(message))
        delete logMsg.data
        try {
            await dispatch("reportQue/sync", message, { root: true });
        } catch (error) {
            console.error("[SyncEngine] : doUpdate() Update failed, queuing message", { data: logMsg, err: error })
            onlineStatus && sendLog("[SyncEngine] : doUpdate() error queuing message", { err: error, data: logMsg });
        }
    }
}

async function doUpdateManyV2({ commit, dispatch, state, rootState, rootGetters }, payload) {
    for (const [key, value] of Object.entries(payload.data)) {
        if (Utils.isObjectEmpty(value)) {
            payload.data[key] = null;
        }
    }

    const message = _createMessage({ report: state.report, user: rootState.auth.user, data: payload.data });
    commit(UPDATE_REPORT_STATE, { data: message.data });
    dispatch("reportQue/addMessageToQue", message, { root: true });
    const onlineStatus = rootGetters['reportQue/onlineStatus']
    if (onlineStatus && !rootGetters['reportQue/isSyncing']) {
        const logMsg = JSON.parse(JSON.stringify(message))
        delete logMsg.data
        try {
            await dispatch("reportQue/sync", message, { root: true });
        } catch (error) {
            console.error("[SyncEngine] : doUpdateMany() Update failed, uploading message", { data: logMsg, err: error })
            onlineStatus && sendLog("[SyncEngine] : doUpdateMany() error uploading message", { err: error, data: logMsg });
        }
    }
};

export async function updateReport({ dispatch }, message) {
    try {
        message = await dispatch("uploadReportImages", message);

        if (message.action === "do-update") {
            console.log("updateReport - Uploading message", message);
            await ReportsApi.doUpdate(message.dataset_id, message);
        }
    } catch (err) {
        const logMsg = JSON.parse(JSON.stringify(message))
        delete logMsg.data
        console.error("updateReport - Had issues uploading message", logMsg, err);
        throw err
    }
}

export async function uploadReportImagesFAST({ commit }, message) {
    commit('reportQue/' + SET_TOTAL_MESSAGE_IMAGES_TO_SYNC, 0, { root: true });
    commit('reportQue/' + SET_TOTAL_MESSAGE_IMAGES_SYNCED, 0, { root: true });

    if (!message.data) {
        return message;
    }

    const imagesToUpload = Object.entries(message.data).reduce((images, [key, value]) => {
        if (value && Utils.isBase64Image(value)) {
            images.push({ key, value });
        }
        return images;
    }, [])

    const totalImagesToUpload = imagesToUpload.length;
    commit('reportQue/' + SET_TOTAL_MESSAGE_IMAGES_TO_SYNC, totalImagesToUpload, { root: true });

    let count = 1;
    const imgPrms = imagesToUpload.map(async ({ key, value }) => {
        let { data } = await Utils.uploadImageBase64(value);
        console.log(`Synced image : ${count} of ${totalImagesToUpload}`);
        commit('reportQue/' + SET_TOTAL_MESSAGE_IMAGES_SYNCED, count, { root: true });
        message.data[key] = data.path;
        count++
        return
    })

    // Wait till all images have completed
    await Promise.all(imgPrms)

    commit('reportQue/' + SET_TOTAL_MESSAGE_IMAGES_TO_SYNC, 0, { root: true });
    commit('reportQue/' + SET_TOTAL_MESSAGE_IMAGES_SYNCED, 0, { root: true });

    return message;
}

export async function uploadReportImages({ commit }, message) {
    commit('reportQue/' + SET_TOTAL_MESSAGE_IMAGES_TO_SYNC, 0, { root: true });
    commit('reportQue/' + SET_TOTAL_MESSAGE_IMAGES_SYNCED, 0, { root: true });

    if (!message.data) {
        return message;
    }

    const imagesToUpload = Object.entries(message.data).reduce((images, [key, value]) => {
        if (value && Utils.isBase64Image(value)) {
            images.push({ key, value });
        }
        return images;
    }, [])

    const totalImagesToUpload = imagesToUpload.length;
    commit('reportQue/' + SET_TOTAL_MESSAGE_IMAGES_TO_SYNC, totalImagesToUpload, { root: true });

    for (const [idx, { key, value }] of imagesToUpload.entries()) {
        console.log(`Syncing image : ${idx + 1} of ${totalImagesToUpload}`);
        commit('reportQue/' + SET_TOTAL_MESSAGE_IMAGES_SYNCED, idx + 1, { root: true });
        let { data } = await Utils.uploadImageBase64(value);
        message.data[key] = data.path;
    }

    commit('reportQue/' + SET_TOTAL_MESSAGE_IMAGES_TO_SYNC, 0, { root: true });
    commit('reportQue/' + SET_TOTAL_MESSAGE_IMAGES_SYNCED, 0, { root: true });

    return message;
}

function _createMessage({ report, user, data }) {
    return {
        company_id: report.company_id,
        user_id: user.id,
        dataset: "reports",
        dataset_id: report.id,
        data,
        action: 'do-update',
        timestamp: Utils.currentTimestamp(),
    }
}

// V1

async function doUpdateV1({ commit, dispatch, state, rootState }, payload) {
    if (Utils.isObjectEmpty(payload.value)) {
        payload.value = null;
    }

    let message = {
        company_id: state.report.company_id,
        user_id: rootState.auth.user.id,
        dataset: "reports",
        dataset_id: state.report.id,
        data: { [payload.key]: payload.value },
        action: 'do-update',
        timestamp: Utils.currentTimestamp(),
    };

    Utils.debounce(payload.key, async () => {
        await dispatch("reportQue/sendMessage", message, { root: true });
    });

};

async function doUpdateManyV1({ commit, dispatch, state, rootState }, payload) {
    for (const [key, value] of Object.entries(payload.data)) {
        if (Utils.isObjectEmpty(value)) {
            payload.data[key] = null;
        }
    }

    let message = {
        company_id: state.report.company_id,
        user_id: rootState.auth.user.id,
        dataset: "reports",
        dataset_id: state.report.id,
        data: payload.data,
        action: 'do-update',
        timestamp: Utils.currentTimestamp(),
    };

    await dispatch("reportQue/sendMessage", message, { root: true });
};
