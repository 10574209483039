<template>
  <div class="r-auth-wrapper">
    <div class="r-auth-inner">
      <div class="r-auth-logo">
        <img src="@/assets/images/reporto-logo.svg" alt="logo" />
      </div>
      <div class="r-auth-title">
        <span style="font-weight: 400"> שלחנו לך קוד אימות לטלפון: </span>
        <br />
        <b v-if="phone">{{ phone }}</b>
      </div>
      <div class="r-auth-form">
        <form
          :class="{
            loading: loading,
          }"
          @submit.prevent="verifyPhone"
        >
          <AuthOtpInput v-model="otpValue" />

          <RButton
            lg
            block
            color="primary"
            class="mt-6"
            type="submit"
            :disabled="!otpValue || otpValue.length < 4"
            :loading="loading"
          >
            כניסה
          </RButton>
        </form>
      </div>

      <div class="r-auth-bottom-text">
        <template v-if="time">
          שליחת קוד אימות חדש תוך
          <b>{{ time }}</b>
          שניות
          <br />
        </template>
        <div class="d-flex justify-center mt-1">
          <button
            class="r-auth-link"
            :class="{
              'is-disabled': time,
            }"
            @click="reSendOtp"
          >
            שלח קוד מחדש
          </button>
          <RIconLoading
            v-if="isResendingOtp"
            size="12"
            class="mr-1"
            style="position: relative; top: 3px"
          />
        </div>
      </div>

      <RouterLink class="r-auth-back-button mt-8" :to="{ name: 'login' }">
        <span>חזרה למסך התחברות</span>
        <RIcon>mdi-arrow-left</RIcon>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import AuthApi from "@/api/AuthApi";
import * as types from "@/store/modules/auth/mutation-types";
import { trackEvent } from "@/services/TrackingService";
import { Utils } from "@/common/Utils";
import AuthOtpInput from "./components/AuthOtpInput.vue";
import { sendLog } from "@/services/LogService";

export default {
  components: { AuthOtpInput },
  data() {
    return {
      RESEND_OTP_TIME: 45,
      time: this.RESEND_OTP_TIME,
      timer: null,
      isRunning: false,
      loading: false,
      phone: null,
      otpValue: null,
      isResendingOtp: false,
    };
  },
  beforeUnmount() {
    this.stopTimer();
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const phoneParam = urlParams.get("phone");
    if (phoneParam) {
      this.phone = phoneParam;
    }
    this.startTimer();

    this.$nextTick(() => {
      this.$el.querySelector("input").focus();
    });
  },
  methods: {
    startTimer() {
      if (this.isRunning) return;

      this.time = this.RESEND_OTP_TIME;
      this.isRunning = true;

      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          this.stopTimer();
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.isRunning = false;
    },
    async verifyPhone() {
      this.loading = true;
      try {
        let response = await AuthApi.verifyOtp(this.otpValue);
        this.$store.commit("auth/" + types.SET_TOKEN, response.token);
        await this.$store.dispatch("auth/fetchMe");

        trackEvent("User Login", { method: "phone" });
        sendLog(
          "[User Auth] : User Sent Otp VerifyPhone.vue > Success: " + this.phone
        );

        await this.$router.push({ name: "home" });
      } catch (e) {
        this.loading = false;
        sendLog(
          "[User Auth] : User Sent Otp VerifyPhone.vue > Error: " + this.phone
        );
        if (e.response && e.response.data.message) {
          this.$toast.error(e.response.data.message);
        } else {
          this.$toast.error("שגיאה בהתחברות עם קוד");
        }
      }
    },
    async reSendOtp() {
      if (!Utils.isValidPhone(this.phone)) {
        this.$toast.error("חובה להזין טלפון תקין");
        return;
      }

      this.isResendingOtp = true;

      try {
        await AuthApi.sendOtp(this.phone);
        trackEvent("User Sent Otp");
        sendLog(
          "[User Auth] : User Otp VerifyPhone.vue > Success: " + this.phone
        );
        this.$toast.success("קוד אימות נשלח מחדש");
        this.startTimer();
        this.otpValue = null;
      } catch (e) {
        this.$toast.error("שגיאה בשליחת קוד אימות מחדש");
      } finally {
        this.isResendingOtp = false;
      }
    },
  },
};
</script>
