import store from "@/store";
import { sendLog } from "../services/LogService";
import {
  SET_ONLINE_STATUS,
  SET_SHOW_SYNC_SCREEN,
  SET_SYNCING_STATUS,
} from "../store/modules/reportQue/mutations";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      prevOnlineStatus: "",
      onlineStatus: navigator.onLine,
      connectionType: navigator.connection?.effectiveType || "unknown",
      downlinkSpeed: navigator.connection?.downlink || 0,
      initialSyncCompleted: false,
      timeoutId: null,
    };
  },

  async created() {
    if (!this.useOfflineSystemV2)
      await this.$store.dispatch("reportQue/backgroundSync");
    await this.$store.dispatch("reportQue/loadMessagesFromForage");

    this.initSync()
  },

  computed: {
    ...mapGetters("reportQue", ["useOfflineSystemV2"]),
  },

  mounted() {
    if (!this.useOfflineSystemV2) return;
    window.addEventListener("online", this.updateConnectionStatus);
    window.addEventListener("offline", this.updateConnectionStatus);
    navigator.connection?.addEventListener(
      "change",
      this.updateConnectionStatus
    );
  },

  beforeUnmount() {
    if (!this.useOfflineSystemV2) return;
    window.removeEventListener("online", this.updateConnectionStatus);
    window.removeEventListener("offline", this.updateConnectionStatus);
    navigator.connection?.removeEventListener(
      "change",
      this.updateConnectionStatus
    );
  },

  methods: {
    async initSync() {
      const {
        connectionType,
        onlineStatus,
        downlinkSpeed,
        prevOnlineStatus,
      } = this;
      const data = { connectionType, onlineStatus, downlinkSpeed, prevOnlineStatus };
      // Initial sync -> To know when to never show sync screen again.
      if (this.useOfflineSystemV2) {
        try {
          sendLog("[Connection Monitor-SyncV2] Initial sync started", { data })
          await store.dispatch("reportQue/sync", { showSyncScreen: true });
          this.initialSyncCompleted = true;

          sendLog("[Connection Monitor-SyncV2] Initial sync completed", { data });
        } catch (err) {
          this.initialSyncCompleted = false;
          sendLog("[Connection Monitor-SyncV2] Had issues with initialSync", { data, err })
          clearTimeout(this.timeoutId);
          const timeoutTime = navigator.onLine ? 6 : 15
          this.timeoutId = setTimeout(() => {
            this.initSync()
          }, timeoutTime * 1000)
        }
      } else {
        await this.checkMessagesQueue();
      }
    },

    async updateConnectionStatus() {
      this.prevOnlineStatus = this.onlineStatus;
      this.onlineStatus = navigator.onLine;
      this.connectionType = navigator.connection?.effectiveType || "unknown";
      this.downlinkSpeed = navigator.connection?.downlink || 0;

      store.commit(`reportQue/${SET_ONLINE_STATUS}`, this.onlineStatus);
      if (this.prevOnlineStatus === this.onlineStatus) return;
      if (this.onlineStatus) {
        // If doesnt have report or initial sync isnt completed -> show sync screen.
        const showSyncScreen = !(
          this.initialSyncCompleted
        );

        const {
          connectionType,
          onlineStatus,
          downlinkSpeed,
          prevOnlineStatus,
        } = this;
        this.$toast.success("חיבור חודש, מתחיל בסנכרון הודעות");
        // send log to server about user connection status
        sendLog("[Connection Monitor] Connection restored", {
          data: {
            connectionType,
            onlineStatus,
            downlinkSpeed,
            prevOnlineStatus,
          },
        });
        // This function will be activated in ANY kind of change of the internet.
        // Here we can add more conditions to decide when to sync -> based on downlinkSpeed/connectionType
        await store.dispatch("reportQue/sync", { showSyncScreen });
        this.initialSyncCompleted = true;
      } else {
        this.$toast.error(
          "זוהתה התנתקות מהרשת, העדכונים יבוצעו בעת חיבור מחדש"
        );
        // Stop syncing.
        store.commit(`reportQue/${SET_SYNCING_STATUS}`, false);
      }
    },

    async checkMessagesQueue() {
      const messageQueue = store.getters["reportQue/messagesSyncQueue"];
      let isSynced = !messageQueue?.length;
      if (!isSynced) {
        store.commit(`reportQue/${SET_SHOW_SYNC_SCREEN}`, true);
        console.log("app still syncing.....", messageQueue?.length);
        setTimeout(() => {
          this.checkMessagesQueue();
        }, 500);
      } else {
        store.commit(`reportQue/${SET_SHOW_SYNC_SCREEN}`, false);
      }
    },
  },
};
