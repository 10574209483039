<template>
  <div>
    <field-width :value="current.style.width" @input="changeColWidth" />

    <field-vertical-align v-model="verticalAlign" />

    <template v-if="canDeleteColumn">
      <div class="d-flex justify-end w-full mt-5 mb-2">
        <RButton color="red" sm @click="$emit('delete', current)"
          >מחיקת עמודה</RButton
        >
      </div>
    </template>
  </div>
</template>

<script>
import FieldVerticalAlign from "../settings/FieldVerticalAlign.vue";
import FieldWidth from "../settings/FieldWidth.vue";
import { typography } from "@/common/settings";

export default {
  components: {
    FieldWidth,
    FieldVerticalAlign,
  },
  props: {
    current: {
      type: Object,
      default: null,
    },
    part: {
      type: String,
      default: "",
    },
    template: {
      type: Object,
      default: null,
    },
    modelValue: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    panel: [0],
    typography,
  }),
  computed: {
    canDeleteColumn() {
      const section = this.currentSection;
      return section && section.columns && section.columns.length > 1;
    },
    currentSection() {
      return this.template[this.part].sections.find((section) => {
        return !!section.columns.find((col) => col.id === this.current.id);
      });
    },
    padding: {
      get() {
        return this.current.style.padding;
      },
      set(padding) {
        this.updateCurrentStyle({ padding });
      },
    },
    verticalAlign: {
      get() {
        return this.current.style.verticalAlign;
      },
      set(verticalAlign) {
        this.updateCurrentStyle({ verticalAlign });
      },
    },
    border: {
      get() {
        return this.current.style.border;
      },
      set(border) {
        this.updateCurrentStyle({ border });
      },
    },
    borderColor: {
      get() {
        return this.current.style.border.color;
      },
      set(color) {
        this.updateCurrentStyle({
          border: { ...this.current.style.border, color },
        });
      },
    },
    backgroundColor: {
      get() {
        return this.current.style.backgroundColor;
      },
      set(backgroundColor) {
        this.updateCurrentStyle({ backgroundColor });
      },
    },
    backgroundImagePath: {
      get() {
        return this.current.style.backgroundImagePath;
      },
      set(backgroundImagePath) {
        this.updateCurrentStyle({ backgroundImagePath });
      },
    },
  },
  methods: {
    changeColWidth(val) {
      const section = this.currentSection;
      if (val > 0 && section.columns.length > 1) {
        const index = section.columns.findIndex(
          (col) => col.id === this.current.id
        );
        const offset = this.current.style.width - val;
        if (index >= 0 && index < section.columns.length) {
          if (index === 0) {
            section.columns[1].style.width =
              section.columns[1].style.width + offset;
          } else {
            section.columns[index - 1].style.width =
              section.columns[index - 1].style.width + offset;
          }
          this.updateCurrentStyle({ width: val });
        }
      }
    },
    updateCurrentStyle(val) {
      this.$emit("update-current", {
        ...this.current,
        style: { ...this.current.style, ...val },
      });
    },
  },
};
</script>
