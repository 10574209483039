<template>
  <div>
    <RSimpleCard
      v-for="areaType in site.areaTypes"
      :key="areaType.id"
      :title="`סוג אזור: ${areaType.name}`"
      class="mb-7"
    >
      <RFieldText v-model="areaType.name" label="שם סוג אזור" />

      <hr class="mt-3 mb-3" />

      <div>
        <div style="font-weight: 600" class="mb-2">מיקומים:</div>

        <div
          v-for="location in areaType.locations"
          :key="location.id"
          class="d-flex u-gap-2 mb-2"
        >
          <RFieldText
            :value="location.name"
            class="flex-grow-1"
            @change="updateLocationName(areaType.id, location.id)"
          />
          <RButton
            color="red"
            xs
            @click="removeLocation(areaType.id, location.id)"
            >מחיקה</RButton
          >
        </div>

        <RButton class="mt-3" color="green" @click="addLocation(areaType.id)"
          >הוספת מיקום</RButton
        >
      </div>

      <hr class="mt-3 mb-3" />

      <div style="font-weight: 600" class="mb-2">צ׳קליסט:</div>

      <template v-if="areaTypeHasChecklist(areaType.id)">
        <table class="mt-6">
          <thead>
            <tr>
              <th>קטגוריה</th>
              <th>תיאור</th>
              <th>מיקום</th>
              <th>סטטוס</th>
              <th>פעולות</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(
                checklistItem, checklistItemIndex
              ) in getAreaTypeChecklist(areaType.id).items"
              :key="checklistItemIndex"
            >
              <td>
                <RFieldSelect
                  v-model="checklistItem.category"
                  :items="site.categories"
                />
              </td>
              <td>
                <RFieldText v-model="checklistItem.description" />
              </td>
              <td>
                <RFieldSelect
                  v-model="checklistItem.location"
                  :items="getAreaTypeLocationsOptions(areaType.id)"
                />
              </td>
              <td>
                <RFieldSelect
                  v-model="checklistItem.status"
                  :items="site.statuses"
                />
              </td>
              <td>
                <RButton
                  color="red"
                  xs
                  @click="
                    removeChecklistItem(
                      getAreaTypeChecklist(areaType.id).id,
                      checklistItem.id
                    )
                  "
                  >מחיקה</RButton
                >
              </td>
            </tr>
          </tbody>
        </table>

        <RButton
          class="mt-3"
          color="green"
          @click="addChecklistItem(areaType.id)"
          >הוספת סעיף</RButton
        >
      </template>
      <template v-else>
        <RButton
          class="mt-3"
          color="green"
          icon-before="mdi-plus"
          @click="createChecklist(areaType.id)"
          >יצירת צ׳קליסט</RButton
        >
      </template>
    </RSimpleCard>

    <RSimpleCard
      style="background-color: #fafafa; max-width: 500px"
      title="הוספת סוג אזור"
    >
      <div class="d-flex u-gap-2 align-center">
        <RFieldText v-model="addAreaTypeName" class="flex-grow-1" />
        <RButton
          :disabled="!addAreaTypeName"
          color="green"
          icon-before="mdi-plus"
          @click="addAreaType"
          >הוספת סוג אזור</RButton
        >
      </div>
    </RSimpleCard>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addAreaTypeName: "",
    };
  },
  computed: {
    site() {
      return this.$store.state.site.site;
    },
  },
  methods: {
    getAreaTypeLocationsOptions(areaTypeId) {
      return this.getAreaTypeLocations(areaTypeId).map((location) => ({
        text: location.name,
        value: location.name,
      }));
    },
    getAreaTypeLocations(areaTypeId) {
      return this.site.areaTypes.find((areaType) => areaType.id === areaTypeId)
        .locations;
    },
    areaTypeHasChecklist(areaTypeId) {
      let checklist = this.getAreaTypeChecklist(areaTypeId);
      return checklist;
    },
    getAreaTypeChecklist(areaTypeId) {
      return this.site.checklists.find(
        (checklist) =>
          checklist.type === "areaType" && checklist.type_id === areaTypeId
      );
    },
    addChecklistItem(areaId) {
      let checklist = this.getAreaTypeChecklist(areaId);
      if (!checklist) {
        this.$store.dispatch("site/addChecklist", {
          checklist: {
            id: crypto.randomUUID(),
            type: "areaType",
            items: [],
          },
        });
      }

      this.$store.dispatch("site/addChecklistItem", {
        checklistId: checklist.id,
        checklistItem: {
          id: crypto.randomUUID(),
          category: "",
          description: "",
          status: "לא טופל",
        },
      });
    },
    removeChecklistItem(checklistId, checklistItemId) {
      this.$store.dispatch("site/removeChecklistItem", {
        checklistId,
        checklistItemId,
      });
    },
    createChecklist(areaTypeId) {
      this.$store.dispatch("site/addChecklist", {
        type: "areaType",
        type_id: areaTypeId,
      });
    },
    addAreaType() {
      this.$store.dispatch("site/addAreaType", {
        name: this.addAreaTypeName,
      });
      this.addAreaTypeName = "";
    },
    addLocation(areaTypeId) {
      this.$store.dispatch("site/addAreaTypeLocation", {
        areaTypeId,
        name: "",
      });
    },
    updateLocationName(areaTypeId, locationId) {
      this.$store.dispatch("site/updateAreaTypeLocation", {
        areaTypeId,
        locationId,
        name: event.target.value,
      });
    },
    removeLocation(areaTypeId, locationId) {
      this.$store.dispatch("site/removeAreaTypeLocation", {
        areaTypeId,
        locationId,
      });
    },
  },
};
</script>
