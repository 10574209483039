<template>
  <div v-if="isLoaded" class="r-page-report">
    <ReportHeader />
    <template v-if="isReportWithDefects">
      <div class="r-page-edit-nav">
        <button
          v-if="isReportWithDefects"
          :class="{
            'is-active': currentNavItem === 'defects',
          }"
          class="r-page-edit-nav-item"
          @click="currentNavItem = 'defects'"
        >
          רשימת ממצאים
        </button>
        <button
          v-if="isReportWithLists"
          :class="{
            'is-active': currentNavItem === 'lists',
          }"
          class="r-page-edit-nav-item"
          @click="currentNavItem = 'lists'"
        >
          צ׳קליסטים וטבלאות
        </button>
        <button
          :class="{
            'is-active': currentNavItem === 'texts',
          }"
          class="r-page-edit-nav-item"
          @click="currentNavItem = 'texts'"
        >
          פרטים נוספים
        </button>
      </div>
      <div class="r-page-report-body">
        <div v-if="currentNavItem === 'defects'">
          <ReportDefects v-if="isReportWithDefects" />
        </div>
        <div v-if="currentNavItem === 'lists'">
          <ReportListsItems />
        </div>
        <div v-if="currentNavItem === 'texts'">
          <ReportTextsItems />
        </div>

        <ReportHelpAlert />
      </div>
    </template>
    <template v-else>
      <div class="r-page-report-body">
        <ReportItems />
      </div>
    </template>
  </div>
  <PageLoader v-else />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReportHeader from "./components/ReportHeader";
import ReportItems from "./components/ReportItems";
import ReportDefects from "./components/ReportDefects";
import ReportListsItems from "./components/ReportListsItems";
import ReportTextsItems from "./components/ReportTextsItems";
import ReportHelpAlert from "./components/ReportHelpAlert.vue";
import { Utils } from "@/common/Utils";
import PageLoader from "@/components/PageLoader.vue";
import store from "@/store";

export default {
  components: {
    ReportHelpAlert,
    ReportItems,
    ReportTextsItems,
    ReportListsItems,
    PageLoader,
    ReportDefects,
    ReportHeader,
  },

  async beforeRouteUpdate(to, from, next) {
    this.isLoaded = false;
    if (to.params.id !== undefined) {
      this.reportId = to.params.id;
      await this.initialize();
    }
    return next();
  },

  data() {
    return {
      currentNavItem: "texts",
      isLoaded: false,
      reportId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("report", ["report"]),
    ...mapGetters("reportQue", ["isSyncing", "isOffline"]),
    ...mapGetters("stocks", ["stocks"]),
    isReportWithLists() {
      let withLists = false;
      if (this.report.data.items) {
        for (const [, item] of Object.entries(this.report.data.items)) {
          if (item.type === "repeater" || item.type === "table") {
            withLists = true;
          }
        }
      }
      return withLists;
    },
    isReportWithDefects() {
      let withDefects = false;
      if (this.report.data.items) {
        for (const [, item] of Object.entries(this.report.data.items)) {
          if (
            item.type === "defectsTable" ||
            item.type === "miniDefectsTable"
          ) {
            withDefects = true;
          }
        }
      }
      return withDefects;
    },
    stock() {
      let stockFound = false;

      if (!this.report.stock_id) {
        return;
      }
      if (!this.stocks) {
        return;
      }

      this.stocks.forEach((stock) => {
        if (stock.id === this.report.stock_id) {
          stockFound = stock;
        }
      });

      return stockFound;
    },
  },
  async created() {
    await this.initialize();
    this.preloadAllImages();
  },

  beforeRouteLeave(to, from, next) {
    const onlineStatus = navigator.onLine;
    const isSyncing = store.getters["reportQue/isSyncing"];
    const fromStoreOnline = store.getters["reportQue/onlineStatus"];
    const messagesSyncQueue = store.getters["reportQue/messagesSyncQueue"];

    let warningMsg;
    if (!onlineStatus || !fromStoreOnline) {
      warningMsg =
        "רגע! שמנו לב שאתם במצב ללא קליטת אינטרנט. אם תעזבו את העמוד הזה תצטרכו חיבור אינטרנט כדי לחזור. בטוחים שתרצו לעזוב את העמוד?";
    } else if (isSyncing || messagesSyncQueue.length) {
      warningMsg =
        "רגע! שמנו לב שלא הסתנכרנו כל השינויים עדיין. תרצו להמתין לסנכרון השינויים באופן מלא?";
    }

    if (warningMsg) {
      const answer = window.confirm(warningMsg);
      next(!!answer);
    } else {
      next();
    }
  },

  methods: {
    ...mapActions("report", ["doUpdate", "doUpdateMany"]),
    async initialize() {
      await this.$store.dispatch("report/getReport", {
        reportId: this.reportId,
      });

      if (this.isReportWithLists) {
        this.currentNavItem = "lists";
      }

      if (this.isReportWithDefects) {
        await this.$store.dispatch("stocks/fetchStocks");
        this.currentNavItem = "defects";
      }

      this.isLoaded = true;

      if (this.$checkRole(["admin", "owner"])) {
        await this.$store.dispatch("users/fetchUsers");
      }
      await this.$store.dispatch("preferences/fetchPreferences");
      await this.$store.dispatch("projects/fetchProjects");
    },
    preloadAllImages() {
      let objFlatten = Utils.flattenObject({
        // ...this.report,
        ...this.stock,
      });

      for (const [, value] of Object.entries(objFlatten)) {
        if (typeof value === "string" && value.includes("images/")) {
          let img = new Image();
          img.src = this.$Reporto.globals.MEDIA_URL + value;
        }
      }
    },
  },
};
</script>
