<template>
  <RAlert
    v-if="
      $checkRole(['owner']) &&
      !company.doneDemoMeeting &&
      !getUserCache() &&
      company.isTrial
    "
    type="info"
    dismissible
    @dismiss="alertClicked"
  >
    <h3 class="mb-2 mt-2">
      <img width="30" src="@/assets/images/icon-zoom.svg" alt="logo" />
      שיחה אישית אחד-על-אחד
    </h3>
    <div class="text--primary">
      היי {{ displayFirstName }} 👋
      <br />
      שמנו לב שעדיין לא דיברנו איתך בזום ורצינו להציג לך את המערכת, להסביר טוב
      יותר ולהתאים אותה במיוחד עבורך.
      <br />
      אנחנו מציעים לקבוע איתנו שיחת קצרה בזום שבה נתאים את המערכת בדיוק לצרכים
      שלך ונענה על כל השאלות באופן אישי.
      <br />
      <br />
      <div class="mb-1 body-2">
        לוחצים על הכפתור למטה כדי
        <strong>לבחור שעה ותאריך שמתאימים לך.</strong>
      </div>
    </div>

    <RButton
      target="_blank"
      :href="demoMeetingUrl"
      color="blue"
      icon-before="mdi-calendar-clock"
      @click="UserClickedScheduleDemoButton"
    >
      תיאום שיחה בזום
    </RButton>
  </RAlert>
</template>
<script>
import { trackEvent } from "@/services/TrackingService";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["displayFirstName", "company", "demoMeetingUrl"]),
  },
  methods: {
    trackEvent,
    alertClicked() {
      trackEvent("Button Clicked", {
        "Button Name": "Schedule Demo Dismiss",
      });
      this.addToUserCache();
    },
    getUserCache() {
      return window.localStorage.getItem("cache/hasSeenDemoAlert");
    },
    addToUserCache() {
      window.localStorage.setItem("cache/hasSeenDemoAlert", true);
    },
    UserClickedScheduleDemoButton() {
      trackEvent("Button Clicked", {
        "Button Name": "Schedule Demo",
      });
    },
  },
};
</script>
