<template>
  <div>
    <div class="app-field">
      <label class="app-field__label"> ריווח </label>
      <field-paddings v-model="padding" />
    </div>
  </div>
</template>

<script>
import { typography } from "@/common/settings";
import FieldPaddings from "../settings/FieldPaddings.vue";

export default {
  components: {
    FieldPaddings,
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    current: {
      type: Object,
      default: null,
    },
    template: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    elements: [
      {
        title: "Text",
        icon: "mdi-format-text",
        element: "textField",
      },
      {
        title: "Image",
        icon: "mdi-image",
        element: "imageField",
      },
    ],
    panel: [0],
    typography,
  }),
  computed: {
    padding: {
      get() {
        return this.current.style.padding;
      },
      set(padding) {
        this.updateCurrentStyle({ padding });
      },
    },
    border: {
      get() {
        return this.current.style.border;
      },
      set(border) {
        this.updateCurrentStyle({ border });
      },
    },
    borderColor: {
      get() {
        return this.current.style.border.color;
      },
      set(color) {
        this.updateCurrentStyle({
          border: { ...this.current.style.border, color },
        });
      },
    },
    backgroundColor: {
      get() {
        return this.current.style.backgroundColor;
      },
      set(backgroundColor) {
        this.updateCurrentStyle({ backgroundColor });
      },
    },
    backgroundImagePath: {
      get() {
        return this.current.style.backgroundImagePath;
      },
      set(backgroundImagePath) {
        this.updateCurrentStyle({ backgroundImagePath });
      },
    },
  },
  methods: {
    updateCurrentStyle(val) {
      this.$emit("update-current", {
        ...this.current,
        style: { ...this.current.style, ...val },
      });
    },
  },
};
</script>
