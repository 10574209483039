<template>
  <div>
    <ReportItemCard
      :label="label"
      :label-total="$ItemsUtils.getTotalValidItems(list)"
    >
      <template #actions>
        <RActions>
          <RAction
            icon="mdi-content-paste"
            text="הדבקת תמונות"
            @click="isPasteModalOpen = true"
          />
        </RActions>
      </template>

      <template #default>
        <div class="report-item-card__body pt-0 pb-0 px-0">
          <draggable
            :list="list"
            v-bind="dragOptions"
            tag="div"
            handle=".sort-handler"
            item-key="id"
            class="app-field--images__list"
            @change="onDragChange"
          >
            <template #item="{ element: image }">
              <div
                class="app-field--images__image"
                :class="{
                  'is-hidden': image.deletedAt,
                }"
              >
                <div
                  class="sort-handler d-flex align-center ml-1 app-field--images__image-drag"
                  style="cursor: grab"
                >
                  <RIcon>mdi-drag</RIcon>
                </div>

                <span
                  v-if="!editImageMode"
                  class="ml-3"
                  style="cursor: pointer"
                  @click="openPreviewModal(image.path)"
                >
                  <img
                    :src="getImageSrc(image.path)"
                    alt=""
                    class="attached-image"
                  />
                </span>

                <span
                  v-if="editImageMode"
                  class="ml-3"
                  style="cursor: pointer"
                  @click.stop="openEditImage(image)"
                >
                  <img
                    :src="getImageSrc(image.path)"
                    class="attached-image"
                    alt=""
                  />
                </span>

                <div class="app-field--images__image-details">
                  <input
                    v-model="image.title"
                    type="text"
                    class="app-field--images__image-input"
                    placeholder="כותרת/הערה לתמונה"
                    maxlength="300"
                    @input="updateImageTitle(image, $event)"
                  />
                </div>

                <div class="app-field--images__image-actions">
                  <RButtonIcon icon="mdi-delete" @click="removeImage(image)" />
                </div>
              </div>
            </template>
          </draggable>
        </div>

        <div class="report-item-card__body pt-0">
          <div class="d-flex align-center mt-4">
            <div
              class="ml-2 app-field--images-addMediaButtonWrapper with-camera-button"
            >
              <RButton
                sm
                icon-before="mdi-camera-outline"
                color="black"
                outlined
                >צילום</RButton
              >

              <input
                ref="camera"
                name="image"
                class="app-field--images-addMediaInput"
                type="file"
                accept="image/*"
                capture="camera"
                multiple
                @change="uploadImages"
              />
            </div>

            <div
              class="app-field--images-addMediaButtonWrapper ml-2 open-camera-button"
            >
              <RButton
                v-if="shouldSeeCameraButton"
                sm
                icon-before="mdi-camera-burst"
                color="black"
                outlined
                @click="openCameraModal"
                >צילום מרובה</RButton
              >
            </div>

            <div class="ml-2 app-field--images-addMediaButtonWrapper">
              <RButton
                sm
                icon-before="mdi-folder-outline"
                color="black"
                outlined
                >בחירה מהגלריה</RButton
              >
              <input
                ref="gallery"
                class="app-field--images-addMediaInput"
                type="file"
                accept="image/*,image/heic"
                multiple
                @change="uploadImages"
              />
            </div>
          </div>

          <v-dialog
            v-if="editImage.isEditImageModalOpen"
            v-model="editImage.isEditImageModalOpen"
            :max-width="700"
            :persistent="true"
          >
            <edit-image-modal
              :is-edit-image-modal-open="editImage.isEditImageModalOpen"
              :upload="editImage.upload"
              :upload-initial="editImage.uploadInitial"
              :close-edit-image="closeEditImage"
              :update-edit-image="updateEditImage"
              :keep-base64="true"
            />
          </v-dialog>

          <RModal v-model="isPasteModalOpen" title="הדבקת תמונות">
            <div
              class="paste-container"
              @dragover.prevent
              @dragenter="dragEnter"
              @dragleave="dragLeave"
              @drop.prevent="dropFiles"
            >
              <div class="u-pointer-events-none">
                <RIcon>mdi-content-paste</RIcon>
                <div class="rp-h4 mt-2">גררו או הדביקו תמונות לכאן</div>
                <div class="rp-p2">
                  תמונות יודבקו כאן ויתווספו לרשימת התמונות.
                </div>
              </div>
              <RFieldText
                v-model="pasteInputValue"
                class="mt-3"
                style="background: #fff; width: 100%; max-width: 300px"
                placeholder="הדביקו תמונות כאן"
              />
            </div>
          </RModal>

          <RModal v-model="isPreviewImageModalOpen" title="תצוגת תמונה">
            <div>
              <img
                :src="getImageSrc(previewImageModalPath)"
                alt=""
                style="
                  max-width: 100%;
                  height: auto;
                  max-height: 90vh;
                  margin: 0 auto;
                  display: block;
                "
              />
            </div>
          </RModal>
        </div>
      </template>
    </ReportItemCard>

    <Teleport to="body">
      <div v-if="isCameraModalOpen" class="custom-camera-modal">
        <div class="camera-container">
          <RButton
            class="top-button"
            :color="currentSnapshot ? 'red' : 'grey'"
            lg
            :icon-before="currentSnapshot ? 'mdi-close' : 'mdi-arrow-right'"
            @click="currentSnapshot ? cancelSnapshot() : closeCameraModal()"
          >
            {{ currentSnapshot ? "ביטול תמונה" : "חזרה לדוח" }}
          </RButton>
          <video
            ref="videoElement"
            class="camera-preview"
            :class="{ hidden: currentSnapshot }"
            autoplay
            playsinline
          ></video>
          <img
            v-if="currentSnapshot"
            :src="currentSnapshot"
            class="camera-preview"
            alt="Current Snapshot"
          />
          <div
            class="overlay"
            @click="currentSnapshot ? saveSnapshot() : takeSnapshot()"
          >
            <div class="overlay-text">
              {{ currentSnapshot ? "לחץ לשמירה" : "צלם תמונה" }}
            </div>
          </div>
          <img
            v-if="previewSnapshot"
            :src="previewSnapshot"
            class="preview-snapshot"
            alt="Preview Snapshot"
          />
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import EditImageModal from "@/components/editImageModal.prev/EditImageModal";
// import {
//   allowedCompaniesForCameraButton,
// } from "@/common/settings";
import { mapActions } from "vuex";
import { convertFileIfHeicToJpg } from "@/common/Utils";
import { sendLog } from "@/services/LogService";
import ReportItemCard from "@/views/reports/components/ReportItemCard.vue";
import localService from "../../../services/LocalService";

export default {
  components: { ReportItemCard, draggable, EditImageModal },
  props: {
    loadList: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    label: {
      type: String,
      default: "",
    },
    updateKey: {
      type: String,
      default: null,
      required: false,
    },
    maxWidth: {
      type: [Number],
      default: 2000,
    },
    editImageMode: {
      type: Boolean,
      default: false,
    },
    imageUploadPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      previewImageModalPath: "",
      isPreviewImageModalOpen: false,
      list: [],
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      },
      editImage: {
        isEditImageModalOpen: false,
        upload: {},
      },
      pasteInputValue: null,
      isPasteModalOpen: false,
      imagesToEdit: [],
      isCameraModalOpen: false,
      currentSnapshot: null,
      previewSnapshot: null,
      stream: null,
    };
  },
  computed: {
    shouldSeeCameraButton() {
      // return allowedCompaniesForCameraButton.includes(
      //   this.$store.state.auth.user.currentCompanyId
      // );
      return true;
    },
    hasCustomDoUpdate() {
      return this.$attrs && this.$attrs['onDoUpdate'];
    },
    hasCustomDoUpdateMany() {
      return this.$attrs && this.$attrs['onDoUpdateMany'];
    },
    sortedList() {
      return [...this.list].sort((a, b) => a.order - b.order);
    },
  },
  watch: {
    isPasteModalOpen(val) {
      if (!val) {
        this.removePasteListener();
      } else {
        this.createPasteListener();
        this.pasteInputValue = "";
      }
    },
    isCameraModalOpen(newValue) {
      if (!newValue) {
        this.resetCameraModal();
      }
    },
  },
  created() {
    this.initImages();
  },
  beforeUnmount() {
    this.removePasteListener();
    this.resetCameraModal();
  },
  methods: {
    ...mapActions("report", ["doUpdate", "doUpdateMany"]),
    captureFrame(videoElement) {
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      canvas.getContext("2d").drawImage(videoElement, 0, 0);
      return canvas.toDataURL("image/jpeg");
    },
    dragEnter(event) {
      event.target.classList.add("hover");
    },
    dragLeave(event) {
      event.target.classList.remove("hover");
    },
    dropFiles(event) {
      event.target.classList.remove("hover");
      this.isPasteModalOpen = false;
      this.$refs.gallery.files = event.dataTransfer.files;
      this.$refs.gallery.dispatchEvent(new Event("change"));
    },
    createPasteListener() {
      document.addEventListener("paste", this.handlePaste);
    },
    removePasteListener() {
      document.removeEventListener("paste", this.handlePaste);
    },

    async handlePaste(evt) {
      const dT = evt.clipboardData || window.clipboardData;
      const files = Array.from(dT.files);
      if (files.length > 0) {
        this.isPasteModalOpen = false;
        const dataTransfer = new DataTransfer();
        files.forEach((file) => {
          dataTransfer.items.add(file);
        });
        this.$refs.gallery.files = dataTransfer.files;
        this.$refs.gallery.dispatchEvent(new Event("change"));
      }
    },
    openPreviewModal(imagePath) {
      this.isPreviewImageModalOpen = true;
      this.previewImageModalPath = imagePath;
    },
    initImages() {
      if (!this.loadList) {
        this.list = [];
      } else if (Array.isArray(this.loadList) && !this.loadList.length) {
        this.list = [];
      } else {
        this.list = Object.values(this.loadList);
      }

      let maxOrder = this.$Utils.findMaxOrderInObject(this.list);
      maxOrder++;

      // 1) add 'order' to missing rows.
      this.list.forEach((row) => {
        if (!row.order) {
          row.order = maxOrder;
          maxOrder++;
        }
      });

      // 2) sort the array
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
    getImageSrc(imagePath) {
      if (!imagePath) {
        return "";
      }
      if (imagePath.length > 50) {
        return imagePath;
      }
      return this.$Reporto.globals.MEDIA_URL + imagePath;
    },
    async removeImage(image) {
      if (confirm("בטוח למחוק?")) {
        image.deletedAt = this.$Utils.currentTimestamp();

        await this.localDoUpdate({
          key: `${this.updateKey}.${image.id}.deletedAt`,
          value: image.deletedAt,
        });

        this.$forceUpdate();
      }
    },
    async uploadImages(event) {
      try {
        const isSquare = localService.getItem(
          localService.keys.imageEditorSettings
        )?.isSquare;
        var filesSelected = event.target.files;
        this.imagesToEdit = [];

        if (filesSelected.length > 0) {
          for (let i = 0; i < filesSelected.length; i++) {
            let file = filesSelected.item(i);
            const convertedFile = await convertFileIfHeicToJpg(file);
            var reader = new FileReader();

            reader.onload = async (e) => {
              try {
                var image = new Image();
                image.onload = async () => {
                  try {
                    const resizedImage = this.resizeImage(image, this.maxWidth);
                    const croppedImage = this.cropImage(image, this.maxWidth);
                    const imageId = this.$Utils.randomString(8);
                    let maxOrder = this.$Utils.findMaxOrderInObject(this.list);
                    maxOrder++;

                    let imageObject = {
                      id: imageId,
                      initialPath: resizedImage,
                      path: isSquare ? croppedImage : resizedImage,
                      title: "",
                      order: maxOrder,
                    };

                    let data = {};
                    for (const [key, value] of Object.entries(imageObject)) {
                      data[`${this.updateKey}.${imageId}.${key}`] = value;
                    }

                    this.list = [...this.list, imageObject];
                    await this.localDoUpdateMany({ data });

                    if (filesSelected.length === 1) {
                      this.openEditImage(imageObject);
                    }

                    this.imagesToEdit.push(imageObject);
                  } catch (err) {
                    console.error("Error during image processing: ", err);
                    this.handleUploadError(err);
                  }
                };

                image.src = e.target.result;
              } catch (err) {
                console.error("Error reading file: ", err);
                this.handleUploadError(err);
              }
            };

            reader.onerror = (err) => {
              console.error("FileReader error: ", err);
              this.handleUploadError(err);
            };

            reader.readAsDataURL(convertedFile);
          }
        }
      } catch (err) {
        console.error("Error during upload process: ", err);
        this.handleUploadError(err);
      }
    },

    cropImage(image, maxSize) {
      const canvas = document.createElement("canvas");
      let width = image.width;
      let height = image.height;

      // Create the canvas context
      const ctx = canvas.getContext("2d");

      // Determine the size of the square (smaller side of the image)
      const squareSize = Math.min(width, height);

      // Calculate the top-left coordinates to crop the image from the center
      const cropX = (width - squareSize) / 2;
      const cropY = (height - squareSize) / 2;

      // Set canvas dimensions to squareSize
      canvas.width = squareSize;
      canvas.height = squareSize;

      // Draw the cropped image onto the canvas
      ctx.drawImage(
        image,
        cropX,
        cropY,
        squareSize,
        squareSize,
        0,
        0,
        squareSize,
        squareSize
      );

      // Now resize to the maxSize if needed
      if (squareSize > maxSize) {
        // Resize to maxSize while maintaining square aspect ratio
        canvas.width = maxSize;
        canvas.height = maxSize;
        ctx.drawImage(
          image,
          cropX,
          cropY,
          squareSize,
          squareSize,
          0,
          0,
          maxSize,
          maxSize
        );
      }

      return canvas.toDataURL("image/jpeg", 0.9);
    },

    resizeImage(image, maxSize) {
      // Create a canvas element for resizing the image
      const canvas = document.createElement("canvas");
      let width = image.width;
      let height = image.height;

      // Create the canvas context
      const ctx = canvas.getContext("2d");

      // Maintain aspect ratio
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      // Set canvas dimensions for resized image
      canvas.width = width;
      canvas.height = height;

      // Draw the image onto the canvas
      ctx.drawImage(image, 0, 0, width, height);

      // Return the resized image as a Base64 string
      return canvas.toDataURL("image/jpeg", 0.9);
    },

    handleUploadError(err) {
      // Notify the user with a fallback (could use a toast or modal)
      this.$toast.error("הייתה שגיאה בהעאת התמונה, נסה שוב.");
      sendLog("[Report Images] - Upload error: ", { err });
    },

    openNextEditImage() {
      if (this.imagesToEdit.length > 0) {
        this.openEditImage(this.imagesToEdit.shift());
      }
    },
    openEditImage(upload) {
      if (!this.editImageMode) return;
      this.editImage.upload = upload;
      this.editImage.isEditImageModalOpen = true;
    },
    closeEditImage() {
      this.editImage.isEditImageModalOpen = false;
      // this.$nextTick(() => {
      //   this.openNextEditImage();
      // });
    },
    async updateImageTitle(image, $event) {
      this.list.forEach((imageInner) => {
        if (imageInner.id === image.id) {
          imageInner.title = $event.target.value;
        }
      });

      await this.localDoUpdate({
        key: `${this.updateKey}.${image.id}.title`,
        value: $event.target.value,
      });
    },
    async updateEditImage(new_path) {
      this.list = this.list.map(image => {
        if (image.id === this.editImage.upload.id) {
          return { ...image, path: new_path };
        }
        return image;
      });

      await this.localDoUpdate({
        key: `${this.updateKey}.${this.editImage.upload.id}.path`,
        value: new_path,
      });
    },
    async onDragChange() {
      this.list = this.list.map((item, index) => {
        const newSort = index + 1;
        if (item.order !== newSort) {
          return { ...item, order: newSort };
        }
        return item;
      });

      let data = {};
      for (const image of this.list) {
        data[`${this.updateKey}.${image.id}.order`] = image.order;
      }
      await this.localDoUpdateMany({ data });
    },
    async localDoUpdate({ key, value }) {
      if (this.hasCustomDoUpdate) {
        this.$emit('doUpdate', {
          key: key,
          value: value,
        });
      } else {
        await this.doUpdate({
          key: key,
          value: value,
        });
      }
    },
    async localDoUpdateMany({ data }) {
      if (this.hasCustomDoUpdateMany) {
        this.$emit('doUpdateMany', { data });
      } else {
        await this.doUpdateMany({ data });
      }
    },
    openCameraModal() {
      this.resetCameraModal();
      this.isCameraModalOpen = true;
      this.$nextTick(() => {
        this.startCamera();
      });
    },

    closeCameraModal() {
      this.isCameraModalOpen = false;
      this.resetCameraModal();
    },

    resetCameraModal() {
      this.currentSnapshot = null;
      this.previewSnapshot = null;
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop());
        this.stream = null;
      }
    },

    cancelSnapshot() {
      this.currentSnapshot = null;
      // Ensure the video stream is visible again
      if (this.$refs.videoElement) {
        this.$refs.videoElement.classList.remove("hidden");
      }
    },

    async startCamera() {
      try {
        await this.getCameraStream();
      } catch (error) {
        console.error("Error accessing camera:", error);
        this.isCameraModalOpen = false;
        this.$toast.error(
          "שגיאה: לא ניתן לגשת למצלמה"
        ); 
      }
    },

    async getCameraStream() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      // Try to find the back camera
      let backCamera = videoDevices.find(
        (device) =>
          device.label.toLowerCase().includes("back") ||
          device.label.toLowerCase().includes("rear") ||
          device.label.toLowerCase().includes("environment")
      );
      // if !backCamera look for camera with word 'iPhone'
      if (!backCamera) {
        const iPhoneCamera = videoDevices.find((device) =>
          device.label.toLowerCase().includes("iphone")
        );
        if (iPhoneCamera) {
          backCamera = iPhoneCamera;
        }
      }

      const constraints = {
        video: {
          facingMode: "environment", // Prefer back camera
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
      };

      // If a specific back camera is found, use its deviceId
      if (backCamera) {
        constraints.video.deviceId = { exact: backCamera.deviceId };
      }

      // For iOS devices, we need to use the 'environment' facingMode
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        delete constraints.video.deviceId;
        constraints.video.facingMode = "environment";
      }

      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.$refs.videoElement.srcObject = this.stream;

      // Remove the transform for Android devices as it's not needed for back camera
      if (/Android/.test(navigator.userAgent)) {
        this.$refs.videoElement.style.transform = "";
      }
    },
    takeSnapshot() {
      this.currentSnapshot = this.captureFrame(this.$refs.videoElement);
    },

    async saveSnapshot() {
      try {
        const imageId = this.$Utils.randomString(8);
        let maxOrder = this.$Utils.findMaxOrderInObject(this.list);
        maxOrder++;

        let imageObject = {
          id: imageId,
          initialPath: this.currentSnapshot,
          path: this.currentSnapshot,
          title: "",
          order: maxOrder,
        };

        let data = {};
        for (const [key, value] of Object.entries(imageObject)) {
          data[`${this.updateKey}.${imageId}.${key}`] = value;
        }

        this.list = [...this.list, imageObject];
        await this.localDoUpdateMany({ data });

        // Show the preview snapshot
        this.previewSnapshot = this.currentSnapshot;
        this.currentSnapshot = null; // Reset currentSnapshot to show camera view

        // Save the image to local disk
        // this.saveImageOnDisk(imageObject);

        // Remove the preview snapshot after a few seconds
        setTimeout(() => {
          this.previewSnapshot = null;
        }, 2000);
      } catch (error) {
        console.error("Error saving snapshot:", error);
        this.$toast.error("שגיאה בשמירת התמונה. אנא נסה שוב.");
      }
    },
    saveImageOnDisk(imageObject) {
      const report = this.$store.state.report.report;
      const address = report.address || "Unknown Address";

      const now = new Date();
      const date = now.toISOString().split("T")[0]; // YYYY-MM-DD
      const time = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-mm-ss

      const filename = `${address}_${date}_${time}.jpg`;

      const element = document.createElement("a");
      element.setAttribute("href", imageObject.path);
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
};
</script>

<style lang="scss" scoped>
.paste-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border: 2px dashed #ddd;
  background: #fafbff;
  padding: 30px 15px;
  border-radius: 6px;
  margin: 15px;
  &.hover {
    border-style: solid;
    border-color: #5e21d2;
    background: #f2ebff;
  }
}

.open-camera-button {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.report-images-container {
  position: relative;
}

.custom-camera-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 10000; // Increase this value to ensure it's above other elements
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1001;
  //background-color: rgba(255, 255, 255, 0.7);
  //border: none;
  //padding: 10px 20px;
  //border-radius: 5px;
  //font-weight: 500;
  //font-size: 16px;
  //cursor: pointer;
}

.camera-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;

  &.hidden {
    display: none;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.overlay-text {
  color: white;
  font-size: 24px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.preview-snapshot {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 90%;
  max-height: 100px;
  z-index: 1002;
}
</style>
