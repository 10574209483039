<template>
  <div class="rp-body">
    <div
      class="rp-body__container rp-body__container--small"
      :style="containerStyle"
      :class="className"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: "",
    },
  },
  computed: {
    containerStyle() {
      let style = {};

      if (this.maxWidth) {
        style["--container-max-width"] = this.maxWidth;
      }
      return style;
    },
  },
};
</script>
