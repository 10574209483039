<template>
  <div class="app-field app-field--image">
    <label class="mb-2 d-block subtitle-2">{{ label }}</label>

    <div v-if="imagePath" class="d-flex align-start flex-column mt-3">
      <span
        style="max-height: 500px; overflow: auto"
        @click="isPreviewImageModalOpen = true"
      >
        <img :src="getImageUrl(imagePath)" class="preview-image" />
      </span>

      <RModal v-model="isPreviewImageModalOpen" size="sm" title="תצוגת תמונה">
        <img
          :src="getImageUrl(imagePath)"
          alt="image"
          style="width: 100%; height: auto"
        />
      </RModal>

      <RButton
        icon-before="mdi-delete"
        class="mt-3"
        color="red"
        outlined
        sm
        @click="removeImage"
      >
        מחיקת תמונה
      </RButton>
    </div>

    <div class="d-flex flex-wrap align-center mt-4">
      <div
        class="ml-2 app-field--image-addMediaButtonWrapper with-camera-button"
      >
        <RButton outlined color="primary" icon-before="mdi-camera-outline">
          צילום
        </RButton>

        <input
          ref="camera"
          class="app-field--image-addMediaInput"
          type="file"
          accept="image/*"
          capture=""
          @change="uploadImage"
        />
      </div>

      <div class="ml-2 app-field--image-addMediaButtonWrapper">
        <RButton
          outlined
          color="primary"
          icon-before="mdi-folder-multiple-outline"
        >
          בחירה מהגלריה
        </RButton>
        <input
          ref="gallery"
          class="app-field--image-addMediaInput"
          type="file"
          accept="image/*"
          multiple
          @change="uploadImage"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [String, Boolean],
      default: "",
    },
    keepBase64: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPreviewImageModalOpen: false,
      imagePath: "",
      canChange: false,
    };
  },
  computed: {
    media_url() {
      return this.$Reporto.globals.MEDIA_URL;
    },
  },
  watch: {
    imagePath(val) {
      if (this.canChange) {
        this.$emit("update:modelValue", val);
      }
    },
    modelValue(val) {
      this.imagePath = val;
    },
  },
  created() {
    this.imagePath = this.modelValue;
    setTimeout(() => {
      this.canChange = true;
    }, 10);
  },
  methods: {
    getImageUrl() {
      if (this.imagePath.length > 50) {
        return this.imagePath;
      }
      return this.media_url + this.imagePath;
    },
    removeImage() {
      if (confirm("בטוח למחוק?")) {
        this.imagePath = "";
      }
    },
    uploadImage(event) {
      if (!event.target.files || !event.target.files.length) {
        return;
      }

      let file = event.target.files[0];

      if (this.keepBase64) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imagePath = reader.result;
          this.$forceUpdate();
        };
      } else {
        let loader = this.$loading.show();
        this.$Utils.uploadImage(file).then(({ data }) => {
          this.imagePath = data.path;
          this.$forceUpdate();
          loader.hide();
        });
      }
    },
    onCameraUploadClick() {
      this.$refs.camera.click();
    },
    onGalleryUploadClick() {
      this.$refs.gallery.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-image {
  max-width: 100%;
  width: 240px;
  // height: 150px;
  background: #fff;
  padding: 5px;
  border-radius: 4px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app-field--image-addMediaButtonWrapper {
  position: relative;

  &.with-camera-button {
    @media (min-width: 1440px) {
      display: none;
    }
  }
}
.app-field--image {
  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
}
.app-field--image-addMediaInput {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
</style>
