<template>
  <div class="r-page-site-page-view">
    <div class="d-flex justify-space-between mb-5 mt-4">
      <div style="font-weight: 600; font-size: 18px">
        מפלס:
        {{ currentLevelName }}
      </div>
      <RButton color="grey" to="/sites/building/">חזרה לכל הקומות</RButton>
    </div>
    <div class="r-site-view-list">
      <router-link
        v-for="(area, areaIndex) in getLevelAreas(currentLevelId)"
        :key="areaIndex"
        :to="`/sites/area/${area.id}`"
        class="r-site-view-list-item"
      >
        <div class="r-site-view-list-item-content">
          <div class="r-site-view-list-item-title">{{ area.text }}</div>
          <div class="r-site-view-list-item-text"></div>
        </div>
      </router-link>

      <router-link
        :to="`/sites/level/${currentLevelId}/add-area/`"
        class="r-site-view-list-item"
        style="background-color: #fafafa"
      >
        <div class="r-site-view-list-item-content">
          <div class="r-site-view-list-item-title">+ הוספת אזור</div>
          <div class="r-site-view-list-item-text"></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentLevelId: this.$route.params.id,
    };
  },
  computed: {
    site() {
      return this.$store.state.site.site;
    },
    currentLevel() {
      return this.site.levels.find((l) => l.id === this.currentLevelId);
    },
    currentLevelName() {
      return this.currentLevel.text;
    },
  },
  methods: {
    getLevelAreas(levelId) {
      let areas = [];

      this.site.levels_areas.forEach((level_area) => {
        if (level_area.level_id === levelId) {
          const area = this.site.areas.find((a) => a.id === level_area.area_id);
          areas.push(area);
        }
      });

      return areas;
    },
  },
};
</script>
