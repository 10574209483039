import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from "vuetify";
import { he } from 'vuetify/locale'
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: 'he',
    fallback: 'he', 
    rtl: {
      he: true
    },
    messages: { he }
  },
  rtl: true,
  icons: {
    defaultSet: "mdi", // This replaces iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        colors: {
          // In Vuetify 3, colors need to be nested under 'colors'
          primary: "#5E21D2",
          secondary: "#A4AFB7",
          anchor: "#5E21D2",
          greyDark: "#485155",
          green: "#13855f",
          blue: "#1d58e3",
          purple: "#5E21D2",
        },
      },
    },
  },
});

export default vuetify;
