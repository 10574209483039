<template>
  <v-btn
    density="compact"
    variant="text"
    icon
    size="24"
    v-bind="$attrs"
  >
    <v-icon :size="18">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>
