async function uploadFile(file) {
  const formData = new FormData();
  formData.append("file", file);

  const response = await window.axios.post(`/api/v1/upload-file`, formData, {
    timeout: 90000,
  });
  return response.data;
}

export default {
  uploadFile,
};
