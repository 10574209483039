<template>
  <div class="app-field">
    <label v-if="label" class="app-field__label"> {{ label }} </label>
    <v-text-field
      v-model="inputText"
      density="compact"
      variant="outlined"
      style="background: #fff"
      hide-details
      v-bind="$attrs"
      @keypress="filter"
      class="custom-text-field"
      color="purple"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    onlyNumbers: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  data() {
    return {
      inputText: this.modelValue,
    };
  },
  watch: {
    inputText(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.inputText = val;
    },
  },
  created() {
    // this.inputText = this.value ? this.value : "";
  },
  methods: {
    filter: function (evt) {
      if (!this.onlyNumbers) {
        return true;
      }
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateParentValue(val) {
      this.inputText = val;
    },
  },
};
</script>

<style>
.custom-text-field .v-field--outlined.v-field--focused .v-field__outline__start,
.custom-text-field .v-field--outlined.v-field--focused .v-field__outline__end {
  background: red !important;
  border-color: #6200ea !important; /* Replace with your desired purple color */
}
</style>
