import { Utils } from "@/common/Utils";
import { reportMetaDataKeys } from "@/common/settings";

export const SET_REPORT = "SET_REPORT";
export const SET_SYNCING = "SET_SYNCING";
export const UPDATE_REPORT_DATA = "UPDATE_REPORT_DATA";
export const UPDATE_REPORT_STATE = "UPDATE_REPORT_STATE";

export default {
  [SET_REPORT](state, report) {
    state.report = report;
  },

  [SET_SYNCING](state, status) {
    state.isSyncing = status;
  },

  [UPDATE_REPORT_DATA](state, payload) {
    let dataFlat = Utils.flattenObject(state.report.data);
    let payloadDataFlat = Utils.flattenObject(payload.data);

    if (payloadDataFlat.user_id) {
      state.report.user_id = payloadDataFlat.user_id;
    }
    if (payloadDataFlat.preference_id) {
      state.report.preference_id = payloadDataFlat.preference_id;
    }
    if ("project_id" in payloadDataFlat) {
      state.report.project_id = payloadDataFlat.project_id;
    }

    delete payloadDataFlat.user_id;
    delete payloadDataFlat.preference_id;
    delete payloadDataFlat.project_id;

    reportMetaDataKeys.forEach((key) => {
      if (payloadDataFlat[key]) {
        state.report[key] = payloadDataFlat[key];
        delete payloadDataFlat[key];
      }
    });

    let newData = { ...dataFlat, ...payloadDataFlat };

    state.report.data = Utils.UnFlattenObject(newData);
  },

  [UPDATE_REPORT_STATE](state, payload) {
    try {

      let dataFlat = Utils.flattenObject(state.report.data);
      let payloadDataFlat = Utils.flattenObject(payload.data);

      if (payloadDataFlat.user_id) {
        state.report.user_id = payloadDataFlat.user_id;
      }
      if (payloadDataFlat.preference_id) {
        state.report.preference_id = payloadDataFlat.preference_id;
      }
      if ("project_id" in payloadDataFlat) {
        state.report.project_id = payloadDataFlat.project_id;
      }

      delete payloadDataFlat.user_id;
      delete payloadDataFlat.preference_id;
      delete payloadDataFlat.project_id;

      reportMetaDataKeys.forEach((key) => {
        if (key in payloadDataFlat) {
          state.report[key] = payloadDataFlat[key];
          delete payloadDataFlat[key];
        }
      });

      let newData = { ...dataFlat, ...payloadDataFlat };

      state.report.data = Utils.UnFlattenObject(newData);
    } catch (err) {
      console.error("Error in UPDATE_REPORT_STATE", err);
    }
  },
};
