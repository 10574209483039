<template>
  <div class="rp-report__items">
    <div
      v-for="item in items"
      :key="item.id"
      :class="{
        'rp-report__item': true,
        'u-hidden': !isEditable(item),
      }"
    >
      <template v-if="item.type === 'repeater'">
        <ReportRepeater :item="item" :update-key="`items.${item.id}`" />
      </template>

      <template v-if="item.type === 'table'">
        <ReportTable
          :label="item.settings.label"
          :load-rows="item.settings.rows"
          :columns="item.settings.columns"
          :update-key="`items.${item.id}.settings.rows`"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { dragOptions } from "@/common/settings";
import ReportRepeater from "@/views/reports/components/ReportRepeater";
import ReportTable from "@/views/reports/components/ReportTable";
import { ItemsUtils } from "@/common/Utils";

export default {
  components: {
    ReportRepeater,
    ReportTable,
  },
  data() {
    return {
      dragOptions,
      isLoaded: false,
      reportId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("report", ["report"]),
    items() {
      return Object.values(this.report.data.items).sort(
        (a, b) => a.order - b.order
      );
    },
  },
  methods: {
    ...mapActions("report", ["doUpdate", "doUpdateMany"]),
    isEditable(item) {
      let itemType = ItemsUtils.getItemType(item.type);
      return (
        !item.settings.hideFromReportEditor &&
        itemType.isEditableOnReport &&
        !item.settings.isDisabled
      );
    },
  },
};
</script>
