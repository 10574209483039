async function getFolders() {
  const response = await window.axios.get(`/api/v1/report-template-folders`);
  return response.data.data;
}

async function createReportTemplateFolder(folderName, parentFolderId = null) {
  const response = await window.axios.post(`/api/v1/report-template-folders`, {
    name: folderName,
    parent_id: parentFolderId,
  });
  return response.data;
}

async function updateReportTemplateFolder(folderId, data) {
  const response = await window.axios.put(
    `/api/v1/report-template-folders/${folderId}`,
    data
  );
  return response.data;
}

async function removeReportTemplateFolder(folderId) {
  const response = await window.axios.delete(
    `/api/v1/report-template-folders/${folderId}`
  );
  return response.data;
}

export default {
  getFolders,
  createReportTemplateFolder,
  updateReportTemplateFolder,
  removeReportTemplateFolder,
};
