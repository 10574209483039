<template>
  <div>
    <div class="pa-5">
      <draggable
        v-model="elements"
        draggable=".element-btn"
        class="elements-draggable-container"
        v-bind="dragOptions"
        :sort="false"
        itemKey="lalala"
        @end="initElements"
      >
        <template #item="{ element, index }">
          <create-field-btn
            :key="index"
            :title="elementsTitles[element.type]"
            :icon="elementsIcons[element.type]"
          />
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import { initializeElement } from "../editorHelpers";
import CreateFieldBtn from "./tools/columnTool/CreateFieldBtn.vue";
import draggable from "vuedraggable";

export default {
  components: {
    CreateFieldBtn,
    draggable,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    elementsTitles: {
      text: "תיבת טקסט",
      image: "תמונה",
    },
    elementsIcons: {
      text: "mdi-format-text",
      image: "mdi-image",
    },
    elements: [],
    dragOptions: {
      group: {
        name: "fields-group",
        pull: "clone",
        put: false,
      },
    },
  }),
  created() {
    this.initElements();
  },
  methods: {
    initElements() {
      this.elements = [
        initializeElement()["textField"],
        initializeElement()["imageField"],
      ];
    },
  },
};
</script>

<style lang="scss">
.elements-draggable-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
</style>
