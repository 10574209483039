import { sendLog } from "@/services/LogService";
import { forageService } from "@/services/ForageService";
import {
  ADD_MESSAGE_TO_SYNC_QUEUE,
  REMOVE_FIRST_MESSAGE,
  SET_MESSAGES_TO_SYNC_QUEUE,
  SET_OFFLINE,
  SET_SHOW_SYNC_SCREEN,
  SET_STORAGE_USED_IN_MB,
  SET_SYNCING_STATUS,
} from "./mutations";
import { UPDATE_REPORT_STATE } from "../report/mutations";
import { getLogData } from "../../../common/Utils";
// import { retryWithBackoff } from "../../../common/Utils";

export const sync = (context, ...args) => {
  if (context.rootGetters["reportQue/useOfflineSystemV2"])
    return syncV2(context, ...args);
  return syncV1(context, ...args);
};

// V2
export async function loadMessagesFromForage({ commit, rootState }) {
  const { messages, megaUsed } = await forageService.getMessagesFromLocalForage(
    rootState.auth
  );

  commit(SET_MESSAGES_TO_SYNC_QUEUE, messages);
  commit(SET_STORAGE_USED_IN_MB, megaUsed);
}

async function syncV2(
  { state, dispatch, commit },
  { showSyncScreen = false } = {}
) {
  if (!state.messagesSyncQueue.length) {
    commit(SET_SHOW_SYNC_SCREEN, false);
    commit(SET_SYNCING_STATUS, false);
    const data = await getLogData()
    console.log("[SyncEngine] SyncV2 - Finished", { data });
    return;
  }

  const messageToSend = state.messagesSyncQueue[0];
  if (!state.isSyncing && state.messagesSyncQueue.length > 1) {
    const data = await getLogData()
    sendLog("[SyncEngine] SyncV2 - Activated", { data });
  }

  commit(SET_SHOW_SYNC_SCREEN, showSyncScreen);
  commit(SET_SYNCING_STATUS, true);

  try {
    // Logging msg size to identify specificly big msgs
    const messageSizeInBytes = new TextEncoder().encode(JSON.stringify(messageToSend)).length;
    const messageSizeInMB = (messageSizeInBytes / Math.pow(2, 20)).toFixed(2);

    console.log(`[SyncEnging] Now syncing message. size: ${messageSizeInMB}`, { data: { messageSizeInMB } });
    await dispatch("report/updateReport", messageToSend, { root: true });
    dispatch("removeMessageFromQue");
    await dispatch("sync", { showSyncScreen });
  } catch (err) {
    commit(SET_SYNCING_STATUS, false);
    throw err;
  }
}

export async function addMessageToQue({ commit, state }, message) {
  commit(ADD_MESSAGE_TO_SYNC_QUEUE, message);
  const megaUsed = await forageService.saveMessagesQueueToLocalForage(
    state.messagesSyncQueue
  );
  commit(SET_STORAGE_USED_IN_MB, megaUsed);
}

export async function removeMessageFromQue({ commit, state }) {
  commit(REMOVE_FIRST_MESSAGE);
  const megaUsed = await forageService.saveMessagesQueueToLocalForage(
    state.messagesSyncQueue
  );
  commit(SET_STORAGE_USED_IN_MB, megaUsed);
}

// V1
async function syncV1({ state, dispatch, commit }) {
  // console.log("syncV1!!");

  if (!state.messagesSyncQueue.length) {
    commit(SET_SHOW_SYNC_SCREEN, false);
    commit(SET_SYNCING_STATUS, false);
    setTimeout(async () => {
      await dispatch("sync");
    }, 10);
  } else {
    commit(SET_SYNCING_STATUS, true);
    try {
      let messageToSend = state.messagesSyncQueue[0];
      messageToSend = await dispatch(
        "report/uploadReportImages",
        messageToSend,
        { root: true }
      );
      // messageToSend = await uploadMessageFiles(messageToSend);

      if (messageToSend.action === "do-update") {
        await window.axios.put(
          `/api/v1/reports/${messageToSend.dataset_id}/do-update`,
          messageToSend
        );
      }

      commit(REMOVE_FIRST_MESSAGE);
      forageService.saveMessagesQueueToLocalForage(state.messagesSyncQueue);
      await dispatch("sync");
      commit(SET_OFFLINE, false);
    } catch (e) {
      console.log("store:sync:error", e);
      commit(SET_OFFLINE, true);
      commit(SET_SYNCING_STATUS, false);
      setTimeout(async () => {
        await dispatch("sync");
      }, 6000);
    }
  }
}

export const sendMessage = ({ commit, dispatch, state }, message) => {
  dispatch("applyMessage", message);
  commit(ADD_MESSAGE_TO_SYNC_QUEUE, message);
  forageService.saveMessagesQueueToLocalForage(state.messagesSyncQueue);
};

export const applyMessage = ({ commit }, message) => {
  if (message.action === "do-update") {
    commit(
      "report/" + UPDATE_REPORT_STATE,
      { data: message.data },
      { root: true }
    );
  }
};

export const backgroundSync = async ({ dispatch }) => {
  await dispatch("sync");
  window.dispatch = dispatch;
};
