export const tools = {
  definitions: {
    title: "הגדרות כלליות",
    component: "definitions-tool",
  },
  header: {
    title: "האדר",
    part: "header",
    component: "paper-tool",
  },
  footer: {
    title: "חלק תחתון",
    part: "footer",
    component: "paper-tool",
  },
  section: {
    title: "אזור",
    component: "section-tool",
  },
  column: {
    title: "עמודה",
    component: "column-tool",
  },
  field: {
    title: "שדה",
    component: "field-tool",
  },
};
