<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class="r-page-table">
    <div class="r-page-table-header">
      <div class="r-page-table-header-content">
        <h3 class="r-page-table-header-title">
          {{ title }}
          <span v-if="totalReports">({{ totalReports }})</span>
        </h3>
      </div>

      <div class="r-page-table-header-actions">
        <RSearchCompact
          v-model="searchQuery"
          style="width: 330px"
          placeholder="חיפוש שם לקוח, מספר דוח, כתובת"
          :initial-open="true"
        />
        <div class="d-flex u-gap-2">
          <RButton
            color="grey-700"
            :icon-before="filtersIcon"
            outlined
            @click="isFiltersModalOpen = true"
          >
            {{ isMobile ? "" : "סינון" }}
          </RButton>
          <AddReportButton color="purple" />
        </div>
      </div>
    </div>

    <div class="r-page-table-body">
      <div v-if="isMobile && isLoaded && reportsSections" class="rm-list">
        <div v-for="item in reportsSections" :key="item.id">
          <div class="rm-group">
            <p>
              {{ item.label }}
              <span>({{ item.reports.length }})</span>
            </p>
          </div>

          <div v-for="report in item.reports" :key="report.id" class="rm-item">
            <router-link
              :to="{
                name: 'reports.edit',
                params: { id: report.id },
              }"
            >
              <div class="rm-item--header">
                <span class="date">
                  {{ $Utils.presentHebrewDate(report.created_at) }}
                </span>
                <span
                  class="number"
                  :class="{
                    draft: report.is_draft,
                  }"
                >
                  <template v-if="report.is_draft" class="mr-2"
                    >טיוטה /
                  </template>
                  {{ report.number }}
                </span>
              </div>
              <div class="rm-item--body">
                <div class="rm-item--body--right">
                  <p class="address">{{ report.address }}</p>
                  <p class="customer">{{ report.customerName }}</p>
                </div>

                <div class="rm-item--body--left">
                  <p>{{ report.user_name }}</p>
                  <RChip v-if="report.tag" class="mt-1" color="grey">{{
                    report.tag
                  }}</RChip>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <v-table v-if="!isMobile && isLoaded && reportsSections">
        <thead>
          <tr>
            <th style="width: 70px">מספר</th>
            <th style="width: 200px">כתובת</th>
            <th style="width: 200px">לקוח</th>
            <th style="width: 120px">משתמש</th>
            <th style="width: 200px">תבנית</th>
            <th style="width: 140px">תווית</th>
            <th style="width: 140px">פרויקט</th>
            <th style="width: 200px">תאריך יצירה</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in reportsSections" :key="item.id">
            <tr class="date-row">
              <td colspan="9">
                {{ item.label }}
                <span>({{ item.reports.length }})</span>
              </td>
            </tr>
            <tr v-for="report in item.reports" :key="report.id">
              <td>
                <router-link
                  :to="{
                    name: 'reports.edit',
                    params: { id: report.id },
                  }"
                  style="
                    color: var(--color-gray-700) !important;
                    text-decoration: underline;
                    text-underline-position: under;
                  "
                  >{{ report.number }}</router-link
                >
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'reports.edit',
                    params: { id: report.id },
                  }"
                  style="
                    color: var(--color-gray-700) !important;
                    text-decoration: underline;
                    text-underline-position: under;
                  "
                  >{{ report.address }}</router-link
                ><span v-if="report.is_draft" class="rm-item--chip mr-2"
                  >טיוטה</span
                >
              </td>
              <td>
                {{ report.customerName }}
              </td>
              <td>
                {{ report.user_name }}
              </td>
              <td>
                {{ report.name }}
              </td>
              <td>
                <RChip v-if="report.tag" color="grey">{{ report.tag }}</RChip>
              </td>
              <td>
                <template v-if="report.project_name">{{
                  report.project_name
                }}</template>
              </td>
              <td>
                {{ $Utils.presentHebrewDate(report.created_at) }}
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>

      <div v-if="isLoadingReports || !isLoaded" class="reports-loader">
        <RIconLoading size="20" />
        <div class="subtitle-1">טוענים דוחות ...</div>
      </div>
    </div>

    <RModal v-model="isFiltersModalOpen" title="סינון דוחות">
      <RFields>
        <RFieldText v-model="filterReportTag" label="תווית" />
        <RFieldSelect
          v-if="filterByUser"
          v-model="filterReportUserId"
          :clearable="true"
          :items="usersOptions"
          label="משתמש"
        />
        <RFieldSelect
          v-model="filterReportTemplateId"
          :clearable="true"
          :items="reportTemplatesOptions"
          label="תבנית"
        />
        <RButton
          color="primary"
          block
          lg
          type="submit"
          @click="isFiltersModalOpen = false"
        >
          סגירה
        </RButton>
      </RFields>
    </RModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddReportButton from "@/components/AddReportButton";

export default {
  components: { AddReportButton },
  props: {
    title: {
      type: String,
      default: null,
    },
    reportsServiceFetch: {
      type: Function,
      required: true,
    },
    filterByUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFiltersModalOpen: false,
      filterReportUserId: null,
      filterReportTemplateId: null,
      filterReportTag: null,
      isLoaded: false,
      isFiltersOpen: false,
      isLoadingReports: false,
      reports: [],
      totalReports: 0,
      page: 1,
      lastPage: 1,
      searchQuery: "",
      awaitingSearch: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["company"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters("reportTemplates", ["reportTemplates"]),
    isMobile() {
      return window.innerWidth < 768;
    },
    filtersIcon() {
      let total = 0;
      if (this.filterReportUserId) {
        total++;
      }
      if (this.filterReportTemplateId) {
        total++;
      }
      if (this.filterReportTag) {
        total++;
      }

      if (total === 1) {
        return "mdi-numeric-1";
      }
      if (total === 2) {
        return "mdi-numeric-2";
      }
      if (total === 3) {
        return "mdi-numeric-3";
      }

      return "mdi-filter";
    },
    usersOptions() {
      return this.users.map((user) => {
        return {
          value: user.id,
          text: user.name,
        };
      });
    },
    reportTemplatesOptions() {
      return this.reportTemplates.map((reportTemplate) => {
        return {
          value: reportTemplate.id,
          text: reportTemplate.name,
        };
      });
    },
    reportsSections() {
      if (!this.reports) {
        return false;
      }

      let reportsToOrganize = [...this.reports];

      let reportsSections = {};

      if (!reportsToOrganize.length) {
        return reportsSections;
      }

      reportsSections = {};

      reportsToOrganize.forEach((report) => {
        let reportDate = this.getReportDate(report);

        // isToday
        // if (this.isToday(reportDate.dateObject)) {
        //     reportsSections.today.reports.push(report);
        // }

        // isYesterday
        // if (this.isYesterday(reportDate.dateObject)) {
        //     reportsSections.yesterday.reports.push(report);
        // }

        // isThisWeek
        // if (this.isThisWeek(reportDate.dateObject)) {
        //     reportsSections.thisWeek.reports.push(report);
        // }

        let date_year_and_month = reportDate.year + "-" + reportDate.month;

        if (!reportsSections[date_year_and_month]) {
          reportsSections[date_year_and_month] = {
            label: this.convertMonthAndYearToString(date_year_and_month),
            reports: [],
          };
        }
        reportsSections[date_year_and_month].reports.push(report);
      });

      return reportsSections;
    },
  },
  watch: {
    searchQuery() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.resetFetchReports();
          this.awaitingSearch = false;
        }, 600);
      }
      this.awaitingSearch = true;
    },
    filterReportTag() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.resetFetchReports();
          this.awaitingSearch = false;
        }, 600);
      }
      this.awaitingSearch = true;
    },
    filterReportTemplateId() {
      this.resetFetchReports();
    },
    filterReportUserId() {
      this.resetFetchReports();
    },
  },
  async created() {
    await this.$store.dispatch("reportTemplates/fetchReportTemplates");
    if (this.$checkRole(["admin", "owner"])) {
      await this.$store.dispatch("users/fetchUsers");
    }
    await this.fetchReports();
    this.isLoaded = true;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.onScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const endOfPage =
        window.innerHeight + window.pageYOffset + 40 >=
        document.body.offsetHeight;

      if (endOfPage) {
        this.fetchReports();
      }
    },
    resetFetchReports() {
      this.reports = [];
      this.lastPage = 1;
      this.page = 1;
      this.fetchReports();
    },
    // add leading zero
    pad(n, width, z) {
      z = z || "0";
      n = n + "";
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n;
    },

    getReportDate(report) {
      let dateObject = new Date(report.created_at);
      return {
        day: this.pad(dateObject.getDate(), 2),
        month: this.pad(dateObject.getMonth() + 1, 2),
        year: dateObject.getFullYear(),
        dateObject: dateObject,
      };
    },
    getReportDayAndMonthString(report) {
      return (
        this.getReportDate(report).day + "." + this.getReportDate(report).month
      );
    },
    getReportDateDayName(report) {
      return this.convertDayNumberToName(
        this.getReportDate(report).dateObject.getDay()
      );
    },
    getReportDateMonthName(report) {
      return this.convertMonthNumberToName(this.getReportDate(report).month);
    },
    convertMonthAndYearToString(month_and_year) {
      var event = new Date(month_and_year);
      var options = { year: "numeric", month: "long" };
      return event.toLocaleDateString("he-IL", options);
    },
    convertMonthNumberToName(monthNumber) {
      var event = new Date(monthNumber);
      var options = { month: "long" };
      return event.toLocaleDateString("he-IL", options);
    },
    convertDayNumberToName(dayNumber) {
      let hebrewDays = [
        "ראשון",
        "שני",
        "שלישי",
        "רביעי",
        "חמישי",
        "שישי",
        "שבת",
      ];
      return hebrewDays[dayNumber];
    },
    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    },
    isYesterday(someDate) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return (
        someDate.getDate() === yesterday.getDate() &&
        someDate.getMonth() === yesterday.getMonth() &&
        someDate.getFullYear() === yesterday.getFullYear()
      );
    },
    isThisWeek(date) {
      const todayObj = new Date();
      const todayDate = todayObj.getDate();
      const todayDay = todayObj.getDay();

      // get first date of week
      const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

      // get last date of week
      const lastDayOfWeek = new Date(firstDayOfWeek);
      lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

      // reset all date times to 0
      todayObj.setHours(0, 0, 0, 0);
      firstDayOfWeek.setHours(0, 0, 0, 0);
      lastDayOfWeek.setHours(0, 0, 0, 0);

      // if date is equal or within the first and last dates of the week
      return date >= firstDayOfWeek && date <= lastDayOfWeek;
    },
    async fetchReports() {
      if (this.isLoadingReports) {
        return;
      }
      if (this.page > this.lastPage) {
        return;
      }

      this.isLoadingReports = true;
      const { data } = await this.reportsServiceFetch({
        page: this.page,
        searchQuery: this.searchQuery,
        userId: this.filterReportUserId,
        reportTemplateId: this.filterReportTemplateId,
        reportTag: this.filterReportTag,
      });
      this.reports.push(...data.data);
      this.isLoadingReports = false;

      this.lastPage = data.meta.last_page;
      this.totalReports = data.meta.total;
      this.page++;
    },
  },
};
</script>

<style lang="scss">
.reports-loader {
  background: #fff;
  padding: 10px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  flex-direction: column;
  @media (max-width: 767px) {
    border-right: 0;
    border-left: 0;
  }

  &__text {
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
  }
}

.r-page-table .v-table tbody tr.date-row > td {
  font-size: 1rem;
  font-family: var(--font-family-noto);
  font-weight: 500;
  height: 52px !important;
  span {
    font-size: 0.875rem;
    font-family: var(--font-family-noto);
  }
}

.rm-list {
  padding-bottom: 8rem;
}

.rm-group {
  padding: 1rem;
  font-size: 18px;
  font-family: var(--font-family-noto);
  font-weight: 700;
  color: var(--color-gray-700) !important;

  p {
    margin-bottom: 0 !important;
  }

  span {
    font-size: 14px;
    font-family: var(--font-family-noto);
  }
}

.rm-item {
  margin: 0 1rem;
  background: white;
  border: 1px solid var(--color-gray-300);
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 0.75rem;
  margin-bottom: 1rem;

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
    font-family: var(--font-family-noto);
    color: var(--color-gray-700) !important;
    margin-bottom: 0.75rem;

    .number {
      font-family: var(--font-family-noto) !important;
      color: var(--color-brand-600) !important;
      background: var(--color-brand-100) !important;
      padding: 0.1rem 0.2rem;
      border-radius: 0.25rem;
      font-weight: 600;
      &.draft {
        background: var(--color-gray-100) !important;
        color: var(--color-gray-600) !important;
      }
    }
  }

  &--body {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    font-size: 0.75rem;
    font-family: var(--font-family-noto);
    color: var(--color-gray-700) !important;
    p {
      margin-bottom: 0;
    }

    &--left {
      text-align: left;
      font-family: var(--font-family-noto);
      font-weight: 700;
      flex-basis: 40%;
      p {
        margin-top: 0.2rem;
      }
    }

    &--right {
      flex-basis: 60%;
      .address {
        font-family: var(--font-family-noto);
        font-weight: 700;
        font-size: 1rem !important;
      }
      .customer {
        font-size: 0.875rem !important;
        color: var(--color-gray-400) !important;
      }
    }
  }
}
</style>
