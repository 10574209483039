<template>
    <v-overlay
      class="d-flex align-center justify-center"
      :model-value="shouldShowSyncScreen"
      persistent
      :opacity="0.89"
      color="#190244"
    >
      <div style="text-align: center; color: #fff">
        <div
          class="mb-1"
          style="
            background: #84e7cc;
            border-radius: 6px;
            display: inline-block;
            padding: 3px 10px;
            color: #055640;
          "
        >
          שינויים שנותרו לסנכרון:
          {{ $store.state.reportQue.messagesSyncQueue.length }}
        </div>
        <br />
        <div
          v-if="totalMessageImagesToSync"
          style="
            background: rgba(255, 255, 255, 0.1);
            border-radius: 6px;
            display: inline-block;
            padding: 3px 10px;
            margin-top: 10px;
            margin-bottom: 10px;
          "
        >
          מסנכרנים
          {{ totalMessageImagesSynced }}
          מתוך
          {{ totalMessageImagesToSync }}
          תמונות
        </div>
        <br />
        <v-icon class="mb-2" :class="{ spin: onlineStatus }">mdi-sync</v-icon>
        <br />
        {{
          onlineStatus
            ? "מסנכרנים את השינויים האחרונים שלכם."
            : "זוהו בעיות בחיבור האינטרנט"
        }}
        <br />
        {{
          onlineStatus
            ? "אנא המתינו...."
            : "אנא בדקו את החיבור לאינטרנט ונסו שוב"
        }}
      </div>
    </v-overlay>
    <v-overlay
      class="d-flex align-center justify-center"
      :model-value="isStorageLimitReached"
      persistent
      :opacity="0.9"
      color="#190244"
    >
      <div style="text-align: center">
        <RIcon :class="{ spin: isSyncing }" class="mb-2">mdi-sync</RIcon>
        <br />
        נגמר המקום במכשיר.
        <br />
        חזרו למצב עם קליטה וסנכרנו את הנתונים
        <br />
        {{ storageUsedInMb }}MB בשימוש
      </div>
    </v-overlay>
</template>

<script>
import monitorConnection from "../mixins/monitorConnection";
import { sendLog } from "@/services/LogService";
import * as localForage from "localforage";
import { mapGetters } from "vuex";

export default {
  mixins: [monitorConnection],

  computed: {
    ...mapGetters("auth", ["user", "company", "isLoggedIn"]),
    ...mapGetters("reportQue", [
      "isStorageLimitReached",
      "storageUsedInMb",
      "isSyncing",
      "totalMessageImagesSynced",
      "totalMessageImagesToSync",
      "showSyncScreen",
    ]),
    shouldShowSyncScreen() {
      return this.showSyncScreen;
    },
  },

  watch: {
    isStorageAlmostFull(val) {
      if (val) {
        sendLog(
          "[Storage] Storage limit almost full , used: " +
            this.storageUsedInMb +
            "MB"
        );
      }
    },
    async isStorageLimitReached(val) {
      if (val) {
        // messages from all companies and users
        let messages_log = [];

        let value = await localForage.getItem(
          "vuex.reportQue.messagesSyncQueue"
        );

        // if value is not array
        if (!Array.isArray(value)) {
          value = JSON.parse(value);
        }

        if (value) {
          value.forEach((message) => {
            let messageSizeInBytes = new TextEncoder().encode(
              JSON.stringify(message)
            ).length;
            const messageSizeInKB = messageSizeInBytes / 1024;
            const messageSizeInMB = messageSizeInKB / 1024;

            messages_log.push({
              company_id: message.company_id,
              report_id: message.dataset_id,
              timestamp: message.timestamp,
              user_id: message.user_id,
              message_size_in_mb: messageSizeInMB,
            });
          });
        }
        sendLog("[Storage] storage limit reached!", {
          data: messages_log,
        });
      }
    },
  },
};
</script>
