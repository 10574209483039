<template>
  <label class="rp-report__item-label">
    {{ label }}
    <template v-if="total"> ({{ total }}) </template>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: null,
    },
    total: {
      type: [Number],
      default: null,
    },
  },
};
</script>
