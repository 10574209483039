import { toolType } from "./toolType";
import { isIPhone, isMobile } from "@/common/helpers/platform";

// Simple EventEmitter implementation
class SimpleEventEmitter {
  constructor() {
    this.events = new Map();
  }

  on(event, callback) {
    if (!this.events.has(event)) {
      this.events.set(event, []);
    }
    this.events.get(event).push(callback);
  }

  off(event, callback) {
    if (!this.events.has(event)) return;
    if (!callback) {
      this.events.delete(event);
      return;
    }
    const callbacks = this.events.get(event).filter(cb => cb !== callback);
    this.events.set(event, callbacks);
  }

  emit(event, data) {
    if (!this.events.has(event)) return;
    this.events.get(event).forEach(callback => callback(data));
  }
}

export class CanvasEditor extends SimpleEventEmitter {
  constructor({
    canvasId,
    image,
    imageUrl,
    initialImageUrl,
    width,
    height,
    canvasElementSize,
  }) {
    super();

    const multiplier = width / canvasElementSize;

    let initialImage;
    let initialized = false;
    let bgImage;
    let canvas = null;
    let context = null;

    let drawWidth;
    let drawHeight;
    let drawX = 0;
    let drawY = 0;
    let tool = null;
    let color = null;
    let fontSize = 14;
    let fontWeight = 400;

    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let startCursor = { x: 0, y: 0 };
    let scale = 1;

    let touchDistance = 0;

    let isDrawing = false;
    let arrowOrigin = { x: 0, y: 0 };
    let history = [];

    let textInitColor;
    const radius = 6 * multiplier;
    const lineWidth = 1.5 * multiplier;
    const zoomSpeed = 6 * multiplier;
    const touchZoomSpeed = 4 * multiplier;
    const canvasWrapperId = "#canvas-wrapper";
    const canvasWrapper = document.querySelector(canvasWrapperId);
    const textToolInputId = "text-tool-input";
    let textToolInput = null;

    // public props
    this.initialText = "הקלד כאן";
    this.fontFamily = "Rubik, sans-serif";
    this.preventWhiteSpace = true;

    this.setTool = async (newTool) => {
      await checkExistsTextInput();
      tool = newTool;
    };

    this.setColor = async (newColor) => {
      await checkExistsTextInput();
      color = newColor;
    };

    this.setFontSize = async (newFontSize) => {
      await checkExistsTextInput();
      fontSize = newFontSize;
    };

    this.setFontWeight = async (newFontWeight) => {
      await checkExistsTextInput();
      fontWeight = newFontWeight;
    };

    // private methods
    const initListeners = () => {
      canvas.addEventListener("mousedown", handleMouseDown.bind(this));
      canvas.addEventListener("mousemove", handleMouseMove.bind(this));
      document.addEventListener("mouseup", handleMouseUp.bind(this));
      canvas.addEventListener("wheel", handleMouseWheel.bind(this), { passive: true });
      document.addEventListener("keyup", handleCanvasKeyUp.bind(this));

      // mobile
      canvas.addEventListener("touchstart", handleTouchStart.bind(this), { passive: true });
      canvas.addEventListener("touchmove", handleTouchMove.bind(this), { passive: true });
      document.addEventListener("touchend", handleTouchEnd.bind(this));
    };

    const removeListeners = () => {
      canvas.removeEventListener("mousedown", handleMouseDown.bind(this));
      canvas.removeEventListener("mousemove", handleMouseMove.bind(this));
      document.removeEventListener("mouseup", handleMouseUp.bind(this));
      canvas.removeEventListener("wheel", handleMouseWheel.bind(this));
      document.removeEventListener("keyup", handleCanvasKeyUp.bind(this));

      //mobile
      canvas.removeEventListener("touchstart", handleTouchStart.bind(this));
      canvas.removeEventListener("touchmove", handleTouchMove.bind(this));
      document.removeEventListener("touchend", handleTouchEnd.bind(this));
    };

    const getCursorOnCanvas = ({ clientX, clientY }) => {
      const { left, top } = canvas.getBoundingClientRect();
      return {
        x: (clientX - left) * multiplier,
        y: (clientY - top) * multiplier,
      };
    };

    const redraw = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      drawImageCover({ image: bgImage, x: drawX, y: drawY });
    };

    const handleMouseDown = (event) => {
      if (!isMobile()) {
        const cursor = getCursorOnCanvas({
          clientX: event.clientX,
          clientY: event.clientY,
        });
        startCursor = { ...cursor };
        if (tool) {
          if (tool !== toolType.text) {
            isDrawing = true;
          }
          switch (tool) {
            case toolType.freeDraw:
              startFreeDraw(cursor);
              break;
            case toolType.ellipse:
              startDrawEllipse(cursor);
              break;
            case toolType.arrow:
              startDrawArrowOrLine(cursor);
              break;
            case toolType.line:
              startDrawArrowOrLine(cursor);
              break;
            case toolType.text:
              drawTextInput({
                clientX: event.clientX,
                clientY: event.clientY,
                pointOnCanvas: cursor,
              });
              break;
            default:
              break;
          }
        } else {
          initNavigation(cursor);
        }
      }
    };

    const handleMouseMove = (event) => {
      const cursor = getCursorOnCanvas({
        clientX: event.clientX,
        clientY: event.clientY,
      });
      if (tool) {
        switch (tool) {
          case toolType.freeDraw:
            freeDraw(cursor);
            break;
          case toolType.ellipse:
            drawEllipse(cursor);
            break;
          case toolType.arrow:
            drawArrow(cursor);
            break;
          case toolType.line:
            drawLine(cursor);
            break;
        }
      } else {
        if (isDragging) {
          navigation(cursor);
        }
      }
    };

    const handleMouseUp = async (event) => {
      if (isDrawing && tool !== toolType.text) {
        isDrawing = false;

        const cursor = getCursorOnCanvas({
          clientX: event.clientX,
          clientY: event.clientY,
        });
        if (cursor.x !== startCursor.x && cursor.y !== startCursor.y) {
          const image = new Image();
          image.src = canvas.toDataURL();
          image.onload = () => {
            bgImage = image;
            drawX = 0;
            drawY = 0;
            scale = 1;
            redraw();
            saveHistory({ image, drawX, drawY, scale });
          };
        }
      }

      if (isDragging) {
        saveHistory({ image: bgImage, drawX, drawY, scale });
        isDragging = false;
      }
    };

    const handleMouseWheel = (event) => {
      if (!tool) {
        zoomInOut(event);
      }
    };

    const handleCanvasKeyUp = (event) => {
      if (tool === toolType.text) {
        if (["Enter", "Escape"].some((key) => key === event.key)) {
          this.emit("changeTool", null);
        }
      }
    };

    const handleTouchStart = (event) => {
      if (event.touches.length) {
        const firstTouch = event.touches[0];
        const firstTouchData = getCursorOnCanvas({
          clientX: firstTouch.clientX,
          clientY: firstTouch.clientY,
        });

        startCursor = { ...firstTouchData };

        if (tool) {
          if (tool !== toolType.text) {
            isDrawing = true;
          }
          switch (tool) {
            case toolType.freeDraw:
              startFreeDraw(firstTouchData);
              break;
            case toolType.ellipse:
              startDrawEllipse(firstTouchData);
              break;
            case toolType.arrow:
              startDrawArrowOrLine(firstTouchData);
              break;
            case toolType.line:
              startDrawArrowOrLine(firstTouchData);
              break;
            case toolType.text:
              event.preventDefault();
              drawTextInput({
                clientX: firstTouch.clientX,
                clientY: firstTouch.clientY,
                pointOnCanvas: firstTouchData,
              });
              break;
            default:
              break;
          }
        } else {
          // navigation
          if (event.touches.length === 1) {
            initNavigation(firstTouchData);
          } else if (event.touches.length === 2) {
            startTouchZoomInOut(event);
          }
        }
      }
    };

    const handleTouchMove = (event) => {
      if (event.touches.length) {
        const firstTouch = event.touches[0];
        const firstTouchData = getCursorOnCanvas({
          clientX: firstTouch.clientX,
          clientY: firstTouch.clientY,
        });

        if (tool) {
          switch (tool) {
            case toolType.freeDraw:
              freeDraw(firstTouchData);
              break;
            case toolType.ellipse:
              drawEllipse(firstTouchData);
              break;
            case toolType.arrow:
              drawArrow(firstTouchData);
              break;
            case toolType.line:
              drawLine(firstTouchData);
              break;
            default:
              break;
          }
        } else {
          if (isDragging) {
            if (event.touches.length === 1) {
              navigation(firstTouchData);
            } else if (event.touches.length === 2) {
              touchZoomInOut(event);
            }
          }
        }
      }
    };

    const handleTouchEnd = async (event) => {
      if (isDrawing && tool !== toolType.text) {
        isDrawing = false;

        if (event.changedTouches.length > 0) {
          const firstTouch = event.changedTouches[0];
          const cursor = getCursorOnCanvas({
            clientX: firstTouch.clientX,
            clientY: firstTouch.clientY,
          });

          if (cursor.x !== startCursor.x && cursor.y !== startCursor.y) {
            const image = new Image();
            image.src = canvas.toDataURL();
            image.onload = () => {
              bgImage = image;
              drawX = 0;
              drawY = 0;
              scale = 1;
              redraw();
            };
            saveHistory({ image, drawX, drawY, scale });
          }
        }
      }

      if (isDragging) {
        saveHistory({ image: bgImage, drawX, drawY, scale });
        isDragging = false;
      }
    };

    // draw images
    const drawImage = (image, x, y, width, height) => {
      context.drawImage(image, x, y, width, height);
    };

    const drawImageCover = ({ image, x, y, alignCenter }) => {
      const aspectRatio = image.width / image.height;
      const canvasAspectRatio = width / height;
      drawWidth = width;
      drawHeight = height;
      if (aspectRatio < canvasAspectRatio) {
        drawHeight = height / aspectRatio;
      } else {
        drawWidth = width * aspectRatio;
      }

      const resWidth = drawWidth * scale;
      const resHeight = drawHeight * scale;

      if (alignCenter) {
        if (resWidth > width) {
          drawX = x = (width - resWidth) / 2;
        }
        if (resHeight > height) {
          drawY = y = (height - resHeight) / 2;
        }
      }

      drawImage(image, x, y, resWidth, resHeight);
    };

    //zoom
    const zoomInOut = (event) => {
      const delta = event.deltaY;
      const prevScale = scale;
      if (delta > 0) {
        scale = (prevScale * (drawWidth - zoomSpeed)) / drawWidth;
      } else {
        scale = (prevScale * (drawWidth + zoomSpeed)) / drawWidth;
      }

      if (this.preventWhiteSpace) {
        scale = scalePreventWhiteSpace({ prevScale, newScale: scale });
      }

      const { x: mouseX, y: mouseY } = getCursorOnCanvas({
        clientX: event.clientX,
        clientY: event.clientY,
      });

      let res = { x: drawX, y: drawY };
      const widthOffset = drawWidth * prevScale - drawWidth * scale;
      const percentFromLeft = 1 - (mouseX - drawX) / (drawWidth * prevScale);
      res.x = drawX + (widthOffset - widthOffset * percentFromLeft);

      const heightOffset = drawHeight * prevScale - drawHeight * scale;
      const percentFromTop = 1 - (mouseY - drawY) / (drawHeight * prevScale);
      res.y = drawY + (heightOffset - heightOffset * percentFromTop);

      if (this.preventWhiteSpace) {
        res = drawPreventWhiteSpace({ x: res.x, y: res.y });
      }

      drawX = res.x;
      drawY = res.y;
      redraw();
    };

    function startTouchZoomInOut(event) {
      const touches = event.touches;
      if (touches.length === 2) {
        const dx = touches[0].clientX - touches[1].clientX;
        const dy = touches[0].clientY - touches[1].clientY;
        touchDistance = Math.sqrt(dx * dx + dy * dy);
      }
    }

    const touchZoomInOut = (event) => {
      const touches = event.touches;
      if (touches.length === 2) {
        const prevScale = scale;
        const dx = touches[0].clientX - touches[1].clientX;
        const dy = touches[0].clientY - touches[1].clientY;
        const newDistance = Math.sqrt(dx * dx + dy * dy);

        if (newDistance < touchDistance) {
          scale = (prevScale * (drawWidth - touchZoomSpeed)) / drawWidth;
        } else {
          scale = (prevScale * (drawWidth + touchZoomSpeed)) / drawWidth;
        }

        if (this.preventWhiteSpace) {
          scale = scalePreventWhiteSpace({ prevScale, newScale: scale });
        }

        touchDistance = newDistance;
        const { x: centerX, y: centerY } = getCursorOnCanvas({
          clientX: (touches[0].clientX + touches[1].clientX) / 2,
          clientY: (touches[0].clientY + touches[1].clientY) / 2,
        });

        let res = { x: drawX, y: drawY };

        const widthOffset = drawWidth * prevScale - drawWidth * scale;
        const percentFromLeft = 1 - (centerX - drawX) / (drawWidth * prevScale);
        res.x = drawX + (widthOffset - widthOffset * percentFromLeft);

        const heightOffset = drawHeight * prevScale - drawHeight * scale;
        const percentFromTop = 1 - (centerY - drawY) / (drawHeight * prevScale);
        res.y = drawY + (heightOffset - heightOffset * percentFromTop);

        if (this.preventWhiteSpace) {
          res = drawPreventWhiteSpace({ x: res.x, y: res.y });
        }

        drawX = res.x;
        drawY = res.y;
        redraw();
      }
    };

    //navigation
    const initNavigation = (cursor) => {
      isDragging = true;
      startX = cursor.x - drawX;
      startY = cursor.y - drawY;
    };

    const scalePreventWhiteSpace = ({ prevScale, newScale }) => {
      if (scale * drawWidth < width || scale * drawHeight < height) {
        return prevScale;
      }
      return newScale;
    };

    const drawPreventWhiteSpace = ({ x, y }) => {
      let res = { x, y };
      const widthOffset = width - drawWidth * scale;
      const heightOffset = height - drawHeight * scale;

      if (widthOffset <= 0) {
        if (x > 0) {
          res.x = 0;
        } else if (x < widthOffset) {
          res.x = widthOffset;
        }
      }

      if (heightOffset <= 0) {
        if (y > 0) {
          res.y = 0;
        } else if (y < heightOffset) {
          res.y = heightOffset;
        }
      }

      return res;
    };

    const navigation = ({ x, y }) => {
      let res = { x: x - startX, y: y - startY };

      if (this.preventWhiteSpace) {
        res = drawPreventWhiteSpace({ x: res.x, y: res.y });
      }

      drawX = res.x;
      drawY = res.y;
      redraw();
    };

    // drawing
    const startFreeDraw = ({ x, y }) => {
      context.beginPath();
      context.moveTo(x, y);
    };

    const freeDraw = ({ x, y }) => {
      if (isDrawing) {
        context.lineTo(x, y);
        context.lineWidth = lineWidth;
        context.strokeStyle = color;
        context.stroke();
      }
    };

    const startDrawEllipse = ({ x, y }) => {
      arrowOrigin = { x, y };
    };

    const drawEllipse = ({ x, y }) => {
      if (isDrawing) {
        const from = { x: arrowOrigin.x, y: arrowOrigin.y };
        const to = { x, y };

        context.strokeStyle = color;
        redraw();

        context.beginPath();
        context.lineWidth = lineWidth;
        let ellipseCenter = { x: (from.x + to.x) / 2, y: (from.y + to.y) / 2 };
        let ellipseRadius = {
          x: Math.abs((from.x - to.x) / 2),
          y: Math.abs((from.y - to.y) / 2),
        };
        context.ellipse(
          ellipseCenter.x,
          ellipseCenter.y,
          ellipseRadius.x,
          ellipseRadius.y,
          0,
          0,
          2 * Math.PI
        );
        context.stroke();
        context.closePath();
      }
    };

    const startDrawArrowOrLine = ({ x, y }) => {
      arrowOrigin = { x, y };
    };

    const drawArrowOrLine = (cursor, isArrow = false) => {
      if (isDrawing) {
        const from = { x: arrowOrigin.x, y: arrowOrigin.y };
        const to = { x: cursor.x, y: cursor.y };

        context.strokeStyle = context.fillStyle = color;
        redraw();
        let x_center = to.x;
        let y_center = to.y;
        let angle;
        let x;
        let y;

        context.beginPath();
        context.lineWidth = lineWidth;
        context.moveTo(from.x, from.y);
        context.lineTo(to.x, to.y);
        context.stroke();
        if (isArrow) {
          context.beginPath();
          angle = Math.atan2(to.y - from.y, to.x - from.x);
          x = radius * Math.cos(angle) + x_center;
          y = radius * Math.sin(angle) + y_center;
          context.moveTo(x, y);
          angle += (1.0 / 3.0) * (2 * Math.PI);
          x = radius * Math.cos(angle) + x_center;
          y = radius * Math.sin(angle) + y_center;
          context.lineTo(x, y);
          angle += (1.0 / 3.0) * (2 * Math.PI);
          x = radius * Math.cos(angle) + x_center;
          y = radius * Math.sin(angle) + y_center;
          context.lineTo(x, y);
        }
        context.closePath();
        context.fill();
      }
    };

    const drawArrow = (cursor) => {
      drawArrowOrLine(cursor, true);
    };

    const drawLine = (cursor) => {
      drawArrowOrLine(cursor);
    };

    const refreshImage = async () => {
      return new Promise((resolve, reject) => {
        try {
          const image = new Image();
          image.src = canvas.toDataURL();
          image.onload = () => {
            bgImage = image;
            drawX = 0;
            drawY = 0;
            scale = 1;
            redraw();
            saveHistory({ image, drawX, drawY, scale });
            resolve();
          };
        } catch (err) {
          reject(err);
        }
      });
    };

    const blurTextInput = (event) => {
      setTimeout(() => {
        if (
          event.currentTarget === textToolInput &&
          tool === toolType.text &&
          textInitColor === this.color
        ) {
          textToolInput?.removeEventListener(
            "focusout",
            blurTextInput.bind(this)
          );
          this.emit("changeTool", null);
        }
      }, 150);
    };

    const checkExistsTextInput = () => {
      if (canvasWrapper && textToolInput) {
        const { value } = textToolInput;
        if (value) {
          printText(value);
        }

        if (canvasWrapper.contains(textToolInput)) {
          canvasWrapper.removeChild(textToolInput);
        }
        textToolInput = null;
      }
    };

    const drawTextInput = ({ clientX, clientY, pointOnCanvas }) => {
      checkExistsTextInput();
      textInitColor = color;
      arrowOrigin.x = pointOnCanvas.x;
      arrowOrigin.y = pointOnCanvas.y;

      textToolInput = document.createElement("input");
      textToolInput.setAttribute("id", textToolInputId);
      textToolInput.setAttribute("dir", "rtl");
      textToolInput.setAttribute("placeholder", this.initialText);
      textToolInput.setAttribute("size", 100);
      textToolInput.style.position = "absolute";
      textToolInput.style.outline = "none";
      textToolInput.style.border = "none";
      textToolInput.style.backgroundColor = "transparent";
      textToolInput.style.webkitBoxShadow = "none";
      textToolInput.style.mozBoxShadow = "none";
      textToolInput.style.boxShadow = "none";
      textToolInput.style.color = color;
      textToolInput.style.fontSize = `${fontSize}px`;
      textToolInput.style.fontWeight = fontWeight;
      textToolInput.style.fontFamily = this.fontFamily;
      textToolInput.style.lineHeight = 0;
      const { left, top } = canvas.getBoundingClientRect();
      textToolInput.style.top = `${clientY - top - fontSize}px`;
      textToolInput.style.right = `${canvasElementSize - (clientX - left)}px`;

      canvasWrapper.appendChild(textToolInput);

      if (isIPhone()) {
        textToolInput.addEventListener("focusout", blurTextInput.bind(this));
        textToolInput.focus();
      } else {
        setTimeout(() => {
          textToolInput.focus();
        }, 100);
      }
    };

    const printText = (text) => {
      context.strokeStyle = context.fillStyle = color;
      context.font = `${fontWeight} ${fontSize * multiplier}px ${
        this.fontFamily
      }`;
      context.fillText(text, arrowOrigin.x, arrowOrigin.y);
      refreshImage();
    };

    // Rotation
    this.rotate = () => {
      let tempImage = new Image();
      tempImage.src = canvas.toDataURL();
      tempImage.onload = () => {
        let cw = canvas.width;
        let ch = canvas.height;
        canvas.width = ch;
        canvas.height = cw;
        cw = canvas.width;
        ch = canvas.height;

        context.save();
        context.translate(0, canvas.height);
        context.rotate(-Math.PI / 2);
        context.drawImage(tempImage, 0, 0);
        context.restore();
        tempImage = null;
        setBgImageFromCanvas();
      };
    };

    this.undo = async () => {
      checkExistsTextInput();
      if (history.length >= 2) {
        history.pop();
        const lastStep = history[history.length - 1];
        bgImage = await convertImageToBase64Image(lastStep.image);
        drawX = lastStep.drawX;
        drawY = lastStep.drawY;
        scale = lastStep.scale;

        redraw();
      }
      sendCanUndoEvent();
    };

    const clearHistory = () => {
      history.splice(1);
      sendCanUndoEvent();
    };

    this.reset = async () => {
      clearHistory();
      bgImage = await convertImageToBase64Image(initialImage);
      drawX = 0;
      drawY = 0;
      scale = 1;
      redraw();
    };

    this.getResult = () => {
      if (initialized) {
        checkExistsTextInput();
        return canvas.toDataURL("image/jpeg", 0.8);
      } else {
        return imageUrl;
      }
    };

    const createImageCopy = (originalImage) => {
      const copyImage = new Image();
      copyImage.src = originalImage.src;
      return copyImage;
    };

    const setBgImageFromCanvas = () => {
      const image = new Image();
      image.src = canvas.toDataURL();
      image.onload = () => {
        bgImage = image;
      };
    };

    const convertImageToBase64Image = async (image) => {
      try {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, 0, 0);
        const base64Image = await createImage(canvas.toDataURL("image/png"));
        return base64Image;
      } catch (e) {
        console.log("Error converting image", e);
      }
    };

    const createImage = async (src) => {
      return new Promise((resolve, reject) => {
        try {
          const image = new Image();
          image.onload = () => {
            resolve(image);
          };
          image.onerror = (err) => {
            reject(err);
          };
          image.crossOrigin = "Anonymous";
          image.src = src;
        } catch (err) {
          reject(err);
        }
      });
    };

    const isBase64 = (src) => {
      const base64Regex = /^data:image\/([a-zA-Z]*);base64,([^\s]*)$/;
      return base64Regex.test(src);
    };

    const saveHistory = ({ image, drawX, drawY, scale }) => {
      history.push({
        image: createImageCopy(image),
        drawX,
        drawY,
        scale,
      });
      sendCanUndoEvent();
    };

    const sendCanUndoEvent = () => {
      this.emit("canUndo", history.length >= 2);
    };

    this.destruct = () => {
      removeListeners();
    };

    const initialize = async () => {
      canvas = document.getElementById(canvasId);
      if (!canvas) {
        console.error(`Element canvas with id ${canvasId} not found.`);
        return;
      }
      context = canvas.getContext("2d");

      let base64Img;
      if (isBase64(imageUrl)) {
        base64Img = image;
      } else {
        base64Img = await convertImageToBase64Image(image);
      }
      bgImage = createImageCopy(base64Img);

      const tempInitialImage = await createImage(initialImageUrl);
      if (isBase64(initialImageUrl)) {
        initialImage = tempInitialImage;
      } else {
        initialImage = await convertImageToBase64Image(tempInitialImage);
      }
      initListeners();
      saveHistory({ image: bgImage, drawX, drawY, scale });
      drawImageCover({ image: bgImage, x: drawX, y: drawY, alignCenter: true });
      initialized = true;
    };

    initialize();
  }
}
