export default {
  ["SET_SITE"](state, site) {
    state.site = site;
  },
  ["REMOVE_AREA"](state, areaId) {
    state.site.areas = state.site.areas.filter((area) => area.id !== areaId);
    state.site.levels_areas = state.site.levels_areas.filter(
      (area) => area.area_id !== areaId
    );
  },
  ["UPDATE_AREA_TEXT"](state, payload) {
    state.site.areas = state.site.areas.map((area) =>
      area.id === payload.id ? { ...area, text: payload.text } : area
    );
  },
  ["ADD_AREA"](state, payload) {
    let area = {
      id: crypto.randomUUID(),
      text: payload.text,
      type_id: payload.type_id,
    };

    state.site.areas.push(area);
    state.site.levels_areas.push({
      level_id: payload.levelId,
      area_id: area.id,
    });

    let checklist = state.site.checklists.find(
      (checklist) => checklist.type_id === payload.type_id
    );
    if (checklist && checklist.items.length > 0) {
      checklist.items.forEach((item) => {
        this.commit("site/ADD_FINDING", {
          category: item.category,
          description: item.description,
          location: item.location,
          status: item.status,
          user: "עידו כהן",
          responsibility: "",
          building: "A",
          level: 1,
          area: area.text,
          notes: "",
          images: [],
        });
      });
    }
  },
  ["REMOVE_LEVEL"](state, levelId) {
    state.site.levels = state.site.levels.filter(
      (level) => level.id !== levelId
    );
    state.site.levels_areas = state.site.levels_areas.filter(
      (area) => area.level_id !== levelId
    );
  },
  ["ADD_LEVEL"](state, payload) {
    state.site.levels.push({
      id: crypto.randomUUID(),
      text: payload.text,
    });
  },
  ["ADD_FINDING"](state, payload) {
    let finding = {
      id: crypto.randomUUID(),
      category: payload.category,
      description: payload.description,
      status: payload.status,
      user: payload.user,
      responsibility: payload.responsibility,
      building: payload.building,
      level: payload.level,
      area: payload.area,
      notes: payload.notes,
      location: payload.location,
      images: payload.images,
      created_at: new Date().toISOString(),
    };

    state.site.findings.push(finding);
  },
  ["UPDATE_FINDING"](state, payload) {
    state.site.findings = state.site.findings.map((finding) =>
      finding.id === payload.id ? { ...finding, ...payload } : finding
    );
  },
  ["REMOVE_CHECKLIST_ITEM"](state, payload) {
    state.site.checklists.forEach((checklist) => {
      if (checklist.id === payload.checklistId) {
        checklist.items = checklist.items.filter(
          (item) => item.id !== payload.checklistItemId
        );
      }
    });
  },
  ["ADD_CHECKLIST_ITEM"](state, payload) {
    state.site.checklists.forEach((checklist) => {
      if (checklist.id === payload.checklistId) {
        checklist.items.push({
          id: crypto.randomUUID(),
          category: payload.checklistItem.category,
          description: payload.checklistItem.description,
          status: payload.checklistItem.status,
        });
      }
    });
  },
  ["ADD_CHECKLIST"](state, payload) {
    state.site.checklists.push({
      id: crypto.randomUUID(),
      type: payload.type,
      type_id: payload.type_id,
      items: [],
    });
  },
  ["ADD_AREA_TYPE"](state, payload) {
    state.site.areaTypes.push({
      id: crypto.randomUUID(),
      name: payload.name,
      locations: [],
    });
  },
  ["ADD_AREA_TYPE_LOCATION"](state, payload) {
    let areaType = state.site.areaTypes.find(
      (areaType) => areaType.id === payload.areaTypeId
    );

    areaType.locations.push({
      id: crypto.randomUUID(),
      name: payload.name,
    });
  },
  ["UPDATE_AREA_TYPE_LOCATION"](state, payload) {
    let areaType = state.site.areaTypes.find(
      (areaType) => areaType.id === payload.areaTypeId
    );

    let location = areaType.locations.find(
      (location) => location.id === payload.locationId
    );

    location.name = payload.name;
  },
  ["REMOVE_AREA_TYPE_LOCATION"](state, payload) {
    let areaType = state.site.areaTypes.find(
      (areaType) => areaType.id === payload.areaTypeId
    );

    areaType.locations = areaType.locations.filter(
      (location) => location.id !== payload.locationId
    );
  },
};
