<template>
  <div class="r-header-card">
    <div class="r-header-card-content">
      <div class="r-header-card-title">{{ title }}</div>
      <div class="r-header-card-subtitle">
        {{ subtitle }}
      </div>
    </div>
    <div class="r-header-card-actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "RSimpleCard",
  props: {
    title: {
      type: String,
      default: undefined,
    },
    subtitle: {
      type: String,
      default: undefined,
    },
  },
};
</script>
