<template>
  <div>
    <div class="r-page-site-page-view">
      <div style="font-weight: 600; font-size: 18px; margin-bottom: 10px">
        בניין A
      </div>
      <div class="r-site-view-list">
        <router-link
          v-for="(level, levelIndex) in site.levels"
          :key="levelIndex"
          :to="`/sites/level/${level.id}`"
          class="r-site-view-list-item"
        >
          <div class="r-site-view-list-item-content">
            <div class="r-site-view-list-item-title">{{ level.text }}</div>
            <div class="r-site-view-list-item-text"></div>
          </div>
        </router-link>

        <router-link
          to="/sites/add-level/"
          class="r-site-view-list-item"
          style="background-color: #fafafa"
        >
          <div class="r-site-view-list-item-content">
            <div class="r-site-view-list-item-title">+ הוספת מפלס</div>
            <div class="r-site-view-list-item-text"></div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    site() {
      return this.$store.state.site.site;
    },
  },
};
</script>
