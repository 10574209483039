<template>
  <div class="rp-report__items">
    <div
      v-for="item in items"
      :key="item.id"
      :class="{
        'rp-report__item': true,
        'u-hidden': !isEditable(item),
      }"
    >
      <template v-if="item.type === 'sideToSideTwoTexts'">
        <ReportItemCard :label="item.settings.label">
          <template #default>
            <div class="report-item-card__body">
              <div class="report-item-sideToSideTwoTexts">
                <AppFieldWysiwyg
                  v-model="item.settings.textRight"
                  :items="['bold', 'italic', 'underline']"
                  label="טקסט צד ימין"
                  @on-user-type="
                    doUpdate({
                      key: 'items.' + item.id + '.settings.textRight',
                      value: $event
                    })
                  "
                />
                <AppFieldWysiwyg
                  v-model="item.settings.textLeft"
                  :items="['bold', 'italic', 'underline']"
                  label="טקסט צד שמאל"
                  @on-user-type="
                    doUpdate({
                      key: 'items.' + item.id + '.settings.textLeft',
                      value: $event
                    })
                  "
                />
              </div>
            </div>
          </template>
        </ReportItemCard>
      </template>

      <template v-if="item.type === 'wysiwyg'">
        <ReportItemCard :label="item.settings.label">
          <template #default>
            <div class="report-item-card__body">
              <AppFieldWysiwyg
                v-model="item.settings.content"
                @on-user-type="
                  doUpdate({
                    key: 'items.' + item.id + '.settings.content',
                    value: $event
                  })
                "
              />
            </div>
          </template>
        </ReportItemCard>
      </template>

      <template v-if="item.type === 'textChoices'">
        <report-label :label="item.settings.label" />
        <AppFieldTextChoices
          v-model="item.settings.content"
          :choices="item.settings.options"
          :multiple="item.settings.multiple"
          @update:model-value="
            doUpdate({
              key: 'items.' + item.id + '.settings.content',
              value: $event
            })
          "
        />
      </template>

      <template v-if="item.type === 'signatures'">
        <report-signatures :load-item="item" :update-key="`items.${item.id}`" />
      </template>

      <template v-if="item.type === 'image'">
        <report-image
          :label="item.settings.label"
          :load-image-url="item.settings.imageUrl"
          :update-key="`items.${item.id}.settings.imageUrl`"
        />
      </template>

      <template v-if="item.type === 'images'">
        <ReportImages
          :label="item.settings.label"
          :load-list="item.settings.images"
          :update-key="`items.${item.id}.settings.images`"
          :edit-image-mode="item.settings.editImage"
        />
      </template>

      <template v-if="item.type === 'files'">
        <ReportFiles
          :label="item.settings.label"
          :load-list="item.settings.files"
          :update-key="`items.${item.id}.settings.files`"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { dragOptions } from "@/common/settings";
import ReportLabel from "@/views/reports/components/ReportLabel";
import ReportImages from "@/views/reports/components/ReportImages";
import ReportImage from "@/views/reports/components/ReportImage";
import ReportSignatures from "@/views/reports/components/ReportSignatures";
import ReportFiles from "@/views/reports/components/ReportFiles";
import { ItemsUtils } from "@/common/Utils";
import ReportItemCard from "./ReportItemCard.vue";

export default {
  components: {
    ReportItemCard,
    ReportLabel,
    ReportImages,
    ReportImage,
    ReportSignatures,
    ReportFiles,
  },
  data() {
    return {
      dragOptions,
      isLoaded: false,
      reportId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("report", ["report"]),
    items() {
      return Object.values(this.report.data.items).sort(
        (a, b) => a.order - b.order
      );
    },
  },
  methods: {
    ...mapActions("report", ["doUpdate", "doUpdateMany"]),
    isEditable(item) {
      let itemType = ItemsUtils.getItemType(item.type);
      return (
        !item.settings.hideFromReportEditor &&
        itemType.isEditableOnReport &&
        !item.settings.isDisabled
      );
    },
  },
};
</script>
