//./plugins/posthog.js
import posthog from "posthog-js";

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init(
      "phc_p0Iip3nUZa6Ev78rIBm4qHMlXkP6CFvzM7FeR4JOqSF",
      {
        api_host: "https://us.i.posthog.com",
        person_profiles: "identified_only",
        autocapture: false,
      }
    );
  },
};

/*
# https://posthog.com/product-engineers/5-ways-to-improve-analytics-data#1-implement-a-naming-convention
// Event name >>> category:object_action
// Example: report_create:submit
Name convention:
- click
- submit
- create
- view
- add
- invite
- update
- delete
- remove
- start
- end
- cancel
- fail
- generate
- send

Example: signup_button_click
 */
