<template>
  <div class="r-field r-field--checkbox">
    <label
      v-if="label"
      class="r-field--checkbox-label"
      @click="inputText = !inputText"
    >
      {{ label }}
    </label>
    <input
      v-model="inputText"
      type="checkbox"
      @change="updateParentValue(inputText)"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputText: false,
    };
  },
  watch: {
    inputText(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.inputText = val;
    },
  },
  created() {
    this.inputText = this.modelValue;
  },
  methods: {
    updateParentValue(val) {
      this.inputText = val;
    },
  },
};
</script>
