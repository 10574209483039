import {
  allowedCompaniesForNewOfflineSystem,
  USE_NEW_OFFLINE_SYSTEM,
} from "@/common/settings";

export const messagesSyncQueue = (state) => state.messagesSyncQueue;
export const isOffline = (state) => state.isOffline;
export const onlineStatus = (state) => state.onlineStatus;
export const showSyncScreen = (state) => state.showSyncScreen;
export const isSyncing = (state) => state.isSyncing;
export const storageUsedInMb = (state) => state.storageUsedInMb;
export const isStorageLimitReached = (state) => state.storageUsedInMb > 3000;
export const isStorageAlmostFull = (state) => state.storageUsedInMb > 1900;
export const totalMessageImagesToSync = (state) =>
  state.totalMessageImagesToSync;
export const totalMessageImagesSynced = (state) =>
  state.totalMessageImagesSynced;

export const useOfflineSystemV2 = (state, getters, rootState, rootGetters) => {
  return true;
  // const companyId = rootGetters["auth/companyId"];
  // return (
  //   USE_NEW_OFFLINE_SYSTEM &&
  //   allowedCompaniesForNewOfflineSystem.includes(companyId)
  // );
};
