<template>
  <div class="app-field">
    <label v-if="label" class="app-field__label"> {{ label }} </label>
    <v-slider
      v-model="inputText"
      :min="min"
      :max="max"
      :step="1"
      dense
      thumb-label="always"
      hide-details
    ></v-slider>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: 0,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      inputText: false,
    };
  },
  watch: {
    inputText(val) {
      this.$emit("update:modelValue", Math.round(val));
    },
    modelValue(val) {
      this.inputText = val;
    },
  },
  created() {
    this.inputText = Number(this.modelValue);
  },
  methods: {
    updateParentValue(val) {
      this.inputText = val;
    },
  },
};
</script>
