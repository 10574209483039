async function getCreditCardIframeUrl() {
  const response = await window.axios.get(
    "/api/v1/billing/credit-card-iframe-url"
  );
  return response.data;
}

async function getNextPayment() {
  const response = await window.axios.get("/api/v1/billing/next-payment");
  return response.data;
}

async function fixPayment() {
  const response = await window.axios.post("/api/v1/billing/fix-payment");
  return response.data;
}

async function cancelSubscription(data) {
  const response = await window.axios.post(
    "/api/v1/billing/cancel-subscription",
    data
  );
  return response.data;
}
async function activateSubscription() {
  const response = await window.axios.post(
    "/api/v1/billing/activate-subscription"
  );
  return response.data;
}

export default {
  getCreditCardIframeUrl,
  getNextPayment,
  fixPayment,
  cancelSubscription,
  activateSubscription,
};
