import mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

const initialState = {
  messagesSyncQueue: [],

  // Forage Data
  storageUsedInMb: 0,
  totalMessageImagesToSync: 0,
  totalMessageImagesSynced: 0,

  // V2
  onlineStatus: true,
  showSyncScreen: false,

  // V1
  isOffline: false,
  isSyncing: false,
};

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations,
};
