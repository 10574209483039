export const SET_MESSAGES_TO_SYNC_QUEUE = "SET_MESSAGES_TO_SYNC_QUEUE";
export const ADD_MESSAGE_TO_SYNC_QUEUE = "ADD_MESSAGE_TO_SYNC_QUEUE";
export const REMOVE_FIRST_MESSAGE = "REMOVE_FIRST_MESSAGE";
export const SET_SYNCING_STATUS = "SET_SYNCING_STATUS";
export const SET_SHOW_SYNC_SCREEN = "SET_SHOW_SYNC_SCREEN";
export const SET_OFFLINE = "SET_OFFLINE";
export const SET_ONLINE_STATUS = "SET_ONLINE_STATUS";
export const SET_STORAGE_USED_IN_MB = "SET_STORAGE_USED_IN_MB";
export const SET_TOTAL_MESSAGE_IMAGES_TO_SYNC =
  "SET_TOTAL_MESSAGE_IMAGES_TO_SYNC";
export const SET_TOTAL_MESSAGE_IMAGES_SYNCED =
  "SET_TOTAL_MESSAGE_IMAGES_SYNCED";

export default {
  [SET_MESSAGES_TO_SYNC_QUEUE](state, messages) {
    state.messagesSyncQueue = messages;
  },
  [ADD_MESSAGE_TO_SYNC_QUEUE](state, message) {
    // Find msgIdx only IF there's one field to update (no updateMany)
    const newFieldsToUpdate = Object.keys(message.data);
    if (newFieldsToUpdate.length === 1) {
      const msgIdx = state.messagesSyncQueue.findIndex((m) => {
        const fieldsToUpdate = Object.keys(m.data);
        if (fieldsToUpdate.length > 1) return false;
        return fieldsToUpdate[0] === newFieldsToUpdate[0];
      });
      // If found index -> remove the previous message
      if (msgIdx >= 0) {
        state.messagesSyncQueue.splice(msgIdx, 1);
      }
    }
    // Either way push the message to the end of the array
    state.messagesSyncQueue.push(message);
  },
  [REMOVE_FIRST_MESSAGE](state) {
    state.messagesSyncQueue.shift();
  },
  [SET_ONLINE_STATUS](state, status) {
    state.onlineStatus = status;
  },
  [SET_SHOW_SYNC_SCREEN](state, show) {
    state.showSyncScreen = show;
  },

  [SET_STORAGE_USED_IN_MB](state, storageUsedInMb) {
    state.storageUsedInMb = storageUsedInMb;
  },
  [SET_TOTAL_MESSAGE_IMAGES_TO_SYNC](state, totalMessageImagesToSync) {
    state.totalMessageImagesToSync = totalMessageImagesToSync;
  },
  [SET_TOTAL_MESSAGE_IMAGES_SYNCED](state, totalMessageImagesSynced) {
    state.totalMessageImagesSynced = totalMessageImagesSynced;
  },

  [SET_SYNCING_STATUS](state, status) {
    state.isSyncing = status;
  },
  [SET_OFFLINE](state, status) {
    state.isOffline = status;
  },
};
