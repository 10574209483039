<template>
  <div class="r-auth-wrapper">
    <div class="r-auth-inner">
      <div class="r-auth-logo">
        <img src="@/assets/images/reporto-logo.svg" alt="logo" />
      </div>
      <div class="r-auth-title">שלחנו לך מייל לאימות</div>
      <div class="r-auth-text">
        המייל נשלח וממתין לאישורך.
        <br />
        צריך להקליק על הקישור בהודעה ששלחנו למייל:
        {{ email }}
        <hr class="mt-3 mb-3" style="opacity: 0.6" />
        <b>טיפ שימושי:</b>
        אם לא מצאת את ההודעה כדאי לבדוק בתיבת הספאם והאם המייל שלך ב-Gmail, הכנו
        לך קיצור דרך
        <br />
      </div>
      <div class="r-auth-form">
        <RButton
          href="https://mail.google.com/mail/u/1/#inbox"
          target="_blank"
          color="red"
        >
          מעבר למייל ב-Gmail
        </RButton>
      </div>
      <RouterLink class="r-auth-back-button mt-4" :to="{ name: 'login' }">
        <span>חזרה למסך התחברות</span>
        <RIcon>mdi-arrow-left</RIcon>
      </RouterLink>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: this.$route.query.email,
    };
  },
};
</script>
