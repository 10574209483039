async function getPayments() {
  const response = await window.axios.get("/api/v1/payments");
  return response.data;
}

async function downloadInvoice(paymentId) {
  await window.axios
    .get(`/api/v1/payments/${paymentId}/download-invoice`, {
      responseType: "blob",
    })
    .then((response) => {
      const headerval = response.headers["content-disposition"];
      if (headerval != null) {
        let filename = headerval
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");
        filename = decodeURIComponent(filename.replace(/\+/g, " "));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        // link.dispatchEvent(new MouseEvent("click"));
        window.URL.revokeObjectURL(url);
        link.remove();
      } else {
        console.error(response);
      }
    })
    .catch((error) => {
      console.error("Fail to download payment invoice file", error);
    });
}

export default {
  getPayments,
  downloadInvoice,
};
