<template>
  <div
    v-if="isLoaded"
    class="r-page-edit"
    :class="{
      disabled: isLoadingEdit || isLoadingDelete,
    }"
  >
    <div class="r-page-edit-header">
      <div class="r-page-edit-header-content">
        <div class="r-page-edit-header-title">
          🗄️ עריכת מאגר /
          {{ stockName }}
        </div>
      </div>
    </div>

    <div class="r-page-edit-nav">
      <button
        :class="{
          'is-active': currentNavItem === 'general',
        }"
        class="r-page-edit-nav-item"
        @click="currentNavItem = 'general'"
      >
        פרטים כלליים
      </button>
      <button
        :class="{
          'is-active': currentNavItem === 'categories',
        }"
        class="r-page-edit-nav-item"
        @click="currentNavItem = 'categories'"
      >
        קטגוריות
      </button>
      <button
        :class="{
          'is-active': currentNavItem === 'locations',
        }"
        class="r-page-edit-nav-item"
        @click="currentNavItem = 'locations'"
      >
        מיקומים
      </button>
      <button
        :class="{
          'is-active': currentNavItem === 'actions',
        }"
        class="r-page-edit-nav-item"
        @click="currentNavItem = 'actions'"
      >
        פעולות
      </button>
    </div>

    <div class="r-page-edit-body">
      <div class="r-page-edit-body-container">
        <div
          v-if="currentNavItem === 'general'"
          class="r-page-edit-body-content"
        >
          <RSimpleCard>
            <RFields>
              <RFieldText
                v-model="stockName"
                label="שם המאגר"
                @change="updateStock"
              />
            </RFields>
          </RSimpleCard>
        </div>

        <div v-if="currentNavItem === 'categories'" class="categories">
          <RSearchCompact
            v-model="searchQuery"
            :initial-open="true"
            placeholder="חיפוש לפי ממצא, המלצה, תקן, הערה ..."
            class="mt-4 r-searchCompact--white r-searchCompact--fullwidth"
            style="max-width: 400px"
          />

          <draggable
            v-model="stockData.categories"
            item-key="id"
            handle=".stockCategory-handle"
            v-bind="dragOptions"
            class="mt-5"
            @change="updateStock"
          >
            <template #item="{ element: category }">
              <div
                :key="category.id"
                class="pa-3 rounded-lg mb-5 stockCategory"
                :class="{
                  'is-open': categoriesVisible[category.id],
                  'is-hidden': !isCategoryInSearch(category),
                }"
                style="border: 1px solid rgba(0, 0, 0, 0.12); background: #fff"
              >
                <div class="stockCategory-header">
                  <RIcon class="stockCategory-handle u-cursor-pointer">
                    mdi-drag
                  </RIcon>
                  <input
                    v-model="category.name"
                    type="text"
                    placeholder="שם הקטגוריה"
                    @change="updateStock"
                  />
                  <div class="stockCategory-actions">
                    <RButton
                      color="grey"
                      sm
                      class="ml-2"
                      @click="addNode(category.id)"
                    >
                      ממצא חדש
                    </RButton>
                    <RButton
                      color="red"
                      sm
                      class="ml-2"
                      @click="removeCategory(category.id)"
                    >
                      מחיקת קטגוריה
                    </RButton>
                  </div>
                  <RButtonIcon
                    class="stockCategory-handle u-cursor-pointer"
                    :class="{
                      'u-rotate-180': categoriesVisible[category.id],
                    }"
                    icon="mdi-chevron-down"
                    @click="toggleCategoryVisability(category.id)"
                  />
                </div>

                <transition name="slide">
                  <div
                    v-if="categoriesVisible[category.id]"
                    class="stockCategory-nodes pr-2"
                  >
                    <draggable
                      v-model="category.nodes"
                      item-key="id"
                      handle=".stockCategoryListItem__handle"
                      v-bind="dragOptions"
                      group="nodes"
                      :empty-insert-threshold="50"
                      class="stockCategoryList"
                      @change="updateStock"
                    >
                      <template #item="{ element: node, index: nodeIndex }">
                        <div
                          :key="node.id"
                          :class="{
                            'is-hidden': !isNodeInSearch(node),
                          }"
                          class="stockCategoryListItem"
                        >
                          <RIcon
                            class="stockCategoryListItem__handle u-cursor-pointer"
                          >
                            mdi-drag</RIcon
                          >

                          <div class="stockCategoryListItem__index">
                            {{ nodeIndex + 1 }}
                          </div>

                          <div
                            class="stockCategoryListItem__title"
                            @click.stop="openNodeModal(node)"
                          >
                            {{ node.name }}
                          </div>
                        </div>
                      </template>
                    </draggable>
                  </div>
                </transition>

                <div class="stockCategory-actions is-bottom">
                  <RButton
                    color="grey"
                    sm
                    class="ml-2"
                    @click="addNode(category.id)"
                  >
                    ממצא חדש
                  </RButton>
                </div>
              </div>
            </template>
          </draggable>

          <RButton color="black" class="d-block mx-auto" @click="addCategory">
            הוספת קטגוריה
          </RButton>
        </div>

        <div v-if="currentNavItem === 'locations'" class="locations">
          <div style="max-width: 600px; margin-inline: auto">
            <draggable
              v-model="stockData.locations"
              handle=".stockCategory-handle"
              item-key="id"
              v-bind="dragOptions"
              class="mt-5"
              @change="updateStock"
            >
              <template #item="{ element: location }">
                <div
                  class="pa-3 rounded-lg mb-5 stockCategory"
                  style="border: 1px solid rgba(0, 0, 0, 0.12); background: #fff"
                >
                  <div class="stockCategory-header">
                    <RIcon class="stockCategory-handle u-cursor-pointer">
                      mdi-drag
                    </RIcon>
                    <input
                      v-model="location.name"
                      type="text"
                      placeholder="שם הקטגוריה"
                      @change="updateStock"
                    />
                    <div>
                      <RButton
                        color="red"
                        sm
                        class="ml-2"
                        @click="removeLocation(location)"
                      >
                        מחיקה
                      </RButton>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>

            <RButton color="black" class="d-block mx-auto" @click="addLocation">
              הוספת מיקום
            </RButton>
          </div>
        </div>

        <div v-if="currentNavItem === 'actions'" class="actions">
          <RButton color="black" class="mt-5" sm @click="openImportModal">
            יבוא מאגר מקובץ
          </RButton>

          <br />

          <RButton
            class="mt-5"
            color="red"
            :loading="isLoadingDelete"
            sm
            @click="removeStock"
          >
            מחיקת מאגר זה
          </RButton>
        </div>
      </div>
    </div>

    <RModal
      v-if="nodeModal.isOpen"
      v-model="nodeModal.isOpen"
      title="עריכת ממצא"
    >
      <AppFieldTextarea v-model="nodeModal.node.name" label="ממצא" />
      <AppFieldTextarea
        v-model="nodeModal.node.default_defect_recommendation"
        label="המלצה"
      />
      <AppFieldTextarea
        v-model="nodeModal.node.internal_note"
        label="הערה לבודק"
      />

      <AppFieldTextarea
        v-model="nodeModal.node.default_defect_standard"
        label="תקן"
      />

      <AppFieldTextarea
        v-model="nodeModal.node.default_defect_note"
        label="הערה"
      />

      <AppFieldText
        v-model="nodeModal.node.default_defect_unit_price"
        label="מחיר ליחידה"
        type="number"
      />

      <AppFieldText
        v-model="nodeModal.node.default_defect_unit_quantity"
        label="כמות יחידות"
        type="number"
      />

      <AppFieldSelect
        v-model="nodeModal.node.default_defect_unit_type"
        label="יחידת מידה"
        :items="unitTypes"
      />

      <AppFieldImages
        v-model="nodeModal.node.default_defect_attachments"
        :max-width="2530"
        label="נספחים"
      />

      <RButton
        class="mt-3 mb-6"
        color="red"
        sm
        @click="removeNode(nodeModal.node.id)"
      >
        מחיקת ממצא
      </RButton>

      <RButton color="primary" lg block @click="saveNode">שמירה</RButton>
    </RModal>

    <RModal
      v-if="importModal.isOpen"
      v-model="importModal.isOpen"
      title="ייבוא מאגר מקובץ"
    >
      <div class="py-3 px-3 mb-5" style="background: #eee; border-radius: 4px">
        <div>
          קובץ ה-EXCEL שתייבאו צריך להיות עם סידור עמודות תואם לעמודות המערכת
          שלנו.
          <br />
          חשוב לדעת: קטגוריות או ממצאים עם שמות קיימים לא יווצרו מחדש.
        </div>
        <RButton
          sm
          color="black"
          class="mt-2"
          outlined
          :href="excelDownloadLink"
        >
          הורדת קובץ אקסל לדוגמא</RButton
        >
      </div>

      <div style="color: #000; margin-bottom: 6px; font-weight: 500">
        בחירת קובץ ליבוא
      </div>
      <input
        type="file"
        accept=".xlsx"
        @change="selectImportFile($event.target.files[0])"
      />

      <RButton
        color="primary"
        block
        lg
        class="mt-3"
        :disabled="!importModal.fileToImport"
        @click="importStock"
        >התחלת יבוא</RButton
      >
    </RModal>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import { unitTypes, dragOptions } from "@/common/settings";
import { Utils } from "@/common/Utils";
import StocksApi from "@/api/StocksApi";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      currentNavItem: "categories",
      dragOptions,
      unitTypes,
      tab: 0,
      isLoadingEdit: false,
      isLoadingDelete: false,
      isLoaded: false,
      stockId: this.$route.params.id,
      stockName: null,
      searchQuery: null,
      stockData: {
        categories: [],
        locations: [],
      },
      categoriesVisible: {},
      nodeModal: {
        isOpen: false,
      },
      importModal: {
        isOpen: false,
        fileToImport: null,
      },
    };
  },
  computed: {
    excelDownloadLink() {
      return this.$Reporto.globals.MEDIA_URL + "app/stock.xlsx";
    },
  },
  async created() {
    await this.fetchStock();
    this.isLoaded = true;
  },
  methods: {
    isCategoryInSearch(category) {
      if (!this.searchQuery) {
        return true;
      }

      let result = false;

      if (category.nodes) {
        category.nodes.forEach((node) => {
          if (this.isNodeInSearch(node)) {
            result = true;
          }
        });
      }

      return result;
    },
    isNodeInSearch(node) {
      return (
        !this.searchQuery ||
        (node.name && node.name.includes(this.searchQuery)) ||
        (node.default_defect_note &&
          node.default_defect_note.includes(this.searchQuery)) ||
        (node.default_defect_standard &&
          node.default_defect_standard.includes(this.searchQuery)) ||
        (node.default_defect_recommendation &&
          node.default_defect_recommendation.includes(this.searchQuery))
      );
    },
    async fetchStock() {
      let loader = this.$loading.show();
      const data = await StocksApi.getStock(this.stockId);

      this.stockData.categories = data.data.categories
        ? data.data.categories
        : [];

      this.stockData.locations = data.data.locations ? data.data.locations : [];

      this.stockName = data.name;

      loader.hide();
    },
    async updateStock() {
      this.isLoadingEdit = true;
      let loader = this.$loading.show();

      try {
        await StocksApi.updateStock(this.stockId, {
          data: this.stockData,
          name: this.stockName,
        });
        this.$toast.success("המאגר עודכן בהצלחה");
        this.isLoadingEdit = false;
        loader.hide();
      } catch (error) {
        this.isLoadingEdit = false;
        loader.hide();
      }
    },
    removeCategory(categoryId) {
      if (confirm("בטוח למחוק את הקטגוריה?")) {
        this.stockData.categories = this.stockData.categories.filter(
          (category) => {
            return category.id !== categoryId;
          }
        );

        this.updateStock();
      }
    },
    removeNode(nodeId) {
      if (confirm("בטוח למחוק את הממצא?")) {
        this.stockData.categories.forEach((category) => {
          if (!category.nodes) {
            return;
          }

          category.nodes.forEach((node, index, object) => {
            if (node.id === nodeId) {
              object.splice(index, 1);
            }
          });
        });

        this.updateStock();
        this.nodeModal.isOpen = false;
      }
    },
    addCategory() {
      this.stockData.categories.push({
        id: Utils.guid(),
        name: "שם הקטגוריה החדשה",
        nodes: [],
      });

      this.updateStock();
    },
    addNode(categoryId) {
      let nodeToAdd = {
        id: Utils.guid(),
        name: "שם הממצא החדש",
        default_defect_note: null,
        default_defect_standard: null,
        default_defect_unit_price: null,
        default_defect_unit_quantity: null,
        default_defect_unit_type: null,
        default_defect_attachments: [],
        default_defect_recommendation: null,
      };

      this.stockData.categories.forEach((category) => {
        if (category.id === categoryId) {
          if (!category.nodes) {
            category.nodes = [];
          }
          category.nodes.push(nodeToAdd);
        }
      });

      this.openNodeModal(nodeToAdd);
      this.updateStock();
    },
    saveNode() {
      this.stockData.categories.forEach((category, categoryIndex) => {
        if (!category.nodes) {
          return;
        }

        category.nodes.forEach((node, nodeIndex) => {
          if (node.id === this.nodeModal.node.id) {
            this.stockData.categories[categoryIndex].nodes[nodeIndex] =
              this.nodeModal.node;
          }
        });
      });

      this.updateStock();
      this.nodeModal.isOpen = false;
    },
    toggleCategoryVisability(categoryId) {
      this.categoriesVisible[categoryId] = !this.categoriesVisible[categoryId];
      this.$forceUpdate();
    },
    openNodeModal(node) {
      this.nodeModal.isOpen = true;
      this.nodeModal.node = { ...node };
    },
    removeLocation(locationToRemove) {
      this.stockData.locations = this.stockData.locations.filter(
        (location) => location !== locationToRemove
      );

      this.updateStock();
    },
    addLocation() {
      this.stockData.locations.push({
        name: "הקלידו מיקום חדש",
      });

      this.updateStock();
    },
    async removeStock() {
      if (!confirm("בטוח למחוק את המאגר?")) return;

      this.isLoadingDelete = true;

      try {
        await StocksApi.removeStock(this.stockId);
        this.$toast.success("המאגר נמחק בהצלחה");
        this.$router.push("/stocks");
        this.isLoadingDelete = false;
      } catch (error) {
        if (error.response.status === 422) {
          this.$refs.modalEditUser.scrollTop = 0;
        }
        this.isLoadingDelete = false;
      }
    },
    openImportModal() {
      this.importModal.isOpen = true;
    },
    selectImportFile(file) {
      this.importModal.fileToImport = file;
    },
    async importStock() {
      let loader = this.$loading.show();

      try {
        let data = await StocksApi.importStock(
          this.stockId,
          this.importModal.fileToImport
        );
        this.$toast.success(
          `היבוא הסתיים בהצלחה.  ${data.totalCategoriesCreated} קטגוריות ${data.totalNodesCreated} ממצאים `
        );
        loader.hide();
        this.importModal.isOpen = false;
        this.importModal.fileToImport = null;

        this.isLoaded = false;
        await this.fetchStock();
        this.isLoaded = true;
      } catch (error) {
        this.$toast.error("שגיאה בתהליך היבוא");
        loader.hide();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.stockNameInput {
  padding: 0 5px;

  &:focus {
    outline: 0;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
}

.stockCategory {
  &:not(.is-open) {
    .stockCategory-actions {
      opacity: 0;
      pointer-events: none;
      display: none;
    }
  }
}

.is-hidden {
  display: none !important;
}

.stockCategory-actions {
  transition: 0.2s;
  &.is-bottom {
    margin-top: 20px;
    padding-right: 8px;
  }
  @media (max-width: 767px) {
    width: 100%;
    order: 4;
    margin-top: 10px;
    padding-right: 5px;
  }
}

.stockCategory-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  input {
    flex: 1;
    padding: 3px;
    margin-left: 10px;
    min-width: 50px;

    &:focus {
      outline: 0;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 4px;
    }
  }
}

.stockCategory-nodes {
  min-height: 50px;
}

.slide-enter-active {
  transition-duration: 0.1s;
  transition-timing-function: linear;
}

.slide-leave-active {
  transition-duration: 0.1s;
  transition-timing-function: linear;
}

.slide-enter-to,
.slide-leave {
  max-height: 300px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.v-list-item__title,
.v-list-item__subtitle {
  text-overflow: unset;
  white-space: initial;
}

.search-input {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
}
.search-input :deep(fieldset) {
  border: 0 !important;
}

.stockCategoryList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;

  &Item {
    border: 1px solid var(--r-border-color);
    background: #fff;
    padding: 8px 6px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    cursor: pointer;
    @media (max-width: 767px) {
      gap: 6px;
    }

    &:hover {
      background: #fafafa;
    }

    &__index {
      font-size: 0.8rem;
      background: var(--r-color-grey);
      width: 22px;
      height: 22px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -4px;
      @media (max-width: 767px) {
        width: 20px;
        height: 20px;
        font-size: 0.7rem;
      }
    }
    &__title {
      flex: 1;
      font-size: 14px;
      @media (max-width: 767px) {
        font-size: 13px;
      }
    }
  }
}
</style>
