export const fetchSite = async ({ commit }) => {
  // get from localStorage
  let site = JSON.parse(localStorage.getItem("site"));
  if (!site) {
    site = {
      number: 1002,
      address: "תל אביב, הרצל 100",
      image: null,
      top_text: "<h2>פרטים כלליים:</h2><p>שם היזם</p>",
      bottom_text: "<h2>הערות נוספות:</h2><p>סתם הערה כאן</p>",
      locations: [
        { text: "חדר רחצה", value: "חדר רחצה" },
        { text: "מטבח", value: "מטבח" },
        { text: "סלון", value: "סלון" },
        { text: "מרפסת", value: "מרפסת" },
        { text: "חדר שינה", value: "חדר שינה" },
        { text: "דלת כניסה", value: "דלת כניסה" },
      ],
      categories: [
        { text: "שלד", value: "שלד" },
        { text: "חשמל", value: "חשמל" },
        { text: "צבע", value: "צבע" },
        { text: "אינסטלציה", value: "אינסטלציה" },
        { text: "מטבח", value: "מטבח" },
        { text: "דלתות", value: "דלתות" },
        { text: "ריצוף", value: "ריצוף" },
      ],
      buildings: [
        { id: "1b3b1d91-6502-4e70-a6b3-1c90d9ffb201", text: "A", value: "A" },
        { id: "2b3b1d91-6502-4e70-a6b3-1c90d9ffb302", text: "B", value: "B" },
        { id: "1b3b1d91-3502-4e70-a6b3-1c90d9ffb302", text: "C", value: "C" },
      ],
      levels: [],
      areas: [],
      levels_areas: [],
      responsibilities: [
        { text: "קבלן", value: "קבלן" },
        { text: "חשמלאי", value: "חשמלאי" },
        { text: "צבעי", value: "צבעי" },
        { text: "אינסטלטור", value: "אינסטלטור" },
        { text: "נגר", value: "נגר" },
        { text: "רצף", value: "רצף" },
        { text: "מהנדס", value: "מהנדס" },
      ],
      statuses: [
        {
          text: "טופל",
          value: "טופל",
          color: "#00C875",
        },
        {
          text: "צריך לטפל",
          value: "צריך לטפל",
          color: "#D50C0C",
        },
        {
          text: "בטיפול",
          value: "בטיפול",
          color: "#FDAB3D",
        },
        {
          text: "לא טופל",
          value: "לא טופל",
          color: "#C4C4C4",
        },
      ],
      findings: [],
      levelTypes: [
        { text: "קומה", value: "floor" },
        { text: "קומת קרקע", value: "ground" },
      ],
      areaTypes: [
        {
          id: "1b3b1d91-6502-4e70-a6b3-1c90d9ffb209",
          name: "דירה",
          locations: [
            {
              id: "1b3b1d91-6502-4e70-a6b3-1c90d9ffb444",
              name: "חדר רחצה",
            },
          ],
        },
        {
          id: "1b3b1d92-6502-4e70-a6b3-1c90d9ffb209",
          name: "מחסן",
          locations: [],
        },
        {
          id: "1b3b1d96-6502-4e70-a6b3-1c90d9ffb209",
          name: "שלד",
          locations: [],
        },
      ],
      checklists: [],
    };
  }
  commit("SET_SITE", site);
};

export const removeArea = async ({ commit }, areaId) => {
  commit("REMOVE_AREA", areaId);
};

export const updateAreaText = async ({ commit }, payload) => {
  commit("UPDATE_AREA_TEXT", payload);
};

export const addArea = async ({ commit }, payload) => {
  commit("ADD_AREA", payload);
};

export const removeLevel = async ({ commit }, levelId) => {
  commit("REMOVE_LEVEL", levelId);
};

export const addLevel = async ({ commit }, payload) => {
  commit("ADD_LEVEL", payload);
};

export const addFinding = async ({ commit }, payload) => {
  commit("ADD_FINDING", payload);
};

export const updateFinding = async ({ commit }, payload) => {
  commit("UPDATE_FINDING", payload);
};

export const removeChecklistItem = async ({ commit }, payload) => {
  commit("REMOVE_CHECKLIST_ITEM", payload);
};

export const addChecklistItem = async ({ commit }, payload) => {
  commit("ADD_CHECKLIST_ITEM", payload);
};

export const addChecklist = async ({ commit }, payload) => {
  commit("ADD_CHECKLIST", payload);
};

export const addAreaType = async ({ commit }, payload) => {
  commit("ADD_AREA_TYPE", payload);
};

export const addAreaTypeLocation = async ({ commit }, payload) => {
  commit("ADD_AREA_TYPE_LOCATION", payload);
};

export const updateAreaTypeLocation = async ({ commit }, payload) => {
  commit("UPDATE_AREA_TYPE_LOCATION", payload);
};

export const removeAreaTypeLocation = async ({ commit }, payload) => {
  commit("REMOVE_AREA_TYPE_LOCATION", payload);
};

// saveSite to localStorage
export const saveSite = async ({ state }) => {
  localStorage.setItem("site", JSON.stringify(state.site));
};
