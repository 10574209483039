<template>
  <div>
    <RSimpleCard title="סרטוני עזרה מהירים">
      <v-list class="rp-vuetify-list">
        <v-list-item
          v-for="(video, index) in videos"
          :key="index"
          @click="playVideo(video)"
        >
          <template #prepend>
            <v-icon color="grey">mdi-youtube</v-icon>
          </template>

          <v-list-item-title>{{ video.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <RButton color="grey" class="mt-2" to="/videos" block>
        לכל הסרטונים
      </RButton>
    </RSimpleCard>

    <v-dialog v-model="isVideoDialogOpen" max-width="1000">
      <v-card>
        <v-card-title
          class="d-flex align-center justify-space-between flex-wrap mb-0"
        >
          <v-btn icon @click="isVideoDialogOpen = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <div class="video-container">
            <iframe
              v-if="isVideoDialogOpen"
              :src="`https://www.youtube.com/embed/${videoDialogIdToShow}?autoplay=1`"
              width="100%"
              height="400"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { homeSupportVideos } from "@/common/settings";
import { trackEvent } from "@/services/TrackingService";

export default {
  data() {
    return {
      isVideoDialogOpen: false,
      videoDialogIdToShow: null,
      videos: homeSupportVideos,
    };
  },
  methods: {
    playVideo(video) {
      this.videoDialogIdToShow = video.id;
      this.isVideoDialogOpen = true;
      trackEvent("Button Clicked", {
        "Button Name": "Home Video",
        "Button Url": video.id,
      });
    },
  },
};
</script>

<style scoped>
.video-container {
  position: relative;
  padding-bottom: 50%;
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.v-dialog__content {
  align-items: center;
}
</style>
