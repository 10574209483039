<template>
  <div class="report-item-card">
    <div class="report-item-card__header">
      <div class="report-item-card__header-title" @click="toggleCard">
        {{ label }}
        <small v-if="labelTotal"> ({{ labelTotal }}) </small>
      </div>
      <div class="report-item-card__header-actions">
        <slot name="actions"></slot>

        <RButtonIcon
          icon="mdi-chevron-down"
          :class="{
            'u-rotate-180': isCardOpen,
          }"
          @click="toggleCard"
        />
      </div>
    </div>

    <transition name="slide">
      <div v-show="isCardOpen" class="report-item-card__content">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ReportItemCard",
  props: {
    label: {
      type: String,
      required: true,
    },
    labelTotal: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      isCardOpen: true,
    };
  },

  methods: {
    toggleCard() {
      this.isCardOpen = !this.isCardOpen;
    },
  },
};
</script>
