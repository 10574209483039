<template>
  <ReportsList
    title="📋 דוחות חברה"
    :reports-service-fetch="reportsServiceFetch"
    :filter-by-user="true"
  />
</template>

<script>
import ReportsList from "@/components/ReportsList";

export default {
  components: {
    ReportsList,
  },
  computed: {},
  methods: {
    reportsServiceFetch(params) {
      return window.axios.get("/api/v1/company/reports/search", { params });
    },
  },
};
</script>
