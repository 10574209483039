<template>
  <div>
    <div class="d-flex u-gap-2">
      <input v-model="colorValue" type="color" class="mt-1" />
      <RFieldText
        v-model="colorValue"
        class="u-flex-1 input-color"
        dense
        outlined
        hide-details
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      colorValue: "",
      colorPickerValue: "",
    };
  },
  watch: {
    colorValue(val) {
      this.$emit("update:modelValue", val);
    },
  },
  created() {
    if (this.modelValue) {
      this.colorValue = this.convertHexaToHex(this.modelValue);
    }
  },
  methods: {
    convertHexaToHex(hexa) {
      if (hexa.length === 9) {
        hexa = hexa.substring(0, hexa.length - 2);
      }
      return hexa;
    },
  },
};
</script>

<style lang="scss">
input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}

.input-color {
  input {
    text-align: left !important;
    direction: ltr;
  }
}
</style>
