import StocksApi from "@/api/StocksApi";

export const fetchStocks = async ({ commit }) => {
  const data = await StocksApi.getStocks();
  commit("SET_STOCKS", data.data);
};

export const fetchStocksLibrary = async ({ commit }) => {
  const response = await StocksApi.getStocksLibrary();
  commit("SET_STOCKS_LIBRARY", response.data);
};
