<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="190px"
    max-width="190px"
  >
    <template #activator="{ props }">
      <div
        v-bind="props"
        class="rounded-circle d-inline-block"
        style="width: 30px; height: 30px; border: 1px solid #eee"
        :style="swatchStyle"
      ></div>
    </template>

    <v-card color="white" class="px-2 py-2">
      <v-text-field
        v-model="color"
        style="display: none"
        density="compact"
        variant="outlined"
        hide-details
        readonly
      ></v-text-field>
      <v-layout :wrap="true" style="margin: 4px -3px -3px -3px;flex-wrap:wrap;">
        <div
          v-for="(suggestedColor, suggestedColorIndex) in suggestedColors"
          :key="suggestedColorIndex"
          :style="suggestedColorStyle(suggestedColor)"
          @click="setColor(suggestedColor)"
        >
          <v-icon v-if="suggestedColor === color" :color="iconColor(color)"
            >mdi-check</v-icon
          >
        </div>
      </v-layout>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "#ffffff",
    },
  },
  data() {
    return {
      menu: false,
      color: this.modelValue,
      suggestedColors: [
        "#c60022",
        "#9c6d1e",
        "#fe8500",
        "#ffb000",
        "#f6db25",
        "#81b532",
        "#13855f",
        "#00b6cb",
        "#648fff",
        "#0044a3",
        "#ffffff",
      ],
    };
  },
  computed: {
    swatchStyle() {
      return {
        backgroundColor: this.color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
      };
    },
  },
  watch: {
    color(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.color = val;
    },
  },
  methods: {
    iconColor(color) {
      if (color === "#fff" || color === "#ffffff") {
        return "black";
      }
      return "white";
    },
    setColor(color) {
      this.color = color;
      this.menu = false;
    },
    suggestedColorStyle(suggestedColor) {
      return {
        backgroundColor: suggestedColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: "5px",
        margin: "3px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #eee",
      };
    },
  },
};
</script>
