<template>
  <div class="r-field r-field--select">
    <label v-if="label" class="r-field-label"> {{ label }} </label>
    <div class="r-field-input">
      <select v-model="selectedValue" @change="handleChange">
        <option v-for="item in items" :key="item.value" :value="item.value">
          {{ item.text }}
        </option>
      </select>
      <div
        v-if="clearable && selectedValue"
        class="r-field--select-clear"
        @click.stop="clearValue"
      >
        <v-btn density="compact" variant="text" icon="mdi-close" size="small"></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      selectedValue: this.modelValue,
    };
  },
  watch: {

    modelValue(newValue) {
      this.selectedValue = newValue;
    },
  },
  methods: {
    handleChange(event) {
      let value = event.target.value;
      if (value === "true") {
        value = true;
      } else if (value === "false") {
        value = false;
      }
      this.selectedValue = value;
      this.$emit("update:modelValue", value);
    },
    clearValue() {
      this.selectedValue = "";
      this.$emit("update:modelValue", this.selectedValue);
    },
  },
};
</script>
