<template>
  <div>
    <AppFieldText
      v-if="editContent"
      v-model="localItem.settings.headline"
      label="כותרת"
    />

    <AppFieldAlign
      v-if="editContent"
      v-model="localItem.settings.headlineAlign"
      label="ישור כותרת"
    />

    <AppFieldSelect
      v-model="localItem.settings.imagesPerRow"
      label="מספר תמונות בשורה"
      :items="[1, 2, 3, 4]"
    />

    <AppFieldSwitch
      v-model="localItem.settings.editImage"
      label="אפשר עריכת תמונה"
    />

    <AppFieldSwitch
      v-model="localItem.settings.pageBreakBefore"
      label="שבירת עמוד לפני"
    />

    <report-images
      label="תמונות"
      :load-list="localItem.settings.images"
      :edit-image-mode="localItem.settings.editImage"
      update-key="images"
      @do-update="doUpdateImages"
      @do-update-many="doUpdateManyImages"
    />
  </div>
</template>

<script>
import ReportImages from "@/views/reports/components/ReportImages.vue";
import { Utils } from "@/common/Utils";

export default {
  components: { ReportImages },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    editContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localItem: {
        settings: {
          images: {},
        },
      },
    };
  },
  watch: {
    localItem: {
      handler(val) {
        this.$emit("update:modelValue", val);
      },
      deep: true,
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
    async doUpdateImages({ key, value }) {
      let cleanData = JSON.parse(
        JSON.stringify({
          [key]: value,
        })
      );

      let dataUploaded = await this.uploadObjectImages(cleanData);

      this.localItem.settings = Utils.flatAndMergeObjects(
        this.localItem.settings,
        dataUploaded
      );
    },
    async doUpdateManyImages({ data }) {
      let cleanData = JSON.parse(JSON.stringify(data));

      let dataUploaded = await this.uploadObjectImages(cleanData);

      this.localItem.settings = Utils.flatAndMergeObjects(
        this.localItem.settings,
        dataUploaded
      );
    },
    async uploadObjectImages(obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (value && Utils.isBase64Image(value)) {
          let loader = this.$loading.show();
          let { data } = await Utils.uploadImageBase64(value);
          loader.hide();
          obj[key] = data.path;
        }
      }

      return obj;
    },
  },
};
</script>
