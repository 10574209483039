<template>
  <div class="py-0 px-0 px-mb-6">
    <ReportTemplateEditor
      v-if="isLoaded"
      :report-template-parent="reportTemplate"
      @update-report="updateReportTemplate"
      @update-should-save="updateShouldSave"
    />
  </div>
</template>

<script>
import ReportTemplatesApi from "@/api/ReportTemplatesApi";

export default {
  beforeRouteLeave(to, from, next) {
    if (this.shouldSave) {
      let warningMessage =
        "רגע! שמנו לב שלא שמרתם את השינויים שעשיתם, רוצים לצאת בלי לשמור?";

      const answer = window.confirm(warningMessage);
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data() {
    return {
      isLoaded: false,
      reportTemplate: null,
    };
  },
  async created() {
    await this.fetchReportTemplate();
    this.isLoaded = true;
  },
  methods: {
    updateShouldSave(val) {
      this.shouldSave = val;
    },
    async fetchReportTemplate() {
      let loader = this.$loading.show();
      const data = await ReportTemplatesApi.getTemplate(this.$route.params.id);
      this.reportTemplate = data;
      loader.hide();
    },
    async updateReportTemplate(reportTemplate) {
      let loader = this.$loading.show();

      try {
        await ReportTemplatesApi.updateTemplate(
          reportTemplate.id,
          reportTemplate
        );

        loader.hide();
        this.$toast.success("תבנית הדוח עודכנה בהצלחה");
      } catch (e) {
        console.log("e", e);

        loader.hide();
        this.$toast.error(
          this.$Utils.toastMessageFromResponse(
            e,
            "אירעה שגיאה בעת עדכון תבנית הדוח"
          )
        );
      }
    },
  },
};
</script>

<style scoped>
.v-list-item {
  transition: 0.2s;
}
</style>
