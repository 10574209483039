<template>
  <div>
    <AppFieldWysiwyg
      v-model="text"
      :items="['bold', 'italic', 'underline']"
      label="טקסט"
    />

    <AppFieldAlign v-model="textAlign" label="יישור טקסט" />

    <div class="app-field">
      <label class="app-field__label"> גודל טקסט </label>

      <div class="d-flex u-gap-3">
        <div class="d-flex align-center" style="flex: 1">
          <input
            v-model="fontSize"
            type="range"
            min="10"
            max="30"
            style="width: 100%"
          />
        </div>
        <div style="width: 120px">
          <RFieldText v-model="fontSize" type="number" min="10" max="30">
          </RFieldText>
        </div>
      </div>
    </div>

    <div class="app-field">
      <label class="app-field__label"> צבע טקסט </label>
      <input-color v-model="color" />
    </div>
  </div>
</template>

<script>
import InputColor from "../settings/InputColor.vue";

export default {
  components: {
    InputColor,
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    panel: [0],
  }),
  computed: {
    field: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit("update:modelValue", val);
      },
    },
    text: {
      get() {
        return this.modelValue.text;
      },
      set(text) {
        this.$emit("update:modelValue", { ...this.modelValue, text });
      },
    },
    fontSize: {
      get() {
        return this.modelValue.style.fontSize;
      },
      set(fontSize) {
        if (!fontSize || fontSize < 10 || fontSize > 30) {
          fontSize = 16;
        }
        this.updateStyle({ fontSize });
      },
    },
    textAlign: {
      get() {
        return this.modelValue.style.textAlign;
      },
      set(textAlign) {
        this.updateStyle({ textAlign });
      },
    },
    color: {
      get() {
        return this.modelValue.style.color;
      },
      set(color) {
        this.updateStyle({ color });
      },
    },
  },
  watch: {
    fontSize(val) {
      if (!val || val < 10 || val > 30) {
        this.fontSize = 16;
      }
    },
  },
  created() {
    const styles = {
      color: this.modelValue.style.color,
      fontSize: this.modelValue.style.fontSize,
    };
    this.updateStyle(styles);
  },
  methods: {
    updateStyle(styleField) {
      this.$emit("update:modelValue", {
        ...this.modelValue,
        style: { ...this.modelValue.style, ...styleField },
      });
    },
  },
};
</script>
