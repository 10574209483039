<template>
  <div class="app-editor-panel__inner">
    <div class="app-editor-panel__header space-between">
      <div class="flex d-flex align-center ">
        <RButtonIcon
          v-if="showbackBtn"
          icon="mdi-arrow-right"
          color="white"
          @click="$emit('onBack')"
        />
        <RButtonIcon
          v-else
          color="white"
          icon="mdi-cog"
          @click.stop="$emit('global-settings-tool')"
        />
      </div>
      <div class="flex d-flex align-center  font-weight-medium">
        {{ title }}
      </div>
      <div class="flex d-flex align-center ">
        <RButtonIcon icon="mdi-grid" color="white" @click="$emit('onBack')" />
      </div>
    </div>

    <div class="app-editor-panel__main pa-3">
      <slot name="default"></slot>
    </div>

    <div class="app-editor-panel__footer">
      <PlaceholdersButton />

      <RButton color="green" @click.stop="$emit('update-preference')">
        שמירה
      </RButton>
    </div>
  </div>
</template>

<script>
import PlaceholdersButton from "@/components/ReportTemplate/PlaceholdersButton.vue";

export default {
  components: {
    PlaceholdersButton,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    showbackBtn: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
