<template>
  <div>
    <template v-if="current.type === 'text'">
      <field-text
        v-model="text"
        :default-color="modelValue.data.textColor"
        :default-font-size="modelValue.data.fontSize"
        :default-font-family="modelValue.data.fontFamily"
      />
    </template>
    <template v-if="current.type === 'image'">
      <div>
        <AppFieldImage v-model="imageUrl" label="תמונה" />

        <div class="app-field">
          <label class="app-field__label"> גודל תמונה (%) </label>

          <div class="d-flex u-gap-3">
            <div class="d-flex align-center" style="flex: 1">
              <input
                v-model="width"
                type="range"
                min="5"
                max="100"
                style="width: 100%"
              />
            </div>
            <div style="width: 120px">
              <RFieldText v-model="width" type="number" min="5" max="100">
              </RFieldText>
            </div>
          </div>
        </div>

        <RFieldSelect
          v-model="imageAlign"
          :items="[
            { text: 'ימין', value: 'right' },
            { text: 'אמצע', value: 'center' },
            { text: 'שמאל', value: 'left' },
          ]"
          label="יישור תמונה"
        />
      </div>
    </template>
    <div class="d-flex justify-end w-full mt-5 mb-2">
      <RButton color="red" sm @click="$emit('delete', current)"
        >מחיקת שדה</RButton
      >
    </div>
  </div>
</template>

<script>
import FieldText from "./FieldText.vue";

export default {
  components: {
    FieldText,
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    current: {
      type: Object,
      default: null,
    },
  },
  computed: {
    text: {
      get() {
        return this.current;
      },
      set(val) {
        this.$emit("update-current", val);
      },
    },
    imageUrl: {
      get() {
        return this.current.imageUrl;
      },
      set(val) {
        const current = { ...this.current, imageUrl: val };
        this.$emit("update-current", current);
      },
    },
    width: {
      get() {
        return this.current.style.width;
      },
      set(width) {
        this.updateCurrentStyle({ width });
      },
    },
    textAlign: {
      get() {
        return this.current.style.textAlign;
      },
      set(textAlign) {
        this.updateCurrentStyle({ textAlign });
      },
    },
    imageAlign: {
      get() {
        return this.current.style.imageAlign;
      },
      set(imageAlign) {
        this.updateCurrentStyle({ imageAlign });
      },
    },
  },
  methods: {
    updateCurrentStyle(val) {
      this.$emit("update-current", {
        ...this.current,
        style: { ...this.current.style, ...val },
      });
    },
  },
};
</script>
