<template>
  <Maintenance v-if="isOnMaintenanceMode" />
  <ForceRefresh />
  <RouterView></RouterView>
</template>

<script>
import Maintenance from "@/components/Maintenance";
import ForceRefresh from "@/components/ForceRefresh.vue";
import { sendLog } from "./services/LogService";
import { getLogData } from "./common/Utils";

export default {
  name: "App",
  components: {
    ForceRefresh,
    Maintenance,
  },
  data() {
    return {
      isOnMaintenanceMode: false,
    };
  },
  mounted() {
    // Attach the beforeunload listener
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeUnmount() {
    // Clean up the listener
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },

  methods: {
    async handleBeforeUnload(event) {
      const data = await getLogData();
      // Log to PostHog
      sendLog("[Before Unload] : event fired", {
        data,
      });

      // Optional: Prevent the default reload behavior in dev environments for testing
      // event.preventDefault();
      event.returnValue = "";
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to) {
        let title = "Reporto";
        if (to.meta.title) {
          title = title + " - " + to.meta.title;
        }
        document.title = title;
      },
    }
  },
};
</script>

<style lang="scss">
@import "./assets/sass/app.scss";
</style>
