<template>
  <div class="app-field">
    <RFieldSelect v-model="vAlign" label="יישור אנכי" :items="alignList" />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "start",
    },
  },
  data: () => ({
    alignList: [
      {
        text: "Top",
        value: "top",
      },
      {
        text: "Middle",
        value: "middle",
      },
      {
        text: "Bottom",
        value: "bottom",
      },
    ],
  }),
  computed: {
    vAlign: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
