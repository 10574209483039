<template>
  <v-list-item
    :to="to"
    v-bind="$attrs"
    :value="text"
    @click="$emit('click', $event)"
    class="r-action-item"
  >
    <template #prepend>
      <v-icon :color="color" class="r-action-icon">{{ icon }}</v-icon>
    </template>

    <v-list-item-title class="r-action-title">{{ text }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "RAction",
  inheritAttrs: false,
  emits: ["click"],
  props: {
    color: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style >
.r-action-item {
  display: flex;
  align-items: center;
  font-size: 13px !important;
}

.r-action-icon {
  font-size: 1rem;
}

.r-action-title {
  flex: 1;
  font-size: 13px;
}

.v-list-item__spacer {
  width: 10px !important;
}
</style>
