<template>
  <div class="r-auth-wrapper">
    <div class="r-auth-inner">
      <div class="r-auth-logo">
        <img src="@/assets/images/reporto-logo.svg" alt="logo" />
      </div>
      <div class="r-auth-title">איפוס סיסמה</div>
      <div class="r-auth-text">איך תרצה לשחזר את הסיסמה שלך?</div>
      <div class="r-auth-form">
        <div class="r-auth-select-buttons">
          <div
            class="r-auth-select-buttons-item"
            :class="{
              'is-active': resetUsing === 'email',
            }"
            @click="resetUsing = 'email'"
          >
            <RIcon>mdi-email-outline</RIcon>
            <span>סיסמה לאימייל</span>
          </div>
          <div
            class="r-auth-select-buttons-item"
            :class="{
              'is-active': resetUsing === 'phone',
            }"
            @click="resetUsing = 'phone'"
          >
            <RIcon>mdi-cellphone</RIcon>
            <span>סיסמה לטלפון</span>
          </div>
        </div>

        <RButton
          color="primary"
          block
          lg
          type="submit"
          class="mt-8"
          :disabled="!resetUsing"
          @click="goToResetPassword"
        >
          המשך לאיפוס סיסמה
        </RButton>
      </div>

      <RouterLink class="r-auth-back-button mt-4" :to="{ name: 'login' }">
        <span>חזרה למסך התחברות</span>
        <RIcon>mdi-arrow-left</RIcon>
      </RouterLink>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resetUsing: null,
    };
  },
  methods: {
    goToResetPassword() {
      if (this.resetUsing === "email") {
        this.$router.push({ name: "forgot-password-email" });
      } else if (this.resetUsing === "phone") {
        this.$router.push({ name: "forgot-password-phone" });
      }
    },
  },
};
</script>
