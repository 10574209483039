<template>
  <div>
    <v-btn-toggle v-model="localItem.settings.signatureAlign" mandatory>
      <v-tooltip top>
        <template #activator="{ props }">
          <v-btn v-bind="props" value="right" outlined height="40">
            <v-icon size="18">mdi-format-align-right</v-icon>
          </v-btn>
        </template>
        <span>ימין</span>
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ props }">
          <v-btn v-bind="props" value="center" outlined height="40">
            <v-icon size="18">mdi-format-align-center</v-icon>
          </v-btn>
        </template>
        <span>אמצע</span>
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ props }">
          <v-btn v-bind="props" value="left" outlined height="40">
            <v-icon size="18">mdi-format-align-left</v-icon>
          </v-btn>
        </template>
        <span>שמאל</span>
      </v-tooltip>
    </v-btn-toggle>

    <v-slider
      v-model="localItem.settings.signatureWidth"
      label="גודל חתימה"
      min="1"
      max="700"
      thumb-label="always"
    ></v-slider>

    <AppFieldSignature
      v-if="editContent"
      v-model="localItem.settings.signature"
      :label="localItem.settings.label"
      :modal-title="localItem.settings.label"
      :loaded="localItem.loaded"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    editContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localItem: null,
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
  },
};
</script>
