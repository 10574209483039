import { Utils } from "@/common/Utils";

export const isLoggedIn = (state) => state.isLoggedIn;
export const isImpersonate = (state) => state.isImpersonate;
export const token = (state) => state.token;
export const user = (state) => state.user;
export const userId = (state) =>
  state.user && state.user.id ? state.user.id : null;
export const company = (state) => state.company;
export const companyId = (state) =>
  state.company && state.company.company_id ? state.company.company_id : null;
export const companies = (state) => state.companies;

export const displayName = (state) => {
  if (!state.user) {
    return null;
  }
  return state.user.name;
};

export const displayFirstName = (state) => {
  if (!state.user) {
    return null;
  }
  const words = state.user.name.trim().split(" ");

  if (words.length === 0) return null;

  return words[0];
};

export const displayNameAcronym = (state) => {
  if (!state.user) {
    return null;
  }
  return state.user.name
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "");
};

export const displayCompanyName = (state) => {
  if (!state.company) {
    return null;
  }
  return state.company.name;
};

export const companyLogoUrl = (state) => {
  if (!state.company || !state.company.logo) {
    return null;
  }
  return Utils.getImageUrl(state.company.logo);
};

export const hasCompany = (state) => {
  if (state.company) {
    return true;
  }
};

export const currentCompanyId = (state) => {
  if (state.company) {
    return state.company.company_id;
  }
};

export const demoMeetingUrl = (state) => {
  return `https://reporto.co.il/demo-meeting/?name=${state.user.name}&email=${state.user.email}&phone=${state.user.phone}`;
};
