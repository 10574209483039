import * as localForage from "localforage";
import { sendLog } from "./LogService";

export const forageService = {
  saveMessagesQueueToLocalForage,
  getMessagesFromLocalForage,
  getStorageUsed,
};

const STORAGE_KEY = "vuex.reportQue.messagesSyncQueue";

async function saveMessagesQueueToLocalForage(messagesSyncQueue) {
  return new Promise((res) => {
    localForage.setItem(STORAGE_KEY, JSON.stringify(messagesSyncQueue), async () => {
      const storage = await getStorageUsed();
      res(storage.megaUsed);
    });
  });
}

async function getMessagesFromLocalForage(authState) {
  console.log('[loaclForage] STORAGE_KEY', STORAGE_KEY)
  
  return new Promise((res, rej) => {
    localForage.getItem(STORAGE_KEY, async (err, value) => {
      console.log('[loaclForage] Got messages from indexedDB', value)

      let currentUserCompanyId = authState.company.company_id;
      let currentUserId = authState.user.id;

      // if value is array
      if (!Array.isArray(value)) {
        value = JSON.parse(value);
      }

      let messagesOnlyFromCurrentCompany = false;

      if (value) {
        messagesOnlyFromCurrentCompany = value.filter(
          (message) =>
            (!message.company_id ||
              message.company_id === currentUserCompanyId) &&
            message.user_id === currentUserId
        );
        const storage = await getStorageUsed();
        sendLog(`[ForageService] Messages loaded from local forage`, { data: { storage } });
        res({ messages: messagesOnlyFromCurrentCompany, megaUsed: storage.megaUsed });
      }

      if (err) {
        console.log(err);
        rej(err);
      }
    });
  });
}

function getStorageUsed() {
  if ("storage" in navigator && "estimate" in navigator.storage) {
    return navigator.storage.estimate().then((v) => {
      const mega = Math.pow(2, 20);
      const megaUsed = (v.usage / mega).toFixed(2);
      const quota = (v.quota / mega).toFixed(2)
      return { megaUsed, quota };
    });
  } else {
    return 0;
  }
}
