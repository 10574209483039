<!-- <template>
  <div class="app-field app-field--wysiwyg">
    <label v-if="label" class="mb-2 d-block subtitle-2">{{ label }}</label>
    <ckeditor
      :editor="editor"
      v-model="editorData"
      :config="editorConfig"
      tag-name="ckeditor"
    />
  </div>
</template>

<script>
import ClassicEditorBase from "@ckeditor/ckeditor5-build-classic";
import { Ckeditor } from "@ckeditor/ckeditor5-vue";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";


class ClassicEditor extends ClassicEditorBase {}
ClassicEditor.builtinPlugins = [...ClassicEditor.builtinPlugins, Underline];

export default {
  name: "AppFieldWysiwyg",
  components: {
    Ckeditor,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: this.modelValue,
      editorConfig: {
        toolbar: ["undo", "redo", "|", "bold", "italic", "underline"],
        language: {
          ui: "he", // RTL language
          content: "he",
        },
        alignment: {
          options: ["left", "right", "center", "justify"],
        },
      },
      contentsLangDirection: "rtl",
    };
  },
  watch: {
    modelValue(newValue) {
      this.editorData = newValue;
    },
    editorData(newValue) {
      this.$emit("update:modelValue", newValue);
      this.$emit("input", newValue);
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
	body {
		margin: 0 !important;
	}
}

.main-container {
	font-family: 'Lato';
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
	min-width: 795px;
	max-width: 795px;
}


.ck.ck-powered-by {
  display: none;
}
.ck.ck-button.ck-on,
.ck.ck-button.ck-on:hover,
.ck.ck-button.ck-on:focus,
.ck.ck-button.ck-on:active {
  --ck-color-button-on-color: var(--ck-color-text);
  --ck-color-button-on-background: #f5f5f5;
}
.ck-editor {
  --ck-focus-ring: 1px solid var(--color-purple);
}
</style> -->

<template>
  <div class="app-field app-field--wysiwyg">
    <label v-if="label" class="mb-2 d-block subtitle-2">{{ label }}</label>
    <div class="main-container">
      <div
        class="editor-container editor-container_classic-editor"
        ref="editorContainerElement"
      >
        <div class="editor-container__editor">
          <div ref="editorElement">
            <ckeditor
              v-if="isLayoutReady"
              v-model="editorData"
              :editor="editor"
              :config="config"
              @input="onInput"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  Autosave,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Indent,
  IndentBlock,
  Italic,
  List,
  Paragraph,
  PasteFromOffice,
  SelectAll,
  SpecialCharacters,
  Strikethrough,
  TextTransformation,
  Underline,
  Undo,
  ParagraphButtonUI,
  HeadingButtonsUI,
  Heading,
} from "ckeditor5";

import translations from "ckeditor5/translations/he.js";

import "ckeditor5/ckeditor5.css";

export default {
  name: "AppFieldWysiwyg",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editorData: this.modelValue || "",
      isLayoutReady: false,
      config: null, // CKEditor needs the DOM tree before calculating the configuration.
      editor: ClassicEditor,
    };
  },
  mounted() {
    this.config = {
      toolbar: {
        items: [
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "fontBackgroundColor",
          "fontColor",
          "|",
          "paragraph",
          "heading0",
          "heading1",
          "heading2",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "alignment:right",
          "alignment:center",
          "|",
          "undo",
          "redo",
        ],
        shouldNotGroupWhenFull: false,
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading0",
            view: {
              name: "h1",
              classes: "h0",
            },
            icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M4 8.5H9V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H10.5C10.7652 3 11.0196 3.10536 11.2071 3.29289C11.3946 3.48043 11.5 3.73478 11.5 4V15.5C11.5 15.7652 11.3946 16.0196 11.2071 16.2071C11.0196 16.3946 10.7652 16.5 10.5 16.5H10C9.73478 16.5 9.48043 16.3946 9.29289 16.2071C9.10536 16.0196 9 15.7652 9 15.5V11H4V15.5C4 15.7652 3.89464 16.0196 3.70711 16.2071C3.51957 16.3946 3.26522 16.5 3 16.5H2.5C2.23478 16.5 1.98043 16.3946 1.79289 16.2071C1.60536 16.0196 1.5 15.7652 1.5 15.5V4C1.5 3.73478 1.60536 3.48043 1.79289 3.29289C1.98043 3.10536 2.23478 3 2.5 3H3C3.26522 3 3.51957 3.10536 3.70711 3.29289C3.89464 3.48043 4 3.73478 4 4V8.5Z" fill="black"/> <path d="M15.7441 19.1776C15.1097 19.1776 14.5628 19.0272 14.1035 18.7266C13.6442 18.4235 13.2903 17.9891 13.0417 17.4233C12.7931 16.8551 12.67 16.1721 12.6724 15.3743C12.6748 14.5765 12.7991 13.8994 13.0453 13.343C13.2938 12.7843 13.6466 12.3594 14.1035 12.0682C14.5628 11.7746 15.1097 11.6278 15.7441 11.6278C16.3786 11.6278 16.9255 11.7746 17.3848 12.0682C17.8464 12.3594 18.2015 12.7843 18.4501 13.343C18.6987 13.9017 18.8218 14.5788 18.8194 15.3743C18.8194 16.1745 18.6951 16.8587 18.4465 17.4268C18.198 17.995 17.844 18.4294 17.3848 18.7301C16.9278 19.0284 16.381 19.1776 15.7441 19.1776ZM15.7441 17.7393C16.1229 17.7393 16.4295 17.5464 16.6639 17.1605C16.8982 16.7723 17.0143 16.1768 17.0119 15.3743C17.0119 14.8487 16.9586 14.4155 16.8521 14.0746C16.7456 13.7313 16.5976 13.4756 16.4082 13.3075C16.2188 13.1394 15.9974 13.0554 15.7441 13.0554C15.3677 13.0554 15.0635 13.246 14.8315 13.6271C14.5995 14.0059 14.4823 14.5883 14.4799 15.3743C14.4776 15.907 14.5285 16.3485 14.6326 16.6989C14.7392 17.0492 14.8883 17.3108 15.0801 17.4837C15.2718 17.6541 15.4932 17.7393 15.7441 17.7393Z" fill="black"/> </svg>',
            title: "Heading 0",
            class: "ck-heading_heading0",
          },
          {
            model: "heading1",
            view: "h1",
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: "h2",
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
      plugins: [
        AccessibilityHelp,
        Alignment,
        Autoformat,
        Autosave,
        Bold,
        Essentials,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        Indent,
        IndentBlock,
        Italic,
        List,
        Paragraph,
        PasteFromOffice,
        SelectAll,
        SpecialCharacters,
        Strikethrough,
        TextTransformation,
        Underline,
        Undo,
        ParagraphButtonUI,
        HeadingButtonsUI,
        Heading,
      ],
      fontFamily: {
        supportAllValues: true,
      },
      fontSize: {
        options: [10, 12, 14, "default", 18, 20, 22],
        supportAllValues: true,
      },
      fontColor: {
        format: "hex",
        colorPicker: false,
        colors: [
          {
            color: "#BFEDD2",
            label: "ירוק בהיר",
          },
          {
            color: "#FBEEB8",
            label: "צהוב בהיר",
          },
          {
            color: "#F8CAC6",
            label: "אדום בהיר",
          },
          {
            color: "#ECCAFA",
            label: "סגול בהיר",
          },
          {
            color: "#C2E0F4",
            label: "כחול בהיר",
          },
          {
            color: "#2DC26B",
            label: "ירוק",
          },
          {
            color: "#F1C40F",
            label: "צהוב",
          },
          {
            color: "#E03E2D",
            label: "אדום",
          },
          {
            color: "#B96AD9",
            label: "סגול",
          },
          {
            color: "#3598DB",
            label: "כחול",
          },
          {
            color: "#169179",
            label: "טורקיז",
          },
          {
            color: "#E67E23",
            label: "כתום",
          },
          {
            color: "#BA372A",
            label: "אדום כהה",
          },
          {
            color: "#843FA1",
            label: "סגול כהה",
          },
          {
            color: "#236FA1",
            label: "כחול כהה",
          },
          {
            color: "#ECF0F1",
            label: "אפור בהיר",
          },
          {
            color: "#CED4D9",
            label: "אפור בהיר",
          },
          {
            color: "#95A5A6",
            label: "אפור",
          },
          {
            color: "#7E8C8D",
            label: "אפור כהה",
          },
          {
            color: "#34495E",
            label: "כחול כהה",
          },
          {
            color: "#000000",
            label: "שחור",
          },
          {
            color: "#ffffff",
            label: "לבן",
          },
        ],
      },
      fontBackgroundColor: {
        format: "hex",
        colorPicker: false,
        colors: [
          {
            color: "#BFEDD2",
            label: "ירוק בהיר",
          },
          {
            color: "#FBEEB8",
            label: "צהוב בהיר",
          },
          {
            color: "#F8CAC6",
            label: "אדום בהיר",
          },
          {
            color: "#ECCAFA",
            label: "סגול בהיר",
          },
          {
            color: "#C2E0F4",
            label: "כחול בהיר",
          },
          {
            color: "#2DC26B",
            label: "ירוק",
          },
          {
            color: "#F1C40F",
            label: "צהוב",
          },
          {
            color: "#E03E2D",
            label: "אדום",
          },
          {
            color: "#B96AD9",
            label: "סגול",
          },
          {
            color: "#3598DB",
            label: "כחול",
          },
          {
            color: "#169179",
            label: "טורקיז",
          },
          {
            color: "#E67E23",
            label: "כתום",
          },
          {
            color: "#BA372A",
            label: "אדום כהה",
          },
          {
            color: "#843FA1",
            label: "סגול כהה",
          },
          {
            color: "#236FA1",
            label: "כחול כהה",
          },
          {
            color: "#ECF0F1",
            label: "אפור בהיר",
          },
          {
            color: "#CED4D9",
            label: "אפור בהיר",
          },
          {
            color: "#95A5A6",
            label: "אפור",
          },
          {
            color: "#7E8C8D",
            label: "אפור כהה",
          },
          {
            color: "#34495E",
            label: "כחול כהה",
          },
          {
            color: "#000000",
            label: "שחור",
          },
          {
            color: "#ffffff",
            label: "לבן",
          },
        ],
      },
      editorData: this.modelValue,
      language: "he",
      placeholder: "",
      translations: [translations],
    };

    this.isLayoutReady = true;
  },

  methods: {
    onInput(newValue) {
      if (typeof newValue !== "string") return;
      this.$emit("update:modelValue", newValue);
      this.$emit("on-user-type", newValue);
    },
  },
};
</script>

<style lang="scss">
// @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@media print {
  body {
    margin: 0 !important;
  }
}

.ck.ck-powered-by {
  display: none;
}

.ck.ck-button.ck-on,
.ck.ck-button.ck-on:hover,
.ck.ck-button.ck-on:focus,
.ck.ck-button.ck-on:active {
  --ck-color-button-on-color: var(--ck-color-text);
  --ck-color-button-on-background: #f5f5f5;
}

.ck-editor {
  --ck-focus-ring: 1px solid var(--color-purple);
}
</style>
