<template>
  <div class="r-field r-field--text">
    <label v-if="label" class="r-field-label"> {{ label }} </label>
    <div class="r-field-input">
      <textarea
        v-model="inputText"
        v-bind="$attrs"
        @change="emitChange"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  data() {
    return {
      inputText: this.value,
    };
  },
  watch: {
    inputText(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.inputText = val;
    },
  },
  created() {
    // this.inputText = this.value ? this.value : "";
  },
  methods: {
    updateParentValue(val) {
      this.inputText = val;
    },
    emitChange(event) {
      this.$emit("change", event.target.value);
    },
  },
};
</script>
