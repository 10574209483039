<template>
  <div
    class="app-field app-field--switch"
    style="display: flex; align-items: center; justify-content: space-between"
  >
    <label
      v-if="label"
      class="app-field__label flex items-center"
      style="margin-bottom: 0; margin-left: 5px"
    >
      <span class="mb-1 ml-1">{{ label }}</span>

      <v-tooltip
        v-if="tooltip"
        v-model="showTooltip"
        left
        transition="slide-x-reverse-transition"
        color="grey darken-4"
      >
        <template #activator="{ props }">
          <v-icon color="grey" small v-bind="props" class="tooltip-icon ">
            mdi-help-circle-outline
          </v-icon>
        </template>
        <!-- eslint-disable vue/no-v-html -->
        <span class="font-weight-medium mb-2" v-html="tooltip"></span>
        <!-- eslint-enable -->
      </v-tooltip>
    </label>
    <v-switch
      v-model="inputText"
      hide-details
      density="compact"
      inset
      flat
      class="mt-0 ml-0"
      :ripple="false"
      color="green"
    ></v-switch>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showTooltip: false,
      inputText: false,
    };
  },
  watch: {
    inputText(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.inputText = val;
    },
  },
  created() {
    this.inputText = this.modelValue;
  },
  methods: {
    updateParentValue(val) {
      this.inputText = val;
    },
  },
};
</script>

<style scoped lang="scss">
.tooltip-icon {
  position: relative;
  top: -3px;
  margin-right: 3px;
}
</style>
