<template>
  <rp-container-small>
    <RSimpleCard title="פרויקט חדש">
      <form @submit.prevent="createNewProject">
        <RFields>
          <RFieldText v-model="name" label="שם הפרויקט" required />
          <RFieldText v-model="customer_name" label="שם לקוח" required />
          <RFieldText v-model="address" label="כתובת" required />

          <RButton
            color="primary"
            block
            lg
            type="submit"
            :loading="isLoading"
            :disabled="!isFormValid"
          >
            הוספת פרויקט חדש
          </RButton>
        </RFields>
      </form>
    </RSimpleCard>
  </rp-container-small>
</template>
<script>
import ProjectsApi from "@/api/ProjectsApi";
import { trackEvent } from "@/services/TrackingService";

export default {
  data() {
    return {
      isLoading: false,
      name: null,
      customer_name: null,
      address: null,
    };
  },
  computed: {
    isFormValid() {
      return (
        this.name &&
        this.name.trim() &&
        this.customer_name &&
        this.customer_name.trim() &&
        this.address &&
        this.address.trim()
      );
    },
  },
  methods: {
    trackEvent,
    async createNewProject() {
      this.isLoading = true;

      try {
        let data = await ProjectsApi.createProject({
          name: this.name,
          customer_name: this.customer_name,
          address: this.address,
        });
        this.$toast.success("הפרויקט נוסף בהצלחה");

        trackEvent("Project Action", {
          "Button Name": "Created",
        });
        this.$posthog.capture("project_create:submit");

        this.isLoading = false;
        await this.$router.push({
          name: "projects.edit",
          params: { id: data.id },
        });
      } catch (error) {
        this.$toast.error("שגיאה בהוספת פרויקט");

        this.isLoading = false;
      }
    },
  },
};
</script>
