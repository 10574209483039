<template>
  <span v-if="impersonateData" class="backtoadmin" @click="stopImpersonate">
  </span>
</template>

<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      impersonateData: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {
    this.impersonateData = this.getImpersonateData();
  },
  methods: {
    stopImpersonate() {
      window.axios
        .delete("/api/v1/impersonate2", {
          data: {
            user_id: this.user.id,
            by_user_id: this.impersonateData.impersonate_by_user_id,
          },
        })
        .then(() => {
          this.removeImpersonateData();
          setTimeout(() => {
            window.location.href = this.impersonateData.impersonate_return_url;
          }, 500);
        })
        .catch((err) => {
          this.removeImpersonateData();
          setTimeout(() => {
            window.location.href = this.impersonateData.impersonate_return_url;
          }, 500);
          console.error(err);
        });
    },
    removeImpersonateData() {
      Cookies.remove("impersonate2_token");
      Cookies.remove("impersonate2_by_user_id");
      Cookies.remove("impersonate2_return_url");

      Cookies.remove("impersonate2_token", { domain: ".reporto.co.il" });
      Cookies.remove("impersonate2_by_user_id", { domain: ".reporto.co.il" });
      Cookies.remove("impersonate2_return_url", { domain: ".reporto.co.il" });
    },
    getImpersonateData() {
      if (!Cookies.get("impersonate2_token")) {
        return false;
      }
      return {
        impersonate_token: Cookies.get("impersonate2_token"),
        impersonate_by_user_id: parseInt(
          Cookies.get("impersonate2_by_user_id")
        ),
        impersonate_return_url: Cookies.get("impersonate2_return_url"),
      };
    },
  },
};
</script>

<style scoped>
.backtoadmin {
  cursor: pointer;
  z-index: 9999;
  position: fixed;
  left: 20px;
  bottom: 10px;
  width: 10px;
  height: 10px;
  padding: 30px;
  background: rgba(0, 0, 0, 0.01);
  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.1);
    display: block;
    width: 6px;
    height: 6px;
  }
}
@media (max-width: 767px) {
  .backtoadmin {
    top: 20px;
    bottom: auto;
    padding: 5px;
    right: 90px;
  }
}
</style>
