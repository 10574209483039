<template>
  <div class="r-auth-wrapper">
    <div class="r-auth-inner">
      <div class="r-auth-logo">
        <img src="@/assets/images/reporto-logo.svg" alt="logo" />
      </div>
      <div class="r-auth-title">צור סיסמה חדשה</div>
      <div class="r-auth-text">
        הסיסמה חייבת להכיל לפחות {{ MIN_PASSWORD_LENGTH }} תווים
      </div>

      <template v-if="isLoading">
        <RIconLoading class="mt-6 mb-4" />
      </template>
      <template v-else>
        <div v-if="areOtpCodesValid" class="r-auth-form">
          <form @submit.prevent="submitResetPassword">
            <RFields>
              <div class="r-auth-field-password">
                <RFieldText
                  v-model="password"
                  label="סיסמה חדשה"
                  autocomplete="off"
                  :type="passwordType"
                  placeholder="הסיסמה שלך"
                  :maxlength="MAX_PASSWORD_LENGTH"
                />
                <div
                  class="r-auth-field-password-visible"
                  @click="showPassword = !showPassword"
                >
                  <RIcon v-if="!showPassword">mdi-eye</RIcon>
                  <RIcon v-else>mdi-eye-off</RIcon>
                </div>
              </div>

              <div class="r-auth-field-password">
                <RFieldText
                  v-model="confirmPassword"
                  autocomplete="off"
                  :type="passwordType"
                  label="אישור הסיסמה"
                  placeholder="הסיסמה החדשה שלך שוב"
                  :maxlength="MAX_PASSWORD_LENGTH"
                />
                <div
                  class="r-auth-field-password-visible"
                  @click="showPassword = !showPassword"
                >
                  <RIcon v-if="!showPassword">mdi-eye</RIcon>
                  <RIcon v-else>mdi-eye-off</RIcon>
                </div>
              </div>

              <RAlert
                v-if="
                  password && confirmPassword && password !== confirmPassword
                "
                type="error"
                class="mt-2"
              >
                הסיסמאות אינן תואמות
              </RAlert>

              <RAlert
                v-if="
                  password &&
                  confirmPassword &&
                  password === confirmPassword &&
                  password.length < MIN_PASSWORD_LENGTH
                "
                type="error"
                class="mt-2"
              >
                אורך הסיסמה חייב להיות לפחות {{ MIN_PASSWORD_LENGTH }} תווים
              </RAlert>

              <RButton
                lg
                :disabled="!canSubmitForm"
                :loading="isSubmitting"
                color="primary"
                block
                type="submit"
              >
                איפוס סיסמה
              </RButton>
            </RFields>
          </form>
        </div>

        <RAlert v-if="!areOtpCodesValid" type="error" class="mt-6">
          <b> תוקף בקשת איפוס הסיסמה פג</b>
          לחץ על הכפתור למטה והתחל את תהליך האיפוס שוב.
        </RAlert>

        <RouterLink class="r-auth-back-button mt-4" :to="{ name: 'login' }">
          <span>חזרה למסך התחברות</span>
          <RIcon>mdi-arrow-left</RIcon>
        </RouterLink>
      </template>
    </div>
  </div>
</template>

<script>
import AuthApi from "@/api/AuthApi";

export default {
  data() {
    return {
      MIN_PASSWORD_LENGTH: 8,
      MAX_PASSWORD_LENGTH: 20,
      otpKey: null,
      otpCode: null,
      showPassword: false,
      password: null,
      confirmPassword: null,
      isSubmitting: false,
      isLoading: true,
      showOtpExpiredError: false,
    };
  },
  computed: {
    areOtpCodesValid() {
      return this.otpKey && this.otpCode && !this.showOtpExpiredError;
    },
    passwordType() {
      return this.showPassword ? "text" : "password";
    },
    canSubmitForm() {
      return (
        this.password &&
        this.confirmPassword &&
        this.password.length >= this.MIN_PASSWORD_LENGTH &&
        this.password === this.confirmPassword
      );
    },
  },
  async created() {
    this.otpKey = window.localStorage.getItem("otp_key");
    this.otpCode = window.localStorage.getItem("otp_code");
    try {
      await AuthApi.validateOtp({
        otpKey: this.otpKey,
        otpCode: this.otpCode,
      });
    } catch (e) {
      this.showOtpExpiredError = true;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async submitResetPassword() {
      try {
        this.isSubmitting = true;
        let response = await AuthApi.resetPasswordWithOtp({
          otpKey: this.otpKey,
          otpCode: this.otpCode,
          password: this.password,
        });

        if (response.success) {
          this.$toast.success("הסיסמא אופסה בהצלחה");
          this.$router.push({ name: "reset-password-success" });
        }
      } catch (e) {
        if (e.response?.data?.message === "שגיאה קוד לא נכון") {
          this.showOtpExpiredError = true;
        } else {
          this.$toast.error(this.$Utils.toastMessageFromResponse(e));
        }
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
