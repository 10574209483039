<template>
  <div v-if="isLoaded">
    <div class="r-page-table">
      <div class="r-page-table-header">
        <div class="r-page-table-header-content">
          <h3 class="r-page-table-header-title">
            🗄️ מאגרים
            <span v-if="stocks && stocks.length">({{ stocks.length }})</span>
          </h3>
        </div>
        <div class="r-page-table-header-actions">
          <RSearchCompact v-model="searchQuery" :initial-open="true" />
          <RButton
            color="purple"
            :to="{
              name: 'stocks.add',
            }"
          >
            מאגר חדש
          </RButton>
        </div>
      </div>

      <div class="r-page-table-body">
        <v-data-table
          v-if="isLoaded && stocks.length"
          :headers="headers"
          :items="stocks"
          :hide-default-footer="true"
          :items-per-page="-1"
          :search="searchQuery"
          no-results-text="לא נמצאו תוצאות לחיפוש"
          class="elevation-0 stocks-table"
          mobile-breakpoint="0"
        >
          <template #[`item.name`]="{ item }">
            <router-link
              :to="{
                name: 'stocks.edit',
                params: { id: item.id },
              }"
              class="text--primary"
              style="text-decoration: underline; text-underline-position: under"
            >
              {{ item.name }}
            </router-link>
          </template>
          <template #[`item.categories`]="{ item }">
            {{ StockUtils.getStockCategories(item).length }}
          </template>
          <template #[`item.nodes`]="{ item }">
            {{ StockUtils.getStockNodes(item).length }}
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ $Utils.presentHebrewDate(item.created_at) }}
          </template>
          <template #[`item.actions`]="{ item }">
            <RActions>
              <RAction
                :to="{
                  name: 'stocks.edit',
                  params: { id: item.id },
                }"
                icon="mdi-pencil"
                text="עריכה"
              ></RAction>
              <RAction
                icon="mdi-content-copy"
                text="שכפול"
                @click="duplicateStock(item.id)"
              ></RAction>
            </RActions>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
  <page-loader v-else />
</template>

<script>
import { mapGetters } from "vuex";
import { StockUtils } from "@/common/Utils";
import PageLoader from "@/components/PageLoader.vue";
import StocksApi from "@/api/StocksApi";

export default {
  components: { PageLoader },
  data() {
    return {
      searchQuery: null,
      StockUtils,
      isLoaded: false,
    };
  },
  computed: {
    ...mapGetters("stocks", ["stocks"]),
    headers() {
      let headers = [];

      headers.push({
        title: "שם",
        value: "name",
        sortable: true,
      });
      
      headers.push({
        title: "קטגוריות",
        sortable: true,
        value: "categories",
      });

      headers.push({
        title: "ממצאים",
        value: "nodes",
        sortable: true,
      });

      if (window.innerWidth > 767) {
        headers.push({
          title: "תאריך יצירה",
          value: "created_at",
          width: "180px",
          sortable: true,
        });
      }

      headers.push({
        title: "פעולות",
        value: "actions",
        width: "80px",
        sortable: false,
      });

      return headers;
    },
  },
  async created() {
    await this.$store.dispatch("stocks/fetchStocks");
    this.isLoaded = true;
  },
  methods: {
    async duplicateStock(stockIdToDuplicate) {
      if (confirm("בטוח לשכפל את המאגר?")) {
        let loader = this.$loading.show();

        await StocksApi.duplicateStock(stockIdToDuplicate);
        await this.$store.dispatch("stocks/fetchStocks");

        this.$toast.success("המאגר שוכפל בהצלחה");

        loader.hide();
      }
    },
  },
};
</script>

<style lang="scss">
.stocks-table {
  th:nth-child(1) {
    min-width: 220px;
  }
  th:nth-child(2) {
    min-width: 110px;
  }
  th:nth-child(3) {
    min-width: 120px;
  }
  th:nth-child(4) {
    min-width: 180px;
  }
}
</style>
